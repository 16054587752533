<template>
  <el-row class="circle-with-percentages" ref="circleWithPercentages">
      <el-col
          class="circle"
          :class="{dark: darkMode}"
          :style="{fontSize: size+'px'}"
      >
        <span :style="{'color': color}">{{ percent }}%
          <div class="title">{{ title }}</div>
        </span>
        <el-row
            class="slice"
            :style="dynamicStylesSlice"
        >
          <el-row
              class="bar"
              :style="dynamicStylesBar"
          ></el-row>
          <el-row
              class="fill"
              :style="dynamicStylesFill"
          ></el-row>
        </el-row>
      </el-col>

  </el-row>

</template>

<script>
export default {
  name: "circle-with-percentages",
  props: {
    title: {
      type: String,
    },
    percent: {
      type: Number,
      default: 10
    },
    color: {
      type: String,
      default: 'green'
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 50
    }
  },

  data() {
    return {
      // ro: null,
      dynamicClassesString:'',
      dynamicStylesBar: {
        position: 'absolute',
        border: '0.1em solid ' + this.color,
        width: '0.8em',
        height: '0.8em',
        clip: 'rect(0em, 0.5em, 1em, 0em)',
        'border-radius': '50%',
        transform: 'rotate(0deg)'
      },
      dynamicStylesSlice: {},
      dynamicStylesFill: {
        border: '0.1em solid ' + this.color,
        width: '0.8em',
        height: '0.8em',
        clip: 'rect(0em, 0.5em, 1em, 0em)',
        'border-radius': '50%',
        transform: 'rotate(0deg)'
      }
    }
  },
  mounted() {
    this.init();
    // this.ro = new ResizeObserver(this.onResize)
    // this.ro.observe(this.$refs.circleWithPercentages)
    // console.log(this.$refs.circleWithPercentages)

  },
  // beforeDestroy () {
  //   this.ro.unobserve(this.$refs.myElement)
  // },
  methods: {
    // onResize () {
    //   debugger
    //   this.$emit('resize', this.$refs.circleWithPercentages.offsetHeight)
    // },
    getComputedPercent() {
      return '+2%';
    },
    init(){
      let deg = 360 / 100 * this.percent;

      this.dynamicStylesBar = {
        transform: 'rotate(' + deg + 'deg)',
        border: '0.1em solid ' + this.color,
        clip: 'rect(0em, 0.5em, 1em, 0em)'
      }
      if(this.percent > 50) {
        this.dynamicStylesFill.transform = 'rotate(180deg)'
        this.dynamicStylesSlice.clip = 'rect(auto, auto, auto, auto)'
      }
    },
    scale() {
      console.log('scale')
      return true;
    }
  }
}

</script>

<style lang="scss" scoped>
.title {
  position: absolute;
  top: 25px;
  font-size: 0.7em;
  text-align: center;
  width: 100%;
  transition: all 0.1s ease-out;
}
.circle:hover span .title {
  opacity: 0;
}
.circle .bar,
.circle .fill {
  position: absolute;
  width: 0.8em;
  height: 0.8em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  transform: rotate(0deg);
}

.circle {
  position: relative;
  font-size: 120px;
  width: 1.003em;
  height: 1.003em;
  left: -0.07px;
  border-radius: 50%;
  margin: 0 0.1em 0.1em 0;
  background-color: #ededed;
}
.circle *,
.circle *:before,
.circle *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.circle > span {
  position: absolute;
  left: 0.05em;
  font-weight: 500;
  z-index: 1;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #cccccc;
  display: block;
  text-align: center;
  white-space: nowrap;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.circle:after {
  position: absolute;
  top: 0.1em;
  left: 0.1em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: #f5f5f5;
  width: 0.8em;
  height: 0.8em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}
.circle .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.circle:hover {
  cursor: default;
}
.circle:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
}
.circle:hover:after {
  top: 0.08em;
  left: 0.08em;
  width: 0.84em;
  height: 0.84em;
}
.circle.dark {
  background-color: #777777;
}
.circle.dark > span {
  color: #777777;
}
.circle.dark:after {
  background-color: #666666;
}



</style>