import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getVATSConfig(payload){
            return requestSender('get', 'integration/get-vats-config', payload);
        },
        getVATSPhones(payload){
            return requestSender('get', 'integration/get-vats-phones', payload);
        },
        getCategoryWithPhone(payload){
            return requestSender('get', 'integration/get-categories-with-phones', payload);
        },

    }
}