<template>
  <el-row>
    <h4>География клиентов</h4>
    <p>за последние 24 часа</p>
    <el-row>
      <el-row class="mt15">
        <el-col :span="8">
            <circular-progress-bar
              :color="'rgb(127, 165, 220)'"
              :percent="30"
            />
        </el-col>
        <el-col :span="16">
          <el-row class="description">
            <el-row>Мобильные тел.</el-row>
            <el-row type="flex">
              <el-col :span="6">76</el-col>
              <el-col
                  :span="18"
                  style="font-size: 10px; margin-top: 5px;"
                  :style="{color:'red'}"
              >+5</el-col>
            </el-row>
          </el-row>
        </el-col>

      </el-row>
      <el-row class="mt15">
        <el-col :span="8">
          <circular-progress-bar
              :color="'rgb(117, 213, 138)'"
              :percent="59"
          />
        </el-col>
        <el-col :span="16">
          <el-row class="description">
            <el-row style="color: #b5b5b5; font-size: 14px;">Звонки из г. Тула</el-row>
            <el-row type="flex">
              <el-col :span="6">30</el-col>
              <el-col
                  :span="18"
                  style="font-size: 10px; margin-top: 5px;"
                  :style="{color:'green'}"
              >+2</el-col>
            </el-row>
          </el-row>
        </el-col>
      </el-row>
      <el-row  class="mt15">
        <el-col :span="8">
          <circular-progress-bar
              :color="'rgb(240, 190, 87)'"
              :percent="11"
          />
        </el-col>
        <el-col :span="16">
          <el-row class="description">
            <el-row style="color: #b5b5b5; font-size: 14px;">Звонки из г. Москва</el-row>
            <el-row type="flex">
              <el-col :span="6">50</el-col>
              <el-col
                  :span="18"
                  style="font-size: 10px; margin-top: 5px;"
                  :style="{color:'green'}"
              >+2</el-col>
            </el-row>
          </el-row>
        </el-col>

      </el-row>
      <el-row type="flex" class="mt15">
        <el-col :span="8">
          <circular-progress-bar
              :color="'rgb(60, 26, 149)'"
              :percent="84"
          />
        </el-col>
        <el-col :span="16">
          <el-row class="description">
            <el-row>Звонки из г. Калуга</el-row>
            <el-row type="flex">
              <el-col :span="6">70</el-col>
              <el-col
                  :span="18"
                  style="font-size: 11px; margin-top: 5px;"
                  :style="{color:'red'}"
              >+2</el-col>
            </el-row>
          </el-row>
        </el-col>
      </el-row>



    </el-row>
  </el-row>
</template>

<script>
import CircleWithPercentages from "@/components/common/CircleWithPercentages";
import CircularProgressBar from "@/components/common/CircularProgressBar";
export default {
  name: "customer-geography",
  components: {CircularProgressBar}
}
</script>

<style lang="scss" scoped>
.el-row {
  > h4 {
    white-space: nowrap;
    margin-bottom: 0;
  }
  > p {
    color: #d5d5d5;
    font-size: 12px;
  }
  .mt15 {
    margin-top: 10px;
    .description {
      margin-left: 15px;
      > .el-row {
        &:first-child {
          color: #b5b5b5;
          font-size: 14px;
        }
        > .el-col {
          &:first-child {
            font-size: 14px;
            font-weight: 900;
            color: #3c3d44;
          }
        }
      }
    }
  }
}

</style>