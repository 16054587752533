<template>
  <div class="edit-organization" v-loading="loading">
    <div class="collapse-title-container">
      <div class="collapse-title">
        <p>Организация</p>
        <p>ID</p>
        <p>Тарифный план</p>
        <p>Остаток минут</p>
        <p>Статус</p>
      </div>
      <div class="collapse-content">
        <p>{{ organization.name }} <i class="el-icon-edit" @click="formDrawer = true" @click.stop.stopPropagation></i></p>
        <p>{{organization.id}}</p>
        <p v-if="organization.tp">{{organization.tp.name}}</p>
        <p v-if="organization.remaining_minutes">{{organization.remaining_minutes[0].minutes }}</p>
        <p v-else>-</p>
        <p v-if="organization.status">{{organization.status.name }}</p>
      </div>
    </div>
    <div class="calls-info" v-if="organization.call_info">
      <div class="calls-info__title">
        <p>Всего</p>
        <p>Входящих</p>
        <p>Исходящих</p>
        <p>Распознано</p>
        <p>Распознано входящих</p>
        <p>Распознано исходящих</p>
      </div>
      <div class="calls-info__content">
        <p>{{ organization.call_info.count_calls }}</p>
        <p>{{ organization.call_info.count_in_calls }}</p>
        <p>{{ organization.call_info.count_out_calls }}</p>
        <p>{{ organization.call_info.count_recognized_calls }}</p>
        <p>{{ organization.call_info.count_recognized_in_calls }}</p>
        <p>{{ organization.call_info.count_recognized_out_calls }}</p>
      </div>

    </div>
    <div class="users">
      <div class="users__header">
        <h2>Пользователи</h2>
        <div
            class="new-user"
            @click="addNewUser(organization.id)"
        >
          <span><i class="el-icon-plus"></i>Добавить</span>
        </div>
      </div>

      <div class="users__container">
        <div
            v-if="prepareLocations"
            v-for="user in organization.users"
            :key="organization.id + '-' + user.id"
        >
          <user-edit
              :user="user"
              :roles="roles"
              :organization-id="organization.id"
              :prepare-locations="prepareLocations"
              @update="getOrganization"
          />
        </div>
      </div>
    </div>
  </div>

<!--  <el-row>-->
<!--    <el-row class="organization">-->
<!--      <organization-edit-->
<!--          :organization="organization"-->
<!--          :tariff-plans="tariffPlans"-->
<!--          @update="getOrganization()"-->
<!--      />-->
<!--    </el-row>-->

<!--    <el-row class="users">-->
<!--      <h2>Пользователи</h2>-->
<!--      <div>-->
<!--        <div-->
<!--            v-for="user in organization.users"-->
<!--            :key="organization.id + '-' + user.id"-->
<!--        >-->
<!--          <user-edit-->
<!--              :user="user"-->
<!--              :roles="roles"-->
<!--              :organization-id="organization.id"-->
<!--              @update="getOrganization"-->
<!--          />-->
<!--        </div>-->
<!--        <div-->
<!--            class="new-user"-->
<!--            @click="addNewUser"-->
<!--        >-->
<!--          <span>-->
<!--            <i class="el-icon-plus"></i>-->
<!--            Добавить-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->


<!--    </el-row>-->

<!--  </el-row>-->

</template>

<script>

import OrganizationEdit from "@/components/management/OrganizationEdit.vue";
import UserEdit from "@/components/management/UserEdit.vue";

export default {
  name: "EditOrganization",
  components: {UserEdit, OrganizationEdit},
  data() {
    return {
      organization: {},
      tariffPlans: [],
      roles:[],
      prepareLocations: [],
      loading: false,
    }
  },
  mounted() {
    this.getOrganization()
    this.getTariffPlans()
    this.getAvailableRoles()
  },
  methods: {
    getOrganization() {
      this.loading = true
      this.$api.management.getOne({
        id: this.$route.params.id
      })
          .then(data => {
            this.organization = data;
            this.loading = false
          })
    },
    getTariffPlans() {
      this.$api.management.getTariffPlans()
          .then(data => {
            this.tariffPlans = data;
          })
    },
    getAvailableRoles() {
      if(!this.$store.getters.user.id) {
        setTimeout(() => {
          this.getAvailableRoles()
        }, 300)
      }else {
        this.$api.management.getAvailableRoles(
            {id: this.$store.getters.user.id}
        )
            .then(data => {
              this.roles = data;
            })
      }
    },
    saveOrganizationData() {

    },
    getPrepareLocations(locations) {
      this.prepareLocations = [];
      this.prepareLocations = locations.map(location => {
        return {
          id: location.id,
          label: location.name,
          children: location.sub_locations.map(subLocation => {
            return {
              id: subLocation.id,
              label: subLocation.name,
              children: subLocation.categories.map(category => {
                return {category_id: category.id, label: category.name}
              })
            }
          })
        }
      })
    },
    saveUserData(id) {

    },
    addNewUser(){
      this.organization.users.push({
        id: null,
        login: null,
        first_name: null,
        last_name: null,
        patronym: null,
        email: null,
        status: 1,
        role: {
          name: 'user'
        },
        categories: []

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-organization {
  .collapse-title-container {
    background: #fff;
    margin: 30px 20px 0 20px;
    padding: 10px 20px;
    border: 1px solid #eee;
    .collapse {
      &-title, &-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        > p {
          text-align: center;
          &:first-child {
            text-align: left;
          }
        }
      }
    }
    p {
      margin-bottom: 0;
      line-height: 1;
    }
    .collapse {
      background: #fff;

      &-title {
        margin-top: 5px;
        p {
          font-size: 10px;
        }
      }
      &-content {
        margin: 5px 0 5px 0;
        p {
          color: #555555;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.6;
        }
      }
    }


  }
  .calls-info {
    margin: 10px 20px;
    background: #fff;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #eee;
    &__title, &__content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      p {
        color: #555555;
        margin-bottom: 0;
        text-align: center;
      }
    }
    &__title {
      p {
        margin-bottom: 10px;
        font-size: 10px;
      }

    }
    &__content {
      p {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  .users {
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 5px 20px 0 20px;
      h2 {
        margin-top: 10px;
        font-weight: bold;
        margin-left: 20px;
      }
      .new-user {
        font-size: 10px;
        border: 1px solid #555555;
        color: #555555;
        border-radius: 4px;
        padding: 2px 10px;
        cursor: pointer;
      }
    }
    h2 {
      margin: 0;
      font-size: 12px;
      color: #555555;
    }

    &__container {
      margin: 0 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .user {
        background: #fff;
        margin: 10px;
        padding: 10px;
        border-radius: 4px;
      }
    }
  }
}

</style>