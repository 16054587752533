<template>
  <li class="item" :style="{pointerEvents:loaded?'unset':'none'}" @click="$emit('modal')">
    <div class="title cont">
      <p v-if="propsUniqueDataItem.type==='name'">фио агента</p>
      <p v-else>Номер агента</p>
      {{ propsUniqueDataItem.title }}
    </div>
    <div class="days cont">
      <p>В расчёте</p>
      <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
      <span v-if="currentItemRating">*** дней</span>
    </div>
    <div class="calls-all cont">
      <p>Количество звонков(всего)</p>
      <div class="row">
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span v-if="currentItemRating">{{computedCountCalls}} вх</span>
        <span class="separator"></span>
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span v-if="currentItemRating">{{computedCountCalls}} исх</span>
      </div>
    </div>
    <div class="calls-day cont">
      <p>Количество звонков(день)</p>
      <div class="row">
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span v-if="currentItemRating">*** вх </span>
        <span class="separator"></span>
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span v-if="currentItemRating">*** исх</span>
      </div>
    </div>
    <div class="rate cont">
      <p>% на смене</p>
      <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
      <span v-if="currentItemRating">*** %</span>
    </div>
    <div class="talk-time cont">
      <p>Общее время коммуникаций(вх/исх)</p>
      <div class="row">
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span v-if="currentItemRating">*** мин (вх)</span>
        <span class="separator"></span>
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span v-if="currentItemRating">*** мин (исх)</span>
      </div>
    </div>
    <div class="days cont" style="display: grid">
      <p>Общее время коммуникаций</p>
      <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
      <span style="text-align: center;width: 100%; justify-self: center" v-if="currentItemRating">{{ computedFormatSecondsToTime }}</span>
    </div>
    <div class="positive-weight cont">
      <p>Количество позитива</p>
      <div class="row">
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span v-if="currentItemRating"> <i>{{ currentItemRating ? currentItemRating.average_positive_on_minutes : '0' }}</i> на 100 мин</span>
        <span class="separator"></span>
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span v-if="currentItemRating"><i>{{ currentItemRating ? currentItemRating.total_positive_weight : '0' }}</i> общ</span>
      </div>
    </div>
    <div class="negative-weight cont">
      <p>Количество негатива</p>
      <div class="row">
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span v-if="currentItemRating"><i>{{ currentItemRating ? currentItemRating.average_negative_on_minutes : '0' }}</i> на 100 мин</span>
        <span class="separator"></span>
        <my-spinner v-if="!currentItemRating" :size="15" class="spinner-btn"/>
        <span  v-if="currentItemRating"><i>{{ currentItemRating ? currentItemRating.total_negative_weight : '0' }}</i> общ</span>
      </div>
    </div>
  </li>
</template>

<script>
import MySpinner from "@/components/UI/MySpinner.vue";

export default {
  name: "userRatingListItem",
  components: {MySpinner},
  props: {
    propsIndex: {
      type: Number
    },
    propsUniqueDataItem: {
      type: Object
    },
    propsStartEndDateForCallListAndCategoryDictionaries: {
      type: Object
    },
    propsSelectedCategories: {
      type: Array
    },
    propsGetCurrentItemRating: {
      type: Function
    },
    propsChangePendingRequests:{
      type:Function
    }
  },
  data() {
    return {
      loaded: false,
      currentItemRating: null
    };
  },
  mounted() {
    this.getRatingData()
  },
  watch: {
    currentItemRating() {
      let currentDataAndIndex = {...this.currentItemRating, index: this.propsIndex}
      this.propsGetCurrentItemRating(currentDataAndIndex)
    }
  },
  computed: {
    computedFormatSecondsToTime() {
      if (this.currentItemRating) {
        if (this.currentItemRating.full_call_time_seconds) {
          let seconds = this.currentItemRating.full_call_time_seconds
          let hours = String(Math.floor(seconds / 3600)).length === 1 ? '0' + Math.floor(seconds / 3600) : Math.floor(seconds / 3600);
          let minutes = String(Math.floor((seconds - (hours * 3600)) / 60)).length === 1
              ? '0' + Math.floor((seconds - (hours * 3600)) / 60)
              : Math.floor((seconds - (hours * 3600)) / 60)
          let remainingSeconds = String(seconds - (hours * 3600) - (minutes * 60)).length === 1
              ? ('0') + (seconds - (hours * 3600) - (minutes * 60))
              : seconds - (hours * 3600) - (minutes * 60)

          return `${hours}:${minutes}:${remainingSeconds}`
        }
      }
      return '00:00:00'
    },
    computedConvertSecondsToMinutes() {
      if (this.currentItemRating) {
        if (this.currentItemRating.full_call_time_seconds) {
          const seconds = this.currentItemRating.full_call_time_seconds
          return Math.round(this.currentItemRating.full_call_time_seconds / 60)
        }
        return 0
      }
      return 0
    },
    computedCountCalls() {
      if (this.currentItemRating) {
        if (this.currentItemRating['calls'].length) {
          return this.currentItemRating['calls'].length
        }
        return 0
      }
      return 0
    }
  },
  methods: {
    getRatingData() {
      if (this.propsUniqueDataItem.type === 'name') {
        this.$api.analysis.getEmployeeRating({
          agent_name: this.propsUniqueDataItem.title,
          categories: this.propsUniqueDataItem.categories,
          date_start: this.propsStartEndDateForCallListAndCategoryDictionaries.start ? this.propsStartEndDateForCallListAndCategoryDictionaries.start : '',
          date_end: this.propsStartEndDateForCallListAndCategoryDictionaries.end ? this.propsStartEndDateForCallListAndCategoryDictionaries.end : '',
        }).then(data => {
          this.currentItemRating = data
          this.loaded = true
          this.propsChangePendingRequests()
        })
      } else {
        this.$api.analysis.getPhoneNumberRating({
          phone_number: this.propsUniqueDataItem.title,
          date_start: this.propsStartEndDateForCallListAndCategoryDictionaries.start ? this.propsStartEndDateForCallListAndCategoryDictionaries.start : '',
          date_end: this.propsStartEndDateForCallListAndCategoryDictionaries.end ? this.propsStartEndDateForCallListAndCategoryDictionaries.end : '',
        }).then(data => {
          this.currentItemRating = data
          this.loaded = true
          this.propsChangePendingRequests()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  margin: 7px 0;
  background: #f4f7fd;
  box-shadow: 1px 1px 2px 1px #f4f7fd;
  border-radius: 4px;
  cursor: pointer;
  border-bottom: solid 1px #f4f7fd;
  transition: .3s;
  overflow: hidden;
  display: grid;
  grid-template-columns: 230px 70px 140px 140px 70px 200px 130px 170px 170px;

  div {
    position: relative;
  }

  &:hover {
    border-color: grey;
  }

  .cont {
    position: relative;
    font-size: 10px;
    font-family: inherit;
    font-weight: normal;
    color: #555;
    padding: 15px 10px 5px;

    p {
      font-size: 8px;
      margin: 0;
      position: absolute;
      top: 2px;
      white-space: nowrap;

    }

    .row {
      display: flex;
      gap: 5px;
    }

  }

  .calls-all, .calls-day {
    p {
      width: 100%;
      margin-left: 10px;
    }

    .row {
      display: grid;
      grid-template-columns: 55px 1px 55px;

      span:first-child {
        justify-self: flex-end;
      }

      span:last-child {
        justify-self: flex-start;
      }
    }
  }

  .talk-time {
    p {
      width: 100%;
      margin-left: 10px;
    }

    .row {
      display: grid;
      grid-template-columns: 87px 1px 87px;

      span:first-child {
        justify-self: flex-end;
      }

      span:last-child {
        justify-self: flex-start;
      }
    }
  }

  .positive-weight, .negative-weight {
    p {
      width: 100%;
      margin-left: 5px;
    }

    .row {
      display: grid;
      grid-template-columns: 72px 1px 72px;

      span:first-child {
        justify-self: flex-end;
      }

      span:last-child {
        justify-self: flex-start;
      }
    }
  }

  .rate {
    text-align: center;
  }

  .negative-weight {
    i {
      color: red;
      font-style: normal;
    }
  }

  .positive-weight {
    i {
      color: green;
      font-style: normal;
    }
  }
}

.separator {
  display: inline-block;
  font-size: 12px;
  width: 1px;
  height: 15px;
  background: #bebebe;
}

</style>