<template>
  <el-row type="flex" justify="center">
    <el-col
        class="location-form-container"
        :span="22"
    >
      <el-row>
        <el-row v-if="selectedCategory.id">
          <h4>Редактирование категории {{ selectedCategory.name }}</h4>
        </el-row>

        <el-row v-else>
          <h4>Добавление категории</h4>
        </el-row>
        <el-form>
          <el-row class="form-fields">

            <el-row>
              <el-col :span="6">Название</el-col>
              <el-col :span="18">
                <el-input
                    type="text"
                    placeholder="Введите название"
                    v-model="selectedCategory.name"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">Описание</el-col>
              <el-col :span="18">
                <el-input
                    type="textarea"
                    :rows="3"
                    placeholder="Введите описание"
                    required
                    v-model="selectedCategory.description">
                </el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">Канал 1</el-col>
              <el-col :span="18">
                <el-input
                    type="text"
                    placeholder="Введите наименование канала 1"
                    v-model="selectedCategory.channel_one_name"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">Канал 2</el-col>
              <el-col :span="18">
                <el-input
                    type="text"
                    placeholder="Введите наименование канала 2"
                    v-model="selectedCategory.channel_two_name"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">Минимальный лимит</el-col>
              <el-col :span="18">
                <el-input
                    type="number"
                    placeholder="Введите минимальный лимит"
                    v-model="selectedCategory.trigger_limit_min"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">Максимальный милит</el-col>
              <el-col :span="18">
                <el-input
                    type="number"
                    placeholder="Введите максимальный милит"
                    v-model="selectedCategory.trigger_limit_max"
                ></el-input>
              </el-col>
            </el-row>


          </el-row>

        </el-form>
      </el-row>
      <el-row class="command-buttons">
        <el-button
            plain
            round
            @click="cancel"
        >
          Отмена
        </el-button>

        <el-button
            type="primary"
            round
            @click="save"
        >
          Сохранить
        </el-button>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "location-form",
  props: {
    selectedCategory: {},
    subLocationId: {},
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.$emit('save-category', this.selectedCategory)
    }
  }
}
</script>

<style lang="scss" scoped>

.location-form-container {
  h4 {
    text-align: center;
  }

  .form-fields {
    > .el-row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      > .el-col {
        &:first-child {
          padding-right: 10px;
          text-align: right;
        }
      }
    }
  }

  .command-buttons {
    text-align: center;
    margin-bottom: 25px;
  }
}

</style>