<template>
  <el-row v-loading="loading">
    <question-menu
        @add="showQuestionForm(null)"
    />
    <client-question
        :questions="questions"
        @show="showQuestionInformationForm"
        @edit="showQuestionForm"
        @delete="deleteQuestion"
    >
    </client-question>

    <el-drawer
        :visible.sync="visibleQuestionDrawer"
        direction="rtl"
        size="50%"
    >
      <question-form
          :selected-question="selectedQuestion"
          @cancel="visibleQuestionDrawer = false"
          @save="save"
      >
      </question-form>
    </el-drawer>
    <el-drawer
        :visible.sync="visibleQuestionInformationDrawer"
        direction="rtl"
        size="50%"
    >
      <question-information-form
          :selected-question="selectedQuestion"
          @cancel="visibleQuestionInformationDrawer = false"
          @save="save"
      >
      </question-information-form>

    </el-drawer>


  </el-row>
</template>

<script>

import ClientQuestion from "@/components/question/ClientQuestion";
import QuestionForm from "@/components/question/QuestionForm";
import QuestionInformationForm from "@/components/question/QuestionInformationForm";
import QuestionMenu from "@/components/question/QuestionMenu";

export default {
  name: 'Question',

  components: {QuestionMenu, QuestionForm, QuestionInformationForm, ClientQuestion},

  data() {
    return {
      loading: false,
      questions: null,
      visibleQuestionInformationDrawer: false,
      visibleQuestionDrawer: false,
      selectedQuestion: {},
      selectedQuestionType: null,
    }
  },
  computed: {},

  mounted() {
    this.getQuestions();
  },
  methods: {
    getQuestions() {
      this.loading = true;
      this.$api.question.get()
          .then(data => {
            this.questions = data

          })
          .finally(() => {
            this.loading = false;
          })
    },
    showQuestionForm(data) {
      if (data) {
        this.selectedQuestion = this.questions.find(question => question.id === data.id);
      }else {
        this.selectedQuestion = {};
      }

      this.visibleQuestionDrawer = true;

    },
    showQuestionInformationForm(data) {

      this.selectedQuestion = this.questions.find(question => question.id === data.id);

      this.visibleQuestionInformationDrawer = true;
    },
    save() {

      this.loading = true;
      if (!this.selectedQuestion.name) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Поля Имя обязательно для заполнения',
        });
        return;
      }

      this.$api.question.save(this.selectedQuestion)
          .then(data => {

            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Вопрос сохранен',
              });
              this.reset();
              this.getQuestions();

            } else {

              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors[0]
              });

            }

          })
          .finally(() => {
            this.loading = false;
          })
    },
    deleteQuestion(data) {
      this.loading = true;
      this.$api.question.delete({id:data.id, type: data.type})
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Вопрос удален',
            });
            this.getQuestions()
          })
          .finally(() => {
            this.loading = false;
          })
    },
    reset() {
      this.visibleQuestionInformationDrawer = false;
      this.visibleQuestionDrawer = false;
      this.selectedQuestion = {};
    }
  }
}

</script>

<style lang="scss" scoped>



</style>
