<template>
  <div>
    <el-dialog title="" :visible.sync="localModalState" @close="$emit('closeModal')">
      <div class="add-category">
        <div class="add-category__headers">
          <h2>Локации</h2>
          <h2>Под локации</h2>
          <h2>Категории</h2>
        </div>
        <div v-if="dataList.length" class="add-category__wrapper">
          <div class="add-category__locations">

            <ul>
              <li
                  v-for="location in dataList"
                  :key="location.id"
                  :class="activeLocation===location.id?'active':''"
                  @click="()=>setActiveLocation(location.id)"
              >
                {{ location.label }}
              </li>
            </ul>
            <locations-inputs-control :propsSetNameForDelete="setNameForDelete"
                                      :activeLocation="activeLocation"
                                      :getLocations="getLocations"
                                      :propsChangeInputShow="changeInputShow"
                                      :propsInputsShow="inputsShow"
                                      @specific_event="setTypeAndVariantInputsControl"
                                      variant="location"
                                      :nameForDelete="setNameForDelete"

            />
          </div>
          <div class="add-category__sub-locations">
            <ul>

              <li
                  v-if="computedActualSubLocationsList[0].children.length"
                  v-for="subLocation in computedActualSubLocationsList[0].children"
                  :key="subLocation.id"
                  :class="activeSubLocation===subLocation.id?'active':''"
                  @click="()=>setActiveSubLocation(subLocation.id)"
              >
                {{ subLocation.label }}
              </li>
            </ul>
            <locations-inputs-control :propsSetNameForDelete="setNameForDelete"
                                      :propsChangeInputShow="changeInputShow"
                                      :activeLocation="activeLocation"
                                      :propsInputsShow="inputsShow"
                                      @specific_event="setTypeAndVariantInputsControl"
                                      variant="sub-location"
                                      :getLocations="getLocations"

            />
          </div>
          <div class="add-category__category">
            <ul>
              <li
                  v-for="category in computedActualCategoryList"
                  :key="category.id"
                  :class="activeCategory===category.id?'active':''"
                  @click="()=>setActiveCategory(category.id)"
              >
                {{ category.label }}
              </li>
            </ul>
            <locations-inputs-control  :activeSubLocation="activeSubLocation"
                                       :propsSetNameForDelete="setNameForDelete"
                                      :propsChangeInputShow="changeInputShow"
                                      :propsInputsShow="inputsShow"
                                      @specific_event="setTypeAndVariantInputsControl"
                                      variant="category"
                                      :getLocations="getLocations"

            />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import LocationsInputs from "@/components/newLocations/LocationsManagement/locationsInputsControl.vue";
import LocationsInputsControl from "@/components/newLocations/LocationsManagement/locationsInputsControl.vue";

export default {
  name: "location-management",
  components: {LocationsInputsControl, LocationsInputs},
  props: {
    modalState: {}
  },
  data() {
    return {
      variant: '',
      selectedTypeInputsControl: '',
      selectedVariantInputsControl: '',
      localModalState: false,
      dataList: [],
      activeCategory: null,
      activeLocation: null,
      activeSubLocation: null,
      inputsShow: false,
    }
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    changeInputShow() {
      this.inputsShow = false
    },
    setTypeAndVariantInputsControl(value) {
      this.selectedTypeInputsControl = value.type
      this.selectedVariantInputsControl = value.variant
      if (value.type !== 'delete') {
        this.inputsShow = true
      } else {
        switch (value.variant) {
          case 'location':
            return this.deleteLocation(this.activeLocation)
          case 'sub-location':
            return;
          case 'category':
            return;
          default:
            return;
        }
      }


    },
    getLocations() {
      this.loading = true;
      this.$api.location.get()
          .then(data => {
            this.dataList = this.getFormattedDataList(data)
            this.activeLocation = this.dataList[0].id

          })
          .finally(() => {
            this.loading = false;
          })
    },
    deleteLocation(id) {
      this.loading = true;
      this.$api.location.delete({id: id})
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Локация удалена',
            });
            this.getLocations()
          })
          .finally(() => {
            this.loading = false;
          })
    },

    getFormattedDataList(data) {
      let locations = []
      if (data.length) {
        data.forEach(loc => {
              let location = {id: loc.id, label: loc.name, children: [], category_length: 0}
              loc['sub_locations'].forEach((sub_loc) => {
                let subLocation = {id: `${sub_loc['location_id'].id}-${sub_loc.id}`, label: sub_loc.name, children: []}
                sub_loc['categories'].forEach((cat) => {
                  let category = {
                    id: `${loc.id}-${sub_loc.id}-${cat.id}`,
                    label: cat.name,
                    selfId: cat.id,
                    locationId: location.id,
                    subLocationId: sub_loc.id,
                  }
                  subLocation['children'].push(category)
                  location['category_length'] += 1
                })
                location['children'].push(subLocation)
              })
              locations.push(location)
            }
        )
      }
      return locations
    },

    setActiveLocation(loc_id) {
      this.activeLocation = loc_id
    },
    setActiveSubLocation(sub_loc_id) {
      this.activeSubLocation = sub_loc_id
    },
    setActiveCategory(cat_id) {
      this.activeCategory = cat_id
    },
  },
  computed: {
    setNameForDelete() {
      if (this.dataList.length) {
        if (this.activeLocation) {
          let allNames = {location: '', 'sub-location': '', category: ''}
          const locationArr = [...this.dataList].filter((item) => item.id === this.activeLocation)
          allNames.location = locationArr[0].label
          if (this.activeSubLocation && locationArr[0]['children'].length) {
            const subLocationArr = [...locationArr[0].children].filter((item) => item.id === this.activeSubLocation)
            allNames['sub-location'] = subLocationArr[0].label
            if (subLocationArr[0]['children'].length && this.activeCategory) {
              const categoryArr = [...subLocationArr[0].children].filter((item) => item.id === this.activeCategory)
              allNames.category = categoryArr[0].label
            }
          }
          return allNames
        }
      }
      return ''
    },
    computedActualSubLocationsList() {
      if (this.dataList.length) {
        if (this.activeLocation) {
          return [...this.dataList].filter((item) => item.id === this.activeLocation)
        }
        return [...this.dataList]
      }
    },
    computedActualCategoryList() {
      if (this.dataList.length) {
        if (this.activeSubLocation) {
          if ([...this.dataList].filter((item) => item.id === this.activeLocation)[0].children.length) {
            return [...this.dataList].filter((item) => item.id === this.activeLocation)[0].children.filter((item2) => item2.id === this.activeSubLocation)[0].children
          } else {
            return null
          }
        }
        if ([...this.dataList].filter((item) => item.id === this.activeLocation)[0].children.length) {
          return [...this.dataList].filter((item) => item.id === this.activeLocation)[0].children[0].children
        }
      }
    },
  },
  watch: {
    modalState() {
      this.localModalState = this.modalState
    },
    activeLocation() {
      if (this.dataList.filter((item) => item.id === this.activeLocation)[0].children.length) {
        this.activeSubLocation = this.dataList.filter((item) => item.id === this.activeLocation)[0].children[0].id
        // if (this.dataList.filter((item) => item.id === this.activeLocation)[0].children.length) {
        //   this.activeCategory = this.dataList.filter((item) => item.id === this.activeLocation)[0].children[0].children[0].id
        // }
      }
    },
    activeSubLocation() {
      try {
        this.activeCategory = this.dataList.filter((item) => item.id === this.activeLocation)[0].children.filter((item2) => item2.id === this.activeSubLocation)[0].children[0].id
      } catch (e) {
        this.activeCategory = null
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.add-category {
  &__headers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 20px;
    border-bottom: solid 1px #bebebe;
    margin-bottom: 10px;

    h2 {
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 20px;
  }

  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .btn-add-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 3px;
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 0;


    li {
      cursor: pointer;
      padding: 8px 7px;
      border: solid 1px #bebebe;
      line-height: 1;
      border-radius: 10px;
      transition: .3s;

      &:hover {
        border: solid 1px #24387f;
      }

      &.active {
        color: #fff;
        background: #24387f;
      }
    }
  }

  div {
    button {
      margin-top: 10px;
      cursor: pointer;
      background: #fff;
      border: solid 1px #24387f;
      border-radius: 50%;
      padding: 7px;
      height: 35px;
      width: 35px;

      i {
        color: #24387f;
        font-size: 16px;
        line-height: 1;
        font-weight: bold;
      }
    }

    .btn-save {
      margin-top: 20px;
      border-radius: 10px;
      font-size: 12px;
      width: 110px;
      transition: .3s;

      &:hover {
        background: #24387f;
        color: #fff;
      }
    }

    .btn-close {
      border: none;
      font-size: 10px;
      padding: 0;
      width: 40px;
      height: 20px;
      margin: 0;
      color: grey;
      transition: .3s;

      &:hover {
        color: red;
      }
    }
  }

  .add-inputs {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: solid 1px black;
    border-right: none;
    border-left: none;
    padding: 10px 0;

    h5 {
      margin-bottom: 5px;
      text-align: center;
    }

    label {
      width: 100%;

      p {
        margin-bottom: 0;
        color: #555;
      }
    }

    input {
      width: 100%;
      font-family: inherit;
    }
  }

  &__sub-locations, &__locations, &__category {
    flex-direction: column;
  }

  &__locations {

  }

  &__sub-locations {

  }

  &__category {

  }
}

</style>
