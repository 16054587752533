<template>
  <div class="info">
    <div class="info__title">
      <h4> Общая информация </h4>
    </div>
    <ul>
      <li class="list__item">
        <span>Выбранный период:</span>
        <span>{{ currentDate.start }}</span>
        <span>{{ currentDate.end }}</span>
      </li>
      <li>
        <span>Чек-листы</span>
        <span></span>
        <span>{{countChecklists.relevant}}/{{countChecklists.all}} </span>
      </li>
      <li>
        <span>Распознано аудио</span>
        <span></span>
        <span>{{recognized.recognized}}/{{recognized.all}}</span>
      </li>
      <li>
        <span>Входящих аудио</span>
        <span></span>
        <span>{{recognized.recognized_in}}/{{recognized.in}}</span>
      </li>
      <li>
        <span>Исходящих аудио</span>
        <span></span>
        <span>{{recognized.recognized_out}}/{{recognized.out}}</span>
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  name: "general-info",
  props: {
    period: {},
    recognized: {},
    countChecklists: {},
  },
  computed: {
    currentDate() {
      return {
        start: this.formatDate(this.period.start),
        end: this.formatDate(this.period.end)
      }
    }
  },
  methods:{
    formatDate(timestamp) {
      let date = new Date(timestamp * 1000)
      return ("0" + date.getDate()).slice(-2)
          + '.'
          + ("0" + (date.getMonth() + 1)).slice(-2)
          + '.'
          + date.getFullYear()
          // + ' '
          // + date.toLocaleTimeString('it-IT');
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  margin: 15px 20px;

  &__title {
    h4 {
      margin-bottom: 40px;
      font-size: 13px;
      display: flex;
      gap: 10px;
      align-items: center;
      color: #000;
      font-weight: 450;
    }

  }

  ul {
    li {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      align-items: center;
      &:first-child{
        span {
          &:nth-child(2) {
            position: relative;
            &:before {
              position: absolute;
              content:'-';
              right: -5px;
            }
          }
        }
      }
      span {
        &:first-child {
          font-weight: 450;
          font-size: 11px;
          color: #252525;
          padding-right: 5px;
        }
        &:last-child {
          text-align: right;
        }
        font-size: 10px;
        color: #252525;
        font-weight: normal;
      }
    }
  }
}
</style>