<template>
  <el-row v-loading="loading" style="background: #fff">
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-submenu index="Словари">
        <template slot="title">Словари</template>
        <el-menu-item index="Словари-Пользовательские">Пользовательские</el-menu-item>
        <el-menu-item index="Словари-Системные">Системные</el-menu-item>
        <el-menu-item index="Словари-Все">Все</el-menu-item>
      </el-submenu>
      <el-menu-item index="Чеклисты">Чек-листы</el-menu-item>
    </el-menu>
    <div class="pending-calls"><h5>Ожидают анализа(аудио): {{ pendingCalls }}</h5></div>
    <div class="container">
      <div class="content">
        <div class="checklist" v-if="selectedPage==='Чеклисты'">
          <checklist/>
        </div>
        <div class="dict" v-if="selectedPage==='Пользовательские'">
          <dictionary
              ref="dictionary"
              :propsShowModalCreateDictGroup="showModalCreateDictGroup"
              :propsGetValueInputSearchChildren="getValueInputSearchChildren"
              :propsInputSearchValue="inputSearchForChildren"
              :type="'client'"
          />
        </div>
        <div class="dict" v-if="selectedPage==='Системные'">
          <dictionary
              :propsShowModalCreateDictGroup="showModalCreateDictGroup"
              :propsGetValueInputSearchChildren="getValueInputSearchChildren"
              :propsInputSearchValue="inputSearchForChildren"
              :type="'system'"
          />
        </div>
        <div v-if="selectedPage==='Все'">
          <div class="dict">
            <dictionary
                :propsShowModalCreateDictGroup="showModalCreateDictGroup"
                :propsGetValueInputSearchChildren="getValueInputSearchChildren"
                :propsInputSearchValue="inputSearchForChildren"
                :type="'system'"
            />
          </div>
          <div class="dict">
            <dictionary
                :propsShowModalCreateDictGroup="showModalCreateDictGroup"
                :propsGetValueInputSearchChildren="getValueInputSearchChildren"
                :propsInputSearchValue="inputSearchForChildren"
                :type="'client'"
                :button-is-show="false"
            />
          </div>
        </div>
      </div>
    </div>

    <!--    <el-tabs type="border-card">-->
    <!--      <el-tab-pane>-->
    <!--        <span slot="label"><i class="el-icon-s-data"></i>Словари</span>-->
    <!--        <dictionary/>-->
    <!--      </el-tab-pane>-->
    <!--      <el-tab-pane>-->
    <!--        <span slot="label"><i class="el-icon-s-management"></i>Чеклисты</span>-->
    <!--        <checklist/>-->
    <!--      </el-tab-pane>-->
    <!--    </el-tabs>-->



  </el-row>
</template>

<script>


import SystemDictionary from "@/components/dictionary/SystemDictionary";
import Dictionary from "@/components/dictionary/Dictionary";
import DictionaryForm from "@/components/dictionary/DictionaryForm";
import DictionaryInformationForm from "@/components/dictionary/DictionaryInformationForm";

import Checklist from "@/components/checklist/Checklist.vue";
import MyButton from "@/components/UI/MyButton.vue";
import AllDictionary from "@/components/dictionary/AllDictionary.vue";
import DictionaryGroupList from "@/components/dictionary/group/dictionaryGroupList.vue";

export default {
  name: 'DictionaryAndChecklist',

  components: {
    DictionaryGroupList,
    AllDictionary,
    MyButton,
    Checklist, DictionaryForm, DictionaryInformationForm, SystemDictionary, Dictionary
  },

  data() {
    return {
      inputGroupDictName:null,
      pendingCalls: 0,
      inputSearchForChildren: '',
      loading: false,
      activeIndex: 'Словари-Пользовательские',
      selectedPage: 'Пользовательские'
    }
  },
  mounted() {
    this.getDictionaries()
    this.getPendingCalls()
  },
  methods: {
    getPendingCalls() {
      this.$api.analysis.getPendingCalls()
          .then(data => {
            this.pendingCalls = data;
          })
    },
    showModalCreateDictGroup() {
      this.$refs.dictionary.showModalCreateDictGroup()
    },
    getValueInputSearchChildren(value) {
      this.inputSearchForChildren = value
    },
    handleSelect(key) {
      this.selectedPage = String(key).replace('Словари-', '')
    },
    test(callBack) {
      return callBack()
    },
    getDictionaries() {
      this.loading = true;
      this.$api.dictionary.get()
          .then(data => {
            let result = []
            data.for_group.forEach((el)=>{
              result.push({value:el.id,label:el.name, checked:false})
            })
            this.$refs.dictionary.setDictionariesList(result)
          })
          .finally(() => {
            this.loading = false;
          })
    },


  },
  watch: {
    activeIndex() {
      // console.log(this.activeIndex)
    },
    selectedPage() {
      this.inputSearchForChildren = ''
    }
  }
}

</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";

::v-deep .el-tabs__nav-wrap {
  display: flex;
  justify-content: center;
}

::v-deep .el-tabs__content {
  background: $--background-color-base;
}

.container {
  padding: 15px;
  min-height: calc(100vh - 187px);

  .title {
    h4 {
      color: #555;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .dict {
    margin-bottom: 20px;
  }

  .content {
    padding: 10px 0;
  }
}

.pending-calls {
  padding: 20px 0 0 15px;
  h5 {
    margin-bottom: 0;
  }
}

::v-deep .el-submenu__title, ::v-deep .el-menu-item {
  font-size: 13px;
  height: 50px;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 50px;
}

.btnAdd {

}

::v-deep .el-submenu__title, ::v-deep .el-menu-item {
  padding: 0 55px;
}

.title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #555;
}

::v-deep{
  .modalAddDictGroup{
    .el-dialog__title{
      color: #555;
      font-weight: bold;
    }
    .modalRow{
      margin-bottom: 10px;
    }
    label{

      p{
        margin-bottom: 5px;
        color: #555;
      }
    }
    .el-input,.el-select,.el-color-picker,.el-color-picker__trigger{
      width: 100%;
      border-radius: 10px;
    }
    .el-color-picker__color,.el-color-picker__color-inner{
      border-radius: 10px;
      border: none;
    }

  }
}


</style>
