<template>
  <div class="scene scene--card">
    <div class="card">
      <div class="card__face card__face--front">
        <div class="locations">
          <div class="locations__info">
            <h4>{{ location['label'] }}</h4>
            <div>
              <p><span>Локация</span> <span>{{ location['children'].length }}</span></p>
              <p><span>Категория</span> <span>{{ location['category_length'] }}</span></p>
            </div>
          </div>
          <div class="locations__img">
          </div>
        </div>
        <div class="base-info">
          <div class="base-info__title">
            <div>За последние</div>
            <div>
              <el-tabs v-model="activeName">
                <el-tab-pane
                    label="24 часа"
                    name="first"
                >
                  <div class="tabs-content">
                    <ul class="tabs-content__list">
                      <li class="tabs-content__item">
                        <p>
                          <span>загружено | в очереди</span>
                          <span>10 | 10</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>распознано | к проверке</span>
                          <span>10 | 10</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>проверено (отмечены замечания)</span>
                          <span>0</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>выполнение чек листов</span>
                          <span>62%</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>клиентов </span>
                          <span>222</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>уникальных клиентов </span>
                          <span>21</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Неделю" name="second">
                  <div class="tabs-content">
                    <ul class="tabs-content__list">
                      <li class="tabs-content__item">
                        <p>
                          <span>загружено | в очереди</span>
                          <span>9 | 10</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>распознано | к проверке</span>
                          <span>1 | 10</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>проверено (отмечены замечания)</span>
                          <span>22</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>выполнение чек листов</span>
                          <span>61%</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>клиентов </span>
                          <span>11</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>уникальных клиентов </span>
                          <span>1</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="Год" name="third">
                  <div class="tabs-content">
                    <ul class="tabs-content__list">
                      <li class="tabs-content__item">
                        <p>
                          <span>загружено | в очереди</span>
                          <span>2210 | 2210</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>распознано | к проверке</span>
                          <span>21210 | 33210</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>проверено (отмечены замечания)</span>
                          <span>212121</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>выполнение чек листов</span>
                          <span>60%</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>клиентов </span>
                          <span>1121212121</span>
                        </p>
                      </li>
                      <li class="tabs-content__item">
                        <p>
                          <span>уникальных клиентов </span>
                          <span>2222222</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
          <div class="base_info__list"></div>
        </div>
        <div class="button-rotate-card" @click="flippedBlock">
          <button type="button">Подробнее...</button>
        </div>
      </div>
      <div class="card__face card__face--back">
        <div class="wrapper-flex">
          <div class="locations">
            <h4>{{ location['label'] }}</h4>
          </div>
          <div class="btns_block">
            <button v-if="this.$store.getters.user.role && this.$store.getters.user.role.name==='admin'"
                    @click="deleteLocations">
              <i class="el-icon-delete-solid"></i>
            </button>
            <button v-if="this.$store.getters.user.role && this.$store.getters.user.role.name==='admin'"
                    @click="showDrawer">
              <i class="el-icon-s-tools"></i>
            </button>
          </div>

        </div>
        <div class="search-block">
          <el-input
              placeholder="Поиск"
              v-model="filterText">
          </el-input>
        </div>
        <el-tree
            class="filter-tree"
            :data="location['children']"
            :props="defaultProps"
            default-expand-all
            :filter-node-method="filterNode"
            @node-click="handleNodeClick"
            ref="tree">
        </el-tree>
        <div class="button-rotate-card" @click="flippedBlock">
          <button type="button">Назад</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LocationCard",
  data() {
    return {
      activeName: 'first',
      stateMyContainer: 0,
      filterText: '',
      stateDrawer: false,
      dataList: [],
      data: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  props: {
    location: {
      type: Object,
      required: true,
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    showDrawer() {
      this.$emit('showDrawer')
    },
    flippedBlock(e) {
      const block = e.currentTarget.parentNode.parentNode
      block.classList.toggle('is-flipped')
    },
    handleNodeClick(data) {
      if (data?.selfId) {
        let path = '/new-categories/' + data.locationId + '/sub-location/' + data.subLocationId + '/category/' + data.selfId + '/'
        this.$router.push(path)
      }
    },
    deleteLocations() {
      alert('Может удалить только администратор')
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";


.my-container {
  .content {
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr;
    gap: 20px;
    transition: .3s;
  }
}


.btn-add-category {

  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
  background: #fff;
  border: none;

  div {
    position: relative;
    padding: 20px;

    &:before, &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: black;
      left: 0;

    }

    &:after {
      transform: rotate(-90deg);
    }
  }

  p {
    color: black;
    padding: 20px;
    margin: 0;
    font-size: 14px;
  }

}

.item {
  padding: 15px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  border-radius: 10px;
  position: relative;

  &:nth-child(1) {
    height: 400px;
  }
}

.locations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__info {
    width: 80%;

    h4 {
      color: #555;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 450;
    }

    div {
      margin-left: 15px;

      p {
        width: 100%;
        margin: 0 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: dotted 1px #cecdcd;

        span {
          color: #555;
          font-size: 13px;
          background: #fff;
          line-height: 15px;
          transform: translateY(5px);

          &:first-child {
            Transform: translate(0, 5px);
            padding: 0 5px 0 0;
          }

          &:last-child {
            padding: 0 0 0 5px;
          }
        }
      }
    }
  }

  &__img {
    position: absolute;
    right: 0;
    top: 5px;
    width: 60px;
    height: 60px;
    //background-image: url("../assets/img/company-icon.jpeg");
    background-image: url("../../assets/img/company-icon.jpeg");
    background-size: cover;
    border-radius: 10px;

  }
}


.base-info {
  margin-top: 10px;
  position: relative;

  &__title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    div {
      &:first-child {
        font-size: 0.9rem;
        white-space: nowrap;
        font-weight: 500;
        color: #555;
      }
    }

    ::v-deep .el-tabs__content {

      position: absolute;
      width: 100%;
      left: 0;
    }

    ::v-deep .el-tabs__item {
      padding: 0 5px;
      font-size: 12px !important;
      height: 30px;
    }

    ::v-deep .el-tabs__header {
      margin-bottom: 10px;
    }

    ::v-deep .el-tabs__nav-wrap::after {
      display: none;
    }
  }
}

.tabs-content {
  &__list {
    margin: 0 5px 0 15px;
  }

  &__item {
    margin-bottom: 10px;

    p {
      border-bottom: dotted 1px #cecdcd;
      color: #555;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8rem;
      font-weight: normal;

      span {
        background: #fff;
        transform: translateY(5px);
        line-height: 1;

        &:nth-child(1) {
          padding: 0 5px 0 0;
        }

        &:nth-child(2) {
          padding: 0 0 0 5px;
        }
      }
    }
  }
}

.card {
  .button-rotate-card {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 20px;
    cursor: pointer;
    min-height: 120px;


    button {
      border: none;
      background: none;
      color: #24387f;
      font-size: 0.9rem;
      font-weight: 500;
      font-family: 'Gotham Pro', sans-serif;
      cursor: pointer;
    }
  }

  &.is-flipped {
    .button-rotate-card {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px;
      cursor: pointer;
      min-height:unset;
    }
  }
}

  .separator-text {
    width: 1px;
    height: 14px;
    background: #555;
  }

  .separator-line {
    margin: 5px 0;
    width: 100%;
    border: solid 1px #555;
  }


  //--------------------
  .scene {
    height: 420px;
    perspective: 600px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
    border-radius: 10px;
  }

  .card {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    transition: transform .5s;
    transform-style: preserve-3d;
    position: relative;
  }

  .card.is-flipped {
    transform: rotateY(180deg);
  }

  .card.is-flipped .card__face--front {
    z-index: -1;
  }

  .card__face {
    padding: 15px;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .card__face--front {
    background: #fff;
    border-radius: 10px;
  }

  .card__face--back {
    border-radius: 10px;
    transform: rotateY(180deg);
    background: #fff;

    .wrapper-flex {
      display: grid;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      grid-template-columns: 6fr 1fr;
      align-items: flex-start;

      .locations {
        h4 {
          color: #555;
          margin: 0;
          line-height: 1;
        }
      }

      .btns_block {
        display: grid;
        gap: 0px;
        grid-template-columns: 1fr 1fr;
      }

      button {
        background: unset;
        border: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: #555;
          transition: .3s;
        }

        &:hover {
          i {
            color: #000;
            transition: .3s;
          }
        }

      }
    }

    .search-block {
      margin-top: 10px;
    }

    .locations {

    }

    ::v-deep .el-tree.filter-tree {
      margin-top: 15px;
      overflow: auto;
      max-height: 260px;

    }

    ::v-deep.el-tree-node > .el-tree-node__children {
      overflow: unset;
    }

  }

  @media (max-width: 1800px) {
    .locations__info {
      width: 75% !important;
    }
  }

  @media (max-width: 1600px) {
    .locations__info {
      width: 80% !important;
    }
  }

  @media (max-width: 1400px) {
    .locations__info {
      width: 75% !important;
    }
  }


  @media (max-width: 1200px) {
    .locations__info {
      width: 80% !important;
    }
  }

  @media (max-width: 1000px) {
    .locations__info {
      width: 70% !important;
    }
  }

  @media (max-width: 850px) {
    .locations__info {
      width: 85% !important;
    }
  }

  @media (max-width: 750px) {
    .locations__info {
      width: 75% !important;
    }
  }

  @media (max-width: 1600px) {
    .my-container {
      .content {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media (max-width: 1200px) {
    .my-container {
      .content {
        grid-template-columns: 1fr  1fr;
      }

    }
  }

  @media (max-width: 850px) {
    .my-container {
      .content {
        grid-template-columns: 1fr;
      }

    }
  }


</style>