<template>
  <el-row class="dictionaries-transfer" v-loading="loading">
    <h4>Редактирование словарей категории</h4>

    <el-input class="search" v-model="search" placeholder="Поиск"></el-input>
    <el-row class="info">

    </el-row>
    <el-row class="available">
      <el-table
          ref="availableDictionariesTable"
          :data="prepareAvailableDataForTransfer"
          width="100%"
          @selection-change="moveToSelected"
      >
        <el-table-column
            fixed="left"
            type="selection"
            width="40"
        >
        </el-table-column>
        <el-table-column
            label="Доступные"
        >
          <el-row class="available-scroll">

          </el-row>
          <el-table-column
              label="Название"
              width="333"
              show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column
              label="Тип"
              align="center"
              width="100"
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.type === 'system' ? 'Системный' : 'Пользовательский' }}
            </template>
          </el-table-column>
          <el-table-column
              label="Успех"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_success"></el-color-picker>
            </template>
          </el-table-column>
          <el-table-column
              label="Отклонен"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_reject"></el-color-picker>
            </template>
          </el-table-column>
          <el-table-column
              label="П/вес"
              width="110"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.positive_weight" :value="scope.row.positive_weight"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
              label="Н/вес"
              width="110"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.negative_weight" :value="scope.row.negative_weight"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
              label="Реверсивный"
              align="center"
              width="100"
          >
            <template slot-scope="scope">

              <el-switch
                  v-model="scope.row.reverse"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
              label="Искать во входящих"
              align="center"
              width="140"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.search_in_call"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
              label="Искать в исходящих"
              align="center"
              width="140"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.search_out_call"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row class="transfer">
      <el-row>
        <el-button :disabled="!preAvailableDictionaries.length" @click="toAvailable">
          <i class="el-icon-arrow-up"></i>
        </el-button>
      </el-row>
      <el-row>
        <el-button :disabled="!preSelectedDictionaries.length" @click="toSelected">
          <i class="el-icon-arrow-down"></i>
        </el-button>
      </el-row>


    </el-row>

    <el-row class="selected">
      <el-table
          ref="availableDictionariesTable"
          :data="prepareSelectedDataForTransfer"
          width="100%"
          @selection-change="moveToAvailable"
      >
        <el-table-column
            fixed="left"
            type="selection"
            width="40"
        >
        </el-table-column>
        <el-table-column
            label="Выбранные"
        >
          <el-table-column
              label="Название"
              width="333"
              show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>

          <el-table-column
              label="Тип"
              align="center"
              width="100"
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.type === 'system' ? 'Системный' : 'Пользовательский' }}
            </template>
          </el-table-column>

          <el-table-column
              label="Успех"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_success" :disabled="true"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Отклонен"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_reject" :disabled="true"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="П/вес"
              width="110"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number
                  v-model="scope.row.positive_weight"
                  :value="scope.row.positive_weight"
                  :disabled="true"
              />
            </template>
          </el-table-column>

          <el-table-column
              label="Н/вес"
              width="110"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number
                  v-model="scope.row.negative_weight"
                  :value="scope.row.negative_weight"
                  :disabled="true"
              />
            </template>
          </el-table-column>

          <el-table-column
              label="Реверсивный"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.reverse"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="true"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
              label="Входящие"
              align="center"
              width="140"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.search_in_call"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="true"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
              label="Исходящие"
              align="center"
              width="140"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.search_out_call"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="true"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-row>

  </el-row>
</template>

<script>
export default {
  name: "category-dictionaries-transfer",
  props: {
    categoryId: {},
    selectedSystemDictionaries: {},
    selectedClientDictionaries: {},
    refresh: {}
  },
  data() {
    return {
      search: '',
      show: true,
      loading: false,
      dictionaries: {
        client: [],
        system: [],
        for_group: []
      },
      preAvailableDictionaries: [],
      preSelectedDictionaries: [],
      selectedDictionaries: [],
      availableDictionaries: [],
    }
  },
  computed: {
    prepareSelectedDataForTransfer: function () {
      let system = this.selectedSystemDictionaries.map(item => this.prepareObjectByType('system', item))
      let client = this.selectedClientDictionaries.map(item => this.prepareObjectByType('client', item))
      this.selectedDictionaries = [...system, ...client]
      return this.withSearch(this.selectedDictionaries)
    },
    prepareAvailableDataForTransfer: function () {
      let system = this.dictionaries.system.map(item => {
        item.type = 'system';
        return item
      })
      let client = this.dictionaries.client.map(item => {
        item.type = 'client';
        return item
      })

      this.dictionaries.for_group.forEach((el) => {
        if (el.from_system_dictionary) {
          el.type = 'system'
          system.push(el)
        } else {
          el.type = 'client'
          system.push(el)
        }
      })
      let availableDictionaries = [...system, ...client]
      let selected = this.selectedDictionaries.map(item => item.id + item.type)
      this.availableDictionaries = availableDictionaries.filter(item => !(selected).includes(item.id + item.type))
      return this.withSearch(this.availableDictionaries)
    },
  },
  mounted() {
    this.getDictionaries();
  },
  methods: {
    withSearch(dictionaries) {
      return dictionaries.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
    },
    prepareObjectByType(type, object) {
      if (object) {
        object.dictionary.type = type;
        object.dictionary.color_reject = object.color_reject;
        object.dictionary.color_success = object.color_success;
        object.dictionary.negative_weight = object.negative_weight;
        object.dictionary.positive_weight = object.positive_weight;
        object.dictionary.reverse = object.reverse;
        return object.dictionary;
      }

    },
    getDictionaries() {
      this.$api.dictionary.get()
          .then(data => {
            this.dictionaries = data;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    moveToSelected(preSelected) {
      this.preSelectedDictionaries = preSelected;
    },
    moveToAvailable(preAvailable) {
      this.preAvailableDictionaries = preAvailable;
    },

    toSelected() {
      this.loading = true;

      this.preSelectedDictionaries.forEach(item => {

        this.$api.category.saveCategoryDictionary({
          category_id: this.categoryId,
          dictionary_id: item.id,
          dictionary_type: item.type,
          positive_weight: item.positive_weight,
          negative_weight: item.negative_weight,
          search_in_call: item.search_in_call,
          search_out_call: item.search_out_call,
          color_success: item.color_success,
          color_reject: item.color_reject,
          reverse: item.reverse,
          channel: item.channel,

        })
            .then(data => {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Чеклисты успешно добавлены',
              });
            })
            .finally(() => {
              this.loading = false;
              this.$emit('reload')
            })
      })

    },
    prepareToDelete(data) {
      let systemDictionaries = this.preAvailableDictionaries.filter(item => item.type === 'system')

      if (systemDictionaries.length) {
        systemDictionaries = systemDictionaries.map(item => item.id)
      }

      let clientDictionaries = this.preAvailableDictionaries.filter(item => item.type === 'client')
      if (clientDictionaries.length) {
        clientDictionaries = clientDictionaries.map(item => item.id)
      }

      return {
        system: systemDictionaries,
        client: clientDictionaries
      }
    },
    toAvailable() {
      this.loading = true;

      let dictionaries = this.prepareToDelete()

      this.$api.category.deleteCategoryDictionary({
        category_id: this.categoryId,
        system_dictionaries: dictionaries.system,
        client_dictionaries: dictionaries.client
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Чеклисты уаспешно удалены',
            });
          })
          .finally(() => {
            this.loading = false;
            this.$emit('reload')
            this.getDictionaries();
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.dictionaries-transfer {
  margin: 0 20px 50px 20px;

  h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .search {
    margin-bottom: 10px;

    ::v-deep .el-input__inner {
      width: 100%;
      height: 30px;
    }
  }

  .info {
    margin-bottom: 10px;

    div {
      &:first-child {
        margin-bottom: 10px;
      }

      &:nth-child(2), &:nth-child(3) {
        display: grid;
        grid-template-columns: 120px 70px;
        margin-left: 20px;

        span {
          &:last-child {
            padding-left: 20px;

            i {
              font-weight: bold;
              font-size: 13px;
              color: #252525;
            }
          }
        }
      }
    }
  }

  .available, .selected {
    max-height: 30vh;
    padding: 0 20px 0 0;
    overflow-y: auto;

  }

  .transfer {
    text-align: center;

    .el-button {
      padding: 0 10px;
      margin: 5px;

      i {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  ::v-deep .el-table {
    position: relative;

    &__header {
      &-wrapper {

      }
    }

    &__cell {
      border-right: none;
      border-left: none;
      padding: 2px 0;
      color: #252525;
      font-size: 11px;

      .cell {
        white-space: nowrap;
      }

      .el-color-picker {
        position: relative;
        top: 5px;

        &__mask,
        &__trigger {
          height: 25px;
          width: 25px;
        }
      }

      .el-input {
        &-number {
          width: 100px;

          &__decrease,
          &__increase {
            width: 20px;
            height: 18px;
            top: 9px;

            i {
              position: relative;
              top: -8px;
            }
          }
        }

        &__inner {
          height: 20px;
          line-height: 20px;
          padding: 0;
        }
      }

      .el-switch {
        &__core {
          height: 18px;

          &:after {
            top: 0;
          }
        }
      }

    }
  }
}
</style>