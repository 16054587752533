<template>
  <div class="statistic" v-if="propsData.length" v-loading="loading">
    <el-collapse v-model="checklistCollapseState">
      <el-collapse-item title="Звонки" name="1">
        <div class="callInfo">
          <div
              class="callInfo__top"
              :style="{gridTemplateColumns:`repeat(${propsData.length+1}, 230px)`}"
          >
            <div></div>
            <div
                v-for="agent in propsData"
                :key="agent.agent_name + '-' + agent.category_id"
            >
              {{ agent.category_name }}<br/>
              {{ agent.agent_name }}

            </div>
          </div>
          <div
              class="callInfo__content"
              :style="{gridTemplateColumns:`repeat(${propsData.length+1}, 230px)`}"
          >
            <div class="callInfo__content-default">
              <ul>
                <li>Всего звонков</li>
                <li>Входящих звонков</li>
                <li>Исходящих звонков</li>
              </ul>
              <ul>
                <li>Всего распознано</li>
                <li>Входящих распознано</li>
                <li>Исходящих распознано</li>
              </ul>
              <ul>
                <li>Системных словарей</li>
                <li>Пользовательских словарей</li>
                <li>Входящих чек-листов</li>
                <li>Исходящих чек-листов</li>
              </ul>
              <!--              <ul>-->
              <!--                <li>Негативные показатели</li>-->
              <!--                <li>Позитивные показатели</li>-->
              <!--              </ul>-->
            </div>
            <div
                class="callInfo__content-default callInfo__content-users"
                v-for="data in propsData"
                v-if="data.calls && data.dictionaries && data.checklist"
            >
              <ul>
                <li>{{ data.calls.all }}</li>
                <li>{{ data.calls.in }}</li>
                <li>{{ data.calls.out }}</li>
              </ul>
              <ul>
                <li>{{ data.calls.recognized }} ({{ calculateInterest(data.calls.recognized, data.calls.all) }}%)</li>
                <li>{{ data.calls.recognized_in }}</li>
                <li>{{ data.calls.recognized_out }}</li>
              </ul>
              <ul>
                <!--            {{data.checklist}}-->
                <li>{{ data.dictionaries.system.length }}</li>
                <li>{{ data.dictionaries.client.length }}</li>
                <li>{{ calculateCountChecklistCount('in', data.checklist) }}</li>
                <li>{{ calculateCountChecklistCount('out', data.checklist) }}</li>
              </ul>
              <!--              <ul>-->
              <!--                <li>Негативные показатели</li>-->
              <!--                <li>Позитивные показатели</li>-->
              <!--              </ul>-->
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Чек-листы" name="2">
        <div class="checklistInfo">
          <div
              class="category-name"
              :style="{gridTemplateColumns:`repeat(${propsData.length+1}, 230px)`}"
          >
            <div></div>
            <div
                v-for="agent in propsData"
                :key="agent.agent_name + '-' + agent.category_id"
            >
              {{ agent.category_name }}<br/>
              {{ agent.agent_name }}
            </div>

          </div>
          <div
              class="wrapper"
              :style="{gridTemplateColumns:`repeat(${propsData.length+1}, 230px)`}"
          >
            <div class="checklistInfo__default" v-if="uniqCheckList">

              <ul v-if="uniqCheckList" v-for="checklist in uniqCheckList">
                <li>Чек-лист: <span>&laquo;{{ checklist.checklist.name }}&raquo;</span></li>
                <li>Кол-во полностью выполненных</li>
                <li>Кол-во частично выполненных</li>
                <li>Средний % выполнения</li>
                <el-collapse class="checklists-collapse" v-model="checklistCollapseDict">
                  <el-collapse-item title="Вопросы" name="check">
                    <li
                        class="dict-client dict"
                        v-for="dict in checklist.checklist.dictionaries.client"
                    >
                      <span v-if="!dict.positive_weight && !dict.positive_weight">0</span>
                      <span
                          :style="{background:dict.dictionary.color_success}"
                          v-if="dict.positive_weight"
                          class="positive"
                      >
                  {{ dict.positive_weight }}
                </span>
                      <span
                          :style="{background:dict.dictionary.color_reject}"
                          v-if="dict.negative_weight"
                          class="negative"
                      >
                  {{ dict.negative_weight }}
                </span>
                      {{ dict.name }}
                      <i v-if="dict.dictionary.reverse" class="el-icon-sort"></i>
                    </li>
                    <li
                        class="dict-system dict"
                        v-for="dict in checklist.checklist.dictionaries.system"
                    >
                <span
                    :style="{background:dict.dictionary.color_success}"
                    v-if="dict.positive_weight"
                    class="positive"
                >
                  {{ dict.positive_weight }}
                </span>
                      <span
                          :style="{background:dict.dictionary.color_reject}"
                          v-if="dict.negative_weight"
                          class="negative"
                      >
                  {{ dict.negative_weight }}
                </span>
                      {{ dict.name }}
                      <i v-if="dict.dictionary.reverse" class="el-icon-sort"></i>
                    </li>
                  </el-collapse-item>
                </el-collapse>
              </ul>
            </div>
            <div
                v-if="uniqCheckList"
                class="checklistInfo__default checklistInfo__client"
                v-for=" agent in propsData">
              <ul v-if="uniqCheckList"
                  :class="checkingForAChecklist(checklist, agent)?'':'not-use'"
                  v-for="checklist in uniqCheckList">
                <li>{{ checkingForAChecklist(checklist, agent) ? 'Применяется' : 'Не применяется' }}</li>
                <li>
                  {{
                    checkingForAChecklist(checklist, agent)
                        ? checkingForAChecklist(checklist, agent).successfully.length
                        : 0
                  }}
                </li>
                <li>
                  {{
                    checkingForAChecklist(checklist, agent)
                        ? checkingForAChecklist(checklist, agent).unsuccessfully.length
                        : 0
                  }}
                </li>
                <li>
                  <my-progress
                      :percentage="checkingForAChecklist(checklist, agent)
                      ? checkingForAChecklist(checklist, agent).average.percentage
                      :0"
                  />
                </li>
                <el-collapse class="checklists-collapse" v-model="checklistCollapseDict">
                  <el-collapse-item title="" name="check">
                    <li
                        class="dict-client dict"
                        v-for="dict in checklist.checklist.dictionaries.client"
                    >
                      {{ getDictionaryValueFromChecklist(dict, checklist, agent, 'client') }}
                    </li>
                    <li
                        class="dict-system dict"
                        v-for="dict in checklist.checklist.dictionaries.system"
                    >
                      {{ getDictionaryValueFromChecklist(dict, checklist, agent, 'system') }}
                    </li>
                  </el-collapse-item>
                </el-collapse>
              </ul>
            </div>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Словари" name="3">
        <div
            class="dictionaryInfo"

        >
          <div
              class="category-name"
              :style="{gridTemplateColumns:`repeat(${propsData.length+1}, 230px)`}"
          >
            <div></div>
            <div
                v-for="agent in propsData"
                :key="agent.agent_name + '-' + agent.category_id"
            >
              {{ agent.category_name }}<br/>
              {{ agent.agent_name }}
            </div>
          </div>
          <div
              :style="{gridTemplateColumns:`repeat(${propsData.length+1}, 230px)`}"
              class="wrapper"
          >
            <div class="dictionaryInfo__default">
              <div class="system" v-if="uniqDictionary.system.length">
                <p>Системные словари</p>
                <ul>
                  <li v-if="uniqDictionary.system.length" v-for="systemDict in uniqDictionary.system"
                  >
                    <span v-if="!systemDict.negative_weight && !systemDict.positive_weight">0</span>
                    <span
                        v-if="systemDict.negative_weight"
                        :style="{background:systemDict.dictionary.color_reject}"
                        class="negative"
                    >{{ systemDict.negative_weight }}</span>
                    <span
                        v-if="systemDict.positive_weight"
                        :style="{background:systemDict.dictionary.color_success}"
                        class="positive"
                    >{{ systemDict.positive_weight }}</span>
                    {{ systemDict.dictionary.name }}
                  </li>
                </ul>
              </div>
              <div class="client" v-if="uniqDictionary.client.length">
                <p>Пользовательские словари</p>
                <ul>
                  <li v-for="clientDict in uniqDictionary.client"
                  >
                    <span v-if="!clientDict.negative_weight && !clientDict.positive_weight">0</span>
                    <span
                        v-if="clientDict.negative_weight"
                        :style="{background:clientDict.dictionary.color_reject}"
                        class="negative"
                    >{{ clientDict.negative_weight }}</span>
                    <span
                        v-if="clientDict.positive_weight"
                        :style="{background:clientDict.dictionary.color_success}"
                        class="positive"
                    >{{ clientDict.positive_weight }}</span>
                    {{ clientDict.dictionary.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div
                class="dictionaryInfo__default dictionaryInfo__client"
                v-for=" agent in propsData"
            >
              <div class="system" v-if="uniqDictionary.system.length">

                <ul>
                  <p>Системные словари </p>
                  <li v-if="uniqDictionary.system.length" v-for="systemDict in uniqDictionary.system"
                  >
                    {{ getDictionaryValueFromCategory(systemDict, agent, 'system') }}
                  </li>
                </ul>
              </div>
              <div class="client" v-if="uniqDictionary.client.length">

                <ul>
                  <p>Пользовательские словари</p>
                  <li v-for="clientDict in uniqDictionary.client"
                  >
                    {{ getDictionaryValueFromCategory(clientDict, agent, 'client') }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

  </div>
</template>

<script>
import MyProgress from "@/components/UI/MyProgress.vue";

export default {
  name: "employees-statistic-block",
  components: {MyProgress},
  props: {
    propsData: {
      type: Array
    },
    locationsData: {
      type: Array
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      checklistCollapseState: ['1', '2', '3'],
      // checklistCollapseState: ['3'],
      uniqCheckList: null,
      uniqDictionary: {system: [], client: []},
      checklistCollapseDict: ['check']

    }
  },
  watch: {
    propsData() {
      this.setUniqChecklistAndUniqDictionary()
    }
  },
  computed: {},
  methods: {

    calculatePercentagesDict(dict, calls,type) {
      if (type ==='dictionary' && calls){
        let interest = 0
        switch (true) {
          case dict.search_in_call === 1 && dict.search_out_call === 1:
            interest = (dict.dictionary.found_count_by_period.all.length / calls.recognized * 100).toFixed(2)
           break;
          case dict.search_in_call === 1 && dict.search_out_call === 0:
            interest = (dict.dictionary.found_count_by_period.all.length / calls.recognized_in * 100).toFixed(2)
            break;
          case dict.search_in_call === 0 && dict.search_out_call ===1:
            interest = (dict.dictionary.found_count_by_period.all.length / calls.recognized_out * 100).toFixed(2)
            break;
          default:
            break;
        }
        return interest === '0.00' ? 0 : !isNaN(+interest) ? interest : 0
      }
      if (type==='checklist' && calls){

        let interest = 0
        switch (true) {
          case dict.search_in_call === 1 && dict.search_out_call === 1:
            interest = (dict.found_count_by_period.all.length / calls.recognized * 100).toFixed(2)
            return interest === '0.00' ? 0 : !isNaN(+interest) ? interest : 0
          case dict.search_in_call === 1 && dict.search_out_call === 0:
            interest = (dict.found_count_by_period.all.length / calls.recognized_in * 100).toFixed(2)
            return interest === '0.00' ? 0 : !isNaN(+interest) ? interest : 0
          case dict.search_in_call === 0 && dict.search_out_call ===1:
            interest = (dict.found_count_by_period.all.length / calls.recognized_out * 100).toFixed(2)
            return interest === '0.00' ? 0 : !isNaN(+interest) ? interest : 0
          default:
            return  0
        }
      }
    },
    getDictionaryValueFromCategory(dict, agent, type) {
      if (dict && agent) {
        if (agent.dictionaries) {
          let currentDict = agent.dictionaries[type].find((f) => f.dictionary_id === dict.dictionary_id)
          if (currentDict) {
            return currentDict.dictionary.found_count_by_period.all.length + ` (${this.calculatePercentagesDict(currentDict, agent.calls,'dictionary')}%)`
          } else {
            return 'не используется'
          }
        }
      }
      return 0
    },
    getDictionaryValueFromChecklist(dict, checklist, agent, type) {

      if (dict && checklist && agent) {
        let currentChecklistDictionaries = this.checkingForAChecklist(checklist, agent)?.checklist.dictionaries
        if (currentChecklistDictionaries) {
          let currentDict = currentChecklistDictionaries[type].find((f) => f.dictionary_id === dict.dictionary_id)

          if (currentDict) {
            return currentDict.dictionary.found_count_by_period.all.length + ` (${this.calculatePercentagesDict(currentDict.dictionary, agent.calls,'checklist')}%)`
          }
        }
      }
      return 0
    },
    checkingForAChecklist(checklist, agent) {
      if (checklist && agent.checklist) {
        if (agent.checklist.find((f) => f.checklist_id === checklist.checklist_id)) {
          return agent.checklist.find((f) => f.checklist_id === checklist.checklist_id)
        }
      }
    },
    calculateInterest(value, all) {
      let int = Math.round(+value / +all * 100)
      if (int && !isNaN(int)) {
        return int
      }
      return 0
    },
    calculateCountChecklistCount(type, data) {
      if (type === 'in') {
        let checkListIn = data.filter((item) => item.search_in_call)
        return checkListIn.length
      }
      if (type === 'out') {
        let checkListOut = data.filter((item) => item.search_out_call)
        return checkListOut.length
      }
    },
    setUniqChecklistAndUniqDictionary() {
      let checklistArr = []
      let dictionarySystemArr = []
      let dictionaryClientArr = []
      this.propsData.forEach((item) => {
        if (item?.checklist) {
          item.checklist.forEach((checklist) => {
            let status = checklistArr.find((item2) => item2.checklist_id === checklist.checklist_id)
            if (!status) {
              checklistArr.push(checklist)
            }
          })
        }
        if (item?.dictionaries) {
          item.dictionaries.client.forEach(dictC => {
            let status = dictionaryClientArr.find((item2) => item2.dictionary_id === dictC.dictionary_id)
            if (!status) {
              dictionaryClientArr.push(dictC)
            }
          })
          item.dictionaries.system.forEach(dictS => {
            let status = dictionarySystemArr.find((item2) => item2.dictionary_id === dictS.dictionary_id)
            if (!status) {
              dictionarySystemArr.push(dictS)
            }
          })
        }
      })

      this.uniqDictionary.system = dictionarySystemArr
      this.uniqDictionary.client = dictionaryClientArr
      this.uniqCheckList = checklistArr
    }
  },

}
</script>

<style lang="scss" scoped>
.statistic {
  margin-top: 20px;
  overflow-x: auto;

  .callInfo {
    //border-top: solid 1px #EBEEF5;

    &__top {
      display: grid;

      div {
        border-bottom: solid 1px #EBEEF5;
        padding: 5px 0;
        font-weight: bold;
        color: #555;
        text-align: center;

        &:nth-child(even) {
          border-left: solid 1px #EBEEF5;
          border-right: solid 1px #EBEEF5;
        }

        &:first-child {
          //border-left: solid 1px #EBEEF5;
        }

        &:last-child {
          //border-right: solid 1px #EBEEF5;
          border-right: none;
        }
      }
    }

    &__content {
      display: grid;

      &-default {
        padding: 15px 0;
        //border-bottom: solid 1px #EBEEF5;

        ul {
          margin-bottom: 20px;

          li {
            text-align: center;
            height: 20px;
            color: #555;
            font-weight: 500;
            font-size: 12px;
            margin-bottom: 3px;
          }
        }

        &:nth-child(even) {
          border-right: 1px solid #EBEEF5;
          border-left: 1px solid #EBEEF5;
        }

        &:last-child {
          //border-right: 1px solid #EBEEF5;
          border-right: none;
        }
      }

      &__content-users {
        &:last-child {
          border-right: solid 1px #ebebeb;
        }
      }
    }
  }

  .checklistInfo {

    display: grid;

    &__default {
      border-right: solid 1px #EBEEF5;
      border-top: solid 1px #EBEEF5;

      &:last-child {
        border-right: none;
      }

      ul {
        width: 230px;
        padding: 20px 0;
        margin-bottom: 0;
        border-bottom: solid 1px #EBEEF5;

        .el-collapse {
          ::v-deep .el-collapse-item__content {
            border-right: solid 1px #EBEEF5;
          }
        }

        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          border-bottom: none;
        }


        li {
          color: #555;
          //font-weight: 500;
          font-size: 12px;
          margin-bottom: 3px;
          text-overflow: ellipsis !important;
          white-space: nowrap;
          overflow: hidden;
          max-width: 280px;
          padding-right: 20px;
          height: 20px;

          &:nth-child(4) {
            margin-bottom: 10px;
          }

          span {
            font-weight: bold;
            font-size: 12px;
          }
        }

        .dict {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 10px;
          //line-height: 1;
          margin-bottom: 5px;
          height: 20px;

          span {
            width: 35px;
            min-width: 35px;
            height: 15px;
            border-radius: 4px;
            text-align: center;
            color: #fff;
            background: #bababa;
            vertical-align: middle;
            line-height: 1.5;
          }

          i {
            transform: rotate(90deg);
            color: red;
            line-height: 1;
          }
        }
      }

      &:last-child {
        ul {
          .el-collapse {
            ::v-deep .el-collapse-item__content {
              border-right: none !important;;
            }
          }
        }
      }
    }

    &__client {

      ul {
        li {
          text-align: center;
          justify-content: center;
          height: 20px;
          padding: 0 20px;
        }

        .dict {
          width: 100%;
          text-align: center !important;
          height: 20px;
          padding: 0 20px;
        }
      }
    }
  }

  .dictionaryInfo {
    display: grid;

    &__default {
      border-top: solid 1px #EBEEF5;
      border-right: solid 1px #EBEEF5;
      padding: 10px 0;

      &:last-child {
        border-right: none;
      }

      .system, .client {
        p {
          color: #555;
          margin-bottom: 10px;
          font-weight: 500;
        }

        ul {
          margin-bottom: 10px;

          li {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 10px;
            margin-bottom: 5px;
            height: 20px;
            color: #555;
            text-overflow: ellipsis !important;
            white-space: nowrap;
            overflow: hidden;
            max-width: 280px;
            padding-right: 20px;

            span {
              width: 35px;
              min-width: 35px;
              height: 15px;
              border-radius: 4px;
              text-align: center;
              color: #fff;
              background: #EBEEF5;
              vertical-align: middle;
              line-height: 1.5;
            }

            i {
              transform: rotate(90deg);
              color: red;
              line-height: 1;
            }
          }
        }
      }
    }

    &__client {
      .system, .client {
        padding: 0 20px;

        p {
          text-align: center;
          opacity: 0;
        }

        ul {
          li {
            justify-content: center;
          }
        }
      }
    }
  }
}

.wrapper {
  display: grid;
}

.category-name {
  display: grid;

  div {

    border: solid 1px #EBEEF5;
    border-top: none;
    border-bottom: none;
    border-left: none;
    padding: 15px 0;
    font-weight: bold;
    color: #555;
    text-align: center;

    &:last-child {
      border-right: none;
    }
  }

}

::v-deep {
  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }

  .el-collapse-item__wrap {
    overflow-x: auto;
  }

  .el-collapse-item__header {
    //height: 10px;
    color: #24387f;
    font-size: 15px;
  }

  .checklists-collapse {
    .el-collapse {
      border-top: none;
    }

    .el-collapse-item__header {
      //margin-top: 10px !important;
      height: 35px;
      background: inherit;
    }

    .el-collapse-item__header i {
      //display: none;
    }

    .el-collapse-item__wrap {
      border-bottom: none;
    }

    .el-collapse-item__content {
      padding-top: 10px;
      //border-right: solid 1px #EBEEF5;
      border-bottom: none;

      &:last-child {

      }

      li {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.not-use {
  > li {
    opacity: 0;

    &:first-child {
      display: flex;
      //color: red !important;
      opacity: 1;
    }
  }

  div {
    li {
      opacity: 0;
    }
  }
}

::v-deep{
  .el-collapse-item__header{
    height: 25px;
    border-top: solid 1px #EBEEF5;
  }
}

</style>