<template>
  <div class="chart">
    <div class="chart__title">
      <h3>Список звонков клиента</h3>
    </div>

  </div>
</template>

<script>
export default {
  name: "userCallList"
}
</script>

<style lang="scss" scoped>
.chart{
  margin-top: 20px;
  &__title{
    margin-bottom: 10px;
    h3{
      color: #555;
      font-size: 15px;
    }
  }
}

</style>