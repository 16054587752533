<template>
  <div class="date">
    <h6>Выберите дату:</h6>
    <el-date-picker
        v-model="value2"
        type="daterange"
        align="right"
        :picker-options="pickerOptions"
        start-placeholder="От"
        end-placeholder="До">
      >
    </el-date-picker>
  </div>
</template>

<script>
export default {
  name: 'custom-date-range-picker-for-employee-interaction',
  props: {
    propsGetSelectedDate: {}
  },
  data() {
    return {
      value2: [],
      firstSelectedDate: null,
      pickerOptions: {
        onPick: (picker) => {
          this.firstSelectedDate = picker.minDate
        },
        disabledDate: (time) => {
          if (!this.firstSelectedDate) {
            return false;
          }
          const inputDate = new Date(this.firstSelectedDate)
          const oneMonthForward = new Date(inputDate);
          oneMonthForward.setMonth(inputDate.getMonth() + 1);
          const oneMonthAgo = new Date(inputDate);
          oneMonthAgo.setMonth(inputDate.getMonth() - 1);
          return time < oneMonthAgo || time > oneMonthForward;
        },
      },
    };
  },
  watch: {
    value2(value) {
      this.firstSelectedDate = null;
      this.propsGetSelectedDate(value)
    },
  },
};
</script>

<style lang="scss" scoped>
.date {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  h6 {
  font-size: 15px;
  margin-bottom: 0px;
  color: #555;
}
}

.disabled {
  color: #ccc;
  pointer-events: none;
}

::v-deep .el-input__inner {
  height: 30px;
}

::v-deep .el-input__icon {
  display: flex;
  align-items: center;
}

::v-deep .el-range-separator {
  display: flex;
  align-items: center;
}

::v-deep .el-range-input {
  width: 79%;
}

::v-deep .el-date-editor--datetimerange {
  width: 350px;
}


</style>
