<template>
  <div class="item">
    <div class="item-header">
      <h4>{{ dictData.dictionary.name }}</h4>
      <button type="button">
        <i class="el-icon-more"></i>
      </button>
    </div>
    <div class="dict-description">
      <p><span>Описание:</span><br/></p>
      <ul>
        <li :key="index" v-for="(row,index) in dictData.dictionary.description">{{
            row.replaceAll('|', ' | ')
          }}
        </li>
      </ul>
    </div>
    <div class="item-footer">
      <div
          v-if="dictData.dictionary.positive_weight || dictData.dictionary.negative_weight"
          class="item-footer__performed"
      >
        <span
            v-if="dictData.dictionary.positive_weight"
            :style="{background:dictData.dictionary.color_success}"
        >
          +{{ dictData.dictionary.positive_weight }}
        </span>
        <span
            v-if="dictData.dictionary.negative_weight"
            :style="{background:dictData.dictionary.color_reject}"
        >
          -{{ dictData.dictionary.negative_weight }}
        </span>

      </div>

      <div class="item-footer__content">
        <div v-if="dictData.dictionary.found.length">
          <p>Найденые фразы:</p>
          <div class="high-lite-block">
            <div
                class="client"
                v-if="propsCallReverse
                     ?dictData.dictionary.found[0]['search_text_highlight_one']
                     :dictData.dictionary.found[0]['search_text_highlight_two']"
                @click="propsGetHighlight(
                     propsCallReverse
                     ? {data:dictData.dictionary.found[0]['search_text_highlight_one'][0],reverse:dictData.dictionary.reverse,target:'client'}
                     : {data:dictData.dictionary.found[0]['search_text_highlight_two'][0],reverse:dictData.dictionary.reverse,target:'client'})"
            >
              <div>
                <p>
                <span class="client-span">
                  Клиент:
                </span>
                  <span
                      v-for="word in propsCallReverse
                     ? dictData.dictionary.found[0]['search_text_highlight_one']
                     : dictData.dictionary.found[0]['search_text_highlight_two']"
                  >
                  <i
                      class="i"
                      v-for="item in word"
                      v-html="item"
                  ></i>
                </span>
                </p>
              </div>
            </div>
            <div
                class="employee"
                v-if="propsCallReverse
                    ?dictData.dictionary.found[0]['search_text_highlight_two']
                    :dictData.dictionary.found[0]['search_text_highlight_one']"
                @click="propsGetHighlight(propsCallReverse
                        ? {data:dictData.dictionary.found[0]['search_text_highlight_two'][0],reverse:dictData.dictionary.reverse,target:'employee'}
                        : {data:dictData.dictionary.found[0]['search_text_highlight_one'][0],reverse:dictData.dictionary.reverse,target:'employee'}                        )"
            >
              <div>
                <p>
                <span class="employee-span">
                  Сотрудник:
                </span>
                  <span
                      v-for="word in propsCallReverse
                     ? dictData.dictionary.found[0]['search_text_highlight_two']
                     : dictData.dictionary.found[0]['search_text_highlight_one']"
                  >
                  <i
                      class="i"
                      v-for="item in word"
                      v-html="item"
                  ></i>
                </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <p v-else></p>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "dictListItem",
  props: {
    dictData: {
      type: Object
    },
    propsGetHighlight: {
      type: Function
    },
    propsCallReverse: {}
  },
  mounted() {
    this.prepareDictionaryDescription()
  },
  methods: {
    prepareDictionaryDescription() {
      if(this.dictData.dictionary.description) {
        this.dictData.dictionary.description =  this.dictData.dictionary.description.split('#');
      }


    }
  }

}
</script>

<style lang="scss" scoped>
.item {

  border-radius: 5px;
  user-select: none;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0px #8080802e;

  .item-header {
    padding: 10px 10px 5px;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: #555;
      font-weight: 500;
      line-height: 1.3;
      //white-space: nowrap; /* Запрещаем перенос строк */
      //overflow: hidden; /* Обрезаем все, что не помещается в область */
      //text-overflow: ellipsis;
      font-size: 13px;
    }

    button {
      cursor: pointer;
      padding: 5px;
      border: none;
      background: none;

      i {
        color: #bababa;
      }
    }

  }

  .dict-description {
    padding: 0 10px;

    ul {
      padding: 5px 10px 0 12px;
      height: 65px;
      overflow: auto;
      list-style: square;

      li {

        word-wrap: break-word;
        list-style: square;
        color: #8a8a8a;
        font-size: 11px;
      }
    }

    p {
      margin: 0;
      font-size: 11px;
      color: #8a8a8a;
      padding: 0 10px 0 0;

      span:first-child {
        font-weight: bold;
      }
    }
  }

  .item-footer {
    position: relative;
    background: #ececec;

    &__performed {
      position: absolute;
      top: -10px;
      right: 10px;
      //background: green;
      border-radius: 2px;
      color: #fff;
      font-size: 12px;

      span {
        background: gray;
        padding: 5px 13px;
        border-radius: 4px;
      }
    }

    &__content {
      p {
        margin: 0;
        font-size: 11px;
        color: #8a8a8a;

      }

      padding: 15px 20px 10px;

      .client {
        p {
          font-size: 11px;
          color: #8a8a8a;
          overflow: hidden;
          max-height: 65px;

          ::v-deep span {
            b {
              font-size: 11px;
              color: #24387f;
              font-weight: normal;
            }
          }
        }
      }

      .employee {
        p {
          font-size: 11px;
          color: #8a8a8a;
          max-height: 65px;
          overflow: hidden;

          ::v-deep span {

            b {
              font-size: 11px;
              color: red;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  .client-span {
    color: #24387f;
  }

  .employee-span {
    color: red;
  }

}

.i {
  font-style: normal !important;
}

.high-lite-block {
  display: grid;
  gap: 10px;
}
</style>