<template>
  <div>
    <input
        type="file"
        id="inputFile"
        multiple
        @change="onChange"
        ref="file"
        accept="audio/*"
    >
    <div
        class="add-files"
        ref="receiver"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
    >
<!--      <div></div>-->
      <i style="font-size: 50px; color:#24387f" class="el-icon-upload"></i>
      <p>
        Перетащите сюда файлы <br/> или
        <label class="label" for="inputFile">
          <span>нажмите</span> <br/>
          <i>максимум <b>100</b> файлов <br/> размер одного файла не больше <b>30мб</b></i>
        </label>
      </p>
    </div>
  </div>

</template>

<script>


export default {
  name: "file-receiver",
  props: {
    propsInfo: {
      type: Object
    }
  },
  data() {
    return {}
  },
  methods: {
    onChange() {
      let filesData = [];

      [...this.$refs.file.files].forEach(el => {
        filesData.push(
            {
              agentName: this.propsInfo.agentName,
              agentPhone: this.propsInfo.agentPhone,
              category: {label:this.propsInfo.category.label,value:this.propsInfo.category.value},
              date: this.propsInfo.date,
              location: this.propsInfo.location,
              revers: this.propsInfo.revers,
              state: this.propsInfo.state,
              subLocation: this.propsInfo.subLocation,
              type: this.propsInfo.type,
              upload: false,
              file: el
            }
        )
      })
      this.$emit('addFile', filesData)
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      let filesData = [];
      [...e.dataTransfer.files].forEach(el => {
        filesData.push(
            {
              agentName: this.propsInfo.agentName,
              agentPhone: this.propsInfo.agentPhone,
              category: this.propsInfo.category,
              date: this.propsInfo.date,
              location: this.propsInfo.location,
              revers: this.propsInfo.revers,
              state: this.propsInfo.state,
              subLocation: this.propsInfo.subLocation,
              type: this.propsInfo.type,
              file: el
            }
        )
      })
      this.$refs.file.files = e.dataTransfer.files;
      this.$emit('addFile', [...e.dataTransfer.files])
      this.isDragging = false;
    },
  },

}
</script>

<style lang="scss" scoped>

.add-files {
  transition: .3s;

  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.13);
  width: 100%;
  min-height: 340.88px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background: #fff;

  &.dragover {
    background-color: #fdf6f2;
    outline-offset: -17px;
  }

  div {
    position: relative;
    padding: 20px;

    &:before, &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: black;
      left: 0;

    }

    &:after {
      transform: rotate(-90deg);
    }
  }

  p {
    color: black;
    padding: 20px;
    margin: 0;
    font-size: 20.555555555px;
    text-align: center;
  }

  .label {
    span {
      cursor: pointer;
      font-weight: bold !important;
      color: #24387f;
    }

    i {
      font-size: 12px;
      color: #b5b5b5;

      b {
        font-size: 12px;
        text-decoration: underline;
      }
    }

  }
}

#inputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -10;

  &:focus {
    outline: none;
  }
}


</style>