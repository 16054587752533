<template>

  <li class="call-list__item"
  >
    <div class="call-list__item-date">
      <span>дата звонка</span>
      <span>
             <el-date-picker
                 v-model="file.date"
                 :picker-options="pickerOptions"
                 type="datetime"
                 placeholder="Выбрать дата и время">
              </el-date-picker>
          </span>
    </div>
    <div class="call-list__item-call-type">
      <span>Тип звонка</span>
      <span>
          <el-select v-model="file.type" placeholder="Выбрать тип">
            <el-option
                v-for="item in optionsTypeCall"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
      </span>
    </div>
    <div class="call-list__item-call-client">
      <span>ФИО сотрудника</span>
      <span>
            <el-input placeholder="Ввести фио" v-model="file.agentName"></el-input>
      </span>
    </div>
    <div class="call-list__item-call-client">
      <span>Телефон сотрудника</span>
      <span>
        <el-input placeholder="Ввести номер" v-model="file.agentPhone"></el-input>
      </span>
    </div>
    <div class="call-list__item-call-client">
      <el-tooltip class="item" effect="dark" content="какой то текст что бы обьяснить что это такое"
                  placement="top">
        <span>Реверс</span>
      </el-tooltip>
      <span>
             <el-select v-model="file.revers" placeholder="Выбрать">
                <el-option
                    v-for="item in optionsReversCall"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
          </span>
    </div>
    <div class="call-list__item-call-client">
      <span>Название файла</span>

      <el-tooltip v-if="file.file['name'].length > 40" class="item" effect="dark" :content="file.file['name']" placement="top">
        <p>{{ file.file['name'] }}</p>
      </el-tooltip>
      <p v-else>{{ file.file['name'] }}</p>
    </div>
    <div class="call-list__item-btns">
      <my-button :disabled="loading" @click="$emit('remove', file)">
        <span>удалить</span>
      </my-button>
      <my-button :disabled="loading" :class="loading?'upload':''" @click="uploadFile">
        <span v-if="!loading">отправить</span>
        <my-spinner v-if="loading" class="spinner-btn"/>
      </my-button>
      <my-button v-if="file.upload" :disabled="loading" :class="loading?'upload':''" @click="uploadFile">
        <span v-if="!loading">отправлено</span>

      </my-button>

    </div>
  </li>

</template>

<script>
import MyButton from "@/components/UI/MyButton.vue";
import MySlider from "@/components/UI/MySlider.vue";
import MySpinner from "@/components/UI/MySpinner.vue";

export default {
  name: "uploader-list-item",
  components: {MySpinner, MySlider, MyButton},
  props: {
    file: {
      type: Object
    },

    propsStateUploadsAllFile:{
      type:Boolean
    }
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      loading: false,
      optionsTypeCall: [{value: 'in', label: 'Входящий'}, {value: 'out', label: 'Исходящий'}],
      optionsReversCall: [{value: '0', label: 'нет'}, {value: '1', label: 'да'}],
    }
  },
  mounted() {
    // console.log(this.file)
  },

  methods: {
    uploadFile() {
      this.loading = true
      this.$emit('send', this.file)
    }
  },
  watch:{
    propsStateUploadsAllFile(){
      this.loading = true
      this.uploadFile()
    },
    dateCallValue(){
      // console.log(this.dateCallValue)
    }

  }
}
</script>

<style lang="scss" scoped>

.call-list__item {
  margin-bottom: 3px;
  overflow: auto;
  display: grid;
  grid-template-columns: 140px  90px 170px 120px 65px 300px 1fr;
  align-items: center;
  height: 40px;
  line-height: 48px;
  background-color: #fff;
  color: #b5b5b5;
  cursor: pointer;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  font-weight: 500;
  transition: border-bottom-color .3s;
  outline: none;
  border-radius: 7px;
  padding: 0 10px;
  gap: 5px;

  div {
    padding: 0 5px;
    display: grid;
    gap: 3px;
    align-items: center;

    span {
      line-height: 1;
      text-align: center;

      &:first-child {
        max-width: 105px;
        text-align: start;
        font-size: 9px;
      }

      &:last-child {
        display: flex;
        align-items: center;
        height: 20px;
        font-weight: normal;
        color: #000;
        font-size: 12px;
      }
    }

    p {
      width: 100%;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      font-weight: normal;
      color: #000;
      font-size: 12px;
    }
  }

  &-btns {
    display: flex !important;
    justify-content: flex-end;

    button {
      font-size: 12px;
      font-weight: 400;
      background: inherit;
      padding: 2px 10px;
      margin: 0 2px;


      &:hover {
        span {
          color: #fff !important;
        }
      }

      span {
        font-size: 12px;
        font-weight: 400;
      }

      &:first-child {
        border-color: #d90000;

        span {
          color: #d90000;
        }

        &:hover {
          background: #d90000;
          border-color: #d90000;
        }
      }

      &:last-child {
        min-width: 82px;

        span {
          color: #24387f;
        }

        &:hover {
          background: #24387f;
          border-color: #24387f;

        }
      }

      &:first-child[disabled] {
        border-color: #8a8a8a !important;

        span {
          color: #8a8a8a !important;
        }

        &:hover {
          background: inherit !important;
          border-color: #8a8a8a !important;

          span {
            color: #8a8a8a !important;
          }
        }
      }
    }

    .upload {
      background: #24387f;

      ::v-deep {
        i {
          color: #fff !important;
        }
      }

    }
  }


  ::v-deep {
    .el-input__prefix, .el-input__suffix {
      display: none;
    }

    .el-input, .el-date-editor, .el-select {
      padding: 0;
    }

    input {
      padding: 0;
      border: none;
      background: inherit;
      height: 20px;
      font-size: 12px;
      cursor: pointer;
      width: 100%;
      font-family: inherit;

      &::-webkit-input-placeholder {
        //color: #24387f;
        //color: #a20000;
        color: #8d8d8d;

      }
    }
  }
}

.separator {
  height: 100%;
  width: 1px;
  background: #ebeef5;
}

.spinner-btn {
  ::v-deep {
    .el-icon-loading {
      font-size: 20px !important;
    }
  }

  i {
    font-size: 10px !important;
  }

}
</style>