<template>
  <el-row type="flex" justify="center">
    <el-col
        class="checklist-form-container"
        :span="22"
    >
      <el-row>
        <el-row v-if="selectedChecklist.id">
          <h4>Редактирование чеклиста {{selectedChecklist.name}}</h4>
        </el-row>

        <el-row v-else>
          <h4>Добавление чеклиста</h4>
        </el-row>

        <el-row class="form-fields">
          <el-row>
            <el-col :span="6">Название</el-col>
            <el-col :span="18">
              <el-input
                  type="text"
                  placeholder="Введите название"
                  v-model="selectedChecklist.name"
              ></el-input>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">Порог срабатывания</el-col>
            <el-col :span="18">
              <el-input
                  type="number"
                  :rows="3"
                  placeholder="Введите описание"
                  v-model="selectedChecklist.limit_result">
              </el-input>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">Цвет срабатывания</el-col>
            <el-col :span="18">
              <el-color-picker v-model="selectedChecklist.color_success"/>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">Цвет невыполнения</el-col>
            <el-col :span="18">
              <el-color-picker v-model="selectedChecklist.color_reject"/>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">Дата начала</el-col>
            <el-col :span="18">
              <el-date-picker
                  v-model="date_start"
                  type="datetime"
                  value-format="timestamp"
                  format="dd.MM.yyyy HH:mm:ss"
                  placeholder="Выберите дату и время">
              </el-date-picker>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">Дата завершения</el-col>
            <el-col :span="18">
              <el-date-picker
                  v-model="date_stop"
                  type="datetime"
                  format="dd.MM.yyyy HH:mm:ss"
                  value-format="timestamp"
                  placeholder="Выберите дату и время">
              </el-date-picker>
            </el-col>
          </el-row>

        </el-row>


      </el-row>
      <el-row class="command-buttons">
        <el-button
            plain
            round
            @click="cancel"
        >
          Отмена
        </el-button>

        <el-button
            type="primary"
            round
            @click="save"
        >
          Сохранить
        </el-button>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "checklist-form",
  props: {
    selectedChecklist :{},
  },
  data() {
    return {
      date_start: null,
      date_stop: null
    }
  },
  mounted() {
    this.date_start = this.selectedChecklist.date_start ? this.selectedChecklist.date_start * 1000 : null;
    this.date_stop  = this.selectedChecklist.date_stop  ? this.selectedChecklist.date_stop  * 1000 : null;
  },
  watch: {

  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.selectedChecklist.date_start = this.date_start ? this.date_start / 1000 : null;
      this.selectedChecklist.date_stop  = this.date_stop  ? this.date_stop  / 1000 : null;
      this.$emit('save')
    }
  }
}
</script>

<style lang="scss" scoped>

  .checklist-form-container {
    h4 {
      text-align: center;
    }

    .form-fields {
      > .el-row {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        > .el-col {
          &:first-child {
            padding-right: 10px;
            text-align: right;
          }
        }
      }
    }

    .command-buttons {
      text-align: center;
      margin-bottom: 25px;
    }
  }

</style>