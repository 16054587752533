<template>
  <el-row class="checklists-transfer" v-loading="loading">
    <h4>Редактироване чеклистов категории</h4>
    <el-input class="search" v-model="search" placeholder="Поиск"></el-input>
    <el-row class="info">

    </el-row>
    <el-row class="available">
      <el-table
          :data="prepareAvailableDataForTransfer"
          width="100%"
          @selection-change="moveToSelected"
      >
        <el-table-column
            fixed="left"
            type="selection"
            width="40"
        >
        </el-table-column>
        <el-table-column
            label="Доступные"
        >
          <el-table-column
              label="Название"
              width="150"
              show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>

          <el-table-column
              label="Дата начала"
              align="center"
              width="150"
          >
            <template slot-scope="scope">
              <el-date-picker
                  v-model="scope.row.date_start"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="timestamp"
                  placeholder="Выберите дату"
              >
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Дата окончания"
              align="center"
              width="150"
          >
            <template slot-scope="scope">
              <el-date-picker
                  v-model="scope.row.date_stop"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="timestamp"
                  placeholder="Выберите дату">
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Успех"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_success"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Отклонен"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_reject"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Лимит результата"
              width="130"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.limit_result" :value="scope.row.limit_result"></el-input-number>
            </template>
          </el-table-column>

          <el-table-column
              label="Уникальные результаты"
              align="center"
              width="180"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.unique_result"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
              label="Реверсивный"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.reverse"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
              label="Искать во входящих"
              align="center"
              width="140"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.search_in_call"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
              label="Искать в исходящих"
              align="center"
              width="140"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.search_out_call"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row class="transfer">
      <el-row>
        <el-button :disabled="!preAvailableChecklists.length" @click="toAvailable">
          <i class="el-icon-arrow-up"></i>
        </el-button>
      </el-row>
      <el-row>
        <el-button :disabled="!preSelectedChecklists.length" @click="toSelected">
          <i class="el-icon-arrow-down"></i>
        </el-button>
      </el-row>


    </el-row>

    <el-row class="selected">
      <el-table
          :data="prepareSelectedDataForTransfer"
          width="100%"
          @selection-change="moveToAvailable"
      >
        <el-table-column
            fixed="left"
            type="selection"
            width="40"
        >
        </el-table-column>
        <el-table-column
            label="Выбранные"
        >
          <el-table-column
              label="Название"
              width="150"
              show-overflow-tooltip
          >
            <template slot-scope="scope">{{ scope.row.checklist.name }}</template>
          </el-table-column>

          <el-table-column
              label="Дата начала"
              align="center"
              width="150"
          >
            <template slot-scope="scope">
              <el-date-picker
                  v-model="scope.row.date_start"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="timestamp"
                  placeholder="Выберите дату"
                  disabled
              >
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Дата окончания"
              align="center"
              width="150"
          >
            <template slot-scope="scope">
              <el-date-picker
                  v-model="scope.row.date_stop"
                  type="date"
                  format="dd.MM.yyyy"
                  value-format="timestamp"
                  placeholder="Выберите дату"
                  disabled
              >
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Успех"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_success" disabled></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Отклонен"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_reject" disabled></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Лимит результата"
              width="130"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number
                  v-model="scope.row.limit_result"
                  :value="scope.row.limit_result"
                  disabled
              >

              </el-input-number>
            </template>
          </el-table-column>

          <el-table-column
              label="Уникальные результаты"
              align="center"
              width="180"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.unique_result"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
              >
              </el-switch>

            </template>
          </el-table-column>

          <el-table-column
              label="Реверсивный"
              align="center"
              width="100"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.reverse"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
              label="Искать во входящих"
              align="center"
              width="140"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.search_in_call"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column
              label="Искать в исходящих"
              align="center"
              width="140"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.search_out_call"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-row>

  </el-row>
</template>

<script>
export default {
  name: "category-dictionaries-transfer",
  props: {
    categoryId: {},
    selectedChecklists: {},
    refresh: {}
  },
  data() {
    return {
      search: '',
      show: true,
      loading: false,
      checklists: [],
      preAvailableChecklists: [],
      preSelectedChecklists: [],
      availableChecklists: [],
    }
  },
  computed: {
    prepareSelectedDataForTransfer: function () {
      this.selectedChecklists.map(item => this.prepareDate(item))
      return this.withSearch(this.selectedChecklists)
    },
    prepareAvailableDataForTransfer: function () {
      let selected = this.selectedChecklists.map(item => item.checklist_id)
      this.availableChecklists = this.checklists.filter(item => !(selected).includes(item.id))
      return this.withSearch(this.availableChecklists)
    },
  },
  mounted() {
    this.getChecklists();
  },
  methods: {
    withSearch(checklists) {
      return checklists.filter(data => {
        let include;
        if (typeof data['checklist'] !== "undefined") {
          include = data.checklist.name.toLowerCase().includes(this.search.toLowerCase())
        } else {
          include = data.name.toLowerCase().includes(this.search.toLowerCase())
        }
        return !this.search || include
      })
    },
    prepareDate(item) {
      item.date_start = item.date_start ? item.date_start * 1000 : null;
      item.date_stop = item.date_stop ? item.date_stop * 1000 : null;
      return item;
    },
    getChecklists() {
      this.$api.checklist.get()
          .then(data => {
            this.checklists = data.map(item => this.prepareDate(item))
          })
          .finally(() => {
            this.loading = false;
          })
    },
    moveToSelected(preSelected) {
      this.preSelectedChecklists = preSelected;
    },
    moveToAvailable(preAvailable) {
      this.preAvailableChecklists = preAvailable;
    },

    toSelected() {
      this.loading = true;

      this.preSelectedChecklists.forEach(item => {

        item.date_start = item.date_start ? item.date_start / 1000: null;
        item.date_stop = item.date_stop ? item.date_stop / 1000 : null;

        this.$api.category.saveCategoryChecklist({
          category_id: this.categoryId,
          checklist_id: item.id,
          limit_result: item.limit_result,
          color_success: item.color_success,
          color_reject: item.color_reject,
          search_in_call: item.search_in_call,
          search_out_call: item.search_out_call,
          unique_result: item.unique_result,
          date_start: item.date_start,
          date_stop: item.date_stop,
          reverse: item.reverse,
        })
            .then(data => {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Чеклисты успешно добавлены',
              });
            })
            .finally(() => {
              this.loading = false;
              // this.selectedChecklists.map(item => this.prepareDate(item))
              this.getChecklists();
              this.$emit('reload')
            })
      })

    },
    toAvailable() {
      this.loading = true;
      this.$api.category.deleteCategoryChecklists({
        category_id: this.categoryId,
        checklists: this.preAvailableChecklists.map(item => item.checklist.id)
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Чеклисты уаспешно удалены',
            });
          })
          .finally(() => {
            this.loading = false;
            this.getChecklists();
            this.$emit('reload')
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.checklists-transfer {
  margin: 0 20px 50px 20px;

  .info {
    margin-bottom: 10px;
    div {
      &:first-child {
        margin-bottom: 10px;
      }
      &:nth-child(2),&:nth-child(3) {
        display: grid;
        grid-template-columns: 120px 70px;
        margin-left: 20px;
        span {
          &:last-child {
            padding-left: 20px;
            i {
              font-weight: bold;
              font-size: 13px;
              color: #252525;
            }
          }
        }
      }
    }
  }

  h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .search {
    margin-bottom: 10px;

    ::v-deep .el-input__inner {
      width: 100%;
      height: 30px;
    }
  }

  .available, .selected {
    max-height: 30vh;
    padding: 0 20px 0 0;
    overflow-y: auto;
  }

  .transfer {
    text-align: center;

    .el-button {
      padding: 0 10px;
      margin: 5px;

      i {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  ::v-deep .el-table {
    //overflow-x: auto;
    &__body {
      &-wrapper {
        //max-height: 24vh;
        //overflow-y: auto;
      }
    }
    &__cell {
      border-right: none;
      border-left: none;
      padding: 2px 0;
      color: #252525;
      font-size: 11px;

      .cell {
        white-space: nowrap;
      }

      .el-date-editor {
        width: 100%;
        padding: 5px 0;

        .el-input {
          &__inner {
            padding: 12px 7px 12px 0;
            text-align: right;
          }

          &__prefix {
            left: 0;
            top: -2px;
          }
        }
      }

      .el-color-picker {
        position: relative;
        top: 5px;

        &__mask,
        &__trigger {
          height: 25px;
          width: 25px;
        }
      }

      .el-input {
        &-number {
          width: 100px;

          &__decrease,
          &__increase {
            width: 20px;
            height: 18px;
            top: 9px;

            i {
              position: relative;
              top: -8px;
            }
          }
        }

        &__inner {
          height: 20px;
          line-height: 20px;
          padding: 0;
        }
      }

      .el-switch {
        &__core {
          height: 18px;

          &:after {
            top: 0;
          }
        }
      }
    }
  }
}
</style>