<template>
  <div class="container">
    <el-drawer
        :style="{display:stateDrawer?'flex':'none'}"
        title="I am the title"
        :visible.sync="stateDrawer"
        :with-header="false"
        size="95%"
    >
      <drawer-call-info
          :categoryName="categoryName"
          :stateDrawer="stateDrawer"
          :callId="selectedCallId"
          :dateCall="selectedDateCallId"
          :clientNumber="clientNumber"
          :clientName="clientName"
          :period="propsStartEndDateForCallList"
      />
    </el-drawer>


    <div class="block-list" v-loading="loading">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item
            v-for="(call,index) in calls"
            :key="call.id"
            :name="call.id"
            size="70%"
        >
          <template slot="title">
            <div class="accordion-title">
              <ul @click="selectedCallId = call['id']">
                <li class="index">
                  <p>{{ call['id'] }}</p>
                </li>

                <li>
                  <p>Дата звонка</p>
                  <p> {{ call['date_call'] ? call['date_call'] : 'не заполнено' }}</p>
                </li>
                <li>
                  <p>Тип звонка</p>
                  <!--                  <p>{{ call['call_type'] === 'in' ? 'Входящий' : 'Исходящий' }}</p>-->
                  <p>{{ textTypeCall(call['call_type']) }}</p>
                </li>
                <li>
                  <p>Тел. клиента</p>
                  <p>{{ call['phone_number'] }}</p>
                </li>
                <li>
                  <p>Имя</p>
                  <p>{{ call['subject_name'] ? call['subject_name'] : 'не заполнено' }}</p>
                </li>
                <li>
                  <p>Время разговора</p>
                  <p> {{ call['call_duration'] }}</p>
                </li>
                <li>
                  <p>Время ожидания</p>
                  <p> {{
                      call['wait_duration'] && call['wait_duration'] !== 0 ? call['wait_duration'] : 'не заполнено'
                    }}</p>
                </li>
                <li>
                  <p>ID звонка</p>
                  <p v-if="call['call_id_user'] && call['call_id_user'].length < 10">{{call['call_id_user']}}</p>
                  <el-tooltip v-if="call['call_id_user'] && call['call_id_user'].length >= 10" placement="top">
                    <div slot="content">{{call['call_id_user']}}</div>
                    <p>{{call['call_id_user'].slice(0,10)}}</p>
                  </el-tooltip>
                </li>
                <li>
                  <p>Проверен</p>
                  <p>нет</p>
                </li>
              </ul>
            </div>
          </template>
          <div
              class="accordion-content"
              v-for="info in call['call_infos']"
              :data-call-id="info.id"
              :data-date-call="call.date_call?call.date_call:'Не заполнен'"
              :data-call-info-status="info.status_audio_id"
              :data-type-call="textTypeCall(call.call_type)"
              :data-client-number="call.phone_number ? call.phone_number:'Не заполнен'"
              :data-client-name="call.subject_name?call.subject_name:'Не заполнен'"
              @click="showDrawer"
              :style="{background:setBackgroundToItemList(info['status_audio_id']),}"
          >
            <ul>
              <li>
                <p>Статус</p>
                <!--                                <p>{{ info['status_audio_id'] ? info['status_audio_id'] : '&#45;&#45;' }}</p>-->
                <el-tooltip class="my-tooltip" effect="dark" :content="setToolTipDescription(info['status_audio_id'])"
                            placement="top">
                  <el-button>
                    <img v-if="info['status_audio_id']&&(info['status_audio_id']===8) " src="@/assets/img/ok.png"
                         alt="icon">
                    <img
                        v-if="info['status_audio_id']&&(info['status_audio_id']===1
                                   || info['status_audio_id']===3
                                   || info['status_audio_id']===9
                                   || info['status_audio_id']===10
                                   || info['status_audio_id']===11
                                  ) " src="@/assets/img/bad.png" alt="icon">
                    <img
                        v-if="info['status_audio_id']&&(info['status_audio_id']===2
                                   || info['status_audio_id']===4
                                   || info['status_audio_id']===5
                                   || info['status_audio_id']===6
                                   || info['status_audio_id']===7
                                   || info['status_audio_id']===12
                                   )" src="@/assets/img/info.png" alt="icon">
                  </el-button>
                </el-tooltip>
              </li>
              <li>
                <p>Ожидание</p>
                <p>{{ info['wait_time'] && info['wait_time'] !== 0 ? info['wait_time'] : '---' }}</p>
              </li>
              <li>
                <p>Внутр. Номер</p>
                <p>{{ info['extension_number'] ? info['extension_number'] : '---' }}</p>
              </li>
              <li>
                <p>время разг.</p>
                <p>{{ info['ring_time'] ? info['ring_time'] : '---' }}</p>
              </li>
              <li>
                <p>Оператор</p>
                <p>{{ info['agent_name'] ? info['agent_name'] : '---' }}</p>
              </li>
              <li v-if="info['status_audio_id']">
                <p>Баллы <span class="plus">+</span> | словарей</p>
                <p v-if="typeof call['dictionaries_score_positive'] !== 'undefined'">
                  <span class="plus"> {{ call['dictionaries_score_positive'] }}</span> |
                  {{ call['dictionaries_count'] }}
                </p>
                <p v-else><span class="plus"> 00</span> | 00</p>
              </li>
              <li
                  v-if="info['status_audio_id']"
              >
                <p>Баллы <span class="minus">-</span> | словарей</p>
                <!--                <p><span class="minus"> 00</span> | 00</p>-->
                <p v-if="typeof call['dictionaries_score_negative'] !== 'undefined'">
                  <span class="minus"> {{ call['dictionaries_score_negative'] }}</span> |
                  {{ call['dictionaries_count'] }}
                </p>
                <p v-else><span class="minus"> 00</span> | 00</p>
              </li>
              <li v-if="info['status_audio_id']">
                <p>% чек-листов| чеклисты</p>
                <p> {{ isNaN(+call['checklists_percent_average'])?0:call['checklists_percent_average'] }} | {{ call.checklists_count }} </p>
              </li>
            </ul>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div
          v-if="!calls.length"
          class="no-calls"
      >
        <h5>За выбраный период данных нет</h5>
      </div>
    </div>
    <paginator
        v-if="totalCountCalls"
        :paginator.sync="paginator"
        @page-changed="getCategoryCalls"
    ></paginator>

  </div>
</template>

<script>

import MySpinner from "@/components/UI/MySpinner.vue";
import DrawerCallInfo from "@/components/newCatigories/drawerCallInfo.vue";
import Paginator from "@/components/Paginator.vue";
import {paginator} from "@/mixins/paginator";
import MyDatePeriodPicker from "@/components/newCatigories/myDatePeriodPicker.vue";


export default {
  name: "callList",
  components: {MyDatePeriodPicker, Paginator, DrawerCallInfo, MySpinner},
  mixins: [paginator],
  props: {
    categoryId: {
      type: Number,
    },
    categoryName: {
      type: String,
    },
    propsStartEndDateForCallList: {
      type: Object
    },
    propsGetTotalCountCallListLength: {
      type: Function
    },
    filters: {
      type: Object
    }

  },
  data() {
    return {
      loading: true,
      activeNames: [],
      calls: [],
      stateDrawer: false,
      selectedCallId: null,
      selectedDateCallId: '',
      selectedCalls: [],
      optionCountCall: [
        {value: 15, label: 15},
        {value: 30, label: 30},
        {value: 60, label: 60},
      ],
      valueSelectCountCalls: 15,
      totalCountCalls: 0,
      typeCall: null,
      clientNumber: null,
      clientName: null,
      search_in_channel: {
        one: 'search_text_highlight_one',
        two: 'search_text_highlight_two',
        full: 'search_text_highlight_full'
      }
    };
  },
  mounted() {
    this.getCategoryCalls()
  },
  methods: {
    setBackgroundToItemList(statusId) {
      switch (statusId) {
        case 8:
          return '#d9f3d9'
        case 1 || 3 || 9 || 10 || 11:
          return '#ffd6d6'
        case 2 || 4 || 6 || 7 || 12:
          return '#dfecf9'
      }
    },
    setToolTipDescription(statusId) {
      switch (statusId) {
        case 1:
          return 'Аудио потеряно'
        case 2:
          return 'Успешно загружен (ожидает распознавания)'
        case 3:
          return 'Плохое аудио'
        case 4:
          return 'Ожидает конвертации'
        case 5:
          return 'Конвертировано'
        case 6:
          return 'На распозновании'
        case 7:
          return 'На распозновании'
        case 8:
          return 'Всё отлично'
        case 9:
          return 'Эта запись является дублем другой записи.'
        case 10:
          return 'Эта запись является дублем другой записи.'
        case 11:
          return ' Ошибка обработки (распознавания)'
        case 12:
          return ' Ошибка обработки (распознавания)'
        default:
          break
      }
    },
    getCategoryCalls() {
      this.loading = true

      this.$api.category.getCategoryCalls({
        filters: this.filters,
        category_id: this.categoryId,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        date_start: this.propsStartEndDateForCallList ? this.propsStartEndDateForCallList?.start : '',
        date_end: this.propsStartEndDateForCallList ? this.propsStartEndDateForCallList?.end : '',
      })
          .then(({data, paginator}) => {

            this.calls = data.calls;
            this.paginatorFill(paginator);
            this.propsGetTotalCountCallListLength(paginator.totalCount)
            this.totalCountCalls = paginator.totalCount
          })
          .finally(() => {
                this.loading = false
              }
          )
    },
    handleChange(val) {

      if (val.includes(this.selectedCallId)) {
        this.getCategoryCall(this.selectedCallId)
      }

    },
    resetAllSelectHighLite() {
      const allTextItems = [...document.querySelectorAll('.recognized-text li i')].filter((item) => item.attributes['data-high-lite']);
      allTextItems.forEach(el => {
        el.classList.remove('highlight')
      })
    },
    getCategoryCall(callId) {
      if (this.activeNames.includes(callId))

        this.$api.category.getCategoryCall({
          call_id: callId,
          date_start: this.propsStartEndDateForCallList ? this.propsStartEndDateForCallList?.start : '',
          date_end: this.propsStartEndDateForCallList ? this.propsStartEndDateForCallList?.end : '',
        })
            .then(data => {
              let index = this.calls.findIndex(item => item.id === callId);

              let scoreData = this.getScoresOnDictionaries(data.dictionaries_search)

              this.calls[index].dictionaries_score_positive = scoreData.positive
              this.calls[index].dictionaries_score_negative = scoreData.negative
              this.calls[index].dictionaries_count = scoreData.dictionariesCount

              let checklistScore = this.getChecklistsPercentAverage(data.checklists_search)

              this.calls[index].checklists_percent_average = checklistScore.percentage
              this.calls[index].checklists_count = checklistScore.count


            })
    },
    getScoresOnDictionaries(data) {
      let score = {positive: 0, negative: 0, dictionariesCount: 0};

      if (data.system.length) {
        data.system.forEach(item => {
          score.positive += item.positive_weight
          score.negative += item.negative_weight
          score.dictionariesCount++
        })
      }
      if (data.client.length) {
        data.client.forEach(item => {
          score.positive += item.positive_weight
          score.negative += item.negative_weight
          score.dictionariesCount++
        })
      }

      return score;
    },
    isSuccessDictionary(dictionary) {

      let search_in_channel = this.getChannelForCheck(dictionary);

      if (!search_in_channel) {
        return false;
      }

      return this.getSuccessDictionaryByChannel(dictionary, search_in_channel);

    },
    getChannelForCheck(dictionary) {
      switch (true) {
        case dictionary.search_in_channel_1 === 1 && dictionary.search_in_channel_2 === 1:
          return this.search_in_channel.full;
        case dictionary.search_in_channel_1 === 1 && dictionary.search_in_channel_2 === 0:
          return this.search_in_channel.one;
        case dictionary.search_in_channel_1 === 0 && dictionary.search_in_channel_2 === 1:
          return this.search_in_channel.two;
      }
      return false;
    },

    getSuccessDictionaryByChannel(dictionary, channel) {

      if ((dictionary.found.length && dictionary.found[0] && !dictionary.reverse) ||
          (!dictionary.found.length && dictionary.reverse)) {
        return true
      }
      return false;
    },
    getSuccessDict(data) {
      let newDict = [...data]

      let newFilteredData = []

      newDict.forEach((checkList) => {
        let successDictArr = []
        let negativeDictArr = []
        const allDictInCheckList = checkList.checklist.dictionaries
        for (let obj in allDictInCheckList) {
          allDictInCheckList[obj].forEach(item => {

            if (this.isSuccessDictionary(item.dictionary)) {
              successDictArr.push(item)
            } else {
              negativeDictArr.push(item)
            }
          })
        }
        const totalCountDict = successDictArr.length + negativeDictArr.length
        let completionRate = Math.round((100 * successDictArr.length) / totalCountDict)


        newFilteredData.push({
          ...checkList,
          successDictArr: successDictArr,
          negativeDictArr: negativeDictArr,
          completionRate: totalCountDict ? completionRate : 0,
          totalCountDict: totalCountDict
        })
      })
      return newFilteredData
    },
    getChecklistsPercentAverage(data) {
      let successfully = {
        percentage: 0,
        totalResult: 0,
        count: 0,
      };

      data = this.getSuccessDict(data)

      data.forEach(item => {
        successfully.count++

        let totalResult = 0

        item.successDictArr.forEach((el) => {
          totalResult += el.positive_weight ? el.positive_weight : el.negative_weight
        })
        if (totalResult) {
          let percentage = +(totalResult / item.limit_result * 100)
          if (percentage > 100) {
            percentage = 100;
          }
          successfully.percentage += percentage
        }
      })


      successfully.percentage = (successfully.percentage / successfully.count).toFixed(2);

      return successfully;
    },
    showDrawer(e, callId = null) {
      if (!callId) {
        console.log(e.currentTarget)
        if(e.currentTarget.attributes['data-call-info-status'].value !== '8') {
          return;
        }
        const selectedCalls = e.currentTarget.attributes['data-call-id'].value
        const selectedDateCalls = e.currentTarget.attributes['data-date-call'].value
        const typeCall = e.currentTarget.attributes['data-type-call'].value
        const clientNumber = e.currentTarget.attributes['data-client-number'].value
        const clientName = e.currentTarget.attributes['data-client-name'].value
        this.stateDrawer = true
        this.selectedCallId = +selectedCalls
        this.selectedDateCallId = selectedDateCalls
        this.typeCall = typeCall
        this.clientNumber = clientNumber
        this.clientName = clientName
      } else {
        this.selectedCallId = callId
        this.stateDrawer = true
        this.resetAllSelectHighLite()
      }

    },
    textTypeCall(data) {
      switch (data) {
        case 'in':
          return 'Входяший'
        case 'out':
          return 'Исходящий'
        default:
          return 'Не заполнен'
      }
    }
  },
  watch: {
    propsStartEndDateForCallList() {
      if (this.propsStartEndDateForCallList) {
        this.getCategoryCalls()
      }
    },
  },
  computed: {}

}
</script>

<style lang="scss" scoped>

.header-calls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;

    .select {
      width: 100px;
      max-width: 65px;

      ::v-deep .el-input__inner {
        border: none;
        background: inherit;
        height: 40px;
        font-weight: 700;
        font-family: inherit;
        padding: 0;
        color: #24387f;
        font-size: 16px;
      }

      ::v-deep .el-input {
        width: 70px;
        display: flex;
        align-items: center;
        gap: 10px;

      }

      ::v-deep .el-select__caret {
        font-size: 11px;
      }

      ::v-deep .el-input__suffix {
        display: none;
      }
    }

    .data-picker {
      ::v-deep .el-input__inner {
        height: 30px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-range-separator {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-range-input {
        width: 79%;
      }

      ::v-deep .el-date-editor--datetimerange {
        width: 350px;
      }
    }
  }

  .counts-call {
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;

      .selectCountCalls {
        width: 20px;

        ::v-deep .el-input__inner {
          padding: 0 0 0 0px;
          height: 20px;
          text-align: center;
          font-weight: bold;
          color: #24387f;
          background: inherit;
          border: none;
        }

        ::v-deep .el-input__suffix-inner {
          display: none;
        }
      }
    }
  }

  p {
    margin: 0;
  }

  h4 {
    margin: 0;
    color: #252525;
    font-size: 15px;
  }

  margin-bottom: 20px;
}

.block-list {
  min-height: calc(100vh - 570px);
}

::v-deep .el-collapse-item__header {
  border-radius: 7px;
}

::v-deep .el-collapse-item__header {
  height: 40px;
}

::v-deep .el-collapse-item__content {
  padding-bottom: 10px;
  //border-top: solid 1px #b5b5b5;
}

.accordion-title {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;


  ul {

    display: grid;
    grid-template-columns: 2.5rem  10rem 6rem 8rem 18rem 6rem 6rem 5rem 3rem;
    gap: 20px;

    li {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;

      p {
        &:first-child {
          font-size: 0.5rem;
        }

        &:last-child {
          color: #555;
          font-size: 0.75rem;
          font-weight: normal;
        }
      }
    }
  }

  .index {
    display: flex;
    align-items: flex-end;

    p {
      font-weight: bold;
      color: #555;
      font-size: 1rem;
    }

  }

  * {
    margin: 0;
    padding: 0;
  }
}

.accordion-content {
  * {
    margin: 0;
    padding: 0;
  }

  border-radius: 5px;
  padding: 4px 10px;
  margin: 3px 20px !important;
  background: #dddddd;
  border: solid 1px #dddddd;

  &:hover {
    border: solid 1px #000;
  }

  ul {
    display: grid;
    grid-template-columns: 3rem 5.5rem 7rem 5.5rem 18rem 8rem 6rem 7rem 3rem;
    gap: 20px;
    cursor: pointer;


    li {
      &:nth-child(6), &:nth-child(7) {
        p:first-child {
          text-align: center;

        }


        p:last-child {
          text-align: center;
        }
      }

      p {
        color: #555;
        font-weight: bold;

        &:first-child {
          color: #fff;
          font-size: 0.5rem;
          text-shadow: 0 0 4px rgb(0 0 0 / 50%);


          span {
            font-weight: normal;
          }
        }

        .plus {
          color: green;
        }

        .minus {
          color: red;
        }

        &:last-child {
          margin-top: 3px;
          font-size: 0.75rem;
          font-weight: normal;
        }

        button {
          font-family: inherit;
          border: none;
          background: none;
          font-weight: normal;
          cursor: pointer;
          font-size: 0.75rem;
          color: #24387f;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

::v-deep .el-drawer__body {
  background: #fff;
}

.pagination-block {
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-calls__title {
  ::v-deep .el-select {
    i {
      line-height: 0;
    }

    input {
      width: 160px;
      font-family: inherit;
      font-size: 12px;
    }
  }


  &:hover {
    input {
      border-color: #DCDFE6;

    }
  }
}

::v-deep .el-input__inner {
  height: 30px;
}


::v-deep .el-picker-panel, ::v-deep .el-date-picker ::v-deep .el-popper {
  position: absolute !important;
}


.hideDatePicker {
  position: absolute;
  opacity: 0;
  z-index: -1111;
}

::v-deep .el-select-group__title {
  font-weight: bold;
  font-size: 12px;
}

::v-deep .el-select-dropdown__item {
  font-size: 12px;
}

.no-calls {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-tooltip {
  background: inherit;
  color: #555;
  font-weight: normal;
  font-size: 11px;
  border: none;

  img {
    width: 13px;
    margin-left: 6px;
  }
}
</style>
