import axiosInstance from "./axiosInstance";

import userModule from "../user";
// import rbacModule from "../rbac";
import locationModule from "../location";
import dictionaryModule from "../dictionary";
import categoryModule from "../category";
import questionModule from "../question";
import checklistModule from "../checklist";
import managementModule from "@/api/management";
import notificationModule from "../notification";
import analysisModule from '../analysis'
import generalModule from '../general'
import integrationsModule from "../integrations";


export default {
    user: userModule(axiosInstance),
    // rbac: rbacModule(axiosInstance),
    management: managementModule(axiosInstance),
    location: locationModule(axiosInstance),
    dictionary: dictionaryModule(axiosInstance),
    category: categoryModule(axiosInstance),
    question: questionModule(axiosInstance),
    checklist: checklistModule(axiosInstance),
    notification: notificationModule(axiosInstance),
    analysis:analysisModule(axiosInstance),
    general: generalModule(axiosInstance),
    integration: integrationsModule(axiosInstance),
}