<template>
  <div :class="['my-container']">
    <el-drawer
        title="I am the title"
        :visible.sync="stateDrawer"
        :with-header="false"
    >
      <drawer-locations-content/>
    </el-drawer>

    <dictionary-is-dashboard/>



    <h2 class="h2-title">Локации</h2>
    <!--    <el-breadcrumb separator-class="el-icon-arrow-right">-->
    <!--      <el-breadcrumb-item>Локации</el-breadcrumb-item>-->
    <!--    </el-breadcrumb>-->
    <div class="content">
      <location-card :location="location" v-for="location in dataList" :key="location['label']"
                     @showDrawer="changeVisibility"/>
      <button
          v-if="$store.getters.user.role && $store.getters.user.role.name==='admin'"
          class="btn-add-category"
          type="button"
          @click="categoryManagementModalState=true"
      >
        <div></div>
        <p>Добавить новую</p>
      </button>
    </div>
    <location-management :modalState="categoryManagementModalState" @closeModal="categoryManagementModalState=false"/>
  </div>

</template>

<script>
import DrawerLocationsContent from "@/components/newLocations/DrawerLocationsContent.vue";
import LocationCard from "@/components/newLocations/LocationCard.vue";
import LocationManagement from "@/components/newLocations/LocationsManagement/LocationsManagement.vue";
import DictionaryIsDashboard from "../components/newLocations/dictionaryIsDashboard/dictionaryIsDashboard.vue";

export default {
  name: "NewLocations",
  components: {DictionaryIsDashboard, LocationManagement, LocationCard, DrawerLocationsContent},
  data() {
    return {
      categoryManagementModalState: false,
      stateDrawer: false,
      dataList: [],
      data: [],
      activeLocation: null,
      activeSubLocation: null,
      activeCategory: null,
      showInputsState: null,
      inputNameState: null,
      inputDescriptionState: null,
      castil: true,
    }
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    deleteLocation() {
      this.$api.location.delete({id: this.activeLocation}).then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Локация удалена',
            });
            this.resetAllAdd()
            this.getLocations();
          }
      )
    },
    resetAllAdd() {
      this.castil = false
      this.showInputsState = null
      this.inputNameState = null
      this.inputDescriptionState = null
      this.castil = true
    },
    addLocation() {
      if (!this.inputNameState || !this.inputNameState.length) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Слишком короткое название локации'
        });
        return
      }
      this.$api.location.save({
        name: this.inputNameState, description: this.inputDescriptionState
      }).then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Локация сохранена',
            });
            this.resetAllAdd()
            this.getLocations();
          }
      )
    },
    addSubLocation() {
      if (!this.inputNameState || !this.inputNameState.length) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Слишком короткое название под локации'
        });
        return
      }
      this.$api.location.saveSubLocation({
        name: this.inputNameState, description: this.inputDescriptionState,
        location_id: this.activeLocation
      }).then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Под локация сохранена',
            });
            this.resetAllAdd()
            this.getLocations();
          }
      )
    },
    addCategory() {
      if (!this.inputNameState || !this.inputNameState.length) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Слишком короткое название категории'
        });
        return
      }
      this.$api.category.save({
        name: this.inputNameState, description: this.inputDescriptionState,
        sub_location_id: this.activeSubLocation.replace('undefined-', ''),
        channel_one_name: 'Сотрудник',
        channel_two_name: 'Клиент',

      }).then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Категория сохранена',
            });
            this.resetAllAdd()
            this.getLocations();
          }
      )
    },
    setActiveLocation(loc_id) {
      this.activeLocation = loc_id
    },
    setActiveSubLocation(sub_loc_id) {
      this.activeSubLocation = sub_loc_id
    },
    setActiveCategory(cat_id) {
      this.activeCategory = cat_id
    },
    changeVisibility() {
      this.stateDrawer = !this.stateDrawer
    },
    getLocations() {
      this.loading = true;
      this.$api.location.get()
          .then(data => {
            this.dataList = this.getFormattedDataList(data)
            this.activeLocation = this.dataList[0].id

          })
          .finally(() => {
            this.loading = false;
          })
    },
    getFormattedDataList(data) {
      let locations = []
      if (data.length) {
        data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .forEach(loc => {
                  let location = {id: loc.id, label: loc.name, children: [], category_length: 0}
                  loc['sub_locations'].forEach((sub_loc) => {
                    let subLocation = {id: `${sub_loc['location_id'].id}-${sub_loc.id}`, label: sub_loc.name, children: []}
                    sub_loc['categories'].forEach((cat) => {
                      let category = {
                        id: `${loc.id}-${sub_loc.id}-${cat.id}`,
                        label: cat.name,
                        selfId: cat.id,
                        locationId: location.id,
                        subLocationId: sub_loc.id,
                      }
                      subLocation['children'].push(category)
                      location['category_length'] += 1
                    })
                    location['children'].push(subLocation)
                  })
                  locations.push(location)
                }
            )
      }
      locations.forEach((el) => {
        el.children.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
        el.children.forEach((el2) =>
            el2.children.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())))
      })
      return locations
    },
  },

}

</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";


.my-container {
  .content {
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr;
    gap: 20px;
    transition: .3s;
  }


}

@media (max-width: 1600px) {
  .my-container {
    .content {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media (max-width: 1200px) {
  .my-container {
    .content {
      grid-template-columns: 1fr  1fr;
    }

  }
}

@media (max-width: 850px) {
  .my-container {
    .content {
      grid-template-columns: 1fr;
    }

  }
}

.btn-add-category {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
  background: #fff;
  border: none;

  div {
    position: relative;
    padding: 20px;

    &:before, &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: black;
      left: 0;

    }

    &:after {
      transform: rotate(-90deg);
    }
  }

  p {
    color: black;
    padding: 20px;
    margin: 0;
    font-size: 14px;
  }

}

.item {
  padding: 15px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  border-radius: 5px;
  position: relative;

  &:nth-child(1) {
    height: 400px;
  }
}

.locations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__info {
    width: 80%;

    h4 {
      color: #555;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }

    div {
      margin-left: 15px;

      p {
        width: 100%;
        margin: 0 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: dotted 1px #cecdcd;

        span {
          color: #555;
          font-size: 13px;
          background: #fff;
          line-height: 15px;
          transform: translateY(5px);

          &:first-child {
            Transform: translate(0, 5px);
            padding: 0 5px 0 0;
          }

          &:last-child {
            padding: 0 0 0 5px;
          }
        }
      }
    }
  }

  &__img {
    position: absolute;
    right: 0;
    top: 5px;
    width: 60px;
    height: 60px;
    background-image: url("../assets/img/company-icon.jpeg");
    background-size: cover;
    border-radius: 5px;

  }
}

.base-info {
  margin-top: 10px;
  position: relative;

  &__title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    div {
      &:first-child {
        font-size: 0.9rem;
        white-space: nowrap;
        font-weight: 500;
        color: #555;
      }
    }

    ::v-deep .el-tabs__content {

      position: absolute;
      width: 100%;
      left: 0;
    }

    ::v-deep .el-tabs__item {
      padding: 0 5px;
      font-size: 12px !important;
      height: 30px;
    }

    ::v-deep .el-tabs__header {
      margin-bottom: 10px;
    }

    ::v-deep .el-tabs__nav-wrap::after {
      display: none;
    }
  }
}

.tabs-content {
  &__list {
    margin: 0 5px 0 15px;
  }

  &__item {
    margin-bottom: 10px;

    p {
      border-bottom: dotted 1px #cecdcd;
      color: #555;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8rem;
      font-weight: normal;

      span {
        background: #fff;
        transform: translateY(5px);
        line-height: 1;

        &:nth-child(1) {
          padding: 0 5px 0 0;
        }

        &:nth-child(2) {
          padding: 0 0 0 5px;
        }
      }
    }
  }
}

.button-rotate-card {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin: 0 15px 10px 0;
    border: none;
    background: none;
    color: #24387f;
    font-size: 0.9rem;
    padding: 5px;
    font-weight: 500;
    font-family: 'Gotham Pro', sans-serif;
    cursor: pointer;
  }
}

.separator-text {
  width: 1px;
  height: 14px;
  background: #555;
}

.separator-line {
  margin: 5px 0;
  width: 100%;
  border: solid 1px #555;
}

//--------------------
.scene {
  height: 420px;
  perspective: 600px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
  border-radius: 5px;
}

.card {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  transition: transform .5s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.card.is-flipped {
  transform: rotateY(180deg);
}

.card.is-flipped .card__face--front {
  z-index: -1;
}

.card__face {
  padding: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card__face--front {
  background: #fff;
  border-radius: 10px;
}

.card__face--back {

  border-radius: 10px;
  transform: rotateY(180deg);
  background: #fff;


  .wrapper-flex {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 8fr 1fr 1fr;

    button {
      background: unset;
      border: none;
      padding: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: #555;
        transition: .3s;
      }

      &:hover {
        i {
          color: #000;
          transition: .3s;
        }
      }

    }
  }

  .search-block {
    margin-top: 10px;
  }

  .locations {

  }

  ::v-deep .el-tree.filter-tree {
    margin-top: 15px;
    overflow: auto;
    max-height: 260px;

  }

  ::v-deep.el-tree-node > .el-tree-node__children {
    overflow: unset;
  }

}

.h2-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 450;
  color: #555;
}

@media (max-width: 1800px) {
  .locations__info {
    width: 75% !important;
  }
}

@media (max-width: 1600px) {
  .locations__info {
    width: 80% !important;
  }
}

@media (max-width: 1400px) {
  .locations__info {
    width: 75% !important;
  }
}

@media (max-width: 1200px) {
  .locations__info {
    width: 80% !important;
  }
}

@media (max-width: 1000px) {
  .locations__info {
    width: 70% !important;
  }
}

@media (max-width: 850px) {
  .locations__info {
    width: 85% !important;
  }
}

@media (max-width: 750px) {
  .locations__info {
    width: 75% !important;
  }
}

::v-deep {
  .el-dialog {
    width: 65%;
    border-radius: 10px;
  }

  .el-textarea {
    textarea {
      font-family: inherit;
      font-size: 14px;
    }
  }

  input {
    font-size: 14px;
  }

}

//.add-category {
//  &__headers {
//    display: grid;
//    grid-template-columns: 1fr 1fr 1fr;
//    width: 100%;
//    gap: 20px;
//    border-bottom: solid 1px #bebebe;
//    margin-bottom: 10px;
//
//    h2 {
//      margin-bottom: 5px;
//    }
//  }
//
//  &__wrapper {
//    display: grid;
//    grid-template-columns: 1fr 1fr 1fr;
//    width: 100%;
//    gap: 20px;
//  }
//
//  .btn-add {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    flex-direction: column;
//  }
//
//  .btn-add-row {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    gap: 10px;
//  }
//
//  ul {
//    display: flex;
//    flex-direction: column;
//    gap: 3px;
//    max-height: 300px;
//    overflow-y: auto;
//    margin-bottom: 0;
//
//
//    li {
//      cursor: pointer;
//      padding: 8px 7px;
//      border: solid 1px #bebebe;
//      line-height: 1;
//      border-radius: 10px;
//      transition: .3s;
//
//      &:hover {
//        border: solid 1px #24387f;
//      }
//
//      &.active {
//        color: #fff;
//        background: #24387f;
//      }
//    }
//  }
//
//  div {
//    button {
//      margin-top: 10px;
//      cursor: pointer;
//      background: #fff;
//      border: solid 1px #24387f;
//      border-radius: 50%;
//      padding: 7px;
//      height: 35px;
//      width: 35px;
//
//      i {
//        color: #24387f;
//        font-size: 16px;
//        line-height: 1;
//        font-weight: bold;
//      }
//    }
//
//    .btn-save {
//      margin-top: 20px;
//      border-radius: 10px;
//      font-size: 12px;
//      width: 110px;
//      transition: .3s;
//
//      &:hover {
//        background: #24387f;
//        color: #fff;
//      }
//    }
//
//    .btn-close {
//      border: none;
//      font-size: 10px;
//      padding: 0;
//      width: 40px;
//      height: 20px;
//      margin: 0;
//      color: grey;
//      transition: .3s;
//
//      &:hover {
//        color: red;
//      }
//    }
//  }
//
//  .add-inputs {
//    margin-top: 10px;
//    display: flex;
//    flex-direction: column;
//    width: 100%;
//    border: solid 1px black;
//    border-right: none;
//    border-left: none;
//    padding: 10px 0;
//
//    h5 {
//      margin-bottom: 5px;
//      text-align: center;
//    }
//
//    label {
//      width: 100%;
//
//      p {
//        margin-bottom: 0;
//        color: #555;
//      }
//    }
//
//    input {
//      width: 100%;
//      font-family: inherit;
//    }
//  }
//
//  &__sub-locations, &__locations, &__category {
//    flex-direction: column;
//  }
//
//  &__locations {
//
//  }
//
//  &__sub-locations {
//
//  }
//
//  &__category {
//
//  }
//}


</style>
