<template>
  <div class="category-dictionaries-container">
    <div>
      <h4>
        Словари
        <i class="el-icon-edit" @click="edit()"></i>
      </h4>
    </div>
    <div class="dictionary-list">
      <ul>
        <div v-if="dictionariesPositive.length">
          <p class="legend_dict">Словари с положительной оценкой</p>
          <div class="li-wrapper">
            <category-dictionaries-new-item
                v-for="(dictionary, index) in dictionariesPositive"
                :props-dict-data="dictionary"
                :key="index"
                :props-date="{
                start:dateStartEndForRequest ? dateStartEndForRequest.start : '',
                end:dateStartEndForRequest ? dateStartEndForRequest.end : ''
                }"
                :props-show-category-call-dialog="showCategoryCallDialog"
            />
          </div>
          <div class="separator"></div>
        </div>



        <div v-if="dictionariesNeutral.length">
          <p class="legend_dict">Словари с нейтральной оценкой</p>
          <div class="li-wrapper">
            <category-dictionaries-new-item
                v-for="(dictionary, index) in dictionariesNeutral"
                :props-dict-data="dictionary"
                :key="index"
                :props-date="{
                start:dateStartEndForRequest ? dateStartEndForRequest.start : '',
                end:dateStartEndForRequest ? dateStartEndForRequest.end : ''
                }"
                :props-show-category-call-dialog="showCategoryCallDialog"

            />
          </div>
          <div class="separator"></div>
        </div>



        <div v-if="dictionariesNegative.length">
          <p class="legend_dict">Словари с негативной оценкой</p>
          <div class="li-wrapper">
            <category-dictionaries-new-item
                v-for="(dictionary, index) in  dictionariesNegative"
                :props-dict-data="dictionary"
                :key="index"
                :props-date="{
                start:dateStartEndForRequest ? dateStartEndForRequest.start : '',
                end:dateStartEndForRequest ? dateStartEndForRequest.end : ''
                }"
                :props-show-category-call-dialog="showCategoryCallDialog"

            />
          </div>
          <div class="separator"></div>
        </div>


        <!--                <li-->
        <!--                    v-for="dictionary in dictionaries"-->
        <!--                >-->
        <!--                  <div>-->
        <!--                    <el-tooltip-->
        <!--                        v-if="dictionary.dictionary.reverse"-->
        <!--                        effect="dark"-->
        <!--                        content="Реверсивный"-->
        <!--                        placement="right-start"-->
        <!--                    >-->
        <!--                      <i class="el-icon-sort"></i>-->
        <!--                    </el-tooltip>-->

        <!--                    <i class="dictionary-color" v-if="dictionary.positive_weight"-->
        <!--                       :style="{background:dictionary.color_success}">-->
        <!--                      <span v-if="dictionary.positive_weight">+{{ dictionary.positive_weight }}</span>-->
        <!--                    </i>-->
        <!--                    <i class="dictionary-color" v-if="dictionary.negative_weight" :style="{background:dictionary.color_reject}">-->
        <!--                      <span v-if="dictionary.negative_weight">-{{ dictionary.negative_weight }}</span>-->
        <!--                    </i>-->
        <!--                    <i class="dictionary-color" v-if="!dictionary.negative_weight && !dictionary.positive_weight" :style="{background:'gray'}">-->
        <!--                      <span v-if="dictionary.negative_weight">0</span>-->
        <!--                    </i>-->
        <!--                    <span>-->
        <!--                    {{ dictionary.dictionary ? dictionary.dictionary.name : 'нет данных' }}-->
        <!--                  </span>-->
        <!--                  </div>-->
        <!--                  <span v-loading="!dictionary.dictionary.loaded_period_data" class="show-call-dialog"-->
        <!--                        @click="showCategoryCallDialog(dictionary, 'dictionary')">-->
        <!--                    {{ dictionary.dictionary.found_count_by_period.all }}-->
        <!--                  </span>-->
        <!--                </li>-->

      </ul>
    </div>

    <el-drawer
        :visible.sync="dictionariesDrawer"
        direction="rtl"
        size="70%"
    >
      <category-dictionaries-transfer
          :category-id="this.$route.params.category_id"
          :selected-system-dictionaries="systemCategoryDictionaries"
          :selected-client-dictionaries="clientCategoryDictionaries"
          @reload="getCategoryDictionaries()"
      />
    </el-drawer>

  </div>


</template>

<script>
import CategoryDictionariesTransfer from "@/components/category/CategoryDictionariesTransfer.vue";
import CategoryCallsDialog from "@/components/newCatigories/CategoryCallsDialog.vue";
import CategoryDictionariesNewItem from "./categoryDictionariesNewItem/categoryDictionariesNewItem.vue";

export default {
  name: "category-dictionaries",
  components: {CategoryDictionariesNewItem, CategoryCallsDialog, CategoryDictionariesTransfer},
  props: {
    category: {},
    dateStartEndForRequest: {
      type: Object,
    }
  },
  data() {
    return {
      countUnloadedLoadedDictionaries: -1,
      dictionariesDrawer: false,
      systemCategoryDictionaries: [],
      clientCategoryDictionaries: [],
      dictionaries: [],
      dictionariesPositive: [],
      dictionariesNegative: [],
      dictionariesNeutral: [],
      addDrawer: false
    }
  },
  mounted() {
    this.getCategoryDictionaries()
  },
  watch: {
    dateStartEndForRequest() {
      this.getCategoryDictionaries()
    },
    countUnloadedLoadedDictionaries() {
      if (this.countUnloadedLoadedDictionaries === 0) {
        this.countUnloadedLoadedDictionaries = -1;
        let oldDictionaries = this.dictionaries;
        this.dictionaries = []
        this.dictionaries = this.sortingByCountCalls(oldDictionaries)
      }
    }
  },
  methods: {
    getCategoryDictionaryFullData(dictionary, index) {
      this.$api.category.getCategoryDictionaryFullData({
        category_id: this.$route.params.category_id,
        dictionary_id: dictionary.dictionary.id,
        // category_dictionary_id: dictionary.id,
        dictionary_type: dictionary.dictionary_type,
        date_start: this.dateStartEndForRequest ? this.dateStartEndForRequest?.start : '',
        date_end: this.dateStartEndForRequest ? this.dateStartEndForRequest?.end : '',
      }).then(data => {
        data.dictionary.loaded_period_data = true;
        this.dictionaries[index] = data;
        this.countUnloadedLoadedDictionaries--;
      })
    },
    getCategoryDictionaries() {
      this.$api.category.getCategoryDictionaries({
        category_id: this.$route.params.category_id,
        date_start: this.dateStartEndForRequest ? this.dateStartEndForRequest?.start : '',
        date_end: this.dateStartEndForRequest ? this.dateStartEndForRequest?.end : '',
      })
          .then(data => {

            this.systemCategoryDictionaries = data.system
            this.clientCategoryDictionaries = data.client
            this.dictionaries = [...data.system, ...data.client]
            this.countUnloadedLoadedDictionaries = this.systemCategoryDictionaries.length + this.clientCategoryDictionaries.length


            this.dictionariesPositive = this.dictionaries.filter((item) => item.positive_weight > 0 && (!item.negative_weight || item.negative_weight == 0))
            this.dictionariesNegative = this.dictionaries.filter((item) => item.negative_weight > 0 && (!item.positive_weight || item.positive_weight == 0))
            this.dictionariesNeutral = this.dictionaries.filter((item) => item.negative_weight == item.positive_weight || ((!item.negative_weight || item.negative_weight == 0) && (!item.positive_weight || item.positive_weight == 0)))


            // this.dictionaries.forEach((item, index) => {
            //   this.getCategoryDictionaryFullData(item, index)
            // })

            this.$emit('update', this.dictionaries)
          })
          .finally(() => {
            this.loading = false;
          })
    },
    edit() {
      this.dictionariesDrawer = true;
    },
    sortingByCountCalls(data) {
      return data.sort((a, b) => b.dictionary.found_count_by_period.all - a.dictionary.found_count_by_period.all)
    },
    showDrawer(callId) {
      this.$emit('show-call-info-drawer', callId)
    },
    showCategoryCallDialog(data, type) {
      this.$emit('show-category-calls-dialog', {data: data, type: type})
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/project-variables";

.category-dictionaries-container {
  ::v-deep .el-loading-spinner {
    .circular {
      width: 15px;
      height: 15px;
      top: 14px !IMPORTANT;
      position: relative;
    }
  }

  ::v-deep .el-loading-parent {
    &--relative {
      width: 20px;
    }
  }

  margin: 15px;

  color: #252525;
  padding-bottom: 0;

  h4 {

    margin-bottom: 0;
    font-size: 13px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 450;

    i {
      line-height: 1;
      font-size: 12px;
      color: #555;
    }
  }
}

.dictionary-title {

  > .el-col {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 450;

    &:first-child {
      text-align: left;
    }
  }

}

.dictionary {
  > .el-col {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    > span {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}

.dictionary-list {
  border-radius: 2px;
  max-height: 435px;
  overflow-y: scroll;

  .legend_dict {
    font-size: 10px;
    color: rgba(85, 85, 85, 0.74);
    margin-bottom: 0;
  }

  ul {
    padding: 5px 0;
    margin-bottom: 0;
    padding-right: 10px;
    display: grid;

    .li-wrapper {
    display: grid;
    }

    li {

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 0px 0 10px;
      position: relative;

      .show-call-dialog {
        color: $--color-primary;
        cursor: pointer;
        font-size: 11px;
      }

      .el-icon-sort {
        position: absolute;
        top: 5px;
        left: -10px;
        transform: rotate(90deg);
        color: red;
        font-weight: 450;
      }


      span {
        font-weight: normal;
      }

      div {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #555;
        line-height: 1.3;
        font-size: 12px;
        font-family: inherit;
      }

      > span:last-child {
        font-weight: normal;
      }

      .dictionary-color {

        width: 35px;
        height: 15px;
        background: gray;
        border-radius: 4px;
        display: flex;
        justify-content: center;

        span {
          text-align: center;
          justify-content: center;
          color: #fff;
          font-style: normal;
        }
      }

      ::v-deep .category-calls .popover-button {
        padding: 0 2px;
      }
    }
  }
}

.separator {
  height: 1px;
  width: 100%;
  margin: 10px 0;
  background: #bebebe54;
}
</style>