import { render, staticRenderFns } from "./QuestionInformationForm.vue?vue&type=template&id=e070692c&scoped=true&"
import script from "./QuestionInformationForm.vue?vue&type=script&lang=js&"
export * from "./QuestionInformationForm.vue?vue&type=script&lang=js&"
import style0 from "./QuestionInformationForm.vue?vue&type=style&index=0&id=e070692c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e070692c",
  null
  
)

export default component.exports