<template>
<div>
  <ul class="list">
    <li class="list__item">
      <span>Применяется:</span>
      <span>c <i>{{propsCheckListInfo.date_start? formatDate(propsCheckListInfo.date_start) :'~'}}</i>
        по <i>{{propsCheckListInfo.date_stop?formatDate(propsCheckListInfo.date_stop):'~'}}</i></span>
      <span></span>
    </li>
    <li class="list__item">
      <span>Применяется на:</span>
      <span>{{getTypeCall(propsCheckListInfo.search_in_call,propsCheckListInfo.search_out_call)}}</span>
      <span></span>
    </li>
    <li class="list__item">
      <span >Сработал в</span>
      <span class="show-call-dialog" @click="showCategoryCallDialog(propsCheckListInfo.successfully, 'checklist')">{{propsCheckListInfo.successfully.length}}</span>

      <span> аудио </span>
    </li>
    <li class="list__item">
      <span>Сработал частично в</span>
      <span class="show-call-dialog" @click="showCategoryCallDialog(propsCheckListInfo.unsuccessfully, 'checklist')">{{propsCheckListInfo.unsuccessfully.length}}</span>

      <span> аудио </span>
    </li>
  </ul>


</div>
</template>

<script>


export default {
  name: "checklist-work-tab-info",
  components: {},
  props:{
    propsCheckListInfo:{}
  },
  data() {
    return {
      selectedCallInfo: {}
    }
  },
  methods:{
    formatDate(date){
      let currentDate = new Date(date *1000)
      return currentDate.toLocaleString("ru", {year:'numeric',month:'numeric',day: 'numeric'})
    },
    getTypeCall(inType,outType){
      switch (true) {
        case inType=== 1  && outType===1 :
          return 'все звонки'
        case inType=== 1:
          return 'входящие звонки'
        case outType=== 1:
          return 'исходящие звонки'
        default:
          return 'не назначен'
      }
    },
    showDrawer(callInfoId) {
      this.$emit('show-call-info-drawer', callInfoId)
    },
    showCategoryCallDialog(data, type) {
      this.$emit('show-category-calls-dialog', {data:data, type: type})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/project-variables";
.list{

  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    font-size: 11px;
    align-items: center;
    font-weight: normal;
    .show-call-dialog {
      color: $--color-primary;
      cursor: pointer;
    }
    & > *:nth-child(2){
      text-align: center;
    }
    &:first-child, &:nth-child(2) {
      grid-template-columns: 2fr 1fr;
      & > *:nth-child(2){
        text-align: right;
      }
    }
    & > *:first-child{
      font-weight: bold;
      padding-left: 5px;
    }
    & > *:last-child{
      text-align: right;

    }

  }

}

</style>