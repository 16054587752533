<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>


<script>
import { mapGetters } from 'vuex';
import DefaultLayout from "./layouts/DefaultLayout";
import ManagementLayout from "@/layouts/ManagementLayout";
import EmptyLayout from "./layouts/EmptyLayout";

export default {
    name:"app",
    components: {DefaultLayout, ManagementLayout, EmptyLayout},
    computed: {
        ...mapGetters([ 'user' ]),
        layout() {
            return this.$route.meta.layout || 'default-layout'
        }
    },

    methods: {

    }

}
</script>

<style lang="scss">
  @import url("../node_modules/@fortawesome/fontawesome-free/css/all.css");
  @import "assets/css/gothampro.css";
  @import "assets/css/style.scss";

</style>
