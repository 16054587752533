<template>
  <div>
    <div class="btn_copy">
      <p>Копировать: </p>
      <button v-if="this.allText.filter((item) => item['channelTag'] === '2').length" @click="copyEmployeeText" class="copy_employee">текст сотрудника</button>
      <button v-if="this.allText.filter((item) => item['channelTag'] === '2').length" @click="copyClientText" class="copy_client">текст клиента</button>
      <button @click="copyAllText" class="copy_all">весь текст</button>
    </div>
    <div v-bind:class="fixHeight?'recognized-text noFix':'recognized-text'" v-if="allText">

      <ul
          @click="$emit('clickToWords',$event.target)"
      >
        <li
            v-for="(i,index) in sortedAllText"
            :key="index"
            :class="propsCallReverse
          ? i.channelTag ==='1'?'client-text':'employee-text'
          : i.channelTag ==='1'?'employee-text':'client-text'"

        >
      <span
          ref="words"
          :data-time="parseFloat(i.alternatives[0].words[0].startTime)"
      >

        {{ getTimeCodeFromNum(i.alternatives[0].words[0].startTime) }}{{
          propsCallReverse !== 0

              ? i.channelTag === '1' ? '-Клиент:' : '-Сотрудник:'
              : i.channelTag === '1' ? '-Сотрудник:' : '-Клиент:'
        }}

      </span>
          <i
              @click="getCurrentTextByTime"
              ref="words"
              v-for="text in i.alternatives[0].words"
              :data-time="parseFloat(text.startTime)"
              :data-high-lite="1"
              :data-target="propsCallReverse!==0 ? i.channelTag ==='1'?'client':'employee' : i.channelTag ==='1'?'employee':'client'"
          >
            {{ `${text.word.replaceAll(',', '').replaceAll('?', '').replaceAll('.', '').replaceAll('!', '').replaceAll('«', '').replaceAll('»', '')}&#8194;`}}
<!--            {{`${text.word}`}}-->
          </i>
        </li>
        <li class="client-text"></li>
        <li class="employee-text"></li>
      </ul>
    </div>
  </div>
</template>

<script>
import login from "@/views/auth/Login.vue";

export default {
  name: "recognized-call-text",
  props: {
    fixHeight: {
      type: Boolean,
      default: false
    },
    allText: {
      type: Array
    },
    currentTimeAudio: {
      type: Number
    },
    propsCallReverse: {}

  },
  methods: {
    copyEmployeeText() {
      let actualJson = []
      if (!this.propsCallReverse) {
        actualJson = this.allText.filter((item) => item['channelTag'] === '1')
      } else {
        actualJson = this.allText.filter((item) => item['channelTag'] === '2')
      }
      let actualText = ''

      actualJson.forEach((el) => {
        let string = this.getTimeCodeFromNum(el.alternatives[0]['words'][0]['startTime']) + '-Сотрудник:'
        el.alternatives[0]['words'].forEach((text) => {
          string += ' ' + text.word
        })
        actualText += string + '\n'
      })
      navigator.clipboard.writeText(actualText)
    },
    copyClientText() {
      let actualJson = []
      if (!this.propsCallReverse) {
        actualJson = this.allText.filter((item) => item['channelTag'] === '2')
      } else {
        actualJson = this.allText.filter((item) => item['channelTag'] === '1')
      }
      let actualText = ''

      actualJson.forEach((el) => {
        let string = this.getTimeCodeFromNum(el.alternatives[0]['words'][0]['startTime']) + '-Клиент:'
        el.alternatives[0]['words'].forEach((text) => {
          string += ' ' + text.word
        })
        actualText += string + '\n'
      })
      navigator.clipboard.writeText(actualText)
    },

    copyAllText() {
      let actualText = ''

      this.allText.forEach((el) => {
        let string = this.getTimeCodeFromNum(el.alternatives[0]['words'][0]['startTime'])

        if (el['channelTag'] === '1' && !this.propsCallReverse || el['channelTag'] === '2' && this.propsCallReverse) {
          string += '-Сотрудник:'
        } else {
          string += '-Клиент:'
        }

        el.alternatives[0]['words'].forEach((text) => {
          string += ' ' + text.word
        })
        actualText += string + '\n'
      })
      navigator.clipboard.writeText(actualText)
    },
    getTimeCodeFromNum(num) {
      let seconds = parseInt(num);
      let minutes = parseInt(String(seconds / 60));
      seconds -= minutes * 60;
      const hours = parseInt(String(minutes / 60));
      minutes -= hours * 60;
      if (String(minutes).length === 1) {
        minutes = '0' + minutes
      }
      if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
      return `${String(hours).padStart(2, 0)}:${minutes}:${String(
          seconds % 60
      ).padStart(2, 0)}`;
    },
    getCurrentTextByTime() {
      this.$refs.words.forEach((el) => {
        let attr = +el.attributes['data-time'].value
        if (+attr <= this.currentTimeAudio) {
          el.classList.add('complited')
        } else {
          el.classList.remove('complited')
        }

      })
    },
    setTimeToAudioPlayerByWord(e) {
      this.toPause()
      let time = parseFloat(e.target.getAttribute('data-time-start'));
      this.audio.currentTime = time - 0.35;
      this.resetCompletedWords();
      this.getCurrentTextByTime(time + 0.15)
      this.toPlay()
    },
  },
  watch: {
    currentTimeAudio() {
      this.getCurrentTextByTime()
    }
  },
  computed:{
    sortedAllText(){
      this.allText.forEach((el)=>
      console.log(+el.alternatives[0].words[0].startTime)
      )
      return this.allText
    }
  }
}
</script>

<style lang="scss" scoped>
.recognized-text.noFix {
  max-height: unset;
}

.recognized-text {
  padding: 0 10px;
  margin-top: 10px;
  max-height:65vh;
  overflow-y: auto;

  ul {
    display: grid;

    li {
      word-wrap: break-word;
      display: inline;
      flex-wrap: wrap;
      font-size: 11px;

      span {
        font-weight: 500;
      }

      i {
        display: inline-flex;
        font-style: normal;
        padding: 0 5px;

      }

      .complited {
        background: #8695c7;
        color: #fff;
      }
    }
  }

  .client-text {
    span {
      color: #24387f;
    }
  }

  .employee-text {
    span {
      color: #24387f;
      color: red;
    }

  }
}

.highlight {
  background: #000 !important;
  color: #fff !important;
}

.btn_copy {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  //flex-direction: column;
  align-items: center;
  button{
    max-width: 110px;
    background: unset;
    font-size: 10px;
    cursor: pointer;
    color: #24387f;
    padding: 2px;
    margin-right: 5px;
    line-height: 1;
    border: solid 1px #24387f ;
    border-radius: 5px;
    font-weight: 500;
  }
  p{
    padding: 2px;
    border: solid 1px inherit ;
    border-radius: 5px;
    font-size: 10px;
    margin-bottom: 0;
    line-height: 1.1;
    color: #505050;
    font-weight: 500;
    margin-right: 5px;
  }
  .copy_employee {

  }

  .copy_client {

  }

  .copy_all {

  }
}
</style>