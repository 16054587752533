<template>
  <el-row class="pagination" type="flex" justify="center" align="middle">
    <el-pagination
        v-if="paginator"
        background
        layout="prev, pager, next"
        :page-count="paginator.pageCount"
        :total="paginator.totalCount"
        :page-size="paginator.perPage"
        @current-change="pageChanged"
    >
    </el-pagination>

    <div class="counts-call">
      <div>
        <p>показать:</p>
        <el-select
            class="selectCountCalls"
            :value="paginator.perPage"
            v-model="paginator.perPage"
            @change="setPerPage(paginator.perPage)"
        >
          <el-option
              v-for="pageSize in paginator.perPageSizes"
              :key="pageSize"
              :label="pageSize"
              :value="pageSize"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </el-row>
</template>

<script>
export default {
  name: "paginator",
  props: {
    paginator: {
      type: Object,
    }
  },
  data() {
    return {}
  },
  methods: {
    pageChanged(page) {
      this.setPaginatorPage(page)
      this.$emit('page-changed', page);
    },

    setPaginatorPage(page) {
      this.paginator.page = page;
      this.$emit('update:paginator', this.paginator);
    },
    setPerPage(perPage) {
      this.paginator.page = 1;
      this.paginator.perPage = perPage;
      this.$emit('update:paginator', this.paginator);
      this.$emit('page-changed');
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";

.pagination {
  position: relative;
  margin-top: 40px;
  width: 100%;
  .counts-call {
    position: absolute;
    right: 0;
    display: flex;
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      p {
        margin: 0;
      }

      .selectCountCalls {
        width:75px;

        ::v-deep .el-input{
          &__suffix-inner{

          }
          &__icon {
            line-height: 1;
          }

        }
      }
    }

    p {

    }
  }
}
::v-deep .el-pagination{
  .number{
    background: #fff !important;
    &.active{
      background: #24387f !important;
    }
  }
}

</style>
