<template>
  <div class="category">
    <!--    <h1 v-if="category.sub_location">{{selectedCategoryName}}</h1>-->
    <div class="category-control">
      <el-select
          v-if="location"
          v-model="selectedCategoryName"
          class="category-select"
          @change="changeCategory"
      >
        <el-option-group
            v-for="sub_location in location.sub_locations"
            :key="sub_location.id"
            :label="sub_location.name">
          <el-option
              v-for="category in sub_location.categories"
              :key="category.id"
              :label="category.name"
              :value="[sub_location.id, category.id]">
          </el-option>
        </el-option-group>
      </el-select>

      <my-date-period-picker
          :totalCountListLength="totalCountListLength"
          :getTime="getStartEndTimeForGetCategoryCalls"
      />
    </div>
    <div class="filters"><i class="el-icon-s-operation" @click="filtersDialog = !filtersDialog"></i></div>


    <el-collapse v-model="activeName" class="collapse">
      <el-collapse-item title="Блок оценки" name="1">
        <div class="category-entities" v-if="category">
          <div class="category-entities__dictionaries">
            <category-dictionaries-new
                v-if="startEndDateForCallListAndCategoryDictionaries['start']"
                :category="category"
                :dateStartEndForRequest="startEndDateForCallListAndCategoryDictionaries"
                @show-category-calls-dialog="showCategoryCallsDialog"
                @update="getCategoryDictionariesForFilter"
            />
          </div>
          <div class="category-entities__checklists">
            <category-checklists

                :category="category"
                :dateStartEndForRequest="startEndDateForCallListAndCategoryDictionaries"
                @set-count-checklists="setCountChecklists"
                @show-category-calls-dialog="showCategoryCallsDialog"
            />
          </div>
          <div class="category-entities__general-info">
            <general-info
                :period="startEndDateForCallListAndCategoryDictionaries"
                :recognized="recognized"
                :count-checklists="countChecklists"
            />
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>

    <hr class="hr-separator">
    <div class="counts-call">

      <p>всего звонков: {{ totalCountListLength }}</p>
    </div>

    <div class="call-list-block" v-if="category.id">
      <call-list
          ref="callListComponent"
          :category-id="category.id"
          :category-name="category.name"
          :filters="filters"
          :propsStartEndDateForCallList="startEndDateForCallListAndCategoryDictionaries"
          :propsGetTotalCountCallListLength="getTotalCountCallListLength"
          @show-category-calls-dialog="showCategoryCallsDialog"
      />
    </div>

    <category-calls-dialog
        :data="callsDialogData.data"
        :type="callsDialogData.type"
        :dialog-visible="dialogVisible"
        @show-call-info-drawer="showCallInfoDrawer"
        @close="dialogVisible = false"
    />

    <el-dialog
        title="Фильтрация"
        width="55%"
        :visible.sync="filtersDialog"
    >
      <div>

        <div class="filters-form">
            <div>
              <h5>Тип звонка</h5>
              <el-select v-model="filters.call_types" multiple>
                <el-option
                    v-for="callType in initFilters.call_types"
                    :key="callType.value"
                    :value="callType.value"
                    :label="callType.name"
                />
              </el-select>
            </div>
            <div>
              <h5>ФИО оператора</h5>
              <el-select v-model="filters.employees" multiple>
                <el-option
                    v-for="employee in initFilters.employees"
                    :key="employee"
                    :value="employee"
                    :label="employee"
                />
              </el-select>
            </div>
            <div>
              <h5>ФИО клиента</h5>
              <el-select v-model="filters.clients" multiple>
                <el-option
                    v-for="client in initFilters.clients"
                    :key="client"
                    :value="client"
                    :label="client"
                />
              </el-select>
            </div>
            <div>
              <h5>Продолжительность звонка(cек)</h5>
              <div style="display: flex; gap: 30px">
                <span>от</span>
                <el-input-number v-model="filters.call_time.from"></el-input-number>
                <span>до</span>
                <el-input-number v-model="filters.call_time.to"></el-input-number>
              </div>
            </div>
            <div>
              <h5>Продолжительность ожидания(cек)</h5>
              <div style="display: flex; gap: 30px">
                <span>от</span>
                <el-input-number v-model="filters.call_time_outs.from"></el-input-number>
                <span>до</span>
                <el-input-number v-model="filters.call_time_outs.to"></el-input-number>
              </div>
            </div>
          <div>
            <h5>Сработали словари</h5>
            <el-select v-model="filters.worked_dictionaries" multiple>
              <el-option
                  v-for="dict in initFilters.worked_dictionaries"
                  :key="dict.value"
                  :label="dict.name"
                  :value="dict.value+'-'+dict.type"
              ></el-option>
            </el-select>
          </div>
<!--          <div>-->
<!--            <h5>Проверен</h5>-->


<!--            <el-select v-model="filters.call_is_checked_statuses" multiple>-->
<!--              <el-option-->
<!--                  v-for="status in initFilters.call_is_checked_statuses"-->
<!--                  :key="status.value"-->
<!--                  :label="status.name"-->
<!--                  :value="status.value"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--          <div>-->
<!--            <h5>Чеклист сработал</h5>-->
<!--            <el-select v-model="filters.checklist_statuses" multiple>-->
<!--              <el-option-->
<!--                  v-for="status in initFilters.checklist_statuses"-->
<!--                  :key="status.value"-->
<!--                  :label="status.name"-->
<!--                  :value="status.value"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--          </div>-->
<!--          <div>-->
<!--            <h5>Чеклист набрал баллов</h5>-->

<!--            <div style="display: flex; gap: 30px;">-->
<!--              <el-select v-model="filters.checklist_score_vector">-->
<!--                <el-option-->
<!--                    v-for="scoreVector in initFilters.checklist_score_vectors"-->
<!--                    :key="scoreVector.value"-->
<!--                    :label="scoreVector.name"-->
<!--                    :value="scoreVector.value"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--              <el-input v-model="filters.checklist_score_vector_value"></el-input>-->
<!--            </div>-->

<!--          </div>-->
<!--          <div>-->
<!--            <h5>Баллы позитив</h5>-->
<!--            <div style="display: flex; gap: 30px">-->
<!--              <span>от</span>-->
<!--              <el-input-number v-model="filters.positive_weight_to"></el-input-number>-->
<!--              <span>до</span>-->
<!--              <el-input-number v-model="filters.positive_weight_from"></el-input-number>-->
<!--            </div>-->

<!--          </div>-->
<!--          <div>-->
<!--            <h5>Баллы негатив</h5>-->
<!--            <div style="display: flex; gap: 30px">-->
<!--              <span>от</span>-->
<!--              <el-input-number v-model="filters.negative_weight_to"></el-input-number>-->
<!--              <span>до</span>-->
<!--              <el-input-number v-model="filters.negative_weight_from"></el-input-number>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <h5>телефон клиента содержит цифры</h5>-->
<!--            <el-input v-model="filters.clientPhone"></el-input>-->
<!--          </div>-->
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="filtersDialog = false">Отмена</el-button>
        <el-button type="primary" @click="getCategoryCalls">Применить</el-button>
      </div>
    </el-dialog>

  </div>



</template>

<script>

import CallList from "@/components/newCatigories/callList.vue";
import {SwiperSlide} from 'swiper-vue2'
import CategoryChecklists from "@/components/category/CategoryChecklists.vue";
import CategoryDictionariesNew from "@/components/newCatigories/CategoryDictionariesNew.vue";
import MyDatePeriodPicker from "@/components/newCatigories/myDatePeriodPicker.vue";
import ChecklistWork from "@/components/newCatigories/checklistTabs/checklistWorkTabItem.vue";
import MySlider from "@/components/UI/MySlider.vue";
import GeneralInfo from "@/components/newCatigories/generalInfo.vue";
import CategoryCallsDialog from "@/components/newCatigories/CategoryCallsDialog.vue";

export default {
  name: "NewCategories",
  components: {
    CategoryCallsDialog,
    GeneralInfo,
    MySlider, SwiperSlide, ChecklistWork, MyDatePeriodPicker, CategoryDictionariesNew, CategoryChecklists, CallList
  },
  mounted() {
    this.getCategory()
    this.getCategoryEmployees()
    this.getCategoryClients()
    this.getLocation()
    this.getToday()
  },
  data() {
    return {
      filtersDialog: false,
      initFilters :{
        call_types: [
          {name : 'Входящий', value: 'in'},
          {name : 'Исходящий', value: 'out'},
          {name : 'Пропущенный', value: 'missed'},
        ],
        employees: [],
        clients: [],
        call_is_checked_statuses: [
          {name : 'Да', value: 'yes'},
          {name : 'Нет', value: 'no'},
        ],
        checklist_statuses: [
          {name : 'Полностью', value: 'full'},
          {name : 'Частично', value: 'part'},
          {name : 'Не сработал', value: 'none'},
        ],
        checklist_score_vectors: [
          {name : 'Более', value: 'more'},
          {name : 'Менее', value: 'less'},
        ],
        worked_dictionaries: []

      },
      filters: {
        call_types: [],
        employees: [],
        clients: [],
        call_is_checked_statuses: [],
        checklist_statuses: [],
        call_time: {
          to: null,
          from: null
        },
        call_time_outs: {
          to: null,
          from: null
        },
        checklist_score_vector: null,
        checklist_score_vector_value: null,
        client_phone: null,
        worked_dictionaries: [],
      },
      category: {
        id: null,
        name: null
      },
      selectedCategoryName: '',
      location: {
        sub_locations: []
      },
      activeName: '1',
      startEndDateForCallListAndCategoryDictionaries: {start: '', end: ''},
      totalCountListLength: 0,
      recognized: [],
      countChecklists: {
        all: 0,
        relevant: 0
      },
      callsDialogData: {
        data: {},
        type: ''
      },
      dialogVisible: false
    }
  },
  watch: {
    startEndDateForCallListAndCategoryDictionaries() {
      this.getCategoryRecognizedCalls()
    },
  },
  methods: {
    setFilterTime(time_type, data) {

      if(time_type === 'time_out') {
        this.filters.call_times = []
      }
    },
    getStartEndTimeForGetCategoryCalls(callback) {
      this.startEndDateForCallListAndCategoryDictionaries = callback
    },
    getDictionaryFullData(data) {

      this.dialogVisible = true;

      this.$api.category.getCategoryDictionaryFullData({
        category_id: this.$route.params.category_id,
        dictionary_id: data.data.dictionary_id,
        dictionary_type:data.data.dictionary_type,
        date_start: this.startEndDateForCallListAndCategoryDictionaries.start,
        date_end: this.startEndDateForCallListAndCategoryDictionaries.end,
        with_data: 1,
      }).then(data => {
        data.dictionary.loaded_period_data = true;
        this.callsDialogData.data = data.dictionary.found_count_by_period.all
      })

    },
    getCategoryChecklistDictionaryFullData(data) {
      this.dialogVisible = true;
      this.$api.category.getCategoryChecklistDictionaryFullData({
        category_id: this.$route.params.category_id,
        dictionary_id: data.data.dictionary_id,
        dictionary_type: data.data.dictionary_type,
        date_start: this.startEndDateForCallListAndCategoryDictionaries.start,
        date_end: this.startEndDateForCallListAndCategoryDictionaries.end,
        with_data: 1,
      }).then(data => {
        data.loaded_period_data = true;
        this.callsDialogData.data = data.found_count_by_period.all
      })
    },
    getTotalCountCallListLength(count) {
      this.totalCountListLength = count
    },
    getCategory() {
      this.loading = true;
      this.$api.category.getCategory({
        category_id: this.$route.params.category_id
      })
          .then(data => {
            this.category = data
            this.selectedCategoryName = data.sub_location.name + ' - ' + data.name

          })
          .finally(() => {
            this.loading = false;
          })
    },
    getCategoryCalls() {
      this.filtersDialog = false;
      this.$refs.callListComponent.getCategoryCalls()
    },
    getCategoryEmployees() {
      this.$api.category.getCategoryEmployees({
        category_id: this.$route.params.category_id
      })
          .then(data => {
            this.initFilters.employees = data.map(item => item.name)
          })
          .finally(() => {
          })
    },
    getCategoryClients() {
      this.$api.category.getCategoryClients({
        category_id: this.$route.params.category_id
      })
          .then(data => {
            this.initFilters.clients = data.map(item => item.name)
          })
          .finally(() => {
          })
    },
    getLocation() {
      this.loading = true;
      this.$api.location.getLocation({
        location_id: this.$route.params.id
      })
          .then(data => {
            this.location = data

          })
          .finally(() => {
            this.loading = false;
          })
    },
    changeCategory(data) {
      this.$router.push('/dashboard')

      let path = '/new-categories/'
          + this.location.id
          + '/sub-location/' + data[0]
          + '/category/' + data[1]
          + '/';

      this.$router.push(path)
      this.$router.go()
    },
    getCategoryRecognizedCalls() {
      this.loading = true;
      this.$api.category.getCategoryRecognizedCalls({
        category_id: this.$route.params.category_id,
        date_start: this.startEndDateForCallListAndCategoryDictionaries.start,
        date_end: this.startEndDateForCallListAndCategoryDictionaries.end,
      })
          .then(data => {
            this.recognized = data

          })
          .finally(() => {
            this.loading = false;
          })
    },
    setCountChecklists(data) {
      this.countChecklists.all = data.length;
      this.countChecklists.relevant = data.filter(item => item.relevant).length
    },
    setStartTimeDate(data) {
      data.setHours(0)
      data.setMinutes(0)
      data.setSeconds(0)
      return data
    },
    setEndTimeDate(data) {
      data.setHours(23)
      data.setMinutes(59)
      data.setSeconds(59)
      return data
    },
    getToday() {
      let start = Math.round(this.setStartTimeDate(new Date()) / 1000)
      let end = Math.round(this.setEndTimeDate(new Date()) / 1000)
      this.startEndDateForCallList = {start: start, end: end}
    },
    showCallInfoDrawer(id) {
      this.$refs.callListComponent.showDrawer(null, id);
    },
    showCategoryCallsDialog(data) {
      this.callsDialogData.type = data.type


      switch (data.type) {
        case 'dictionary':
          this.getDictionaryFullData(data)
          break;
        case 'checklist-dictionary':
          this.getCategoryChecklistDictionaryFullData(data)
          break;
        case 'checklist':
          this.dialogVisible = true;
          data.loaded_period_data = true;
          this.callsDialogData.data = data.data
          break;

      }
    },
    getCategoryDictionariesForFilter(data) {

      this.initFilters.worked_dictionaries = data.map(item => {
        return {'value': item.dictionary.id, 'name': item.dictionary.name, type: item.dictionary_type}
      })
    }

  },

}
</script>

<style lang="scss" scoped>
@import "../assets/css/project-variables";
.filters {
  text-align: right;
  i {
    cursor: pointer;
    font-size: 32px;
    color: $--button-default-color;
  }
}

.filters-form {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr;
     > .el-select {
       width: 350px;;
     }
   }

}

.category-select {
  ::v-deep {
  .el-input__inner {
    height: 30px;
  }

    .el-select__caret{
      line-height: 0;
    }

  //.el-input__suffix {
  //  top: 4px;
  //}
  //
  //.is-focus {
  //  .el-input__suffix {
  //    top: -4px;
  //  }
  //}
}
}


::v-deep .category-select.el-select {
  width: 50%;

  .el-input {
    background: #f5f7fd;

    input {
      background: #fff;
    }

    &__inner {
      background-color: transparent;
    }

  }
}

.category {
  h1 {
    margin-bottom: 0px;
    line-height: 1;
    font-size: 20px;
  }

  margin: 20px;

  &-entities {
    display: grid;
    grid-template-columns: 1fr 1.7fr 1fr;
    column-gap: 15px;
    padding: 3px;

    &__dictionaries,
    &__general-info,
    &__checklists {
      background: #fff;
      border-right: 4px;
      box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
      border-radius: 10px;
    }

  }


  .collapse {
    border: none;

    ::v-deep .el-collapse {
      border: none;

      &-item__header,
      &-item__wrap {
        background-color: transparent;
        color: #252525;
        font-weight: bold;
        border: none
      }

      &-item__content {
        padding: 0;
      }
    }
  }

  ::v-deep .el-breadcrumb {
    margin-bottom: 20px;
    line-height: 1.5;
  }

}

.call-list-block {
  margin-top: 20px;
}

.hr-separator {
  border-top: 1px solid #EBEEF5;
  border-bottom: 1px solid #EBEEF5;
  margin-top: 20px;
}

.checklist-work {
  background: #fff;
  padding: 15px;
  max-width: 570px;
  width: 100%;
}

.category-select {
  background: #fff;
}

.category-control {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.counts-call {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  p {
    margin-bottom: 0;
  }
}
</style>
