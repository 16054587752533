<template>
  <div class="switch">
    <h6>Искать по</h6>
    <el-radio-group v-model="switchState" size="small">
      <el-radio-button label="имени"></el-radio-button>
      <el-radio-button label="телефону"></el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: "MySwitch",
  props:{
    propsGetSwitchState:{
      type:Function
    }
  },
  data() {
    return {
      switchState: 'имени',

    }
  },
  watch:{
    switchState(){
      this.propsGetSwitchState(this.switchState)
    }
  }
}
</script>

<style lang="scss" scoped>
.switch{
  min-width: 170px;
}
h6 {
  font-size: 15px;
  margin-bottom: 10px;
  color: #555;
}
::v-deep{
  .el-radio-button__inner{
    font-size: 12px;
    font-weight: normal;
    width: 85px;
  }
}
</style>