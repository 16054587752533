<template>
  <el-row v-loading="loading">
    <el-row class="category">
      <h1>Категория: {{ $store.getters.category.name }}</h1>
      <el-row class="general-info">

        <el-row type="flex">
          <el-col :span="14" class="dictionaries">
            <category-dictionaries
              :category="selectedCategory"
            />
          </el-col>

          <el-col :span="10" class="checklists">
            <category-checklists
               :category="selectedCategory"
            />
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col :span="4">
            <customer-geography/>
          </el-col>

          <el-col :span="3">
            <report/>
          </el-col>

          <el-col :span="17">
            <about-audio/>
          </el-col>
        </el-row>



      </el-row>
      <el-row class="trend">
        <el-row type="flex">
          <el-col :span="14">
            <h2>Тенденции</h2>
          </el-col>
          <el-col :span="11">
            <el-row type="flex" class="trend__description" justify="end">

              <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="по"
                  start-placeholder="Начало"
                  end-placeholder="Окончание"
                  :picker-options="dateRangeOptions">
              </el-date-picker>


            </el-row>
          </el-col>
        </el-row>

        <trend-chart
            :data="chartData"
            :dates="categories"
            :detail="false"
        />

      </el-row>

      <el-row class="calls" v-loading="loading">
        <category-calls
            :category-id="$route.params.category_id"
        />
      </el-row>
    </el-row>






  </el-row>

</template>

<script>



import CategoryForm from "@/components/category/CategoryForm";
import CustomerGeography from "@/components/CustomerGeography";
import Report from "@/components/Report";
import AboutAudio from "@/components/AboutAudio";
import TrendChart from "@/components/TrendChart";
import CategoryCalls from "@/components/category/CategoryCalls";
import {getDaysArray} from "@/utils/helpers";
import {mapMutations} from "vuex";
import CategoryChecklists from "@/components/category/CategoryChecklists.vue";
import CategoryDictionaries from "@/components/category/CategoryDictionaries.vue";


export default {
  name: "category",
  components: {
    CategoryChecklists, CategoryDictionaries, CategoryForm, CustomerGeography, Report, AboutAudio, TrendChart, CategoryCalls},
  props: {
    subLocation: {}
  },
  data() {
    return {
      loading: false,
      dateRange: '',
      dateRangeOptions: {
        shortcuts: [{
          text: 'Пред. неделя',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Пред.месяц',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'За 3 месяца',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

      visibleCategoryDrawer: false,
      visibleLocationCategoryDrawer: false,
      visibleCategoryChecklistsDrawer: false,
      visibleCategoryDictionaryDrawer: false,
      selectedCategory: {},
      clientDictionaries: [],
      systemDictionaries: [],
      visibleDictionaryDrawer: false,
      selectedDictionary: {},


      days: 15,
      chartData: [{
        name: 'Всего аудио',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 120, 123, 61, 58, 63, 60, 43],
        color: '#642cf7',
      }, {
        name: 'К обработке',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 44, 61, 58, 63, 60, 54],
        color: '#f6d080'
      }, {
        name: 'Негативные',
        data:[35, 41, 36, 26, 45, 48, 52, 53, 41,65, 61, 58, 63, 165, 67],
        color: '#75d688'
      },{
        name: 'Позитивные',
        data: [35, 41, 36, 26, 45, 44, 55, 57, 56, 61, 61, 58, 63, 60, 32],
        color: '#5583f8'
      }],
      categories: [],

    }
  },
  created() {

  },
  mounted() {
    this.getCategory()
  },
  methods: {
    getCategory() {
      this.loading = true;
      this.$api.category.getCategory({
        category_id: this.$route.params.category_id
      })
          .then(data => {
            this.selectedCategory = data
          })
          .finally(() => {
            this.loading = false;
          })
    },
    showCategoryForm(id = null) {
      if (id) {
        this.selectedCategory = this.categories.find(category => category.id === id)[0]
      } else {
        this.selectedCategory = {};
      }
      this.visibleCategoryDrawer = true;
    },
    showLocationCategory() {
      this.visibleLocationCategoryDrawer = true;
    },
    save() {
      this.loading = true;
      if (!this.selectedCategory.name || !this.selectedCategory.description) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Поля Имя и Описание обязательны для заполнения',
        });
        return;
      }

      this.$api.category.save(this.selectedCategory)
          .then(data => {

            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Категория сохранена',
              });
              this.reset();
              this.getCategories();

            } else {

              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors[0]
              });

            }

          })
          .finally(() => {
            this.loading = false;
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.category {

  padding:  0 30px;
  h1 {
    margin-left: 10px;
    font-weight: bold;
  }
  margin: 0;
  h4 {
    margin-bottom: 10px;
    font-size: 19px;
  }
  .general-info {
    .el-col {
      background: #fff;
      padding: 30px;
      border-radius: 5px;
      margin: 10px;
      box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;
    }
  }
  .trend, .calls {
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;
    &__description {
      font-size: 14px;
      color: #b5b5b5;
      i {
        font-size: 27px;
        color: #000;
        cursor: pointer;
        position: absolute;
        right: 0;
      }
      .el-col {
        > span {
          &:nth-child(odd) {
            color: #000;
            font-weight: 900;
          }
          &:nth-child(even) {
            font-size: 16px;
          }
          &.color {
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            top: 5px;
            left: -15px;
          }
        }
      }
    }
  }
  i.el-icon-circle-plus-outline {
    position: absolute;
    top: 5px;
    right: 0;
    color: #b5b5b5;
    transition: all 0.1s ease-out;
    font-weight: bold;
    font-size: 16px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .dictionaries, .checklists {
    p {
      color: #b5b5b5;
      margin-bottom: 10px;
      margin-left: 15px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

</style>