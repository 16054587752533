var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.checkListData.relevant)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-header"},[_c('h4',[_vm._v(_vm._s(_vm.checkListData.checklist.name))]),_vm._m(0)]),_c('div',{staticClass:"item-body__progress"},[_c('my-progress',{attrs:{"percentage":_vm.getTheTotalNumberAndCompletedPoints.percentage}})],1),_c('div',{staticClass:"item-body"},[_c('ul',{staticClass:"item-body__positive-list"},_vm._l((_vm.checkListData.successDictArr),function(positive_dict){return _c('li',{on:{"click":function($event){return _vm.propsGetHighLightChecklist(positive_dict.dictionary.reverse
        ? false
        :positive_dict.dictionary.found[0])}}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","placement":"top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(positive_dict.dictionary.found[0])?_c('div',{staticClass:"tool-tip-employee"},[(
            (_vm.propsCallReverse && positive_dict.dictionary.found[0].search_text_highlight_two)
             || (!_vm.propsCallReverse &&positive_dict.dictionary.found[0].search_text_highlight_one))?_c('div',[_vm._v("Сотрудник: "),_vm._l((_vm.propsCallReverse
                 ? positive_dict.dictionary.found[0].search_text_highlight_two
                 : positive_dict.dictionary.found[0].search_text_highlight_one),function(word){return _c('i',{domProps:{"innerHTML":_vm._s(word.join(','))}})})],2):_vm._e()]):_c('div',{staticClass:"revers-dict"},[_c('span',[_vm._v("Словарь реверсивный:")]),_c('br'),_vm._v(_vm._s(positive_dict.dictionary.description)+" ")]),(positive_dict.dictionary.found[0])?_c('div',{staticClass:"tool-tip-client"},[(
              (_vm.propsCallReverse && positive_dict.dictionary.found[0].search_text_highlight_one)
             || (!_vm.propsCallReverse &&positive_dict.dictionary.found[0].search_text_highlight_two))?_c('div',[_vm._v("Клиент: "),_vm._l((_vm.propsCallReverse
                 ? positive_dict.dictionary.found[0].search_text_highlight_one
                 : positive_dict.dictionary.found[0].search_text_highlight_two),function(word){return _c('i',{domProps:{"innerHTML":_vm._s(word.join(','))}})})],2):_vm._e()]):_vm._e()]},proxy:true}],null,true)},[_c('span',{staticClass:"null-style"},[_vm._v(_vm._s(positive_dict.dictionary.name)+" "),(positive_dict.dictionary.reverse)?_c('i',{staticClass:"el-icon-sort reverse-icon"}):_vm._e()])])],1)}),0),_c('ul',{staticClass:"item-body__negative-list"},_vm._l((_vm.checkListData.negativeDictArr),function(negative){return _c('li',[_vm._v(_vm._s(negative.dictionary.name)+" "),(negative.dictionary.reverse)?_c('i',{staticClass:"el-icon-sort reverse-icon"}):_vm._e()])}),0)]),_c('div',{staticClass:"item-footer"},[_c('div',{staticClass:"item-footer__performed"},[(_vm.getTheTotalNumberAndCompletedPoints.percentage === 0)?_c('span',{style:({background:_vm.checkListData.color_reject})},[_vm._v(" Не выполнен ")]):(_vm.getTheTotalNumberAndCompletedPoints.percentage < 100)?_c('span',{style:({background: 'orange'})},[_vm._v(" Выполнен не полностью ")]):_c('span',{style:({background:_vm.checkListData.color_success})},[_vm._v(" Выполнено ")])]),_c('div',{staticClass:"item-footer__content"},[_c('div',{staticClass:"points"},[_vm._v(" Необходимый результат для выполнения чек-листа: "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.checkListData.limit_result))])]),_c('div',{staticClass:"points"},[_vm._v(" Набрано баллов: "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.getTheTotalNumberAndCompletedPoints.totalResult))])])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{attrs:{"type":"button"}},[_c('i',{staticClass:"el-icon-more"})])}]

export { render, staticRenderFns }