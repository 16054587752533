<template>
  <el-row type="flex" justify="center">
    <el-col 
        class="location-form-container" 
        :span="12"
    >

      <h4>Вопрос "{{selectedQuestion.name}}"</h4>

      <el-row style="text-align: left;">
        <ul>
          <li>Название: {{selectedQuestion.name}}</li>
          <li>Описание: {{selectedQuestion.description}}</li>
          <li>Запрос: {{selectedQuestion.query}}</li>
          <li>Реверсивный: {{selectedQuestion.reverse}}</li>
        </ul>
      </el-row>


    </el-col>


  </el-row>
</template>

<script>
export default {
  name: "question-form",
  props: {
    selectedQuestion :{},
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.$emit('save')
    }
  }
}
</script>

<style lang="scss" scoped>

  .location-form-container {
    text-align: center;
    > *:not(:first-child) {
      margin-bottom: 25px;
    }
  }

</style>