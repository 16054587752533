import axios from "axios";
import store from "@/store";

const axiosInstance = axios.create({
    baseURL:  process.env.NODE_ENV === 'production'
        ? 'https://api.audio-control.by/api/'
        : 'https://api.audio-control.by/api/',
})

let isRefreshing = false;
let failedQueue = [];
const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

axiosInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token');
    return config;
});

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    err => {
        const originalRequest = err.config;

        if (err.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise(function(resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
                        return axiosInstance(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            return new Promise(function(resolve, reject) {

                const refreshToken = localStorage.getItem('refresh_token');

                if(!refreshToken) {
                    window.location.href = '/login';
                }

                axiosInstance.post('auth/get-auth-user-by-refresh-token', {
                        refresh_token: refreshToken
                    })
                    .then(({ data }) => {

                        let access_token = data.data.access_token;
                        let refresh_token = data.data.refresh_token;

                        if(!access_token || !refresh_token || data.errors) {
                            window.location.href = '/login';
                        }


                        localStorage.setItem('access_token', access_token);
                        localStorage.setItem('refresh_token', refresh_token);
                        processQueue(null, null);
                        resolve(axiosInstance(originalRequest));
                    })
                    .catch(err => {
                        processQueue(err, null);
                        reject(err);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(err);
    }
);



export default axiosInstance
