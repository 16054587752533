var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('swiper',{staticClass:"my-slider",attrs:{"slides-per-view":1,"space-between":0,"loop":false,"pagination":false,"navigation":true,"breakpoints":{
    '640': {
      slidesPerView: 2,
    },
    '800': {
      slidesPerView: 2,
    },
    '1024': {
      slidesPerView: 2,
    },
    '1500': {
      slidesPerView: _vm.slidePerDesk,
    },
  }}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }