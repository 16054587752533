<template>
  <el-row>

    <div class="top-block">
      <div class="title">
        <h4>{{ setNameBLock }}</h4>
      </div>
      <div class="btn-wrapper" v-if="buttonIsShow">
        <el-input
            :class="'input-search'"
            placeholder="Ищем словарь ?"
            suffix-icon="el-icon-search"
            :value="propsInputSearchValue"
            @input="changeInput"
        >


        </el-input>
        <my-button :class="'btnAdd'" @click="showDictionaryForm()">
          Добавить
        </my-button>
        <my-button @click="showModal">Добавить группу</my-button>
      </div>
    </div>

    <group
        ref="group"
        v-if="type === 'client'"
        :groups="clientDictionariesGroups"
        @update-list="getDictionariesGroups"
    ></group>

    <!--    v-for="(dict, index) in selectedDictionaries"-->
    <div class="dictionaries-container">
      <dictionary-item

          :key="type +'-'+ dict.id+'-'+index"
          v-for="(dict, index) in computedDictionaries"
          :dictData="dict"
          :type="type"
          @show="showDictionaryInformationForm"
          @edit="showDictionaryForm"
          @delete="deleteDictionary"
      />

    </div>

    <el-drawer
        :visible.sync="visibleDictionaryDrawer"
        direction="rtl"
        size="50%"
    >
      <dictionary-form
          :selected-dictionary="selectedDictionary"
          :loading="loading"
          @cancel="visibleDictionaryDrawer = false"
          @save="save"
      >
      </dictionary-form>
    </el-drawer>
    <el-drawer
        :visible.sync="visibleDictionaryInformationDrawer"
        direction="rtl"
        size="50%"
    >
      <dictionary-information-form
          :selected-dictionary="selectedDictionary"

          @cancel="visibleDictionaryInformationDrawer = false"
          @save="save"
      >
      </dictionary-information-form>
    </el-drawer>

    <!--    <drawer-calls-list/>-->
  </el-row>
</template>

<script>

import DictionaryForm from "@/components/dictionary/DictionaryForm.vue";
import DictionaryInformationForm from "@/components/dictionary/DictionaryInformationForm.vue";
import DictionaryItem from "@/components/dictionary/DictonaryItem.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MySearchInput from "@/components/UI/MySearchInput.vue";
import DrawerCallsList from "@/components/dictionary/drawer-calls-list.vue";
import Group from "@/components/dictionary/group/Group.vue";

export default {
  name: "dictionary",
  components: {
    Group,
    DrawerCallsList,
    MySearchInput,
    MyButton, DictionaryItem, DictionaryInformationForm, DictionaryForm
  },
  props: {
    propsShowModalCreateDictGroup:{
      type:Function
    },
    type: {
      type: String,
      required: true
    },
    propsGetValueInputSearchChildren: {
      type: Function
    },
    propsInputSearchValue:{
      type:String
    },
    buttonIsShow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputSearch: '',
      loading: false,
      dictionaries: {},
      clientDictionariesGroups: [],
      systemDictionariesGroups: [],
      visibleDictionaryInformationDrawer: false,
      visibleDictionaryDrawer: false,
      emptyDictionary: {
        reverse: 0,
        type: 'client',
        search_in_channel_1: 1,
        search_in_channel_2: 1,
        search_in_call: 1,
        search_out_call: 1,
        change_to_type: null
      },
      selectedDictionary: {},
    }
  },
  mounted() {
    this.getDictionaries();
    this.getDictionariesGroups();
  },
  methods: {
    showModalCreateDictGroup() {
      this.$refs.group.showDictionaryForm()
    },
    setDictionariesList(data) {
      this.$refs.group.setDictionariesList(data)
    },
    showModal(){
      this.propsShowModalCreateDictGroup()
    },
    changeInput(value) {
      this.propsGetValueInputSearchChildren(value)
    },
    getDictionaries() {
      this.loading = true;
      this.$api.dictionary.get()
          .then(data => {
            this.dictionaries = data;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getDictionariesGroups() {
      this.$api.dictionary.getDictionariesGroups()
          .then(data => {
            this.clientDictionariesGroups = data.client_groups
          })
    },
    showDictionaryForm(data) {
      if (data) {
        if (data.type === 'system') {
          this.selectedDictionary = this.dictionaries['system'].find(dictionary => dictionary.id === data.id);
          this.selectedDictionary.type = 'system';
        } else {
          this.selectedDictionary = this.dictionaries['client'].find(dictionary => dictionary.id === data.id);
          this.selectedDictionary.type = 'client';
        }
      } else {
        this.selectedDictionary = this.emptyDictionary
      }
      this.visibleDictionaryDrawer = true;

    },
    showDictionaryInformationForm(data) {
      if (data.type === 'system') {
        this.selectedDictionary = this.dictionaries['system'].find(dictionary => dictionary.id === data.id);
        this.selectedDictionary.type = 'system'
      } else {
        this.selectedDictionary = this.dictionaries['client'].find(dictionary => dictionary.id === data.id);
        this.selectedDictionary.type = 'client'
      }

      this.selectedDictionary.type = data.type
      this.visibleDictionaryInformationDrawer = true;
    },
    save() {
      this.loading = true;
      if (!this.selectedDictionary.name) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Поля Имя обязательно для заполнения',
        });
        return;
      }

      if (this.selectedDictionary.change_to_type === this.selectedDictionary.type) {
        this.selectedDictionary.change_to_type = null;
      }
      this.$api.dictionary.save(this.selectedDictionary)
          .then(data => {

            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Словать сохранен',
              });
              this.reset();
              this.getDictionaries();

            } else {

              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors.message
              });
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },
    deleteDictionary(data) {
      this.loading = true;
      this.$api.dictionary.delete({id: data.id, type: data.type})
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Словарь удален',
            });
            this.getDictionaries()
          })
          .finally(() => {
            this.loading = false;
          })
    },
    reset() {
      this.visibleDictionaryInformationDrawer = false;
      this.visibleDictionaryDrawer = false;
      this.selectedDictionary = this.emptyDictionary
    }
  },
  computed: {
    setNameBLock() {
      switch (this.type) {
        case 'client':
          return 'Пользовательские'
        case 'system':
          return 'Системные'
        default:
          return 'Все'
      }
    },
    computedDictionaries() {
      if (this.dictionaries) {
        if (this.propsInputSearchValue.length > 2) {
          return (this.dictionaries[this.type].filter((item) => item.name.toLocaleLowerCase().indexOf(this.propsInputSearchValue.toLocaleLowerCase()) !== -1))
        }
        return this.dictionaries[this.type]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dictionaries-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 1700px) {
    & {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  @media (max-width: 1500px) {
    & {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: 1200px) {
    & {
      grid-template-columns: 1fr 1fr;
    }
  }
}


.add {
  text-align: right;
  margin-bottom: 10px;
}

.top-block {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
  }
}

.search-block {
  margin-bottom: 20px;
}

.title {
  h4 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    color: #555;
  }
}

.btn-wrapper {
  display: flex;
  gap: 15px;

  button {
    height: 30px;
    font-weight: 500;
    font-size: 12px;
    &:last-child{
      width: 140px;
    }
  }
}

::v-deep {
  .input-search {
    margin-right: 20px;
    max-width: 220px;


    input {
      height: 30px;
      border: none;
      border-bottom: solid 1px black;
      border-radius: 0;

    }

    .el-input__suffix {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

</style>