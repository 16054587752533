import Vue from 'vue'

import ElementUI from 'element-ui';

import './assets/css/element-variables.scss';
import 'element-ui/lib/theme-chalk/display.css';

import locale from 'element-ui/lib/locale/lang/ru-RU'
import App from './App.vue'

import vueDebounce from 'vue-debounce'

import ApiPlugin from './plugins/api'
import ConstantsPlugin from './plugins/constants'

import acl from './acl';

import router from './router'

import store from './store'
import VueApexCharts from 'vue-apexcharts'
import jwtDecode from 'vue-jwt-decode'


Vue.config.productionTip = false
Vue.component('apexchart', VueApexCharts)
Vue.use(ApiPlugin);
Vue.use(ConstantsPlugin);
Vue.use(VueApexCharts);
Vue.use(ElementUI, {locale});
Vue.use(vueDebounce);
Vue.use(acl);
Vue.use(jwtDecode);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
