import Vue from "vue";

const Constants = {
    global: {
        notifyDurationSuccess: 800,
        notifyDurationError: 1200,
    },


    rbac: {
        roles: {
            ADMIN: 'admin',
            USER: 'user',
            MANAGER: 'manager'

        }
    },
    // audio_url :'https://int.audio-control.by/get-audio'
    audio_url: process.env.NODE_ENV === 'production'
        ? 'https://api.audio-control.by'
        : 'http://cabinet.audio-control.local',
}

export default {
    install(Vue) {
        Vue.prototype.$constants = Constants;
    }
}
