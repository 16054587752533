<template>
  <el-row>
    <el-menu
        class="el-menu-vertical"
        router
        :default-active="$route.path"
        menu-trigger="click"
        :collapse="toggle"
    >

      <el-menu-item
          v-if="$can('menu-see-management')"
          index="/management/edit"
          :route="{name: 'Edit'}"
      >
        <div>
          <p class="menu-icon location-icon">
            <img src="../assets/img/icons/custom/page_paper_file_document_house_home_icon.svg">
          </p>
          <p>Менеджмент</p>
          <p>Организаций</p>
        </div>
      </el-menu-item>
      <el-menu-item
          index="Locations"
          :route="{name: 'New Locations'}"
      >
        <div>
          <p class="menu-icon constructor-icon">
            <img src="../assets/img/icons/custom/page_paper_file_document_house_home_icon.svg">

          </p>
          <!--          <p>New</p>-->
          <p>Локации</p>
        </div>
      </el-menu-item>
      <el-menu-item
          v-if="$can('menu-see-dictionaries-and-checklists')"
          index="dictionary-and-checklist"
          :route="{name: 'DictionaryAndChecklist'}"
      >
        <div>
          <p class="menu-icon dictionary-and-checklist-icon">
            <img src="../assets/img/icons/custom/page_paper_file_document_text_icon.svg">
          </p>
          <p>Словари</p>
          <p>Чек-листы</p>
        </div>
      </el-menu-item>
<!--      <el-menu-item-->
<!--          v-if="$can('menu-see-edit-organization') && $store.getters.user.organization_id && $store.getters.user.role.name !== 'admin'"-->
<!--          index="organization/edit/"-->
<!--          :route="{name: 'EditOrganization', params: {id:  $store.getters.user.organization_id}}"-->
<!--      >-->
<!--        <div>-->
<!--          <p class="menu-icon location-icon">-->
<!--            <img src="../assets/img/icons/custom/page_paper_file_document_write_pencil_icon.svg">-->

<!--          </p>-->
<!--          <p>Редактир...</p>-->
<!--          <p>организации</p>-->
<!--        </div>-->
<!--      </el-menu-item>-->
      <el-menu-item
          v-if="$can('menu-see-search')"
          index="search"
          :route="{name: 'Search'}"
      >
        <div>
          <p class="menu-icon search-icon">
            <img src="../assets/img/icons/custom/page_paper_file_document_search_icon.svg">
          </p>
          <p>Поиск</p>
        </div>
      </el-menu-item>

<!--      <el-menu-item-->
<!--          v-if="$can('menu-see-violations')"-->
<!--          index="violation"-->
<!--          :route="{name: 'Violation'}"-->
<!--      >-->
<!--        <div>-->
<!--          <p class="menu-icon violation-icon">-->
<!--            <img src="../assets/img/icons/custom/page_paper_file_document_remove_delete_icon.svg">-->
<!--          </p>-->
<!--          <p>Нарушения</p>-->
<!--        </div>-->
<!--      </el-menu-item>-->

<!--      <el-menu-item-->
<!--          v-if="$can('menu-see-trends')"-->
<!--          index="trend"-->
<!--          :route="{name: 'Trend'}"-->
<!--      >-->
<!--        <div>-->
<!--          <p class="menu-icon trend-icon">-->
<!--            <img src="../assets/img/icons/custom/page_paper_file_document_message_bubble_conversation_icon_228149.svg">-->
<!--          </p>-->
<!--          <p>Тренды</p>-->
<!--        </div>-->
<!--      </el-menu-item>-->

<!--      <el-menu-item-->
<!--          v-if="$can('menu-see-constructor')"-->
<!--          index="constructor"-->
<!--          :route="{name: 'Constructor'}"-->
<!--      >-->
<!--        <div>-->
<!--          <p class="menu-icon constructor-icon">-->
<!--            <img src="../assets/img/icons/custom/page_paper_file_document_write_pencil_icon.svg">-->
<!--          </p>-->
<!--          <p>Конструктор</p>-->
<!--          <p>запросов</p>-->
<!--        </div>-->
<!--      </el-menu-item>-->
      <el-menu-item
          index="Locations"
          :route="{name: 'analytics'}"
      >
        <div>
          <p class="menu-icon constructor-icon">
            <img src="../assets/img/icons/custom/page_paper_file_document_analytics_chart_icon.svg">
          </p>
          <!--          <p>New</p>-->
          <p>Аналитика</p>
        </div>
      </el-menu-item>
      <el-menu-item
          v-if="!$can('menu-see-management')"
          index="constructor"
          :route="{name: 'upload-calls'}"
      >
        <div>
          <p class="menu-icon constructor-icon">

            <img src="../assets/img/icons/custom/page_paper_file_document_downloads_icon.svg">
          </p>
          <p>Загрузить</p>
          <p>звонки</p>
        </div>
      </el-menu-item>
<!--      <el-menu-item-->
<!--          v-if="!$can('menu-see-management')"-->
<!--          index="constructor"-->
<!--          :route="{name: 'Integrations'}"-->
<!--      >-->
<!--        <div>-->
<!--          <p class="menu-icon constructor-icon">-->

<!--            <img src="../assets/img/icons/icon-move.svg">-->
<!--          </p>-->
<!--          <p></p>-->
<!--          <p>Интеграции</p>-->
<!--        </div>-->
<!--      </el-menu-item>-->
<!--      <el-menu-item-->
<!--          :style="{opacity:0.3}"-->
<!--          v-if="$can('menu-see-locations')"f-->
<!--          index="/locations"-->
<!--          :route="{name: 'Locations'}"-->
<!--      >-->
<!--        <div>-->
<!--          <p class="menu-icon location-icon">-->
<!--            <img src="../assets/img/icons/custom/page_paper_file_document_house_home_icon.svg">-->
<!--          </p>-->
<!--          <p>Локации</p>-->
<!--          <p>вл. Локации</p>-->
<!--        </div>-->
<!--      </el-menu-item>-->




<!--      <el-menu-item-->
<!--          index="/organization"-->
<!--          :route="{name: 'Organization'}"-->
<!--      >-->
<!--        <i class="el-icon-user"></i>-->
<!--        <span>Организации</span>-->
<!--      </el-menu-item>-->

<!--      <el-menu-item-->
<!--          index="/user"-->
<!--          :route="{name: 'User'}"-->
<!--      >-->
<!--        <i class="el-icon-user"></i>-->
<!--        <span>Пользователи</span>-->
<!--      </el-menu-item>-->

<!--      <el-menu-item-->
<!--          index="/location"-->
<!--          :route="{name: 'Location'}"-->
<!--      >-->
<!--        <i class="el-icon-location-outline"></i>-->
<!--        <span>Локиции</span>-->
<!--      </el-menu-item>-->

<!--      <el-menu-item-->
<!--          index="/category"-->
<!--          :route="{name: 'Category'}"-->
<!--      >-->
<!--        <i class="el-icon-user"></i>-->
<!--        <span>Категории</span>-->
<!--      </el-menu-item>-->


<!--      <el-menu-item-->
<!--          index="/question"-->
<!--          :route="{name: 'Question'}"-->
<!--      >-->
<!--        <i class="el-icon-setting"></i>-->
<!--        <span>Вопросы</span>-->
<!--      </el-menu-item>-->

<!--      <el-menu-item-->
<!--          index="/statistics"-->
<!--          :route="{name: 'Statistics'}"-->
<!--      >-->
<!--        <i class="el-icon-setting"></i>-->
<!--        <span>Статистика</span>-->
<!--      </el-menu-item>-->

    </el-menu>
  </el-row>
</template>

<script>
export default {
  name: "general-menu",
  props: {
    toggle: {
      type: Boolean,
    },
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";
.el-menu {
  border: none;
  width: 100%;
  margin-top: 25px;
  .el-menu-item {
    height: auto;
    padding:12px 3px 12px 0!important;
    border-left: 5px solid transparent;
    box-sizing: border-box;
    &:hover, &:focus, &.is-active{
      background-color: #eaf0fd;
      border-color: $--color-primary;

    }

    p {
      text-align: center;
      margin: 0;
      line-height: 1;
      font-size: 10px;
      color: #696969;
      line-height: 1.3;
      &.menu-icon {
        width: 40px;
        height: 40px;
        background-size: contain;
        margin: 0 auto 5px auto;
      }
      &.location-icon {

      }
      &.dictionary-and-checklist-icon {

      }
      &.search-icon {

      }
      &.violation-icon {

      }
      &.trend-icon {

      }
      &.constructor-icon {

      }
    }


  }
}

</style>
