<template>
  <el-row class="custom-player" v-loading="loadFile">
    <div class="audio-player">
      <div class="timeline" ref="timeline" @click="timelineHandler">
        <div class="progress" :style="{ width: timeLineWidth }"></div>
      </div>
      <div class="controls">

        <div class="play-container">
          <div class="toggle-play play" ref="playButton" @click="togglePlayHandler">
          </div>
        </div>

        <div class="name">{{ audioName }}</div>
        <div class="speed">Скорость:</div>

        <div>
          <div class="time">
            <div class="current">{{ audioCurrentTime }}</div>
            <div class="divider">/</div>
            <div class="length">{{ audioLength }}</div>
          </div>
          <div class="volume-container">
            <div class="volume-button">
              <div class="volume el-icon-microphone" ref="volume"></div>
            </div>

            <div class="volume-slider" ref="volumeSlider" @click="changeVolumeHandler($event)">
              <div class="volume-percentage" :style="{width: volumePercentage}"></div>
            </div>
          </div>
          <div class="other-controls">
            <a download="true" :href="`https://int.audio-control.by/get-audio-save?path=${this.audioPath}`">
              <i class="el-icon-download"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="speed-slider">
        <el-slider
            show-stops
            :max="200"
            :step="200 / 10"
            :show-tooltip="false"
        >
        </el-slider>
      </div>
    </div>
  </el-row>
</template>

<script>
import axios from "axios";

export default {
  name: "my-custom-audio-player",
  props: {
    audioName: {
      type: String,
      default: 'Загружаю...'
    },
    audioPath: {
      type: String,
    },
    getCurrentTimeAudio: {},
    chosenWordRecognized: {
      type: [Number, String]
    },
    stateDrawer: {
      type: Boolean
    }
  },
  data() {
    return {
      audio: null,
      audioLength: null,
      volumePercentage: null,
      timeLineWidth: '0',
      audioCurrentTime: '00:00',
      loadFile: ''
    }
  },
  methods: {
    playAudio() {
      this.audio.addEventListener('timeupdate', this.setTimeLineProgressAndCurrentTime)
      this.audio.play()
      this.$refs.playButton.classList.remove("play");
      this.$refs.playButton.classList.add("pause");
      this.endAudio()

    },
    stopAudio() {
      this.audio.pause()
      this.$refs.playButton.classList.remove("pause");
      this.$refs.playButton.classList.add("play");
      this.audio.removeEventListener('timeupdate', this.setTimeLineProgressAndCurrentTime)
    },
    endAudio() {
      this.audio.addEventListener('ended', () => {
        this.sendCurrentTimeToParentNode(0)
        this.$refs.playButton.classList.remove("pause");
        this.$refs.playButton.classList.add("play");
        this.audio.removeEventListener('timeupdate', this.setTimeLineProgressAndCurrentTime)
      })
    },


    setTimeLineProgressAndCurrentTime() {
      this.timeLineWidth = this.audio.currentTime / this.audio.duration * 100 + "%";
      this.audioCurrentTime = this.getTimeCodeFromNum(this.audio.currentTime);
      this.sendCurrentTimeToParentNode(this.audio.currentTime)
    },

    sendCurrentTimeToParentNode(time) {
      this.getCurrentTimeAudio(time)
    },

    getTimeCodeFromNum(num) {
      let seconds = parseInt(num);
      let minutes = parseInt(String(seconds / 60));
      seconds -= minutes * 60;
      const hours = parseInt(String(minutes / 60));
      minutes -= hours * 60;
      if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
      return `${String(hours).padStart(2, 0)}:${minutes}:${String(
          seconds % 60
      ).padStart(2, 0)}`;
    },
    changeVolumeHandler(e) {
      this.audio.volume = e.offsetX / this.$refs.volumeSlider.clientWidth
      this.volumePercentage = e.offsetX + '%';
    },
    toMute() {
      this.$refs.volume.classList.remove("el-icon-microphone");
      this.$refs.volume.classList.add("el-icon-turn-off-microphone");
    },
    toUnmute() {
      this.$refs.volume.classList.add("el-icon-microphone");
      this.$refs.volume.classList.remove("el-icon-turn-off-microphone");
    },
    setTimeToAudioPlayerByWord(e) {
      let time = parseFloat(e.target.getAttribute('data-time-start'));
      this.getCurrentTextByTime(time)
    },
    timelineHandler(e) {
      this.audio.currentTime = e.offsetX / parseInt(this.$refs.timeline.clientWidth) * this.audio.duration;
    },
    togglePlayHandler() {
      (this.audio.paused && this.audio.playbackRate > 0) ? this.playAudio() : this.stopAudio()
    },
    downloadFile() {
      axios.get(`https://int.audio-control.by/get-audio-save?path=${this.audioPath}`).then()
    }

  },
  watch: {
    audioPath() {
      this.audio = new Audio(`https://int.audio-control.by/get-audio?path=${this.audioPath}`)
      // this.audio = new Audio(`http://127.0.0.1:8181/get-audio?path=${this.audioPath}`)
      this.audio.onerror = () => {
        alert('Ошибка файла')
      }

      this.audio.onloadeddata = () => {
        this.audioLength = this.getTimeCodeFromNum(this.audio.duration)
      }
    },
    chosenWordRecognized() {
      this.audio.currentTime = this.chosenWordRecognized
    },
    stateDrawer() {
      this.stopAudio()
      this.timeLineWidth = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-player {
  margin: 15px 0;

  .audio-player {
    height: 38px;
    width: 100%;
    background: #ddd;
    box-shadow: 0 0 3px 0 #ddd;
    color: #444444;
    font-size: 0.65em;
    overflow: hidden;
    display: grid;
    grid-template-rows: 6px auto;
    border-radius: 35px;
    margin: 0 auto;
    z-index: 5;

    .speed-slider {
      background: #b7b7b7;

      z-index: 9;
      height: 30px;
      cursor: pointer;

      .el-slider {
        height: 0;
        top: 16px;
        position: absolute;
        width: calc(100% - 30px);
        max-width: calc(790px - 30px);
        margin: 0 15px;

        ::v-deep .el-slider__runway {
          background: #b7b7b7;
        }

        ::v-deep .el-slider__button-wrapper {
          height: 20px;
          width: 20px;
          top: -7px;
        }

        ::v-deep .el-slider__bar {
          background: #a5a5a5;
        }
      }
    }

    .timeline {
      background: white;
      width: 100%;
      position: relative;
      cursor: pointer;
      box-shadow: 0 2px 10px 0 #0008;

      .progress {
        background: #b7b7b7;
        width: 0;
        height: 100%;
        transition: 0.25s;
      }
    }

    .controls {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      height: 26px;
      padding: 0 20px;

      .time, .name, .speed {
        margin-top: 3px;
      }

      .other-controls {
        margin-top: 3px;
        font-size: 21px;
        margin-left: 5px;

        i {
          margin-top: 8px;
          display: inline-block;
          margin-left: 5px;
          cursor: pointer;
        }

      }

      > * {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .toggle-play {
        &.play {
          cursor: pointer;
          position: relative;
          left: 0;
          height: 0;
          width: 0;
          border: 7px solid #0000;
          border-left: 13px solid white;

          &:hover {
            transform: scale(1.1);
          }
        }

        &.pause {
          height: 15px;
          width: 20px;
          cursor: pointer;
          position: relative;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            background: white;
            content: "";
            height: 15px;
            width: 3px;
          }

          &:after {
            position: absolute;
            top: 0;
            right: 8px;
            background: white;
            content: "";
            height: 15px;
            width: 3px;
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .time {
        display: flex;

        > * {
          padding: 2px;
        }
      }

      .volume-container {
        cursor: pointer;

        .volume-button {
          font-size: 25px;
          display: flex;
          align-items: center;

          .volume {
            transform: scale(0.7);
          }
        }

        position: relative;
        z-index: 2;

        .volume-slider {
          position: absolute;
          left: -3px;
          top: 5px;
          z-index: -1;
          width: 0;
          height: 15px;

          background: #b7b7b7;
          box-shadow: 0 0 20px #000a;
          transition: .25s;
          transform: rotate(180deg);
          display: flex;
          justify-content: flex-start;

          .volume-percentage {

            background: #fff;
            height: 100%;
            width: 75%;
          }
        }

        &:hover {
          .volume-slider {
            transform: rotate(180deg);
            left: -103px;
            width: 100px;
          }
        }
      }
    }
  }
}
</style>