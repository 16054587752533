<template>
  <div class="block" v-loading="loadingLocations">
    <div>
      <div class="row">
        <div>
          <h5>Выберите дату и время <span>*</span></h5>
          <el-date-picker
              v-model="dateTimeState"
              :picker-options="pickerOptions"
              type="datetime"
              default-time="'12:00:00'"
              placeholder="Дата и время">
          </el-date-picker>
        </div>
        <div>
          <h5>Выберите тип звонка <span>*</span></h5>
          <el-select v-model="typeCallState" placeholder="Тип звонка">
            <el-option
                v-for="item in typeCallOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <div>
          <el-checkbox v-model="reversState">Выберите реверс </el-checkbox>
        </div>

      </div>

      <div class="row">
        <div>
          <h5>Выберите локацию звонка <span>*</span></h5>
          <el-select v-model="locationState" placeholder="Локация звонка">
            <el-option
                v-for="item in locationOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div>
          <h5 :class="locationState?'':'disabled-title'">Выберите подлокацию звонка <span>*</span></h5>
          <el-select v-model="subLocationState" placeholder="Подлокация звонка" :disabled="!locationState">
            <el-option
                v-for="item in filteredSubLocationOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="row">
        <div style="flex-basis: 100%">
          <h5 :class="subLocationState?'':'disabled-title'">Выберите категорию звонка <span>*</span></h5>
          <el-select v-model="categoryState" placeholder="Категория звонка" :disabled="!subLocationState">
            <el-option
                v-for="item in filteredCategoryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
<!--      <div class="row">-->
<!--        <h5>Выберите реверс <span>*</span></h5>-->
<!--        <el-select v-model="reversState" placeholder="Реверс">-->
<!--          <el-option-->
<!--              v-for="item in reverseOptions"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </div>-->
      <div class="row text">
        <div>
          <h5>ФИО сотрудника</h5>
          <el-input placeholder="ФИО сотрудника" v-model="agentNameState"></el-input>
        </div>
        <div>
          <h5>Номер сотрудника</h5>
          <el-input maxlength="15"  placeholder="Номер сотрудника" v-model="agentPhoneState"></el-input>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "collection-of-general-call-information",
  props: {
    propsGetCollectInfo: {
      type: Function
    },

  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: 1
      },
      dateTimeState: null,
      locationState: null,
      subLocationState: null,
      categoryState: null,
      agentNameState: null,
      agentPhoneState: null,
      locations: null,
      loadingLocations: true,
      typeCallState: null,
      reversState: false,
      typeCallOptions: [{label: 'Входящий', value: 'in'}, {label: 'Исходящий', value: 'out'}],
      reverseOptions: [{label: 'Да', value: '1'}, {label: 'Нет', value: '0'}],
      locationOptions: [{label: '1', value: '1'}, {label: '2', value: '2'}, {label: '3', value: '3'}, {
        label: '4', value: '4'
      }],
      sunLocationOptions: [{label: '1', value: '1'}, {label: '2', value: '2'}, {label: '3', value: '3'}, {
        label: '4', value: '4'
      }],
      categoryOptions: [{label: '1', value: '1'}, {label: '2', value: '2'}, {label: '3', value: '3'}, {
        label: '4', value: '4'
      }],
    }
  },
  mounted() {
    this.getLocations()
  },
  methods: {
    collectOptions(data) {
      let locationsOptions = []
      let subLocationsOptions = []
      let categoryOptions = []
      data.forEach(loc => {
        locationsOptions.push({label: loc.name, value: loc.id})
        loc['sub_locations'].forEach(sub => {
          subLocationsOptions.push({loc_id: loc.id, value: sub.id, label: sub.name})
          sub.categories.forEach(cat => {
            categoryOptions.push({loc_id: loc.id, sub_id: sub.id, value: cat.id, label: cat.name})
          })
        })
      })
      this.locationOptions = locationsOptions
      this.sunLocationOptions = subLocationsOptions
      this.categoryOptions = categoryOptions
    },
    getLocations() {
      this.loading = true;
      this.$api.location.get()
          .then(data => {
            this.locations = data
            this.collectOptions(data)
          })
          .finally(() => {
            this.loadingLocations = false;
          })
    },
    checkInfoFile() {
      if (this.dateTimeState && this.categoryState && this.typeCallState && (this.reversState || !this.reversState) && this.locationState
          && this.subLocationState) {
        return this.propsGetCollectInfo({
          state: true,
          date: this.dateTimeState,
          type: this.typeCallState,
          revers: this.reversState ===true? 1 :0,
          location: this.locationOptions.filter(item => item.value === this.locationState)[0].label,
          subLocation: this.sunLocationOptions.filter(item => item.value === this.subLocationState)[0].label,
          category: {
            label: this.categoryOptions.filter(item => item.value === this.categoryState)[0].label,
            value: this.categoryOptions.filter(item => item.value === this.categoryState)[0].value
          },
          agentName: this.agentNameState,
          agentPhone: this.agentPhoneState,
          files: []
        })
      }
      return this.propsGetCollectInfo({
        state: false,
        date: '',
        type: '',
        revers: '',
        location: '',
        subLocation: '',
        category: {label: '', value: ''},
        agentName: '',
        agentPhone: '',
        files: []
      })
    }
  },
  watch: {
    dateTimeState() {
      this.checkInfoFile()
    },
    categoryState() {
      this.checkInfoFile()
    },
    typeCallState() {
      this.checkInfoFile()
    },
    reversState() {
      this.checkInfoFile()
      console.log(this.reversState)
    },
    locationState() {
      this.subLocationState = ''
    },
    subLocationState() {
      this.categoryState = ''
    },
    agentNameState() {
      this.checkInfoFile()
    },
    agentPhoneState() {
      this.checkInfoFile()
    },
  },
  computed: {
    filteredSubLocationOptions() {
      return this.sunLocationOptions.filter(item => item.loc_id === this.locationState)
    },
    filteredCategoryOptions() {
      return this.categoryOptions.filter(item => item.sub_id === this.subLocationState)
    }
  }
}
</script>

<style lang="scss" scoped>
.block {
  padding: 15px;
  background: #fff;
  width: 100%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.13);

  .row {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    div {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
    }

    h5 {
      font-size: 0.9rem;
      color: #555;
      margin-bottom: 5px;
      font-weight: 500;
      display: flex;
      flex-wrap: nowrap;
      gap: 3px;

      span {
        color: red;
      }
    }
  }
}

::v-deep {
  .el-date-editor, .el-select {
    box-sizing: border-box;
    width: 100%;
    margin-left: 0;
    padding-right: 25px;

    i {
      line-height: 0 !important;
    }

    input {
      box-sizing: border-box;
      padding-right: 0;
      height: 30px;
      font-family: inherit;
      font-size: 12px;
      line-height: 0 !important;
      width: 100%;

      &::placeholder {
        font-size: 13px;
      }
    }
  }
  .row{
    gap: 20px;
  }

  .el-input,.el-select{
    padding-right: 0 !important;
  }
  .row.text {
    gap: 20px;
    .el-input {
      margin-left: 0;
      padding-right: 0 !important;

      .input[type="text"] {

      }

      .el-input__inner {
        height: 30px;
      }
    }
  }

}
::v-deep{
  .el-checkbox__label{
    color: #555;
  }
}

.desсription-revers {
  margin-top: 20px;
  font-size: 0.8rem;
  font-weight: normal;
}

.disabled-title {
  color: #bababa !important;
}
</style>