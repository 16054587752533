<template>
  <div class="select">
    <h6>Выберите чек-лист:</h6>
    <el-select v-model="value" @change="getCategoryChecklistData" placeholder="чек-лист">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled">
      </el-option>
    </el-select>
  </div>

</template>

<script>
export default {
  name: 'custom-select-checklist',
  props: {
    propsCategoryAndDate: {
      default: null
    },
    propsShowSpinners:{}
  },
  data() {
    return {
      options: null,
      value: ''
    }
  },
  methods: {
    getCategoryChecklists() {
      if (this.propsCategoryAndDate['date'].length === 2 && this.propsCategoryAndDate['date']) {
        const startDate = Math.round(new Date(this.propsCategoryAndDate['date'][0]) / 1000)
        const endDate = Math.round(new Date(this.propsCategoryAndDate['date'][1]) / 1000)
        this.$api.category.getCategoryChecklists({
          category_id: this.propsCategoryAndDate['category'],
          date_start: startDate,
          date_end: endDate,
        })
            .then(data => {
              let arr = []
              data.forEach(el =>
                  arr.push({value: el['id'], label: el['checklist']['name']})
              )
              this.options = arr
              this.value = '';
            })
      }
    },
    getCategoryChecklistData(value) {
      this.propsShowSpinners()
      const startDate = Math.round(new Date(this.propsCategoryAndDate['date'][0]) / 1000)
      const endDate = Math.round(new Date(this.propsCategoryAndDate['date'][1]) / 1000)
      this.$api.category.getCategoryChecklistByAgentName({
        agent_name: this.propsCategoryAndDate['employee'],
        category_checklist_id: value,
        date_start: startDate,
        date_end: endDate,
      })
          .then(data => {
            this.$emit('update-checklist', data);
          })

    }
  },
  watch: {
    propsCategoryAndDate: {
      handler(newVal) {
        this.getCategoryChecklists();
      },
      deep: true,
    },
  },

}
</script>

<style lang="scss" scoped>
.select {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  min-width: 300px;
  width: 100%;

  h6 {
    font-size: 15px;
    margin-bottom: 0;
    color: #555;
  }

  ::v-deep .el-input__inner {
    height: 30px;
  }

  ::v-deep .el-input__icon {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-range-separator {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-range-input {
    width: 79%;
  }

  ::v-deep .el-date-editor--datetimerange {
    width: 350px;
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
}

</style>