<template>
  <div class="dictionaryIsDashboard" v-if="dictionaryList.length">
    <h2 class="h2-title">Основные показатели</h2>
    <div class="content">
      <dictionary-is-dashboard-item
          v-for="item in this.dictionaryList"
          :key="item.id"
          :propsData="item"
      />
    </div>
  </div>

</template>

<script>
import DictionaryIsDashboardItem from "./dictionaryIsDashboardItem.vue";

export default {
  name: "dictionaryIsDashboard",
  components: {DictionaryIsDashboardItem},

  data() {
    return {
      dictionaryList: []

    }
  },
  mounted() {
    this.getDictionaryIsDashboard()
  },
  methods: {
    getDictionaryIsDashboard() {
      this.$api.dictionary.getIsDashBoard().then((data) => {
        this.dictionaryList = data
      })
    }
  }
}


</script>


<style scoped lang="scss">
.dictionaryIsDashboard {
  display: grid;
  width: 100%;

  .h2-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 450;
    color: #555;
  }
  .content {
    width: 100%;
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr;
    gap: 20px;
    transition: .3s;
    @media (max-width: 1600px) {
      & {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    @media (max-width: 1200px) {
      & {
        grid-template-columns: 1fr  1fr;
      }
    }
  }



}
</style>