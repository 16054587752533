<template>
  <div class="sorted" v-if="propsStateSort.status">
    <div></div>
    <div></div>
    <div>
      <i
          :class="propsStateSort.field==='allDaysIn'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='allDaysIn' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC',field: null })
          : propsChangeSort({status:true,type:'ASC',field:null})"
      ></i>
      <i
          :class="propsStateSort.field==='allDaysOut'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='allDaysOut' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC',field: null })
          : propsChangeSort({status:true,type:'ASC',field:null})"
      ></i>
    </div>
    <div>
      <i
          :class="propsStateSort.field==='dayIn'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='dayIn' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC',field: null })
          : propsChangeSort({status:true,type:'ASC',field: null })"
      ></i>
      <i
          :class="propsStateSort.field==='dayOut'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='dayOut' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC', field:null})
          : propsChangeSort({status:true,type:'ASC', field:null})"
      ></i>
    </div>
    <div></div>
    <div>
      <i
          :class="propsStateSort.field==='allTimeTalkIn'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='allTimeTalkIn' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC', field:null})
          : propsChangeSort({status:true,type:'ASC', field:null})"
      ></i>
      <i
          :class="propsStateSort.field==='full_call_time_seconds1'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='full_call_time_seconds1' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC', field:null})
          : propsChangeSort({status:true,type:'ASC', field:null})"
      ></i>
    </div>
    <div>
      <i
          :class="propsStateSort.field==='full_call_time_seconds'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='full_call_time_seconds' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC', field:'full_call_time_seconds'})
          : propsChangeSort({status:true,type:'ASC', field:'full_call_time_seconds'})"
      ></i>
    </div>
    <div>
      <i
          :class="propsStateSort.field==='average_positive_on_minutes'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field ==='average_positive_on_minutes' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC', field:'average_positive_on_minutes'})
          : propsChangeSort({status:true,type:'ASC', field:'average_positive_on_minutes'})"
      ></i>
      <i
          :class="propsStateSort.field==='total_positive_weight'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='total_positive_weight' && propsStateSort.type ==='ASC'
           ? propsChangeSort({status:true,type:'DESC', field:'total_positive_weight'})
          : propsChangeSort({status:true,type:'ASC', field:'total_positive_weight'})"
      ></i>
    </div>
    <div>
      <i
          :class="propsStateSort.field==='average_negative_on_minutes'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='average_negative_on_minutes' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC', field:'average_negative_on_minutes'})
          : propsChangeSort({status:true,type:'ASC', field:'average_negative_on_minutes'})"
      ></i>
      <i
          :class="propsStateSort.field==='total_negative_weight'
          ? 'el-icon-d-caret active'
          :'el-icon-d-caret'"
          @click="propsStateSort.field==='total_negative_weight' && propsStateSort.type ==='ASC'
          ? propsChangeSort({status:true,type:'DESC', field:'total_negative_weight'})
          : propsChangeSort({status:true,type:'ASC', field:'total_negative_weight'})"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "sorted-block",
  props: {
    propsStateSort: {
      type: Object
    },
    propsChangeSort: {
      type: Function
    }
  },

}
</script>

<style lang="scss" scoped>
.sorted {
  display: grid;
  grid-template-columns: 230px 70px 140px 140px 70px 200px 130px 170px 170px;

  div {
    display: flex;
    gap: 20px;
    justify-content: center;

    i {
      cursor: pointer;
      font-size: 12px;
      color: #bebebe;

      &.active {
        color: #24387f;
      }
    }

    &:nth-child(3), &:nth-child(4), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
      margin-left: 10px;
    }

    &:nth-child(4) {
      margin-left: 10px;
    }
  }


}
</style>