<template>
  <el-row v-loading="loading">
    <el-row class="title" type="flex"  justify="space-between" align="middle">
      <el-row type="flex" align="middle">
        <h2>Список звонков за последний(е)</h2>
        <el-select v-model="selectedValue" @change="getCategoryCalls">
          <el-option
              v-for="item in callsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-row>
      <el-row
          v-if="calls && calls.length"
      >
        <button  @click="getFilteredCalls(10, 70)">test</button>
        Всего звонков: ({{calls.length}})
      </el-row>
    </el-row>
    <el-row>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item
            v-for="call in filteredCalls"
            :key="call.id"
            :name="call.id"
        >
          <template slot="title">
            <el-row>
              <el-col :span="4">ID пользователя</el-col>
              <el-col :span="4">Тел. номер</el-col>
              <el-col :span="4">Имя</el-col>
              <el-col :span="4">Продолжительность</el-col>
              <el-col :span="4">Создан</el-col>
              <el-col :span="4"></el-col>
            </el-row>
            <el-row>
              <el-col :span="4">{{call.call_id_user}}</el-col>
              <el-col :span="4">{{call.phone_number}}</el-col>
              <el-col :span="4">{{call.subject_name?call.subject_name:'не заполнено'}}</el-col>
              <el-col :span="4">{{call.call_duration / 60 }} мин</el-col>
              <el-col :span="4">{{call.created_at}}</el-col>
              <el-col :span="4"></el-col>
            </el-row>
          </template>

          <el-row>
            <el-row style="background: #000;">sdfdsfds</el-row>
            <el-row
                v-if="call.call_infos.length"
                class="call-info"
                type="flex"
                v-for="info in call.call_infos"
                :key="info.id"
            >

              <el-col>{{info.agent_name}}</el-col>
              <el-col>{{info.extension_number}}</el-col>
              <el-col>{{info.wait_time}}</el-col>
              <el-col>{{info.hold_time}}</el-col>
              <el-col>{{info.max_pause}}</el-col>
              <el-col>{{info.ring_time}}</el-col>
              <el-col>{{info.created_at}}</el-col>

            </el-row>
            <el-row v-else>
              <p>Подробная информация отсутствует</p>
            </el-row>

          </el-row>

        </el-collapse-item>
      </el-collapse>
      <paginator
          :paginator.sync="paginator"
          @page-changed="getCategoryCalls"
      ></paginator>
    </el-row>
  </el-row>
</template>

<script>
import {paginator} from "@/mixins/paginator";
import Paginator from "@/components/Paginator.vue";
export default {
  name: "category-calls",
  components: {Paginator},
  mixins: [paginator],
  props: {
    categoryId: {},
  },
  data() {
    return {
      loading: false,
      calls: [],
      selectedCallId: null,
      selectedCall:{},
      filteredCalls: [],
      callsOptions: [{
        value: 'День',
        label: 'День'
      }, {
        value: '15 дней',
        label: '15 дней',
      }, {
        value: 'Месяц',
        label: 'Месяц'
      }, {
        value: 'Год',
        label: 'Год'
      }],
      selectedValue: '',
      activeNames: []
    }
  },
  mounted() {
    this.selectedValue = this.callsOptions[0].value
    this.getCategoryCalls();
  },

  methods: {
    handleChange(val) {
      this.selectedCallId = val[val.length -1];
      this.getCategoryCall();
      console.log(val);
    },
    getCategoryCall() {
      this.$api.category.getCategoryCall({
        call_id: this.selectedCallId
      })
          .then(data => {
            this.selectedCall = data;
          })
    },
    getCategoryCalls() {
      this.$api.category.getCategoryCalls({
        category_id: this.categoryId,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
      })
          .then( ({data, paginator}) => {
            this.calls = data.calls;
            this.getFilteredCalls()
            this.paginatorFill(paginator);
          })
          .finally()
    },
    getFilteredCalls(index = 0, count = 10) {
      this.filteredCalls = this.calls;
      //this.filteredCalls = this.calls.slice(Math.random() * (index - count) + count, count)
    }
  }


}
</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";
  .title {
    margin-bottom: 20px;
    h2 {
      margin-right: 5px;
      margin-bottom: 0;
    }
  }
  .el-select {
    max-width: 100px;
    ::v-deep .el-input--suffix .el-input__inner {
      text-align: center;
    }
  }

  ::v-deep .el-collapse-item__header {
    display: block;
    line-height: normal;
    height: auto;
    position: relative;
    color: $--color-text-primary;
   .el-row {
      padding: 10px 0;
    }
    .el-collapse-item__arrow {
      position: absolute;
      z-index: 6;
      right: 0;
      top: 12px;
    }
  }
  .call-info {
    border: 1px solid $--color-primary-light-7;
    color: $--color-text-primary;
    padding: 5px 10px;
    flex-wrap: nowrap;
    margin-bottom: 5px;
    border-radius: 5px;
  }
</style>