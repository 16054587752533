import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getUniqueUsers(payload){
            return requestSender('get', '/organization/get-unique-employees', payload);
        },
        getEmployeeRating(payload){
            return requestSender('get', 'organization/get-employee-rating', payload);
        },
        getUniquePhoneNumbers(payload){
            return requestSender('get', 'organization/get-unique-phone-numbers', payload);
        },
        getPhoneNumberRating(payload){
            return requestSender('get', 'organization/get-phone-number-rating', payload);
        },
        getPendingCalls(payload){
            return requestSender('get', 'organization/get-pending-calls', payload);
        },

        getEmployeeInteraction(payload){
            return requestSender('get', 'analysis/get-employee-interaction', payload);
        },

    }
}