<template>
  <div>
    <el-progress  :percentage="percentage" :color="colors"></el-progress>


  </div>

</template>

<script>
export default {
  name: "MyProgress",
  props:{
    percentage:{
      type:Number,
      required:true,
      default:0
    }
  },
  data(){
    return{
      colors:[
        {color: '#f52525', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#5cb87a', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#6f7ad3', percentage: 100}
      ]
    }
  },
  methods:{
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .el-progress {
    display: grid;
    grid-template-columns: 5fr 1fr;
    align-items: center;
    &__text {
      display: inline-block;
    }
    &-bar {
      padding: 0;
    }
  }
</style>