<template>
  <div class="vats-config">
    <div class="wrapper">
      <div class="vats-on">
        <!--        <h4 :class="integrationState?'active':''">Интеграция {{integrationState?'включена':'выключена'}} </h4>-->
        <!--        <el-switch-->
        <!--            v-model="integrationState"-->
        <!--            active-color="#13ce66"-->
        <!--            inactive-color="#ff4949">-->
        <!--        </el-switch>-->

        <h4 :class="!integrationState?'active':''">Интеграция</h4>
      </div>
      <div class="hr"></div>
      <form @submit="submitChange" class="form">
        <div>
          <label>
            <p>ID клиента</p>
            <el-input required size="small" placeholder="" v-model="idClient"></el-input>
          </label>
        </div>
        <div>
          <label>
            <p>Первичный код для интеграции</p>
            <el-input required size="small" placeholder="" v-model="token"></el-input>
          </label>
        </div>
        <div>
          <label>
            <p>Интервал забора звонков в минутах</p>
            <el-select required size="small" v-model="periodState" placeholder="">
              <el-option
                  v-for="item in periodOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </label>
        </div>
        <div>
          <label>
            <p>Минимальная длительность звонка в секундах</p>
            <el-input-number
                required
                size="small"
                v-model="minLengthCall"
                @change="handleChange"
                :min="0"
                :max="10000"
                :controls="false"
            ></el-input-number>
          </label>
        </div>
        <div class="btn-block">
          <button @click="resetInputsValue" type="button">Сбросить</button>
          <button type="submit">Сохранить</button>
        </div>
      </form>
    </div>

  </div>

</template>

<script>
export default {
  name: "vats-config",
  props:{
    propsVatsConfig:{}
  },
  data() {
    return {
      integrationState: false,
      periodState: 30,
      idClient: '',
      token: '',
      minLengthCall: 0,
      periodOptions: [{
        value: '30',
        label: '30'
      }, {
        value: '60',
        label: '60'
      }, {
        value: '90',
        label: '90'
      }, {
        value: '180',
        label: '180'
      }],
    };
  },
  mounted() {
    this.setDefaultValueInputs()
  },

  methods: {
    setDefaultValueInputs(){
      console.log(this.propsVatsConfig)
        if (!this.propsVatsConfig){
          return null
        }
        this.idClient = this.propsVatsConfig.company_id
        this.token = this.propsVatsConfig.token
        this.minLengthCall = this.propsVatsConfig.min_call_length
        this.periodState = this.propsVatsConfig.time_period


    },
    handleChange(value) {
      console.log(value)
      if (!value || value === 'undefined') {
        this.minLengthCall = 0
      }
    },
    submitChange(e) {
      console.log(e)
      e.preventDefault()
    },
    resetInputsValue() {
      this.periodState = 30
      this.idClient = ''
      this.token = ''
      this.minLengthCall = 0
    }
  }

}

</script>

<style scoped lang="scss">
.vats-config {
  min-height: 66vh;
  display: flex;
  justify-content: flex-start;

  .wrapper {
    padding: 15px;
    border-radius: 5px;
    max-width: 500px;

    width: 100%;
    border: solid 1px #E4E7ED;

    .vats-on {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        margin-bottom: 0;
        color: #bebebe;
        transition: color .3s;

        &.active {
          color: #000;
        }
      }
    }

    .hr {
      margin: 5px 0;
      width: 100%;
      height: 1px;
      background: #E4E7ED;
    }

    .form {
      margin-top: 10px;

      div {
        margin-bottom: 3px;
      }

      label {
        &:first-child {
          margin-top: 0;
        }

        p {
          margin-bottom: 0;
          font-size: 12px;
          color: #555;
        }

        &::v-deep {
          .el-input, .el-select, .el-input-number {
            width: 100% !important;
          }

          input, select {
            width: 100% !important;
            border-radius: 5px;
            text-align: start;
          }
        }
      }

      .btn-block {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        gap: 20px;

        button {
          cursor: pointer;
          background: unset;
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          font-weight: bold;

          &:first-child {
            background: #dc0000;
          }

          &:last-child {
            background: #24387f;
          }
        }
      }
    }
  }
}
</style>