<template>
  <div >
    <el-input placeholder="Please input" v-model="input3" class="input-with-select">
      <el-select v-model="select" slot="prepend" placeholder="Select">
        <el-option label="По названию" value="1"></el-option>
        <el-option label="Order No." value="2"></el-option>
        <el-option label="Tel" value="3"></el-option>
      </el-select>
      <el-button slot="append" icon="el-icon-search"></el-button>
    </el-input>
  </div>
</template>

<script>
export default {
  name: "my-search-input",
  data(){
    return{
      input3: '',
      select: ''
    }
  }
}
</script>

<style scoped>
.el-select .el-input {
  width: 110px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>