<template>
  <div>
<!--    <div class="edit-organization">-->
<!--      <div class="collapse-title-container">-->
<!--        <div class="collapse-title">-->
<!--          <p>Организация</p>-->
<!--          <p>ID</p>-->
<!--          <p>Тарифный план</p>-->
<!--          <p>Остаток минут</p>-->
<!--          <p>Статус</p>-->
<!--        </div>-->
<!--        <div class="collapse-content">-->
<!--          <p>{{ organization.name }} <i class="el-icon-edit" @click="formDrawer = true" @click.stop.stopPropagation></i></p>-->
<!--          <p>{{organization.id}}</p>-->
<!--          <p>{{organization.tp.name}}</p>-->
<!--          <p v-if="organization.remaining_minutes.length">{{organization.remaining_minutes[0].minutes }}</p>-->
<!--          <p v-else>-</p>-->
<!--          <p>{{organization.status.name }}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="calls-info" v-if="organization.call_info">-->
<!--        <div class="calls-info__title">-->
<!--          <p>Всего</p>-->
<!--          <p>Входящих</p>-->
<!--          <p>Исходящих</p>-->
<!--          <p>Распознано</p>-->
<!--          <p>Распознано входящих</p>-->
<!--          <p>Распознано исходящих</p>-->
<!--        </div>-->
<!--        <div class="calls-info__content">-->
<!--          <p>{{ organization.call_info.count_calls }}</p>-->
<!--          <p>{{ organization.call_info.count_in_calls }}</p>-->
<!--          <p>{{ organization.call_info.count_out_calls }}</p>-->
<!--          <p>{{ organization.call_info.count_recognized_calls }}</p>-->
<!--          <p>{{ organization.call_info.count_recognized_in_calls }}</p>-->
<!--          <p>{{ organization.call_info.count_recognized_out_calls }}</p>-->
<!--        </div>-->

<!--      </div>-->
<!--      <div class="users">-->
<!--        <div class="users__header">-->
<!--          <h2>Пользователи</h2>-->
<!--          <div-->
<!--              class="new-user"-->
<!--              @click="addNewUser(organization.id)"-->
<!--          >-->
<!--            <span><i class="el-icon-plus"></i>Добавить</span>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="users__container">-->
<!--          <div-->
<!--              v-if="prepareLocations"-->
<!--              v-for="user in organization.users"-->
<!--              :key="organization.id + '-' + user.id"-->
<!--          >-->
<!--            <user-edit-->
<!--                :user="user"-->
<!--                :roles="roles"-->
<!--                :organization-id="organization.id"-->
<!--                :prepare-locations="prepareLocations"-->
<!--                @update="getOrganization"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


<!--    <el-row>-->
<!--      <h2>Организация: <span style="font-weight: bold;">"{{organization.name}}"</span></h2>-->
<!--      <el-form-->
<!--          label-position="'right'"-->
<!--          label-width="180px"-->
<!--          :model="organization">-->
<!--        <el-form-item label="Название">-->
<!--          <el-input v-model="organization.name"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="E-mail">-->
<!--          <el-input v-model="organization.email"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="Регистрационный код">-->
<!--          <el-input v-model="organization.org_registration_key"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="Тарифный план">-->
<!--          <el-select v-model="organization.tariff_plan_id">-->
<!--            <el-option-->
<!--                v-for="plan in tariffPlans"-->
<!--                :key="plan.id"-->
<!--                :label="plan.name"-->
<!--                :value="plan.id"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="Статус активности">-->
<!--          <el-select v-model="organization.status_id">-->
<!--            <el-option-->
<!--                v-for="status in statuses"-->
<!--                :key="status.id"-->
<!--                :label="status.name"-->
<!--                :value="status.id"-->
<!--            >-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item style="text-align: right" wrap="wrap">-->
<!--          <el-button type="primary" @click="save">Изменить</el-button>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-row>-->
  </div>



</template>

<script>
import UserEdit from "@/components/management/UserEdit.vue";

export default {
  name: "organization-edit",
  components: {UserEdit},
  props: {
    organization: {},
    tariffPlans:{},
    statuses: {}
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    save() {
      this.$api.management.save(this.organization)
          .then(data => {
            if(!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }else{
              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors,
              });
            }
          })
          .finally(() => {
            this.formDrawer = false;
            this.loading = true;
          })
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit-organization {
    .collapse-title-container {
      .collapse {
        &-title, &-content {
          display: grid;
          grid-template-columns: 3fr 1fr 2fr 2fr 2fr 2fr;
          > p {
            text-align: center;
            &:first-child, &:last-child {
              text-align: left;
            }
          }
        }
      }
      p {
        margin-bottom: 0;
        line-height: 1;
      }
      .collapse {
        &-title {
          margin-top: 5px;
          p {
            font-size: 10px;
          }
        }
        &-content {
          margin: 5px 0 5px 0;
          p {
            color: #555555;
            font-size: 14px;
            line-height: 1.6;
          }
        }
      }


    }
    .calls-info {
      margin: 10px 20px;
      background: #fff;
      padding: 5px;
      border-radius: 4px;
      border: 1px solid #eee;
      &__title, &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        p {
          color: #555555;
          margin-bottom: 0;
          text-align: center;
        }
      }
      &__title {
        p {
          margin-bottom: 10px;
          font-size: 10px;
        }

      }
      &__content {
        p {
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .users {
      &__header {
        display: flex;
        justify-content: space-between;
        padding: 5px 20px 0 20px;
        h2 {
          margin-top: 10px;
          font-weight: bold;
          margin-left: 20px;
        }
        .new-user {
          font-size: 10px;
          border: 1px solid #555555;
          color: #555555;
          border-radius: 4px;
          padding: 2px 10px;
          cursor: pointer;
        }
      }
      h2 {
        margin: 0;
        font-size: 12px;
        color: #555555;
      }

      &__container {
        margin: 0 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .user {
          background: #fff;
          margin: 10px;
          padding: 10px;
          border-radius: 4px;
        }
      }
    }
  }

</style>