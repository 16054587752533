<template>
  <form
      @change="formChange"
  >
    <ul class="list">
      <li class="item" :key="item.value" v-for="item in formData">
        <label>
          <p>{{item.label}}</p>
          <div>
            <input @change="(e)=>{changeInput(e,item)}" :checked="item.checked" type="checkbox">
          </div>
        </label>
      </li>
    </ul>
  </form>
</template>

<script>
export default {
  name: 'dictionary-group-list',
  props:{
    propsDictList:{
      type: Array
    },
    checked: {
      Type: Array
    },
  },
  mounted() {
    this.propsDictList.forEach(item => {
      this.checked.includes(item.value) ? item.checked = true : item.checked = false;
    })
    // console.log(this.propsDictList)
    console.log(this.checked)
  },
  data(){
    return{
      formData:this.propsDictList
    }
  },
  methods:{
    formChange(e, item){
      this.$emit('update-dictionaries', this.formData)
    },
    changeInput(e,id){
      id.checked = e.target.checked
      console.log(id)
    }
  },
}
</script>

<style lang="scss" scoped>
.list{
  max-height: 300px;
  overflow: auto;
  display: grid;
  gap: 1px;
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  padding: 10px;
  .item{
    cursor: pointer !important;
    &:hover{
      label{
        p{
          color: #24387f;
          text-decoration: underline;

        }
      }
    }
    label{
      display: flex;
      align-items: center;
      gap: 3px;
      p{
        margin-bottom: 3px;
        font-size: 12px;
      }
      div{
        order: -1;
        display: inline-block;
        input{
          z-index: -21 ;
        }
      }

    }
  }
}

</style>