import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        get(payload){
            return requestSender('get', 'dictionary/', payload);
        },
        getIsDashBoard(payload){
            return requestSender('get', 'dictionary/get-dashboard-dictionaries/', payload);
        },
        save(payload){
            return requestSender('post', 'dictionary/save', payload);
        },
        delete(payload){
            return requestSender('post', 'dictionary/delete', payload);
        },


        getCategoryDictionaries(payload){
            return requestSender('get', 'dictionary/get-category-dictionaries', payload);
        },

        getDictionariesGroup(payload){
            return requestSender('get', 'dictionary/get-dictionaries-group', payload);
        },
        getDictionariesGroups(payload){
            return requestSender('get', 'dictionary/get-dictionaries-groups', payload);
        },
        getDictionaryInformationByPeriods(payload){
            return requestSender('get', 'dictionary/get-dictionary-information-by-periods', payload);
        },

        saveDictionariesGroup(payload){
            return requestSender('post', 'dictionary/save-dictionaries-group', payload);
        },

        deleteDictionariesGroup(payload){
            return requestSender('post', 'dictionary/delete-dictionaries-group', payload);
        },
        deleteDictionaryFromGroup(payload){
            return requestSender('post', 'dictionary/delete-dictionary-from-group', payload);
        },

    }
}