<template>
  <div class="container">
    <div class="title">
      <h4>Анализ взаимодействия сотрудника </h4>
    </div>
    <div class="content" v-loading="loading">
      <div class="controls">
        <custom-cascader-category-back
            :multipart="false"
            :propsGetSelectedCategoryAndEmployee="getSelectedCategoryAndEmployee"
        />
        <custom-date-range-picker-for-employee-interaction-back
            :propsGetSelectedDate="getSelectedDate"
        />
        <custom-select-checklist-back
            :class="!dataToSend['category'] || dataToSend['date'].length !==2 ?'disabled':''"
            :props-category-and-date="dataToSend"
            :props-show-spinners="showSpinners"
            @update-checklist="updateChecklistDictionaries"
        />
      </div>
      <div class="separator"></div>

      <div class="info">
        <div class="calls">
          <div v-if="!loadingSpinner && checklist" class="legend">
            <div>id звонка</div>
            <div class="date-call">дата звонка</div>
            <div class="pointer">% выполнения / баллы</div>
            <div
                v-for="(dictionary, index) in checklist.dictionaries.client"
                :key="dictionary.id"
                class="dictName pointer"
            >
              <el-popover
                  placement="top-start"
                  trigger="hover"
                  :content="dictionary.name">
                <div slot="reference">
                  блок  (К-{{ index + 1 }})
                </div>
              </el-popover>
            </div>
            <div
                v-for="(dictionary, index) in checklist.dictionaries.system"
                :key="dictionary.id"
                class="dictName pointer"
            >
              <el-popover
                  placement="top-start"
                  trigger="hover"
                  :content="dictionary.name">
                <div slot="reference">
                  блок (С-{{ index + 1 }})
                </div>
              </el-popover>
            </div>
          </div>
          <MySpinner v-if="loadingSpinner" />
          <ul class="callList" v-if="!loadingSpinner && calls">

            <li v-for="(call, index) in calls">
              <div @click="showDrawer(call.call_info_id)">
                <div>{{ call.call_info_id }}</div>
                <div class="date-call">{{ convertDate(call.date_call).date }}</div>
<!--                <div v-html="call.response_rate['text']"></div>-->
                <div>{{call.checklist_result.percent + '% / ' + call.checklist_result.score}}</div>
                <div
                    v-for="(dictionary, index) in checklist.dictionaries.client"
                >
<!--                  <span :style="{color:+item.worked.split('/')[0]?'green':'red'}">  ({{ item.worked }})</span>-->
                  <span>  ({{  dictionary.dictionary.worked_call_infos.includes(call.call_info_id) ? renderWorked(dictionary) : renderUnworked(dictionary) }})</span>

                </div>
                <div
                    v-for="(dictionary, index) in checklist.dictionaries.system"
                >
<!--                  <span :style="{color:+item.worked.split('/')[0]?'green':'red'}">  ({{ item.worked }})</span>-->
                  <span>  ({{ dictionary.dictionary.worked_call_infos.includes(call.call_info_id) ? renderWorked(dictionary) : renderUnworked(dictionary) }})</span>
                </div>
              </div>

            </li>
          </ul>
          <MySpinner v-if="loadingSpinner"/>
        </div>
        <div class="checklist"
             style="min-height: 350px;"
             v-if="!loadingSpinner && calls && checklist"
             :style="{maxHeight:getMaxHeight}">
          <!--          <h5>Название чек-листа</h5>-->
          <h5>{{ checklistName }}</h5>

          <div class="separator"></div>
          <ul>
            <li class="checklistLegend">
              <p>Название словаря</p>
              <p>Вес/п</p>
              <p>Вес/н</p>
              <p>Реверс</p>
            </li>
            <li
                v-for="(item, index) in checklist.dictionaries.client"
                :key="item.id"
            >
              <el-popover
                  v-if="item.name.length>30"
                  placement="top-start"
                  trigger="hover"
                  :content="item.name">
                <p slot="reference" class="checklistName">

                  {{ index + 1 }} .
                  {{ ' ' + item.name.length > 30 ? ' ' + item.name.slice(0, 40) + '...' : ' ' + item.name }}
                </p>
              </el-popover>
              <p
                  v-if="item.name.length <= 30"
                  slot="reference"
                  class="checklistName"
              >
                {{ index + 1 }}.
                {{ ' ' + item.name.length > 30 ? ' ' + item.name.slice(0, 40) + '...' : ' ' + item.name }}
              </p>
              <p class="work_count">{{ item.positive_weight ? item.positive_weight : null }}</p>
              <p class="not_word_count">{{ item.negative_weight ? item.negative_weight : null }}</p>
              <p class="not_word_count"><i v-if="item.dictionary.reverse" class="el-icon-sort reverse-icon"></i></p>

            </li>
            <li
                v-for="(item, index) in checklist.dictionaries.system"
                :key="item.id"
            >
              <el-popover
                  v-if="item.name.length>30"
                  placement="top-start"
                  trigger="hover"
                  :content="item.name">
                <p slot="reference" class="checklistName">
                  {{ index + 1 }} .
                  {{ ' ' + item.name.length > 30 ? ' ' + item.name.slice(0, 40) + '...' : ' ' + item.name }}
                </p>
              </el-popover>
              <p
                  v-if="item.name.length <= 30"
                  slot="reference"
                  class="checklistName"
              >
                {{ index + 1 }}.
                {{ ' ' + item.name.length > 30 ? ' ' + item.name.slice(0, 40) + '...' : ' ' + item.name }}
              </p>
              <p class="work_count">{{ item.positive_weight ? item.positive_weight : null }}</p>
              <p class="not_word_count">{{ item.negative_weight ? item.negative_weight : null }}</p>
              <p class="not_word_count"><i v-if="item.dictionary.reverse" class="el-icon-sort reverse-icon"></i></p>
            </li>
          </ul>
          <p style="margin-left: 10px; font-size: 10px; color: #555; font-weight: 500">Сработал, если набрано более
            {{ checklist ? checklist.limit_result : null }} баллов
          </p>
        </div>
      </div>
    </div>

    <el-drawer
        :style="{display:stateDrawer?'flex':'none'}"
        :visible.sync="stateDrawer"
        :with-header="false"
        size="95%"
    >
      <drawer-call-info
          v-if="selectedCallId"
          :categoryName="categoryName"
          :stateDrawer="stateDrawer"
          :callId="selectedCallId"
          :dateCall="selectedDateCallId"
          :clientNumber="clientNumber"
          :clientName="clientName"
          :period="propsStartEndDateForCallList"
      />
    </el-drawer>
  </div>
</template>

<script>


import CustomSelectChecklist
  from "@/components/analytics/employeeInteraction/customSelectChecklist.vue";
import CustomCascaderCategory
  from "@/components/analytics/employeeInteraction/customCascaderCategory.vue";
import CustomDateRangePickerForEmployeeInteraction
  from "@/components/analytics/employeeInteraction/customDateRangePickerForEmployeeInteraction.vue";
import DrawerCallInfo from "@/components/newCatigories/drawerCallInfo.vue";
import MySpinner from "@/components/UI/MySpinner.vue";
import login from "@/views/auth/Login.vue";
import CustomCascaderCategoryBack from "@/components/analytics/employeeInteractionBack/customCascaderCategoryBack.vue";
import CustomDateRangePickerForEmployeeInteractionBack
  from "@/components/analytics/employeeInteractionBack/customDateRangePickerForEmployeeInteractionBack.vue";
import CustomSelectChecklistBack from "@/components/analytics/employeeInteractionBack/customSelectChecklistBack.vue";

export default {
  name: 'employee-interaction-back',
  components: {
    CustomSelectChecklistBack,
    CustomDateRangePickerForEmployeeInteractionBack,
    CustomCascaderCategoryBack,
    MySpinner,
    DrawerCallInfo,
    CustomSelectChecklist,
    CustomCascaderCategory,
    CustomDateRangePickerForEmployeeInteraction
  },
  data() {
    return {
      backData: null,
      chartOptions: {
        chart: {
          zoom: {
            enabled: false
          }
        },
        xaxis: {
          categories: []
        }
      },
      series: [
        {
          name: 'Средний процент срабатывания за день',
          data: []
        }
      ],


      loading: false,
      loadingSpinner: false,
      calls: [],
      checklist: null,
      checklistName: '',
      checklistDictionaries: {
        client: [],
        system: []
      },
      dataToSend: {
        date: [],
        category: null,
        employee: null,
        org_id: null,
        checklist: null
      },
      categoryName: null,
      propsStartEndDateForCallList: {start: null, end: null},
      selectedCallId: null,
      selectedDateCallId: null,
      clientNumber: null,
      clientName: null,
      stateDrawer: false,
      sorted_type: null,
    };
  },
  methods: {
    convertDate(data) {
      if(!data) {
        return {
          date: null,
          time: null
        };
      }
      return {
        date: new Date(data).toLocaleDateString('ru-Ru',
            {
              'month': "2-digit",
              'day': "2-digit",
              'year': 'numeric'
            }),
        time: new Date(data).toLocaleTimeString('ru-Ru',
            {
              'hour': "numeric",
              'minute': "numeric",
              'second': 'numeric'
            }),
      }
    },
    renderWorked(dictionary){
      return dictionary.positive_weight
          ? dictionary.positive_weight + '/' + dictionary.positive_weight
          : dictionary.negative_weight + '/' + dictionary.negative_weight
    },
    renderUnworked(dictionary){
      return dictionary.positive_weight
          ? 0 + '/' + dictionary.positive_weight
          : 0 + '/' + dictionary.negative_weight
    },




    showSpinners() {
      this.loadingSpinner = true
    },
    getSelectedDate(value) {
      this.dataToSend['date'] = value
    },
    getSelectedCategoryAndEmployee(value) {
      this.dataToSend['category'] = value['category']
      this.dataToSend['employee'] = value['employee']
      this.categoryName = value['categoryName']
    },
    updateChecklistDictionaries(data) {
      if (data) {
        this.checklist = data.checklist
        this.checklistName = data.checklist.name
        this.calls = data.calls
      }

      this.loadingSpinner = false
    },


    getWorkedDictionaries(call, dictionary, dictionaryType, tempData2) {
      let rs = call.result_search.find(item => item.dictionary_id === dictionary.dictionary.id && item.dictionary_type === dictionaryType)
      let worked = (rs && this.foundInChannel(rs, dictionary.dictionary)) ||
          (!rs && dictionary.dictionary.reverse || this.notFoundInChannelForReverse(rs, dictionary.dictionary))
      if (worked) {
        let index = this.calls.findIndex(item => item.id === call.id)
        call.worked_result += dictionary.positive_weight ? +dictionary.positive_weight : +dictionary.negative_weight
      }
    },

    getWorkedCurrentDictionaries(call, dictionary, dictionaryType, index) {
      let rs = call.result_search.find(item => item.dictionary_id === dictionary.dictionary.id && item.dictionary_type === dictionaryType)
      let worked = (rs && this.foundInChannel(rs, dictionary.dictionary)) ||
          (!rs && dictionary.dictionary.reverse || this.notFoundInChannelForReverse(rs, dictionary.dictionary))
      if (worked) {
        return dictionary.positive_weight
            ? dictionary.positive_weight + '/' + dictionary.positive_weight
            : dictionary.negative_weight + '/' + dictionary.negative_weight
      } else {
        return 0 + '/' + dictionary.dictionary.positive_weight
      }

    },
    showDrawer(id) {
      this.selectedCallId = id
      this.stateDrawer = true
    },
    foundInChannel(rs, dictionary) {
      switch (true) {
        case dictionary.search_in_channel_1 === 1 && dictionary.search_in_channel_2 === 1 && rs.search_text_highlight_full && !dictionary.reverse:
          return true;
        case dictionary.search_in_channel_1 === 1 && dictionary.search_in_channel_2 === 0 && rs.search_text_highlight_one && !dictionary.reverse:
          return true;
        case dictionary.search_in_channel_1 === 0 && dictionary.search_in_channel_2 === 1 && rs.search_text_highlight_two && !dictionary.reverse:
          return true
      }
      return false;
    },
    notFoundInChannelForReverse(rs, dictionary) {

      if (rs) {
        switch (true) {
          case dictionary.search_in_channel_1 === 1 && dictionary.search_in_channel_2 === 1 && !rs.search_text_highlight_full:
            return true;
          case dictionary.search_in_channel_1 === 1 && dictionary.search_in_channel_2 === 0 && !rs.search_text_highlight_one:
            return true;
          case dictionary.search_in_channel_1 === 0 && dictionary.search_in_channel_2 === 1 && !rs.search_text_highlight_two:
            return true
        }
      }

      return false;
    },
    getChecklistStatus(call) {
      let statuses = [
        '<span style="color:#440000; font-weight:bold"><span>',
        '<span style="color:#990000; font-weight:bold"><span>',
        '<span style="color:#994D00; font-weight:bold"><span>',
        '<span style="color:#FF9933; font-weight:bold"><span>',
        '<span style="color:#7199ff; font-weight:bold"><span>',
        '<span style="color:#2256ff; font-weight:bold"><span>',
        '<span style="color:#12a612; font-weight:bold"><span>',
        '<span style="color:darkgray; font-weight:bold">Некорректный<span>',
      ]

      const result = call.worked_result;


      if (!this.checklist.limit_result || isNaN(result)) {
        return {text: statuses[7] + '/ 0% / 0', value: 0}
      }

      let percent = (result / this.checklist.limit_result * 100).toFixed(2)
      percent = percent > 100.00 ? '100.00' : percent

      switch (true) {
        case +percent < 5:
          return {text: statuses[0] + percent + '% / ' + result, value: percent}
        case +percent < 10:
          return {text: statuses[1] + percent + '% / ' + result, value: percent}
        case +percent < 30:
          return {text: statuses[2] + percent + '% / ' + result, value: percent}
        case +percent < 50:
          return {text: statuses[3] + percent + '% / ' + result, value: percent}
        case +percent < 70:
          return {text: statuses[4] + percent + '% / ' + result, value: percent}
        case +percent < 100:
          return {text: statuses[5] + percent + '% / ' + result, value: percent}
        default:
          return {text: statuses[6] + percent + '% / ' + result, value: percent}
      }
    },

    getNotReverseResult(call, index) {

      let result = 0;

      const client = this.checklistDictionaries.client.filter(item => !item.dictionary.reverse)
      const system = this.checklistDictionaries.system.filter(item => !item.dictionary.reverse)

      client.forEach(dict => {
        let found = call.result_search.find(rs => rs.dictionary_id === dict.dictionary_id && rs.dictionary_type === 'client')
        if (found && this.foundInChannel(found, dict.dictionary)) {
          result += dict.positive_weight ? dict.positive_weight : dict.negative_weight
        }
      })

      system.forEach(dict => {
        let found = call.result_search.find(rs => rs.dictionary_id === dict.dictionary_id && rs.dictionary_type === 'system')
        if (found && this.foundInChannel(found, dict.dictionary)) {
          result += dict.positive_weight ? dict.positive_weight : dict.negative_weight
        }
      })

      return result;
    },
    getReverseResult(call) {

      let result = 0;

      const clientReverse = this.checklistDictionaries.client.filter(item => item.dictionary.reverse)
      const systemReverse = this.checklistDictionaries.system.filter(item => item.dictionary.reverse)

      clientReverse.forEach(dict => {
        const found = call.result_search.find(rs => rs.dictionary_id === dict.dictionary.id && rs.dictionary_type === 'client')
        if (!found || this.notFoundInChannelForReverse(found, dict.dictionary)) {
          result += dict.positive_weight ? dict.positive_weight : dict.negative_weight
        }
      })

      systemReverse.forEach(dict => {
        const found = call.result_search.find(rs => rs.dictionary_id === dict.dictionary.id && rs.dictionary_type === 'system')
        if (!found || this.notFoundInChannelForReverse(found, dict.dictionary)) {
          result += dict.positive_weight ? dict.positive_weight : dict.negative_weight
        }
      })

      return result;
    },
    getUniqueResultSearch(call) {

      const allDict = [...call.checklist.checklist.dictionaries.system, ...call.checklist.checklist.dictionaries.client]
      const needChecklistDictionaries = allDict.map(dict => dict.dictionary_id + dict.dictionary_type)

      let object = {}

      call.result_search.forEach(rs => {
        object[rs.dictionary_id + rs.dictionary_type] = rs
      })
      let data = []

      Object.keys(object).forEach((key) => {
        if (needChecklistDictionaries.includes(key)) {
          data.push([object[key]][0])
        }
      })

      return data;
    },
    sortCallList(dict = null, index = null) {
      if (!dict) {
        if (this.sorted_type === 'total-desc') {
          this.sorted_type = 'total-asc'
        } else {
          this.sorted_type = 'total-desc'
        }
      } else {
        if (this.sorted_type === dict.dictionary_type + '_' + index + '_desc') {
          this.sorted_type = dict.dictionary_type + '_' + index + '_asc'
        } else {
          this.sorted_type = dict.dictionary_type + '_' + index + '_desc'
        }
      }
    }
  },


  computed: {
    getMaxHeight() {
      return [...this.checklistDictionaries.client, ...this.checklistDictionaries.system].length * 30 + 72 <= 610
          ? [...this.checklistDictionaries.client, ...this.checklistDictionaries.system].length * 30 + 78 + 'px'
          : '610px'
    },
    sorted_calls() {
      if (this.sorted_type) {
        switch (true) {
          case this.sorted_type === 'total-desc':
            return this.calls.sort((a, b) => a.response_rate['value'] - b.response_rate['value'])
          case this.sorted_type === 'total-asc':
            return this.calls.sort((a, b) => b.response_rate['value'] - a.response_rate['value'])
          case this.sorted_type.split('_')[0] === 'client' && this.sorted_type.split('_')[2] === 'desc':
            return this.calls.sort((a, b) =>
                +a.checklist.checklist.dictionaries[this.sorted_type.split('_')[0]][this.sorted_type.split('_')[1]].worked.split('/')[0] -
                +b.checklist.checklist.dictionaries[this.sorted_type.split('_')[0]][this.sorted_type.split('_')[1]].worked.split('/')[0]
            )
          case this.sorted_type.split('_')[0] === 'client' && this.sorted_type.split('_')[2] === 'asc':
            return this.calls.sort((a, b) =>
                +b.checklist.checklist.dictionaries[this.sorted_type.split('_')[0]][this.sorted_type.split('_')[1]].worked.split('/')[0] -
                +a.checklist.checklist.dictionaries[this.sorted_type.split('_')[0]][this.sorted_type.split('_')[1]].worked.split('/')[0]
            )
          case this.sorted_type.split('_')[0] === 'system' && this.sorted_type.split('_')[2] === 'desc':
            return this.calls.sort((a, b) =>
                +a.checklist.checklist.dictionaries[this.sorted_type.split('_')[0]][this.sorted_type.split('_')[1]].worked.split('/')[0] -
                +b.checklist.checklist.dictionaries[this.sorted_type.split('_')[0]][this.sorted_type.split('_')[1]].worked.split('/')[0]
            )
          case this.sorted_type.split('_')[0] === 'system' && this.sorted_type.split('_')[2] === 'asc':
            return this.calls.sort((a, b) =>
                +b.checklist.checklist.dictionaries[this.sorted_type.split('_')[0]][this.sorted_type.split('_')[1]].worked.split('/')[0] -
                +a.checklist.checklist.dictionaries[this.sorted_type.split('_')[0]][this.sorted_type.split('_')[1]].worked.split('/')[0]
            )
          default:
            return this.calls
        }
      } else {
        return this.calls
      }
    }
  },
  watch: {
    dataToSend() {

    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 15px;

  .content {

    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    background: #fff;
    min-height: 75vh;

    .title {
      margin-bottom: 30px;

      h4 {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 700;
        color: #555;

        span {
          font-weight: normal;
          font-size: 14px;
        }
      }
    }

    .controls {
      width: 100%;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      gap: 40px;
    }

    .disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .info {
      max-height: 650px;
      overflow-y: auto;

      display: grid;
      grid-template-columns: 3fr 1fr;
      margin: 10px 0;
      gap: 20px;


    }

    i.el-icon-sort {
      transform: rotate(90deg);
      color: red;
    }

    .checklist {
      min-width: 400px;
      margin-right: 10px;
      border-radius: 5px;
      border: solid 1px #8080802e;
      box-shadow: 2px 2px 7px 0 #8080802e;
      padding: 10px;
      position: sticky;
      overflow: auto;
      top: 0;


      h5 {
        margin: 0;
        color: #555;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
      }

      ul {
        margin: 10px 0;

        li {

          display: grid;
          grid-template-columns: 7fr 1fr 1fr 1fr;
          margin-bottom: 5px;
          gap: 1px;
          border-radius: 5px;

          &:last-child {
            margin-bottom: 0 !important;
          }

          &:nth-child(even) {
            background: #eef3ff;
          }

          p {
            line-height: 1;
            padding: 5px 10px;
            margin: 0;

            &.checklistName {
              color: #555;
              cursor: pointer;
              font-size: 10px;
              padding-right: 0;
            }

            &.work_count {
              color: green;
              font-size: 10px;

            }

            &.not_word_count {
              color: red;
              font-size: 10px;
            }

            &.work_count,
            &.not_word_count {
              justify-self: center;
              justify-content: center;
              align-items: center;
              display: flex;
            }

          }

        }

        .checklistLegend {
          padding: 0 !important;

          p {
            padding: 0 !important;
            font-size: 9px !important;

            text-align: center;

            &:first-child {
              border: none;
              text-align: start;
              padding: 0 10px !important;
            }
          }
        }
      }

    }

    .calls {
      position: relative;
      overflow: auto;


      .legend {
        border-radius: 5px;
        background: #24387f;
        padding: 5px;
        position: sticky;
        top: 0;
        width: max-content;
        color: #fff;


        //.background{
        //  position: absolute;
        //  width: 100%;
        //  top: 0;
        //  right: 0;
        //  left: 0;
        //  bottom: 0;
        //  background: red;
        //  height: 100%;
        //}
      }

      .callList {
        width: 100%;
        max-height: 568px;

      }

      .callList li > div {
        cursor: pointer;
        transition: background-color .2s linear;

        &:hover {
          background: #ececec;
        }

        padding: 5px 0;

        > div {
          &:nth-child(3) {
            min-width: 180px;
          }
        }
      }

      .legend > div {
        &:nth-child(3) {
          min-width: 180px;
        }
      }

      .callList li > div, .legend {
        display: flex;


        div {
          min-width: 65px;
          font-size: 9px;
          text-align: center;
        }

        .date-call {
          min-width: 100px;
        }
      }
    }


  }
}

.pointer {
  cursor: pointer;
}

.separator {
  margin: 5px 0;
  height: 1px;
  background: #b5b5b5;
  width: 100%;
}

</style>
