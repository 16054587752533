<template>
  <el-row type="flex" justify="center">
    <el-col
        class="dictionary-form-container"
        :span="22"
    >
      <el-row>
        <el-row>
          <h4>{{ title }}</h4>
        </el-row>
        <el-form>
          <el-form-item label="Название">
            <div v-if="$store.getters.user.role.name !=='admin' && selectedDictionary.type === 'system'">
              <div>{{ selectedDictionary.name }}</div>
            </div>
            <div v-else>
              <el-input
                  type="text"
                  placeholder="Введите название"
                  v-model="selectedDictionary.name"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="Описание">
            <div v-if="$store.getters.user.role.name !=='admin' && selectedDictionary.type === 'system'">
              <div>{{ selectedDictionary.description }}</div>
            </div>
            <div v-else>
              <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="Введите описание"
                  v-model="selectedDictionary.description">
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="Запрос">
            <div
                v-if="$store.getters.user.role.name !=='admin' && selectedDictionary.type === 'system' || selectedDictionary.from_system_dictionary">
              <div>Системные данные скрыты</div>
            </div>
            <div v-else>
              <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="Введите описание"
                  v-model="selectedDictionary.query">
              </el-input>
            </div>


          </el-form-item>
          <el-form-item label="Положительный вес"
          >
            <el-input
                type="number"
                min="0"
                max="100"
                v-model="selectedDictionary.positive_weight"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Отрицательный вес"
          >
            <el-input
                type="number"
                min="0"
                max="100"
                v-model="selectedDictionary.negative_weight"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Реверс">
            <el-switch
                v-model="selectedDictionary.reverse"
                :active-value="1"
                :inactive-value="0"
            />
          </el-form-item>
          <el-form-item label="Поиск в канале 1">
            <el-switch
                v-model="selectedDictionary.search_in_channel_1"
                :active-value="1"
                :inactive-value="0"
            />
          </el-form-item>
          <el-form-item label="Поиск в канале 2">
            <el-switch
                v-model="selectedDictionary.search_in_channel_2"
                :active-value="1"
                :inactive-value="0"
            />
          </el-form-item>
          <el-form-item
              v-if="$store.getters.user.role.name === 'admin'"
              label="Системный"
          >
            <el-switch
                v-model="dictionaryType"
                :disabled="selectedDictionary.type === 'system'"
                :active-value="'system'"
                :inactive-value="'client'"
            />
          </el-form-item>

          <el-form-item label="Искать во входящих:">
            <el-switch
                v-model="selectedDictionary.search_in_call"
                :active-value="1"
                :inactive-value="0"
            />
          </el-form-item>
          <el-form-item label="Искать в исходящих:">
            <el-switch
                v-model="selectedDictionary.search_out_call"
                :active-value="1"
                :inactive-value="0"
            />
          </el-form-item>

          <el-form-item label="Цвет">
            <el-color-picker v-model="selectedDictionary.color_success"/>
          </el-form-item>
          <el-form-item label="Цвет 'не найдено'">
            <el-color-picker v-model="selectedDictionary.color_reject"/>
          </el-form-item>
          <el-form-item label="Отображать на главной странице">
            <el-switch
                v-model="selectedDictionary.in_dashboard"
                :active-value="1"
                :inactive-value="0"
            />
          </el-form-item>
          <el-form-item class="buttons">
            <el-button plain round @click="cancel">Отмена</el-button>
            <el-button type="primary" :disabled="loading?true:false" round @click="save">
              {{ loading ? 'Работаю...' : 'Сохранить' }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-row>

      <el-row class="command-buttons">

      </el-row>
    </el-col>


  </el-row>
</template>

<script>
export default {
  name: "dictionary-form",
  props: {
    selectedDictionary: {},
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dictionaryType: 'client'
    }
  },
  mounted() {

    // if(!Object.keys(this.selectedDictionary).length === 0) {
    //   this.selectedDictionary.reverse = 0;
    //   this.selectedDictionary.type = 'client'
    // }
  },
  computed: {
    title() {
      this.dictionaryType = this.selectedDictionary.type
      return this.selectedDictionary.id
          ? 'Редактирование словаря ' + "\"" + this.selectedDictionary.name + "\""
          : 'Добавление словаря'
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.selectedDictionary.change_to_type = this.dictionaryType;
      this.$emit('save')
    }
  }
}
</script>

<style lang="scss" scoped>

.dictionary-form-container {
  h4 {
    text-align: center;
    margin-bottom: 10px;
  }

  ::v-deep .el-form {
    background: #fff;
    padding: 20px;
    border-radius: 4px;

    &-item {
      margin-bottom: 20px;

      &__label {
        font-weight: bold;
        line-height: 1;
      }

      &__content {
        line-height: 1;

        .el-switch {
          margin-top: -4px;
        }

        .el-color-picker {
          margin-top: -15px;
        }
      }
    }
  }

  .buttons {
    text-align: right;
    margin-bottom: 25px;
  }
}

</style>