<template>
  <div class="login">
    <el-card style="padding: 24px">
      <h2>Установите новый пароль</h2>
      <el-form
        ref="passForm"
        :model="model"
        :rules="rules"
        @submit.native.prevent="setNewPassword"
      >
        <el-form-item label="Новый пароль" prop="newPassword">
          <el-tooltip placement="bottom-start" popper-class="password-info">
            <div slot="content">
              <p>
                Пароль должен содержать не менее 8 <br>
                символов и включать
              </p>
              <ul>
                <li>Строчные латинские буквы: abcd...xyz;</li>
                <li>Прописные латинские буквы: ABCD...XYZ;</li>
                <li>Цифры: 123...90;</li>
                <li>Специальные символы: !%()_+ и т.д.</li>
              </ul>
            </div>
            <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z" stroke="#767a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.1201 11.9998C12.4336 11.1087 13.0523 10.3573 13.8667 9.87863C14.6811 9.39999 15.6386 9.22503 16.5697 9.38473C17.5007 9.54443 18.3452 10.0285 18.9536 10.7512C19.5619 11.4738 19.8949 12.3885 19.8934 13.3331C19.8934 15.9998 15.8935 17.3331 15.8935 17.3331" stroke="#767a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16 22.667H16.0129" stroke="#767a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </el-tooltip>
          <el-input type="password" v-model="model.newPassword"></el-input>
        </el-form-item>

        <el-button
          :loading="loading"
          class="login-button"
          type="primary"
          native-type="submit"
          block
        >
          Установить пароль
        </el-button>

      </el-form>
    </el-card>
  </div>
</template>

<script>


import router from "@/router";

export default {
  name: 'RestoreStepTwo',
  components: {},

  data() {
    const validateNewPass = (rule, value, callback) => {
      let successCount = 0;
      [/^(?=.*\d)/, /^(?=.*[a-z])/, /^(?=.*[A-Z])/, /^(?=.*[$&+,:;=?@#|'<>.^*()%!-])/].forEach(rule => {
        if( value.match(rule) ) {
          successCount++;
          if( successCount >= 3 ){
            callback();
          }
        }
      });
      callback(new Error('Пароль не соответствует политике безопасности'));
    };

    return {
      loading: false,
      model: {
        newPassword: null,
      },
      rules: {
        newPassword: [
          {required: true, message: 'Поле обязательно для заполнения', trigger: 'change'},
          { min: 8, message: 'Длина пароля должна быть не менее 8 символов', trigger: 'change' },
          { validator: validateNewPass, trigger: 'change' }
        ],
      },
    }
  },
  computed: {},

  mounted() {

  },
  methods: {
    setNewPassword() {
      this.$refs.passForm.validate((result) => {
        if (!result) {
          return;
        }

        this.loading = true;
        this.$api.user.setNewPassword({
          token: this.$route.params.token,
          password: this.model.newPassword,
        })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Сохранено',
              message: 'Пароль успешно установлен'
            });
            this.$router.push({ name: 'Login'});
          })
          .finally(() => {
            this.loading = false;
          })

      });
    },

  }
}

</script>

<style lang="scss" scoped>

.login {
  height: 90vh;
  position: relative;
  /*border: 3px solid green;*/
}

.el-card {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


/*.login {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.login-button {
  width: 100%;
}

.login-form {
  width: 290px;
}

</style>
<style lang="scss">

.login .el-card {
  //margin-top:300px;
  width: 340px;
  //display: flex;
  justify-content: center;
}
</style>
