<template>
  <swiper
      class="my-slider"
      :slides-per-view="1"
      :space-between="0"
      :loop="false"
      :pagination="false"
      :navigation="true"
      :breakpoints="{
      '640': {
        slidesPerView: 2,
      },
      '800': {
        slidesPerView: 2,
      },
      '1024': {
        slidesPerView: 2,
      },
      '1500': {
        slidesPerView: slidePerDesk,
      },
    }"
  >
    <slot></slot>
  </swiper>

</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])
export default {
  name: "MySlider",
  props:{
    slidePerDesk:String,
    default:4
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods:{
  }
}
</script>

<style lang="scss" scoped>
.my-slider{
  ::v-deep .swiper-button-next,
  ::v-deep .swiper-button-prev {
    transition: .3s;
    background: rgba(37, 37, 37, 0.5);
    border-radius: 50px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #fff;
    &:after{
      font-size: 15px;
    };
  }
  ::v-deep .swiper-button-disabled{
    opacity: 0;
  }
  ::v-deep .swiper-slide {
    padding: 7px;
    height: 100% ;

  }

}

</style>