<template>
  <el-row class="audio-container" >

    <el-row type="flex" justify="space-between" align="middle">
      <el-row class="audio-information">
        <h2>Информация об аудио</h2>
        <el-row>инфа</el-row>
        <el-row>инфа</el-row>
        <el-row>инфа</el-row>
        <el-row>инфа</el-row>
        <el-row>инфа</el-row>
      </el-row>
      <el-row class="custom-player">
        <div class="audio-player">
          <div class="timeline" @click="timelineHandler($event)">
            <div class="progress" :style="{ width: progressWidth }"></div>
          </div>
          <div class="controls">

            <div class="play-container">
              <div class="toggle-play play" @click="togglePlayHandler()">
              </div>
            </div>

            <div class="name">{{ audioName }}</div>
            <div class="speed">Скорость: {{ audioSpeed / 100}}</div>

            <div>
              <div class="time">
                <div class="current">{{audioCurrentTime}}</div>
                <div class="divider">/</div>
                <div class="length">{{audioLength}}</div>
              </div>
              <div class="volume-container">
                <div class="volume-button" @click="changeMuteVolume()">
                  <div class="volume el-icon-microphone"></div>
                </div>

                <div class="volume-slider" @click="changeVolumeHandler($event)">
                  <div class="volume-percentage" :style="{width: volumePercentage}"></div>
                </div>
              </div>
              <div class="other-controls">
                <i class="el-icon-download" @click="download"></i>
              </div>
            </div>
          </div>
          <div class="speed-slider">
            <el-slider
                v-model="audioSpeed"
                show-stops
                :max="200"
                :step="200 / 10"
                :show-tooltip="false"
                :format-tooltip="formatTooltip"
                @change="changePlayerSpeed"
            >
            </el-slider>
          </div>
        </div>
      </el-row>
    </el-row>


    <el-row>
      <el-col :span="12">
        <h2>Клиент</h2>
        <el-row class="client-dialog-detail">
          <el-row >
            <p
                @click="setTimeToAudioPlayerByWord($event)"
                v-for="(pItem, i) in channelOneJsonData"
                :key="'p-one-'+i"
            >
              <span
                  v-for="(sItem, i) in pItem.alternatives[0].words"
                  :key="'s-one-'+i"
                  :data-time-start="parseFloat(sItem.startTime).toFixed(6)"
              >
                {{sItem.word}}
              </span>
            </p>
          </el-row>
        </el-row>
      </el-col>
      <el-col :span="12">
        <h2>Сотрудник</h2>
        <el-row class="customer-dialog-detail">
          <el-row>
            <p
                @click="setTimeToAudioPlayerByWord($event)"
                v-for="(pItem, i) in channelTwoJsonData"
                :key="'p-two-'+i"
            >
              <span
                  v-for="(sItem, i) in pItem.alternatives[0].words"
                  :key="'s-two-'+i"
                  :data-time-start="parseFloat(sItem.startTime).toFixed(6)"
              >
                {{sItem.word}}
              </span>
            </p>
          </el-row>
        </el-row>
      </el-col>
    </el-row>



    <el-row>
      <h2>Весь диалог</h2>
      <el-row class="full-dialog">
        <el-row>
          <p
              @click="setTimeToAudioPlayerByWord($event)"
              v-for="(pItem, i) in fullJsonData"
              :key="'p-full-'+i"
          >
            <span
                v-for="(sItem, i) in pItem.alternatives[0].words"
                :key="'s-full-'+i"
                :data-time-start="parseFloat(sItem.startTime).toFixed(6)"
            >
              {{sItem.word}}
            </span>
          </p>
        </el-row>
      </el-row>
    </el-row>

  </el-row>
</template>

<script>


export default {
  name: "detailed-audio-with-text",
  components: {},
  props: {
    audioName: {
      type: String,
      default: 'Загружаю...'
    },
    audioPath: {
      type: String,
    },
    // json:{}
  },
  data() {
    return {
      json: {},
      channelOneJsonData: [],
      channelTwoJsonData: [],
      fullJsonData:[],
      fullText: '',
      channelOneText:'',
      channelTwoText:'',
      audioSliderValue: 0,
      audioPlayer: null,
      playButton: null,
      audio: {
        playbackRate: 1,
      },
      twoChannels: false,
      timeline: null,
      volumeSlider: null,
      volume: null,
      volumePercentage: null,
      speedSlider: null,
      audioSpeed: 100,
      progressWidth: '0',
      audioLength: null,
      audioCurrentTime: '00:00',
    }
  },
  mounted() {
    this.init();
    this.initChannels()
    //console.log(this.json)
  },
  methods: {
    getElementsWithAttributeValue(attribute, value) {
      return []
          .slice
          .call(document.querySelectorAll(`span[${attribute}]`))
          .filter(elem => elem.getAttribute(attribute) <= value);

    },
    getCurrentTextByTime(time) {
      let completedWords = this.getElementsWithAttributeValue('data-time-start', time -  - 0.4);
      completedWords.forEach(item => item.classList.add('completed'))
    },
    initChannels() {
      this.json = testBigJson;
      // console.log(this.json)
      // console.log(this.json)
      this.fullJsonData = testBigJson.response.chunks;

      this.twoChannels = this.fullJsonData.find(item => item.channelTag === '2');

      this.twoChannels
          ? this.splitDataByTwoChannels()
          : this.splitDataByChannel();

    },
    splitDataByChannel() {
      this.fullJsonData.map( (item, i) =>
          (i%2 === 0)
            ? this.channelOneJsonData.push(item)
            : this.channelTwoJsonData.push(item)
      );
    },

    splitDataByTwoChannels() {

    },
    init() {
      this.audioPlayer = document.querySelector(".audio-player");
      this.volumeSlider = this.audioPlayer.querySelector(".controls .volume-slider");
      this.speedSlider = this.audioPlayer.querySelector(".audio-player .speed-slider");
      this.timeline = this.audioPlayer.querySelector(".timeline");
      this.volume = this.audioPlayer.querySelector(".volume-container .volume");
      this.playButton = this.audioPlayer.querySelector(".controls .toggle-play");

      //this.audio = new Audio(this.audioPath + this.audioName);
      this.audio = new Audio(sound);

      this.audio.addEventListener("loadeddata", () => {
            this.audioLength = this.getTimeCodeFromNum(this.audio.duration);
            this.audio.volume = .75;
            this.audio.playbackRate = this.audioSpeed / 100;
          }
      );
      this.audio.addEventListener("timeupdate", () => {
            this.getCurrentTextByTime(this.audio.currentTime)
        // console.log(this.audio.currentTime)
          }
      );
      //check audio percentage and update time accordingly
      setInterval(() => {
        this.progressWidth = this.audio.currentTime / this.audio.duration * 100 + "%";
        this.audioCurrentTime = this.getTimeCodeFromNum(this.audio.currentTime);
      }, 500);

    },
    setTimeToAudioPlayerByWord(e) {
      this.toPause()
      let time = parseFloat(e.target.getAttribute('data-time-start'));
      this.audio.currentTime = time -  0.35;
      this.resetCompletedWords();
      this.getCurrentTextByTime(time + 0.15)
      this.toPlay()
    },
    timelineHandler(e) {
      this.resetCompletedWords();
      this.audio.currentTime = e.offsetX / parseInt(this.timeline.clientWidth) * this.audio.duration;
      this.getCurrentTextByTime(this.audio.currentTime)
    },
    resetCompletedWords() {
      [].slice.call(document.querySelectorAll(`span[data-time-start]`)).map(item => item.classList.remove('completed'))
    },
    changeVolumeHandler(e) {
      this.audio.volume = e.offsetX / this.volumeSlider.clientWidth;
      (this.audio.volume * 100) ? this.audio.muted = true : this.audio.muted = false;
      this.audio.muted ? this.toMute() : this.toUnmute()
      this.volumePercentage = newVolume * 100 + '%';
    },
    changePlayerSpeed(val) {
      this.audio.playbackRate = this.formatTooltip(val);
      this.audioSpeed = val;
      (this.audioSpeed === 0) ? this.toPause() : this.toPlay();
    },
    togglePlayHandler() {
      //toggle between playing and pausing on button click
      (this.audio.paused && this.audio.playbackRate > 0) ? this.toPlay() : this.toPause()
    },
    toPlay() {
      this.playButton.classList.remove("play");
      this.playButton.classList.add("pause");
      this.audio.play();
    },
    toPause() {
      this.playButton.classList.remove("pause");
      this.playButton.classList.add("play");
      this.audio.pause();
    },
    toMute() {
      this.volume.classList.remove("el-icon-microphone");
      this.volume.classList.add("el-icon-turn-off-microphone");
    },
    toUnmute(){
      this.volume.classList.add("el-icon-microphone");
      this.volume.classList.remove("el-icon-turn-off-microphone");
    },
    changeMuteVolume() {
      this.audio.muted = ! this.audio.muted;
      this.audio.muted ? this.toMute() : this.toUnmute()
    },
    formatTooltip(val) {
      return val / 100;
    },
    download () {
      const fullPath = this.audioPath + this.audioName;
      const a = document.createElement('a');
      a.href = fullPath;
      a.download = this.audioName;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getTimeCodeFromNum(num) {
      //turn 128 seconds into 2:08
      let seconds = parseInt(num);
      let minutes = parseInt(seconds / 60);
      seconds -= minutes * 60;
      const hours = parseInt(minutes / 60);
      minutes -= hours * 60;

      if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
      return `${String(hours).padStart(2, 0)}:${minutes}:${String(
          seconds % 60
      ).padStart(2, 0)}`;
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../assets/css/project-variables';
.audio-container {
  margin: 0 20px;
  > .el-row {
    &:first-child {
      > .el-row {
        width: 50%;
      }
    }

  }
  .custom-player{
    margin: 10px 20px;
    .audio-player {
      height: 38px;
      width: 100%;
      max-width: 790px;
      background: #ddd;
      box-shadow: 0 0 3px 0 #ddd;
      color: #444444;
      font-size: 0.65em;
      overflow: hidden;
      display: grid;
      grid-template-rows: 6px auto;
      border-radius: 35px;
      margin: 0 auto;
      z-index: 5;
      .speed-slider {
        background: #b7b7b7;

        z-index: 9;
        height: 30px;
        cursor: pointer;
        .el-slider {
          height: 0;
          top: 16px;
          position: absolute;
          width: calc(100% - 30px);
          max-width: calc(790px - 30px);
          margin: 0 15px;
          ::v-deep .el-slider__runway {
            background: #b7b7b7;
          }
          ::v-deep .el-slider__button-wrapper {
            height: 20px;
            width: 20px;
            top: -7px;
          }
          ::v-deep .el-slider__bar {
            background: #a5a5a5;
          }
        }
      }
      .timeline {
        background: white;
        width: 100%;
        position: relative;
        cursor: pointer;
        box-shadow: 0 2px 10px 0 #0008;
        .progress {
          background: #b7b7b7;
          width: 0;
          height: 100%;
          transition: 0.25s;
        }
      }
      .controls {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        height: 26px;
        padding: 0 20px;
        .time, .name, .speed {
          margin-top: 3px;
        }
        .other-controls {
          margin-top: 3px;
          font-size: 21px;
          margin-left: 5px;
          i {
            margin-top: 8px;
            display: inline-block;
            margin-left: 5px;
            cursor: pointer;
          }

        }
        > * {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .toggle-play {
          &.play {
            cursor: pointer;
            position: relative;
            left: 0;
            height: 0;
            width: 0;
            border: 7px solid #0000;
            border-left: 13px solid white;
            &:hover {
              transform: scale(1.1);
            }
          }
          &.pause {
            height: 15px;
            width: 20px;
            cursor: pointer;
            position: relative;
            &:before {
              position: absolute;
              top: 0;
              left: 0;
              background: white;
              content: "";
              height: 15px;
              width: 3px;
            }
            &:after {
              position: absolute;
              top: 0;
              right: 8px;
              background: white;
              content: "";
              height: 15px;
              width: 3px;
            }
            &:hover {
              transform: scale(1.1);
            }
          }
        }
        .time {
          display: flex;

          > * {
            padding: 2px;
          }
        }
        .volume-container {
          cursor: pointer;
          .volume-button {
            font-size: 25px;
            display: flex;
            align-items: center;
            .volume {
              transform: scale(0.7);
            }
          }

          position: relative;
          z-index: 2;
          .volume-slider {
            position: absolute;
            left: -3px;
            top: 5px;
            z-index: -1;
            width: 0;
            height: 15px;
            background: white;
            box-shadow: 0 0 20px #000a;
            transition: .25s;
            .volume-percentage {
              background: #b7b7b7;
              height: 100%;
              width: 75%;
            }
          }
          &:hover {
            .volume-slider {
              left: -123px;
              width: 120px;
            }
          }
        }
      }
    }
  }

  h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  .audio-information {
    height: 20vh;
  }
  .full-dialog, .full-dialog-detail, .client-dialog-detail, .customer-dialog-detail{
    overflow-y: auto;
    line-height: 1.6;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    ::v-deep p {
      color: $--color-text-regular;
      margin: 0;
      text-align: left;
    }
    ::v-deep span {
      cursor: pointer;
      display: inline-block;
      padding: 3px 5px;
      background:  transparent;
      &.completed {
        background: $--color-primary;
        color: #fff;
      }
    }
  }
  .full-dialog-detail, .client-dialog-detail, .customer-dialog-detail {
    height: 20vh;
    text-align: center;
  }
  .full-dialog {
    height: 28vh;
  }
}



</style>