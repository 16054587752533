<template>
  <el-row type="flex" justify="center">
    <el-col
        class="location-form-container"
        :span="22"
    >
      <el-row>
        <h4>Редактирование организации {{ org.name }}</h4>
        <el-form>
          <el-row class="form-fields">
            <el-row>
              <el-col :span="6">Название</el-col>
              <el-col :span="18">
                <el-input
                    type="text"
                    placeholder="Введите название"
                    v-model="org.name"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">E-mail</el-col>
              <el-col :span="18">
                <el-input
                    type="text"
                    placeholder="Введите e-mail"
                    v-model="org.email"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">MASTER KEY</el-col>
              <el-col :span="18">
                <el-input
                    type="textarea"
                    :rows="1"
                    placeholder="Введите master key"
                    required
                    v-model="org.master_key">
                </el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">Страна</el-col>
              <el-col :span="18">
                <el-input
                    type="text"
                    placeholder="Введите наименование канала 1"
                    v-model="org.country"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">Регистрационный код</el-col>
              <el-col :span="18">
                <el-input
                    type="text"
                    placeholder="Введите наименование канала 2"
                    v-model="org.registration_key"
                ></el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="6">Статус</el-col>
              <el-col :span="18">
                <el-switch
                    v-model="org.status"
                    :active-value="1"
                    :inactive-value="0"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                </el-switch>
              </el-col>
            </el-row>
          </el-row>

        </el-form>
      </el-row>
      <el-row class="command-buttons">
        <el-button
            plain
            round
            @click="cancel"
        >
          Отмена
        </el-button>

        <el-button
            type="primary"
            round
            @click="save"
        >
          Сохранить
        </el-button>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "organization-form",
  props: {
    org: {},
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.$emit('save', this.org)
    }
  }
}
</script>

<style lang="scss" scoped>

.location-form-container {
  h4 {
    text-align: center;
  }

  .form-fields {
    > .el-row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      > .el-col {
        &:first-child {
          padding-right: 10px;
          text-align: right;
        }
      }
    }
  }

  .command-buttons {
    text-align: center;
    margin-bottom: 25px;
  }
}

</style>