<template>
  <el-row>
    <div class="dictionaries-container">
      <dictionary-item
          :key="dict.id"
          v-for="dict in systemDictionaries"
          :dictData="dict"
          @show="showDictionaryInformationForm"
          @edit="showDictionaryForm"
          @delete="deleteDictionary"
      />
      <dictionary-item
          :key="-dict.id"
          v-for="dict in clientDictionaries"
          :dictData="dict"
          @show="showDictionaryInformationForm"
          @edit="showDictionaryForm"
          @delete="deleteDictionary"
      />
    </div>

    <el-drawer
        :visible.sync="visibleDictionaryDrawer"
        direction="rtl"
        size="50%"
    >
      <dictionary-form
          :selected-dictionary="selectedDictionary"
          @cancel="visibleDictionaryDrawer = false"
          @save="save"
      >
      </dictionary-form>
    </el-drawer>
    <el-drawer
        :visible.sync="visibleDictionaryInformationDrawer"
        direction="rtl"
        size="50%"
    >
      <dictionary-information-form
          :selected-dictionary="selectedDictionary"
          @cancel="visibleDictionaryInformationDrawer = false"
          @save="save"
      >
      </dictionary-information-form>
    </el-drawer>
    <el-row class="add">
      <el-button type="primary" @click="showDictionaryForm()"><i class="el-icon-plus"></i>Добавить</el-button>
    </el-row>

  </el-row>
</template>

<script>
import ClientDictionary from "@/components/dictionary/ClientDictionary.vue";
import SystemDictionary from "@/components/dictionary/SystemDictionary.vue";
import DictionaryForm from "@/components/dictionary/DictionaryForm.vue";
import DictionaryInformationForm from "@/components/dictionary/DictionaryInformationForm.vue";
import ClientDictionaryNew from "@/components/dictionary/DictonaryItem.vue";
import ClientDictionaryItem from "@/components/dictionary/DictonaryItem.vue";
import DictionaryItem from "@/components/dictionary/DictonaryItem.vue";

export default {
  name: "all-dictionary",
  components: {
    DictionaryItem, SystemDictionary, ClientDictionary, DictionaryInformationForm, DictionaryForm
  },
  data() {
    return {
      systemDictionaries: [],
      clientDictionaries: [],
      visibleDictionaryInformationDrawer: false,
      visibleDictionaryDrawer: false,
      selectedDictionary: {
        reverse: 0,
        type: 'client',
      },
      selectedDictionaryType: null,
    }
  },
  mounted() {
    this.getDictionaries();
  },
  methods: {
    getDictionaries() {
      this.loading = true;
      this.$api.dictionary.get()
          .then(data => {
            this.systemDictionaries = data.system
            this.clientDictionaries = data.client
          })
          .finally(() => {
            this.loading = false;
          })
    },
    showDictionaryForm(data) {
      if (data) {
        if (data.type === 'system') {
          this.selectedDictionary = this.systemDictionaries.find(dictionary => dictionary.id === data.id);
          this.selectedDictionary.type = 'system';
        } else {
          this.selectedDictionary = this.clientDictionaries.find(dictionary => dictionary.id === data.id);
          this.selectedDictionary.type = 'client';
        }
      } else {
        this.selectedDictionary = {
          reverse: 0,
          type: 'client'
        }
      }
      this.visibleDictionaryDrawer = true;

    },
    showDictionaryInformationForm(data) {
      if (data.type === 'system') {
        this.selectedDictionary = this.systemDictionaries.find(dictionary => dictionary.id === data.id);
      } else {
        this.selectedDictionary = this.clientDictionaries.find(dictionary => dictionary.id === data.id);
      }
      this.selectedDictionary.type = data.type

      this.visibleDictionaryInformationDrawer = true;
    },
    save() {
      this.loading = true;

      if (!this.selectedDictionary.name) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Поля Имя обязательно для заполнения',
        });
        return;
      }
      this.$api.dictionary.save(this.selectedDictionary)
          .then(data => {

            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Словать сохранен',
              });
              this.reset();
              this.getDictionaries();

            } else {

              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors.message
              });
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },
    deleteDictionary(data) {
      this.loading = true;
      this.$api.dictionary.delete({id: data.id, type: data.type})
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Словарь удален',
            });
            this.getDictionaries()
          })
          .finally(() => {
            this.loading = false;
          })
    },
    reset() {
      this.visibleDictionaryInformationDrawer = false;
      this.visibleDictionaryDrawer = false;
      this.selectedDictionary = {
        reverse: 0,
        type: 'client'
      }
      this.selectedDictionaryType = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.dictionaries-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.add {
  text-align: right;
  margin-bottom: 10px;
}
</style>