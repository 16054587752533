<template>
  <div class="user">
    <h2>Пользователь: <span style="font-weight: bold">"{{user.login}}"</span></h2>
    <i
        v-if="$store.getters.user.id !== user.id && user.id"
        class="el-icon-delete"
        @click="deleteUser"
    ></i>
    <el-form
        label-position="'right'"
        :model="user"
    >
      <div class="user-scroll">
        <el-form-item label="Логин">
          <el-input v-model="user.login"></el-input>
        </el-form-item>
        <el-form-item label="Фамилия">
          <el-input v-model="user.first_name"></el-input>
        </el-form-item>
        <el-form-item label="Имя">
          <el-input v-model="user.last_name"></el-input>
        </el-form-item>
        <el-form-item label="Отчество">
          <el-input v-model="user.patronym"></el-input>
        </el-form-item>
        <el-form-item label="E-mail">
          <el-input v-model="user.email"></el-input>
        </el-form-item>
        <el-form-item label="Роль">
          <el-select v-model="user.role.name">
            <el-option
                v-for="role in roles"
                :key="role.name"
                :label="role.description"
                :value="role.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Новый пароль">
          <el-input type="password" v-model="user.new_password"></el-input>
        </el-form-item>
        <el-form-item label="Подтверждение пароля">
          <el-input type="password" v-model="user.new_password_confirm"></el-input>
        </el-form-item>
      </div>
      <div class="user-fixed">
        <el-form-item label="Активен">
          <el-switch
              :disabled="$store.getters.user.id === user.id && user.role.name !== 'admin'"
              v-model="user.status"
              :active-value="1"
              :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="Категории">
          <i class="el-icon-edit" @click="categoryDialog = true"></i>
          <div class="categories" v-if="user.categories.length">

            <span
                v-for="category in user.categories"
                :key="category.id"
            >
              {{category.category.name}}
            </span>
          </div>
          <div class="categories" v-else><span>Назначения отсутствуют</span></div>
          <el-dialog title="Принадлежность пользователя к категории" :visible.sync="categoryDialog">
            <el-tree
                :data="prepareLocations"
                show-checkbox
                default-expand-all
                node-key="category_id"
                ref="user-locations-tree"
                highlight-current
                :props="defaultPropsLocations"
                :default-checked-keys="checkedUserCategories"
            >
            </el-tree>
            <el-row type="flex" justify="end">
              <el-button @click="categoryDialog = !categoryDialog">Отменить</el-button>
              <el-button type="primary" @click="saveUserCategories">Сохранить</el-button>
            </el-row>
          </el-dialog>
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button
              type="primary"
              @click="save(user)"
          >
            {{user.id? 'Изменить': 'Сохранить'}}
          </el-button>
        </el-form-item>
      </div>


    </el-form>
  </div>

</template>

<script>
export default {
  name: "user-edit",
  props: {
    organizationId:{},
    user: {},
    roles:{},
    prepareLocations: {},
  },
  data() {
    return {
      categoryDialog: false,
      defaultPropsLocations: []
    }
  },
  mounted() {
    console.log('dfdf')
  },
  computed:{
    checkedUserCategories() {
        return this.user.categories
            ? this.user.categories.map( category => category.category_id)
            : [];
    }
  },
  methods: {
    save() {
      this.user.role = this.user.role.name;
      this.user.organization_id = this.organizationId;

      this.$api.management.saveUser(this.user)
          .then(data => {
            if(!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }else{
              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors,
              });
            }
            this.$emit('update')
          })
          .finally(() => {
            this.formDrawer = false;
            this.loading = true;
          })
    },
    deleteUser(){
      this.$api.management.deleteUser({id: this.user.id})
          .then(data => {
            if(!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }else{
              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors,
              });
            }
            this.$emit('update')
          })
          .finally(() => {
            this.formDrawer = false;
            this.loading = true;
          })

    },
    getAvailableRoles() {
      if(!this.$store.getters.user.id) {

      }
      this.$api.management.getAvailableRoles(
          {id: this.$store.getters.user.id}
      )
          .then(data => {
            this.roles = data;
          })
    },
    saveUserCategories() {

      let categories = this.$refs["user-locations-tree"].getCheckedKeys().filter(function( element ) {
        return element !== undefined;
      });

      this.$api.management.saveUserCategories({
        organization_id: this.organizationId,
        user_id: this.user.id,
        categories: categories
      })
          .then(data => {
            if(!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });

            }else{
              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors,
              });
            }
          })
          .finally(() => {
            this.categoryDialog = false
            this.$emit('update', this.organizationId)
          })

    }
  }
}
</script>

<style lang="scss" scoped>

  .user {
    border: 1px solid #eee;
    .user-scroll {
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .el-select{
      width: 100%;
    }
    ::v-deep .el-form {
      &-item {
        margin: 10px;
        position: relative;
        &__content {
          line-height: 1;
        }
        .el-icon-edit {
          position: absolute;
          top: 0;
          right: 10px;
        }
        &__label{
          width: 100%;
          text-align: left;
          font-weight: bold;
          font-size: 10px;
          line-height: 1;
        }
        .el-input {
          font-size: 12px;

          &__inner {
            border-radius: 4px;
            height: 30px;

          }
          &__suffix {
            .el-icon-arrow-up {
              &:before {
                position: relative;
                top: -5px;
              }

            }
          }
        }
        .categories {
          height: 45px;
          overflow-y: clip;
          span {
            padding-right: 5px;
            font-size: 10px;
            color: #555555;
          }
        }
        .el-button {
          &--primary {
            padding: 5px 10px;
            font-size: 12px;
          }
        }
      }
    }

    position: relative;
    .el-icon-delete {
      position: absolute;
      color: #555555;
      top: 15px;
      right: 10px;
      font-size: 14px;
    }
  }
</style>