<template>
  <el-row>
    <el-header class="header" style="height: auto;">
      <el-row type="flex" justify="space-between" align="middle">
        <el-row class="header__search" type="flex">
          <i class="el-icon-search" ></i>
          <el-input placeholder="Быстрый поиск ... " v-model="headerSearch"></el-input>
        </el-row>
        <el-row class="header__account">
          <notifications></notifications>
          <div class="header__account-inner">
            <div v-if="user.id" class="header__account-name">{{ user.login }}</div>
            <div class="header__account-position">{{ user.position }}</div>
          </div>

          <el-avatar
              :src="require('@/assets/img/avatar.png')"
              class="header__account-avatar"
          >
          </el-avatar>


        </el-row>
        <div class="header__logout" @click="logout">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 20H6C5.46957 20 4.96086 19.8127 4.58579 19.4793C4.21071 19.1459 4 18.6937 4 18.2222V5.77778C4 5.30628 4.21071 4.8541 4.58579 4.5207C4.96086 4.1873 5.46957 4 6 4H10" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16 16L20 12L16 8" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M20 12H10" stroke="#A3A6A7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </el-row>
    </el-header>
    <el-row type="flex">
      <el-aside>
        <el-row>
          <router-link
              class="header__logo"
              :to="{ name: 'Locations'}"
          >
<!--            <img src="@/assets/img/logo.svg" alt="logo" class="img-responsive">-->
            <span>KG</span>
          </router-link>
        </el-row>
        <general-menu
            :default-active="$route.path"></general-menu>
      </el-aside>
      <el-main>
        <div class="organization-management">
          <div class="menu">
            <management-menu/>
          </div>
          <div class="content">
            <router-view></router-view>
          </div>
        </div>
      </el-main>
    </el-row>
  </el-row>

</template>

<script>
import GeneralMenu from "@/components/GeneralMenu";
import {mapGetters} from 'vuex';

import Notifications from "@/components/notifications/Notifications";

import ManagementMenu from "@/components/management/managementMenu";
export default {
  name: "default-layout",
  components: {GeneralMenu, Notifications, ManagementMenu},
  computed: {
    ...mapGetters(['user']),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },
  created() {
    this.$store.dispatch('fetchUser');
  },
  data() {
    return {
      notifications: 2,
      isCollapse: false,
      headerSearch: null,
    }
  },
  methods: {
    activeUserSelected(selection) {
      localStorage.userId = selection[0].id;
      this.$router.go();
    },
    logout() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      this.$router.go();
    },
  }
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";
.header {
  z-index: 65;
  background: #fff;
  max-height: 125px;
  position: sticky;
  top: 0;
  text-align: center;
  margin-left: 85px;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  width: calc(100% - 110px) !important;
  &__logo {
    display: flex;
    background: $--color-primary;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin: 20px auto 0 auto;
    font-size: 20px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  &__account-name {
    color: $--color-primary;
  }
  &__search {
    width: 70%;
    margin-right: auto;
    .el-icon-search {
      color: #ddd;
      font-size: 24px;
      position: relative;
      top: 7px;
      z-index: 2;
    }
    .el-input {
      left: -10px;
      input {
        border: none;
        font-size: 14px!important;
        font-weight: bold;
      }
    }

  }
  &__account {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 0 16px 0 0;
    &-inner {
      padding-right: 10px;
    }
    &-bell {
      position: relative;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      .attention {
        position: absolute;
        top: -4px;
        right: 0;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        background-color: $--color-secondary-red;
        border: 2px solid $--color-white;
        border-radius: 50%;
      }

      &.active {
        cursor: pointer;
      }
    }

    &-avatar {
      background: #fff;
      margin: 0 16px 0 0;
      border-radius: 50%;
      img {
        width: 100%;
        border-radius: 50%;
      }
    }

    &-name {
      font-size: 14px;
      font-weight: 700;
    }

    &-position {
      font-size: 12px;
      color: $--color-text-regular-4;
    }

    &-link {
      font-size: 14px;
      color: $--color-primary;
    }
  }

  &__logout {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      display: block;
      height: auto;

      path {
        transition: all .4s;
      }
    }

    &:hover {

      svg {

        path {
          stroke: $--color-primary;
        }
      }
    }
  }
}
.el-aside {
  z-index: 66;
  width: 85px!important;
  height: 100vh !important;
  padding: 0;
  margin: 0;
  max-width: 100%;
  min-height: 92vh;
  position: fixed;
  overflow-x: hidden;
  top: 0;
  box-shadow: 1px 1px 2px #ddd;
  transition: all 300ms linear;

}
.el-main {
  display: block;
  flex: none;
  overflow: auto;
  box-sizing: border-box;
  background: $--background-color-base;
  height: calc(100vh - 125px);
  padding: 5px;
  margin-left: 85px;
  width: calc(100% - 110px)!IMPORTANT;

  .organization-management {
    display: grid;
    grid-template-columns: 90px 1fr;
  }
}

.content {
  padding: 25px 30px;
}

</style>