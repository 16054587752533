<template>
  <el-row v-loading="loading">

    <el-row class="add-location">
      <el-button type="primary" @click="showLocationForm()"><i class="el-icon-plus"></i>Добавить локацию</el-button>
    </el-row>

    <el-row>
      <p v-if="testManticoreData.length">{{ testManticoreData[9]['data']['text_channel_1'][0] }}</p>
    </el-row>

    <el-collapse v-model="activeNames" class="collapse-locations">
      <el-collapse-item
          v-for="location in locations"
          :key="location.id"
          :title="'Локация: '+ location.name"
          :name="location.id"
      >

        <el-row v-if="locations.length">

          <el-row
              class="location"
          >
            <el-row class="page-command-buttons">
              <el-popover
                  class="location-popover"
                  placement="right"
                  width="200"
                  trigger="click"
              >
                <el-row>
                  <p>
                    <i
                        class="el-icon-edit"
                        @click="showLocationForm(location.id)">Редактировать</i>
                  </p>
                </el-row>
                <el-row>
                  <p>
                    <el-popconfirm
                        @confirm="deleteLocation(location.id)"
                        title="Вы уверены?"
                    >

                      <i slot="reference" class="el-icon-delete">Удалить</i>

                    </el-popconfirm>
                  </p>

                </el-row>
                <i class="el-icon-more-outline" slot="reference"></i>
              </el-popover>
              <!--              <h1>Локация: {{location.name}}</h1>-->
              <i
                  class="el-icon-refresh"
                  @click="updateLocation(location.id)"
              ></i>
            </el-row>

            <el-row type="flex" class="general-info">
              <el-col :span="5">
                <sub-locations
                    :location="location"
                    @update="updateLocation"
                />
              </el-col>
              <el-col :span="4">
                <customer-geography/>
              </el-col>
              <el-col :span="3">
                <report/>
              </el-col>
              <el-col :span="12">
                <about-audio/>
              </el-col>
            </el-row>
            <el-row class="trend">
              <el-row type="flex">
                <el-col :span="12">
                  <h2>Тенденции</h2>
                </el-col>
                <el-col :span="12">
                  <el-row type="flex" justify="end" class="trend__description">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="по"
                        start-placeholder="Начало"
                        end-placeholder="Окончание"
                        :picker-options="dateRangeOptions">
                    </el-date-picker>
                  </el-row>
                </el-col>
              </el-row>
              <el-row>
                <trend-chart
                    :data="chartData"
                    :dates="categories"
                />
              </el-row>
            </el-row>
          </el-row>
        </el-row>
      </el-collapse-item>

    </el-collapse>

    <el-drawer
        :visible.sync="visibleLocationDrawer"
        direction="rtl"
        size="40%"
    >
      <location-form
          :selected-location="selectedLocation"
          @cancel="visibleLocationDrawer = false"
          @update="updateLocation"
          @save="save"
      >
      </location-form>
    </el-drawer>

    <el-drawer
        :visible.sync="audioDrawer"
        direction="rtl"
        size="90%"
    >
      <div>
        <detailed-audio-with-text
            :audio-name="'SoundHelix-Song-1.mp3'"
            :audio-path="'https://www.soundhelix.com/examples/mp3/'"
            :json="{}"
        />
      </div>
    </el-drawer>


  </el-row>


</template>

<script>

import SubLocations from "@/components/location/SubLocations";
import CustomerGeography from "@/components/CustomerGeography";
import LocationForm from "@/components/location/LocationForm";
import Report from "@/components/Report";
import AboutAudio from "@/components/AboutAudio";
import TrendChart from "@/components/TrendChart";
import DetailedAudioWithText from "@/components/DetailedAudioWithText";

import {getDaysArray} from "@/utils/helpers";

export default {
  name: "Locations",
  components: {LocationForm, SubLocations, CustomerGeography, Report, AboutAudio, TrendChart, DetailedAudioWithText},
  data() {
    return {

      activeNames: [],

      loading: false,
      locations: [],
      audioDrawer: true,
      visibleLocationDrawer: false,
      selectedLocation: {},


      dateRange: '',
      dateRangeOptions: {
        shortcuts: [{
          text: 'Пред. неделя',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Пред.месяц',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'За 3 месяца',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

      days: 15,
      chartData: [{
        name: 'Всего аудио',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 120, 123, 61, 58, 63, 60, 43],
        color: '#642cf7',
      }, {
        name: 'К обработке',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 44, 61, 58, 63, 60, 54],
        color: '#f6d080'
      }, {
        name: 'Негативные',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 65, 61, 58, 63, 165, 67],
        color: '#75d688'
      }, {
        name: 'Позитивные',
        data: [35, 41, 36, 26, 45, 44, 55, 57, 56, 61, 61, 58, 63, 60, 32],
        color: '#5583f8'
      }],
      categories: [],
      testManticoreData: [],
    }
  },
  created() {
    this.getLocations();
    this.categories = this.getCategories(this.days);
  },
  mounted() {
    this.testManticore();
  },
  methods: {


    testManticore() {
      this.$api.location.testManticore()
          .then(data => {
            this.testManticoreData = data;
          })
          .finally()
    },
    getLocations() {
      this.loading = true;
      this.$api.location.get()
          .then(data => {
            this.locations = data
            this.activeNames = this.locations.map(loc => loc.id)
          })
          .finally(() => {
            this.loading = false;
          })
    },
    updateLocation(id = null) {
      this.loading = true;
      this.$api.location.getLocation({location_id: id})
          .then(data => {
            let index = this.locations.findIndex(location => location.id === id);
            this.locations[index] = data;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    deleteLocation(id) {
      this.loading = true;
      this.$api.location.delete({id: id})
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Локация удалена',
            });
            this.getLocations()
          })
          .finally(() => {
            this.loading = false;
          })
    },

    showLocationForm(id = null) {
      if (id) {
        this.selectedLocation = this.locations.find(location => location.id === id)
      } else {
        this.selectedLocation = {};
      }
      this.visibleLocationDrawer = true;
    },

    save() {
      this.loading = true;
      if (!this.selectedLocation.name || !this.selectedLocation.description) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Поля Имя и Описание обязательны для заполнения',
        });
        return;
      }

      this.$api.location.save(this.selectedLocation)
          .then(data => {

            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Локация сохранена',
              });
              this.reset();
              this.getLocations();

            } else {

              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors[0]
              });

            }

          })
          .finally(() => {
            this.loading = false;
          })
    },
    reset() {
      this.visibleLocationDrawer = false;
      this.selectedLocation = {};
    },

    getCategories() {
      let daysAgo = new Date(new Date().setDate(new Date().getDate() - 14));
      let dayslist = getDaysArray(daysAgo, new Date());
      dayslist.map((v) => v.toISOString().slice(0, 10)).join("")


      let categories = [];

      dayslist.forEach(date => {
        categories.push(`${date.getDate()} ${date.toLocaleString('ru', {month: 'short'})}`);
      })

      return categories;
    },

    getDateRange() {


    }

  }
}
</script>

<style lang="scss">
.collapse-locations {
  .el-collapse-item__header {
    display: flex;
    align-items: center;
    height: 48px;
    line-height: 48px;
    background-color: transparent;
    color: #1a2122;
    cursor: pointer;
    font-size: 24px;
    border-bottom: 1px solid #EBEEF5;
    font-weight: 700;
    transition: border-bottom-color 0.3s;
    outline: none;
  }

  .el-collapse-item__wrap {
    will-change: height;
    background-color: transparent;
    overflow: hidden;
    box-sizing: border-box;
    border-bottom: 1px solid #EBEEF5;
  }
}
</style>

<style lang="scss" scoped>


.add-location {
  text-align: right;
  margin-left: 5px;
  margin-bottom: 10px;
}

.location {
  margin: 0 0 10px 0;

  h1 {
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .el-icon-refresh {
    transition: all .1s ease-out;

    &:hover, :focus {
      color: #363636;
      transform: scale(1.1);
    }
  }


  h4 {
    margin-bottom: 10px;
    font-size: 19px;
  }

  .general-info {
    .el-col {
      background: #fff;
      padding: 30px;
      border-radius: 5px;
      margin: 10px;
      box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;
    }
  }

  .trend {
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;

    &__description {
      font-size: 14px;
      color: #b5b5b5;

      i {
        font-size: 27px;
        color: #000;
        cursor: pointer;
        position: absolute;
        right: 0;

        .el-date-editor .el-range-separator {
          width: 8%;
        }
      }

      .el-col {
        > span {
          &:nth-child(odd) {
            color: #000;
            font-weight: 900;
          }

          &:nth-child(even) {
            font-size: 16px;
          }

          &.color {
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            top: 5px;
            left: -15px;
          }
        }

      }

    }

  }


}
</style>
