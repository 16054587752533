<template>
  <el-drawer
      title="I am the title"
      :visible.sync="stateDrawerCalls"
      :direction="'ltr'"
      :with-header="false"
      size="45%"
  >
    <div class="title">
      <h3>  Список звонков</h3>
    </div>
    <div
        class="content"
    >
      <div class="call-list">
        <ul>
          <li>
            <p>id звонка</p>
            <p>------</p>
          </li>
          <li>
            <p>Внутр. Номер</p>
            <p>------</p>
          </li>
          <li>
            <p>Оператор</p>
            <p>-----</p>
          </li>
          <li>
            <p>Клиент</p>
            <p>-----</p>
          </li>
          <li>
            <p>Дата разговора</p>
            <p>-----</p>
          </li>
        </ul>
      </div>

    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "drawer-calls-list",
  data() {
    return {
      stateDrawerCalls: true,
    };
  }
}
</script>

<style lang="scss" scoped>
.title{

  h3{
    font-family: inherit;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
  }
}
.content {
  * {
    margin: 0;
    padding: 0;
  }

  border-radius: 5px;
  padding: 4px 10px;
  margin: 3px 20px !important;
  background: #dddddd;
  border: solid 1px #dddddd;

  &:hover {
    border: solid 1px #000;
  }

  ul {
    //display: flex;
    //align-items: center;
    //justify-content: flex-start;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    gap: 20px;
    cursor: pointer;


    li {
      padding: 5px 10px;
      &:nth-child(6), &:nth-child(7) {
        p:first-child {
          text-align: center;

        }


        p:last-child {
          text-align: center;
        }
      }

      p {
        color: #555;
        font-weight: bold;

        &:first-child {
          color: #fff;
          font-size: 0.5rem;
          text-shadow: 0 0 4px rgb(0 0 0 / 50%);


          span {
            font-weight: normal;
          }
        }

        .plus {
          color: green;
        }

        .minus {
          color: red;
        }

        &:last-child {
          margin-top: 3px;
          font-size: 0.75rem;
          font-weight: normal;
        }

        button {
          font-family: inherit;
          border: none;
          background: none;
          font-weight: normal;
          cursor: pointer;
          font-size: 0.75rem;
          color: #24387f;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

</style>