<template>
  <el-row class="dictionaries" v-loading="loading">

    <el-row>
      <el-table
          ref="availableDictionariesTable"
          :data="prepareAvailableDataForTransfer"
          width="100%"
          @selection-change="moveToSelected"
      >
        <el-table-column
            fixed="left"
            type="selection"
            width="40"
        >
        </el-table-column>
        <el-table-column
            label="Доступные"
        >
          <el-table-column
              label="Название"
              width="310">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>

          <el-table-column
              label="Тип"
              align="center"
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.type === 'system' ? 'Системный' : 'Пользовательский' }}
            </template>
          </el-table-column>

          <el-table-column
              label="Успех"
              align="center"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_success"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Отклонен"
              align="center"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_reject"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="П/вес"
              width="120"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.positive_weight" :value="scope.row.positive_weight"></el-input-number>
            </template>
          </el-table-column>

          <el-table-column
              label="Н/вес"
              width="120"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.negative_weight" :value="scope.row.negative_weight"></el-input-number>
            </template>
          </el-table-column>

          <el-table-column
              label="Реверсивный"
              align="center"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.reverse"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
              >
              </el-switch>
              {{ scope.row.reverse }}
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row class="transfer">
      <el-row>
        <el-button :disabled="!preAvailableDictionaries.length" @click="toAvailable">
          <i class="el-icon-arrow-up"></i>
        </el-button>
      </el-row>
      <el-row>
        <el-button :disabled="!preSelectedDictionaries.length" @click="toSelected">
          <i class="el-icon-arrow-down"></i>
        </el-button>
      </el-row>


    </el-row>

    <el-row>
      <el-table
          ref="availableDictionariesTable"
          :data="prepareSelectedDataForTransfer"
          width="100%"
          @selection-change="moveToAvailable"
      >
        <el-table-column
            fixed="left"
            type="selection"
            width="40"
        >
        </el-table-column>
        <el-table-column
            label="Доступные"
        >
          <el-table-column
              label="Название"
              width="310">
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>

          <el-table-column
              label="Тип"
              align="center"
              show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.type === 'system' ? 'Системный' : 'Пользовательский' }}
            </template>
          </el-table-column>

          <el-table-column
              label="Успех"
              align="center"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_success" :disabled="true"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="Отклонен"
              align="center"
          >
            <template slot-scope="scope">
              <el-color-picker v-model="scope.row.color_reject" :disabled="true"></el-color-picker>
            </template>
          </el-table-column>

          <el-table-column
              label="П/вес"
              width="120"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number
                  v-model="scope.row.positive_weight"
                  :value="scope.row.positive_weight"
                  :disabled="true"
              />
            </template>
          </el-table-column>

          <el-table-column
              label="Н/вес"
              width="120"
              align="center"
          >
            <template slot-scope="scope">
              <el-input-number
                  v-model="scope.row.negative_weight"
                  :value="scope.row.negative_weight"
                  :disabled="true"
              />
            </template>
          </el-table-column>

          <el-table-column
              label="Реверсивный"

              align="center"
          >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.reverse"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  :disabled="true"
              >
              </el-switch>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </el-row>

  </el-row>
</template>

<script>
export default {
  name: "checklist-dictionaries-transfer",
  props:{
    checklist: {},
    selectedSystemDictionaries: {},
    selectedClientDictionaries: {},
    refresh: {}
  },
  data() {
    return {
      show: true,
      loading: false,
      dictionaries: {
        client: [],
        system: [],
        for_group: [],
      },
      preAvailableDictionaries:[],
      preSelectedDictionaries:[],
      selectedDictionaries:[],
      availableDictionaries: [],
    }
  },
  computed: {
    prepareSelectedDataForTransfer: function () {
      let system = this.selectedSystemDictionaries.map( item => this.prepareObjectByType('system', item) )
      let client = this.selectedClientDictionaries.map( item => this.prepareObjectByType('client', item) )
      this.selectedDictionaries =  [...system, ...client]
      return this.selectedDictionaries;
    },
    prepareAvailableDataForTransfer: function ()  {
      let system = this.dictionaries.system.map( item => {item.type = 'system'; return item} )
      let client = this.dictionaries.for_group.map( item => {item.type = 'client'; return item} )
      let availableDictionaries = [...system, ...client]
      let selected = this.selectedDictionaries.map( item =>  item.id + '-' + item.type )
      this.availableDictionaries = availableDictionaries.filter(item => {
        let include = (selected).includes(item.id+'-'+item.type)
        if(!include){
          return item;
        }
      })
      return this.availableDictionaries
    },
  },
  mounted(){
    this.getDictionaries();
  },
  methods: {
    prepareObjectByType(type, object){
      if(object) {
        object.dictionary.type = type;
        object.dictionary.color_reject = object.color_reject;
        object.dictionary.color_success = object.color_success;
        object.dictionary.negative_weight = object.negative_weight;
        object.dictionary.positive_weight = object.positive_weight;
        object.dictionary.reverse = object.reverse;
        return object.dictionary;
      }

    },
    getDictionaries() {
      this.$api.dictionary.get()
          .then( data => {
            this.dictionaries = data;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    moveToSelected(preSelected) {
      this.preSelectedDictionaries = preSelected;
    },
    moveToAvailable(preAvailable) {
      this.preAvailableDictionaries = preAvailable;
    },

    toSelected() {
      this.loading = true;

      this.preSelectedDictionaries.forEach(item => {
        this.$api.checklist.saveChecklistDictionary({
          checklist_id: this.checklist.id,
          dictionary_id: item.id,
          dictionary_type: item.type,
          positive_weight: item.positive_weight,
          negative_weight: item.negative_weight,
          color_success: item.color_success,
          color_reject: item.color_reject,
          channel: item.channel,
          reverse:item.reverse
        })
            .then(data => {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Чеклисты успешно добавлены',
              });
            })
            .finally(() => {
              this.loading = false;
              this.$emit('reload', this.checklist.id)
            })
      })

    },
    toAvailable() {
      this.loading = true;
      this.$api.checklist.deleteChecklistDictionary({
        checklist_id: this.checklist.id,
        dictionaries: this.preAvailableDictionaries.map(item => item.id)
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Чеклисты уаспешно удалены',
            });
          })
          .finally(() => {
            this.loading = false;
            this.$emit('reload', this.checklist.id)
            this.getDictionaries();
          })
    }
  }
}
</script>

<style lang="scss" scoped>
  .dictionaries {
    margin: 0 20px 50px 20px;
    .transfer {
      text-align: center;
      .el-button {
        padding: 5px 10px;
        margin: 5px;
        i {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
    ::v-deep .el-table {
      &__cell {
        border-right: none;
        border-left: none;
      .el-color-picker {
        position: relative;
        top: 5px;
        &__mask,
        &__trigger{
          height: 30px;
          width: 30px;
        }
      }

      .el-input {
        &-number {
          width: 100px;
          &__decrease,
          &__increase {
            width: 30px;
            height: 28px;
            top: 5px;
            i {
              position: relative;
              top: -3px;
            }
          }
        }
        &__inner {
          height: 30px;
          line-height: 30px;
          padding: 0;
        }


      }
      }
    }
  }
</style>