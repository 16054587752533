<template>
  <div class="category" v-loading="loadingLocations">
    <h6>Выберите сотрудников</h6>
    <el-cascader
        class="cascade"
        v-model="category_first"
        :options="filteredOptions"
        @change="handleChange"
        placeholder="Выбрать сотрудников"
        :props="{
              multiple:multipart
            }"
        clearable
        filterable
    >
    </el-cascader>
  </div>

</template>

<script>


export default {
  name: "selection-block-category-employees",
  props:{
    multipart:{
      default:true
    },
    getCategory:{
      type:Function
    },
    getLocationsData:{
      type:Function
    }
  },
  data() {
    return {
      category_first: null,
      category_two: null,
      loadingLocations:true,
      locationsData:null,
      optionsCategoryTwo: [{value: 0, label: '1'}, {value: 1, label: '2'}],
      optionsCategoryFirst: [{value: 0, label: '1'}, {value: 1, label: '2'}],
      options:[
        {
          label:'locations',
          value:'1',
          children:[
              {
                label:'sublocations',
                value:2,
                children:[
                    {
                      label:'categoryies',
                      value:'1',
                      children:[
                        {
                          label:'agents',
                          value:'1',
                        }
                      ]
                    }
                ]
              }
          ]
        },
      ]
    }
  },
  mounted() {
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.loading = true;
      this.$api.location.getAllWithAgents()
          .then(data => {
            this.locationsData = data
            this.getLocationsData(data)
          })
          .finally(() => {
            this.loadingLocations = false;
          })
    },
    handleChange(value) {
      let filteredCategory = []
      value.forEach(el=>{
        if (el[2]){
          filteredCategory.push([el[2], el[3]])
        }
      })
      this.getCategory(filteredCategory);
    }
  },
  computed:{
    filteredOptions(){
      let filteredOptions=[]
      if ( this.locationsData){
        this.locationsData.forEach(location=>{
          let locationChildren=[]
          let locationObj = {value:location.id,label:location.name,children:locationChildren}
          filteredOptions.push(locationObj)
          location.sub_locations.forEach(subLocation=>{
            let subLocationsChildren = []
            let subLocationsObj = {value:subLocation.id,label:subLocation.name,children:subLocationsChildren}
            locationChildren.push(subLocationsObj)
            subLocation.categories.forEach(category=>{
              let categoryChildren = []
              let categoryObj = {value:category.id,label:category.name, children:categoryChildren}
              subLocationsChildren.push(categoryObj)
              category.agents.forEach(agent=> {
                let agentObj = {value:agent.agent_name, label:agent.agent_name, }
                categoryChildren.push(agentObj)
              })
            })
          })
        })
      }
      return filteredOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  flex-basis: 75%;
  h6 {
    font-size: 15px;
    margin-bottom: 10px;
    color: #555;
  }

  div {
    display: flex;
    align-items: center;
    line-height: 1;
    gap: 10px;

    p {
      margin: 0;
      font-weight: bold;
      color: #555;

    }
  }

}

</style>