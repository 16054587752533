import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {

        getCategories(payload){
            return requestSender('get', 'category/', payload);
        },
        getCategory(payload){
            return requestSender('get', 'category/get-category', payload);
        },
        getCategoryAgent(payload){
            return requestSender('get', 'category/get-category-agent', payload);
        },
        getCategoryClients(payload){
            return requestSender('get', 'category/get-category-clients', payload);
        },
        getCategoryEmployees(payload){
            return requestSender('get', 'category/get-category-employees', payload);
        },
        save(payload){
            return requestSender('post', 'category/save', payload);
        },


        getCategoryRecognizedCalls(payload){
            return requestSender('get', 'category/get-category-recognized-calls', payload);
        },


        getCategoryChecklists(payload){
            return requestSender('get', 'category/get-category-checklists', payload);
        },
        getCategoryChecklistByAgentName(payload){
            return requestSender('get', 'category/get-category-checklist-by-agent-name', payload);
        },
        getCategoryChecklistAnalytics(payload){
            return requestSender('get', 'category/get-category-checklist-analytics', payload);
        },
        saveCategoryChecklist(payload){
            return requestSender('post', 'category/save-category-checklist', payload);
        },
        deleteCategoryChecklists(payload){
            return requestSender('post', 'category/delete-category-checklist', payload);
        },




        getCategoryDictionaries(payload){
            return requestSender('get', 'category/get-category-dictionaries', payload);
        },

        getCategoryDictionaryFullData(payload){
            return requestSender('get', 'category/get-category-dictionary-full-data', payload);
        },
        getCategoryChecklistDictionaryFullData(payload){
            return requestSender('get', 'category/get-category-checklist-dictionary-full-data', payload);
        },
        saveCategoryDictionary(payload){
            return requestSender('post', 'category/save-category-dictionary', payload);
        },
        deleteCategoryDictionary(payload){
            return requestSender('post', 'category/delete-category-dictionary', payload);
        },




        delete(payload){
            return requestSender('post', 'category/delete', payload);
        },



        getCategoryCalls(payload){
            return requestSender('get', 'category/get-category-calls', payload);
        },
        getCategoryCall(payload){
            return requestSender('get', 'category/get-category-call', payload);
        },



    }
}