<template>
  <div class="category" v-loading="loadingLocations">
    <h6>Выберите сотрудника</h6>
    <el-cascader
        class="cascade"
        v-model="category_first"
        :options="filteredOptions"
        @change="handleChange"
        placeholder="Выбрать сотрудников"
        :props="{
              multiple:false
            }"
        clearable
        filterable
    >
    </el-cascader>
  </div>

</template>

<script>


import login from "@/views/auth/Login.vue";

export default {
  name: "custom-cascader-category-back",
  props: {
    multipart: {
      default: true
    },
    propsGetSelectedCategoryAndEmployee:{},
    categoryName: {},
    agentName: {}


  },
  data() {
    return {
      category_first: null,
      category_two: null,
      loadingLocations: true,
      locationsData: null,
      optionsCategoryTwo: [{value: 0, label: '1'}, {value: 1, label: '2'}],
      optionsCategoryFirst: [{value: 0, label: '1'}, {value: 1, label: '2'}],
      options: [
        {
          label: 'locations',
          value: '1',
          children: [
            {
              label: 'sublocations',
              value: 2,
              children: [
                {
                  label: 'categoryies',
                  value: '1',
                  children: [
                    {
                      label: 'agents',
                      value: '1',
                    }
                  ]
                }
              ]
            }
          ]
        },
      ]
    }
  },
  mounted() {
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.loading = true;
      this.$api.location.getAllWithAgents()
          .then(data => {
            this.locationsData = data
          })
          .finally(() => {
            this.loadingLocations = false;
          })
    },
    handleChange(value) {
      let filteredCategory = []
      value.forEach(el => {
        if (el[2]) {
          filteredCategory.push([el[2], el[3]])
        }
      })
      this.propsGetSelectedCategoryAndEmployee({
        category:value[2],
        employee:value[3],
        categoryName: this.getCategoryName(value)
      })
    },
    getCategoryName(value) {
     const location = this.locationsData.find(item => item.id === value[0])
     const subLocation = location.sub_locations.find(item => item.id === value[1])
     const category = subLocation.categories.find(item => item.id === value[2])
      console.log(category.name)
      return category.name
    }
  },
  computed: {
    filteredOptions() {
      let filteredOptions = []
      if (this.locationsData) {
        this.locationsData.forEach(location => {
          let locationChildren = []
          let locationObj = {value: location.id, label: location.name, children: locationChildren}
          filteredOptions.push(locationObj)
          location.sub_locations.forEach(subLocation => {
            let subLocationsChildren = []
            let subLocationsObj = {value: subLocation.id, label: subLocation.name, children: subLocationsChildren}
            locationChildren.push(subLocationsObj)
            subLocation.categories.forEach(category => {
              let categoryChildren = []
              let categoryObj = {value: category.id, label: category.name, children: categoryChildren}
              subLocationsChildren.push(categoryObj)
              category.agents.forEach(agent => {
                let agentObj = {value: agent.agent_name, label: agent.agent_name,}
                categoryChildren.push(agentObj)
              })
            })
          })
        })
      }
      return filteredOptions
    }
  }
}
</script>

<style lang="scss" scoped>
h6 {
  font-size: 15px;
  margin-bottom: 0;
  color: #555;
}

::v-deep .el-input__inner {
  height: 30px;
}

::v-deep .el-input__icon {
  display: flex;
  align-items: center;
}

::v-deep .el-range-separator {
  display: flex;
  align-items: center;
}

::v-deep .el-range-input {
  width: 79%;
}

::v-deep .el-cascader {
  min-width: 250px;
  width: 100%;
}

::v-deep {
  .el-select {
    width: 100%;
  }
}

</style>