<template>
  <li
      :style="{order: -count}"
  >
    <div>
      <el-tooltip
          v-if="propsDictData.dictionary.reverse"
          effect="dark"
          content="Реверсивный"
          placement="right-start"
      >
        <i class="el-icon-sort"></i>
      </el-tooltip>

      <i
          class="dictionary-color" v-if="propsDictData.positive_weight"
          :style="{background:propsDictData.color_success}">
        <span v-if="propsDictData.positive_weight">+{{ propsDictData.positive_weight }}</span>
      </i>
      <i
          class="dictionary-color"
          v-if="propsDictData.negative_weight"
          :style="{background:propsDictData.color_reject}"
      >
        <span v-if="propsDictData.negative_weight">-{{ propsDictData.negative_weight }}</span>
      </i>
      <i
          class="dictionary-color"
          v-if="!propsDictData.negative_weight && !propsDictData.positive_weight"
          :style="{background:'gray'}"
      >
        <span :style="{color:'#fff', fontSize:'10px',lineHeight:1.7}">
          {{ propsDictData.positive_weight }}/{{ propsDictData.negative_weight }}
        </span>
      </i>
      <span>
            {{ propsDictData.dictionary ? propsDictData.dictionary.name : 'нет данных' }}
      </span>
    </div>
        <span
            v-loading="loading"
            class="show-call-dialog"
            @click="showCallDialog(propsDictData,'dictionary')"
        >
                {{ count }}
        </span>
  </li>
</template>

<script>
export default {
  name: "category-dictionaries-new-item",
  props: {
    propsDictData: {},
    propsDate:{},
    propsShowCategoryCallDialog:{type:Function}
  },
  data() {
    return {
      count:null,
      loading:true
    }
  },
  mounted() {
    this.getCategoryDictionaryFullData()
  },

  methods: {
    showCallDialog(data, type){
      console.log(data)
      console.log(type)
      this.propsShowCategoryCallDialog(data,type)

    },
    getCategoryDictionaryFullData() {
      this.loading=true
      this.$api.category.getCategoryDictionaryFullData({
        category_id: this.$route.params.category_id,
        dictionary_id: this.propsDictData.dictionary.id,
        // category_dictionary_id: dictionary.id,
        dictionary_type:this.propsDictData.dictionary_type,
        date_start:this.propsDate?this.propsDate.start:"" ,
        date_end: this.propsDate?this.propsDate.end:"",
      }).then(data => {
        this.loading=false
        console.log(data.dictionary.found_count_by_period.all)
        this.count = data.dictionary.found_count_by_period.all
      })
    },
  },
  watch:{
    propsDate(){
      this.getCategoryDictionaryFullData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/project-variables";
li {

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px 0 10px;
  position: relative;

  .show-call-dialog {
    color: $--color-primary;
    cursor: pointer;
    font-size: 11px;
  }

  .el-icon-sort {
    position: absolute;
    top: 5px;
    left: -10px;
    transform: rotate(90deg);
    color: red;
    font-weight: 450;
  }


  span {
    font-weight: normal;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #555;
    line-height: 1.3;
    font-size: 12px;
    font-family: inherit;
  }

  > span:last-child {
    font-weight: normal;
  }

  .dictionary-color {

    width: 35px;
    height: 15px;
    background: gray;
    border-radius: 4px;
    display: flex;
    justify-content: center;

    span {
      text-align: center;
      justify-content: center;
      color: #fff;
      font-style: normal;
    }
  }

  ::v-deep .category-calls .popover-button {
    padding: 0 2px;
  }
}

</style>