import Vue from 'vue'
import VueRouter from 'vue-router'
import Rbac from '../views/Rbac'
import Organization from '../views/Organization'
import User from '../views/User'
import EditOrganization from '../views/EditOrganization'
import Location from '../views/Locations'
import Category from '../views/Category'

import DictionaryAndChecklist from '../views/DictionaryAndChecklist'
import DictionariesGroup from '../views/DictionariesGroup.vue'
import Question from '../views/Question'

import Statistics from '../views/Statistics'

// management organization
import Create from "@/components/management/Create";
import Edit from "@/components/management/Edit";

// end management organization
import Login from "../views/auth/Login";
import Restore from "@/views/auth/Restore";
import RestoreStepTwo from "@/views/auth/RestoreStepTwo";


import NotFound from "@/views/404";

import NewCategories from "@/views/NewCategories.vue";
import NewLocations from "@/views/NewLocations.vue";
import Search from "@/views/Search.vue";
import UploadCalls from "@/views/UploadCalls.vue";
import NewLogin from "@/views/auth/NewLogin.vue";
import analytics from "@/views/Analytics.vue";
import basicCategoryAnalysis from "@/views/BasicCategoryAnalysis.vue";
import basicEmployeeAnalysis from "@/views/BasicEmployeeAnalysis.vue";
import ComparativeAnalysisEmployee from "@/views/ComparativeAnalysisEmployee.vue";
import Integrations from "@/views/Integrations.vue";
import employeeInteraction from "@/components/analytics/employeeInteraction/employeeInteraction.vue";
import employeeInteractionBack from "@/components/analytics/employeeInteractionBack/employeeInteractionBack.vue";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/new-locations',
    },

    {
        path: '/new-categories/:id(\\d+)/sub-location/:sub_location_id(\\d+)/category/:category_id(\\d+)',
        name: 'New Categories',
        component: NewCategories,
        meta: {title: 'NewCategories', requiresAuth: true}
    },
    {
        path: '/new-locations',
        name: 'New Locations',
        component: NewLocations,
        meta: {title: 'NewLocations', requiresAuth: true}
    },
    {
        path: '/upload-calls',
        name: 'upload-calls',
        component: UploadCalls,
        meta: {title: 'Загрузка звонков', requiresAuth: true}
    },
    {
        path: '/analytics',
        name: 'analytics',
        component: analytics,
        meta: {title: 'Аналитика звонков', requiresAuth: true}
    },
    {
        path: '/basic-category-analysis',
        name: 'basic category analysis',
        component: basicCategoryAnalysis,
        meta: {title: 'Сравнительный анализ категории', requiresAuth: true}
    },
    {
        path: '/basic-employee-analysis',
        name: 'basic employee analysis',
        component: basicEmployeeAnalysis,
        meta: {title: 'Анализ эффективности сотрудников', requiresAuth: true}
    },
    {
        path: '/employee-interaction',
        name: 'employee interaction',
        component: employeeInteraction,
        meta: {title: 'Анализ взаимодействия сотрудника', requiresAuth: true}
    },
    {
        path: '/employee-interaction-back',
        name: 'employee interaction back',
        component: employeeInteractionBack,
        meta: {title: 'Анализ взаимодействия сотрудника - back', requiresAuth: true}
    },
    {
        path: '/comparative-analysis-employee',
        name: 'ComparativeAnalysisEmployee',
        component: ComparativeAnalysisEmployee,
        meta: {title: 'Сравнительный анализ сотрудников', requiresAuth: true}
    },
    {
        path: '/rbac',
        name: 'Rbac',
        component: Rbac,
        meta: {title: 'Rbac', requiresAuth: true}
    },
    {
        path: '/organization/edit/:id(\\d+)',
        name: 'EditOrganization',
        component: EditOrganization,
        meta: {title: 'Редактирование организации', requiresAuth: true}
    },
    {
        path: '/management/create',
        name: 'Create',
        component: Create,
        meta: {title: 'Добавление организации', requiresAuth: true, layout: 'management-layout'}
        //meta: {title: 'Добавление организации', requiresAuth: true}
    },
    {
        path: '/management/edit',
        name: 'Edit',
        component: Edit,
        meta: {title: 'Редактирование организации', requiresAuth: true, layout: 'management-layout'}
    },
    {
        path: '/locations',
        name: 'Locations',
        component: Location,
        meta: {title: 'Локации/доп. Локации', requiresAuth: true},
    },
    {
        path: '/dictionaries-and-checklists',
        name: 'DictionaryAndChecklist',
        component: DictionaryAndChecklist,
        meta: {title: 'Словари / Чек-листы', requiresAuth: true},
    },
    {
        path: '/dictionaries-group/:id(\\d+)',
        name: 'DictionariesGroup',
        component: DictionariesGroup,
        meta: {title: 'Группа словарей', requiresAuth: true},
    },
    {
        path: '/search',
        name: 'Search',
        component: Search,
        meta: {title: 'Поиск', requiresAuth: true},
    },

    {
        path: '/location/:id(\\d+)/sub-location/:sub_location_id(\\d+)/category/:category_id(\\d+)',
        name: 'Category',
        component: Category,
        meta: {title: 'Категории', requiresAuth: true},
    },
    {
        path: '/organization/:organization(\\d+)',
        name: 'Organization',
        component: Organization,
        meta: {title: 'Организации', requiresAuth: true},
    },
    {
        path: '/user',
        name: 'User',
        component: User,
        meta: {title: 'Пользователи', requiresAuth: true}
    },
    // {
    //     path: '/category',
    //     name: 'Category',
    //     component: Category,
    //     meta: {title: 'Категории', requiresAuth: true},
    // },

    {
        path: '/question',
        name: 'Question',
        component: Question,
        meta: {title: 'Вопросы', requiresAuth: true},
    },

    {
        path: '/statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {title: 'Статистика', requiresAuth: true},
    },

    {
        path: '/login',
        name: 'Login',
        component: NewLogin,
        meta: {title: 'Логин', layout: 'empty-layout'}
    },
    // {
    //     path: '/new-login',
    //     name: 'NewLogin',
    //     component: NewLogin,
    //     meta: {title: 'New - Логин', layout: 'empty-layout'}
    // },
    {
        path: '/restore',
        name: 'Restore',
        component: Restore,
        meta: {title: 'Восстановление пароля', layout: 'empty-layout'}
    },
    {
        path: '/restore/:token([\\w\\-\\+\\=]+)',
        name: 'RestoreStepTwo',
        component: RestoreStepTwo,
        meta: {title: 'Восстановление пароля', layout: 'empty-layout'}
    },
    {
        path: '/integrations',
        name: 'Integrations',
        component: Integrations,
        meta: {title: 'Интеграции', }
    },

    { path: '/404', name: '404', component: NotFound },
    { path: '*', redirect: '/404' },

]


const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    base: process.env.NODE_ENV === 'production'
        // ? '/performance/'
        ? '/'
        : '/',
    routes
})



router.beforeEach((to, from, next) => {

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if(to.matched.some(r => r.meta.requiresAuth)) {

        if (localStorage.getItem('access_token') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        }
    }

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));


    next();
});

export default router
