import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        login(payload){
            return requestSender('post', 'auth/login', payload);
        },



        getAuthUser(){
            return requestSender('get', 'user/get-auth-user');
        },
        getProfile(payload){
            return requestSender('get', 'user/get-profile', payload);
        },
        saveProfile(payload){
            return requestSender('post', 'user/save-profile', payload);
        },
        saveNewPassword(payload){
            return requestSender('post', 'user/save-new-password', payload);
        },



        search(payload){
            return requestSender('get', 'user/search', payload);
        },
        save(payload){
            return requestSender('post', 'user/save', payload);
        },
        setActive(payload){
            return requestSender('post', 'user/set-active', payload);
        },
        delete(payload){
            return requestSender('post', 'user/delete', payload);
        },
        importFromFile(payload){
            return requestSender('post', 'user/import-users-from-file', payload,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
        },

    }
}