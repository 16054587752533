<template>
  <div class="calls">
    <el-dialog
        :visible.sync="dialogVisibleLocal"
        @close="close"
        width="85%"
        :close-on-press-escape="true"
        :close-on-click-modal="true"
        :title="type === 'checklist' ? 'Аудио - по чеклистам': 'Аудио - по словарям'"
    >
      <div
          class="calls-header"
      >
        <div>Кто ответил</div>
        <div>Тип звонка</div>
        <div>ID звонка</div>
        <div>Дата звонка</div>
        <div>Продолжительность</div>
        <div v-if="type === 'checklist'">Чек-лист набрал баллов</div>
        <div v-if="type === 'checklist'">Чек-лист выполнен на %</div>
      </div>

      <div class="calls-body" v-if="data">
        <div
            v-for="(datum, index) in data"
            :key="index + '-' + datum.id"
            @click="showDrawer(datum.id)"
            class="calls-body__call"
        >

            <div>{{datum.agent_name && datum.agent_name !== 'null' ? datum.agent_name : 'Не определено'}}</div>
            <div>{{datum.call_type === 'in' ? 'Входящий' : 'Исходящий'}}</div>
            <div>{{datum.id}}</div>
            <div>{{datum.date}}</div>
            <div>{{datum.duration}}</div>
            <div v-if="type === 'checklist'">{{datum.total_result}}</div>
            <div v-if="type === 'checklist'">{{datum.percentage}}</div>

        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "category-calls-dialog",
  props: {
    data: {},
    type: {
      type: String
    },
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisibleLocal: this.dialogVisible,
    }
  },
  mounted() {
  },
  watch: {
    dialogVisible() {
      this.dialogVisibleLocal = this.dialogVisible
    },
  },
  methods: {
    showDrawer(callId) {
      this.$emit('show-call-info-drawer', callId)
    },
    close() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
.calls {
  padding: 0 30px;
  box-sizing: border-box;
  text-align: center;
  &-header {
    display: grid;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -20px;
    gap: 20px;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    div {
      &:first-child {
        text-align: left;
      }
    }
  }
  &-body {
    height: 50vh;
    overflow-y: auto;
    &__call {
      display: grid;
      gap: 20px;
      align-items: center;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      transition: background 200ms linear;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      div {
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        padding: 5px 0;
        font-size: 11px;
        &:first-child {
          text-align: left;
        }
      }
      &:hover {
        background: #f5f7fd;
      }
    }
  }

  &-call {
    &__body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

      //height: 80vh;
      //overflow-y: auto;

    }


    ::v-deep .el-button {
      background: #000;
    }

    ::v-deep .el-statistic {
      .head {
        font-size: 12px;
      }
      .con {
        margin-bottom: 3px;
        padding: 0 10px;
        .number {
          font-size: 14px;
        }

      }
    }
  }

  .popover-button {
    color: #24387f;
    cursor:pointer;
    font-size: 12px;
    padding: 0 10px;
  }
}

</style>