<template>
  <el-row type="flex" justify="center" >
    <el-col
        class="location-form-container"
        :span="22"
    >
      <el-row>
        <el-row v-if="selectedSubLocation.id">
          <h4>Редактирование вл. локации {{selectedSubLocation.name}}</h4>
        </el-row>

        <el-row v-else>
          <h4>Добавление вл. локации</h4>
        </el-row>
        <el-row class="form-fields">
          <el-row>
            <el-col :span="6">Название</el-col>
            <el-col :span="18">
              <el-input
                  type="text"
                  placeholder="Введите название"
                  v-model="selectedSubLocation.name"
              ></el-input>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">Описание</el-col>
            <el-col :span="18">
              <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="Введите описание"
                  v-model="selectedSubLocation.description">
              </el-input>
            </el-col>
          </el-row>
        </el-row>


      </el-row>
      <el-row class="command-buttons">
        <el-button
            plain
            round
            @click="cancel"
        >
          Отмена
        </el-button>

        <el-button
            type="primary"
            round
            @click="save"
        >
          Сохранить
        </el-button>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "sub-location-form",
  props: {
    locationId: null,
    selectedSubLocation :{},
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      if(!this.selectedSubLocation.location_id) {
        this.selectedSubLocation.location_id = this.locationId;
      }
      this.$api.location.saveSubLocation( this.selectedSubLocation)
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Вл. локация сохранена',
            });
            this.$emit('update', this.locationId)
          })
          .finally(() => {
            this.loading = false;
          })
    },

  }
}
</script>

<style lang="scss" scoped>

  .location-form-container {
    h4 {
      text-align: center;
    }

    .form-fields {
      > .el-row {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        > .el-col {
          &:first-child {
            padding-right: 10px;
            text-align: right;
          }
        }
      }
    }

    .command-buttons {
      text-align: center;
      margin-bottom: 25px;
    }
  }

</style>