<template>
    <div class="item" v-if="checkListData.relevant">
        <div class="item-header">
            <h4>{{ checkListData.checklist.name }}</h4>
            <button type="button">
                <i class="el-icon-more"></i>
            </button>

        </div>
        <div class="item-body__progress">

            <my-progress
                    :percentage="getTheTotalNumberAndCompletedPoints.percentage"
            />
        </div>
        <div class="item-body">
            <ul class="item-body__positive-list">
                <li
                        v-for="positive_dict in checkListData.successDictArr"
                        @click="propsGetHighLightChecklist(positive_dict.dictionary.reverse
            ? false
            :positive_dict.dictionary.found[0])"
                >
                    <el-tooltip
                            class="item"
                            effect="light"
                            placement="top"

                    >
                        <template #content>
                            <div class="tool-tip-employee" v-if="positive_dict.dictionary.found[0]">
                                <div v-if="
                (propsCallReverse && positive_dict.dictionary.found[0].search_text_highlight_two)
                 || (!propsCallReverse &&positive_dict.dictionary.found[0].search_text_highlight_one)"
                                >Сотрудник:
                                    <i v-for="word in propsCallReverse
                     ? positive_dict.dictionary.found[0].search_text_highlight_two
                     : positive_dict.dictionary.found[0].search_text_highlight_one"
                                       v-html="word.join(',')"
                                    ></i>
                                </div>

                            </div>
                            <div class="revers-dict" v-else><span>Словарь реверсивный:</span>
                                <br/>{{ positive_dict.dictionary.description }}
                            </div>
                            <div class="tool-tip-client" v-if="positive_dict.dictionary.found[0]">
                                <div v-if="
                  (propsCallReverse && positive_dict.dictionary.found[0].search_text_highlight_one)
                 || (!propsCallReverse &&positive_dict.dictionary.found[0].search_text_highlight_two)"

                                >Клиент:
                                    <i v-for="word in propsCallReverse
                     ? positive_dict.dictionary.found[0].search_text_highlight_one
                     : positive_dict.dictionary.found[0].search_text_highlight_two"
                                       v-html="word.join(',')"
                                    ></i>
                                </div>

                            </div>
                        </template>
                        <span class="null-style">{{ positive_dict.dictionary.name }} <i
                                v-if="positive_dict.dictionary.reverse" class="el-icon-sort reverse-icon"/></span>
                    </el-tooltip>

                </li>
            </ul>

            <ul class="item-body__negative-list">
                <li v-for="negative in checkListData.negativeDictArr">{{ negative.dictionary.name }} <i
                        v-if="negative.dictionary.reverse" class="el-icon-sort reverse-icon"/></li>
            </ul>

        </div>
        <div class="item-footer">
            <div class="item-footer__performed">

        <span
                v-if="getTheTotalNumberAndCompletedPoints.percentage === 0"
                :style="{background:checkListData.color_reject}"
        >
          Не выполнен
        </span>

                <span
                        v-else-if="getTheTotalNumberAndCompletedPoints.percentage < 100"
                        :style="{background: 'orange'}"
                >
          Выполнен не полностью
        </span>

                <span
                        v-else
                        :style="{background:checkListData.color_success}"
                >
          Выполнено
        </span>

            </div>
            <div class="item-footer__content">
                <div class="points">
                    Необходимый результат для выполнения чек-листа: <span
                        style="font-weight: bold;"> {{ checkListData.limit_result }}</span>
                </div>
                <div class="points">
                    Набрано баллов: <span style="font-weight: bold">{{
                    getTheTotalNumberAndCompletedPoints.totalResult
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MyProgress from "@/components/UI/MyProgress.vue";

export default {
    name: "checkListItem",
    components: {MyProgress},
    props: {
        checkListData: {
            type: Object,
        },
        propsGetHighLightChecklist: {
            type: Function
        },
        propsCallReverse: {
            type: Number
        }
    },
    computed: {
        getTheTotalNumberAndCompletedPoints() {
            let successfully = {
                percentage: 0,
                status: false,
                totalResult: 0
            };
            this.checkListData.successDictArr.forEach((el) => {
                successfully.totalResult += el.positive_weight ? el.positive_weight : el.negative_weight
            })
            if (successfully.totalResult) {
                successfully.percentage = +(successfully.totalResult / this.checkListData.limit_result * 100).toFixed(2)
                if (successfully.percentage > 100) {
                    successfully.percentage = 100;
                }
            }
            if (successfully.totalResult > this.checkListData.limit_result) {
                successfully.status = true
            }
            return successfully;
        }
    },


}
</script>

<style lang="scss" scoped>
.item {
  border-radius: 5px;
  user-select: none;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0px #8080802e;

  .item-body__progress {
    padding: 0 10px 10px;
  }


  .item-header {
    padding: 10px 10px 0;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: #555;
      font-weight: 500;
      font-size: 13px;
    }

    button {
      cursor: pointer;
      padding: 5px;
      border: none;
      background: none;

      i {
        color: #bababa;
      }
    }
  }

  .item-body {
    padding: 0 10px;
    margin-bottom: 10px;
    max-height: 300px;
    min-height: 300px;
    overflow: auto;

    &__positive-list, &__negative-list {
      margin: 0 0 0px 20px;

      li {
        cursor: pointer;
        list-style: disc;
        margin: 0;
        color: #555;
        line-height: 1.5;
        font-size: 11px;

        &::marker {
          color: green;;
        }
      }
    }

    &__negative-list {
      padding: 0 10px 25px 0px;

      li {
        &::marker {
          color: red;;
        }
      }
    }

    &__progress {
      margin: 0px 0;
    }
  }

  .item-footer {
    position: relative;
    background: #ececec;

    &__performed {
      position: absolute;
      top: -10px;
      right: 10px;

      span {
        color: #fff;
        font-size: 12px;
        border-radius: 2px;
        padding: 5px 5px;
        background: gray;
      }
    }

    &__content {

      padding: 15px 20px 10px;

      .points {
        font-size: 11px;
        color: #8a8a8a;
      }

      .score {
        font-size: 11px;
        color: #8a8a8a;
      }
    }
  }
}

.null-style {
  background: inherit !important;
  box-shadow: none;
  border: none;
  padding: 3px 0 !important;
}

.revers-dict {
  max-width: 200px;
  color: #555;

  font-weight: normal;

  span {
    font-weight: bold;
  }
}

.reverse-icon {
  transform: rotate(90deg);
  color: red;
  line-height: 1;
}
</style>