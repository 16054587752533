<template>
  <div>
    <div class="btn-add-row">
      <button
          @click="()=>{
        showInputsState='addLocation'
        $emit('specific_event',{type:'add',variant:variant})
      }"
          v-if="!showInputsState && !propsInputsShow"
          type="button"
          class="btn-add"
      >
        <i class="el-icon-plus"></i>
      </button>
      <!--      <button-->
      <!--          @click="()=>{-->
      <!--        showInputsState='addLocation'-->
      <!--        $emit('specific_event',{type:'edit',variant:variant})-->
      <!--      }"-->
      <!--          v-if="!showInputsState && dataList"-->
      <!--          type="button"-->
      <!--      >-->
      <!--        <i class="el-icon-edit-outline"></i>-->
      <!--      </button>-->

      <el-popover
          v-if="!showInputsState  && !propsInputsShow && propsSetNameForDelete[variant]"
          placement="top"
          width="260"
          style="overflow: hidden !important;"
          v-model="deleteVisible">
        <p class="popoverTitle">Вы уверени что хотите удалить <span
            style="color:#c90000">{{ propsSetNameForDelete[variant] }}</span> и все звонки в ней?</p>
        <div style="text-align: center; margin: 0">
          <el-button class="popoverButton popoverButtonCancel " size="mini" type="text" @click="deleteVisible = false">
            Нет
          </el-button>
          <el-button class="popoverButton popoverButtonSuccess " type="primary" size="mini" @click="()=>{
              loggs()
             $emit('specific_event',{type:'delete',variant:variant})
            deleteVisible = false
          }">Да
          </el-button>
        </div>
        <el-button slot="reference">
          <i class="el-icon-delete"></i>
        </el-button>
      </el-popover>

    </div>
    <div v-if="showInputsState==='addLocation'" class="add-inputs">
      <h5>Добавить новую {{ editTitle() }}</h5>
      <label>
        <p>Название</p>
        <el-input size="small" placeholder="Введите название" v-model="inputNameState"></el-input>
      </label>
      <label>
        <p>Описание</p>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="Введите описание"
            resize="none"
            :autosize="true"
            v-model="inputDescriptionState">
        </el-input>
      </label>
      <div class="otherImputs" v-if="variant==='category'">
        <label>
          <p>Канал 1</p>
          <el-input
              type="textarea"
              :rows="1"
              placeholder="Название аудио канала 1"
              resize="none"
              :autosize="true"
              v-model="channelName1">
          </el-input>
        </label>
        <label>
          <p>Канал 2</p>
          <el-input
              type="textarea"
              :rows="1"
              placeholder="Название аудио канала 2"
              resize="none"
              :autosize="true"
              v-model="channelName2">
          </el-input>
        </label>
        <div class="weightBlock">
          <label>
            <p>Минимальный лимит</p>
            <el-input-number v-model="minWeight"  :step="1" :min="0" :max="100"></el-input-number>

          </label>
          <label>
            <p>Максимальный лимит</p>
            <el-input-number v-model="maxWeight"  :step="1" :min="0" :max="100"></el-input-number>
          </label>
        </div>

      </div>

      <div class="btn-add">
        <button @click="saveData" class="btn-save">Сохранить</button>
        <button @click="resetAllAdd" class="btn-close">отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'locations-inputs-control',
  props: {
    getLocations: {},
    propsInputsShow: {},
    propsChangeInputShow: {},
    propsSetNameForDelete: {},
    activeLocation: {},
    activeSubLocation: {},

    dataList: {},
    type: {
      type: String,
    },
    variant: {}
  },
  data() {
    return {
      deleteVisible: false,
      showInputsState: '',
      inputNameState: '',
      inputDescriptionState: '',
      channelName1: '',
      channelName2: '',
      maxWeight: null,
      minWeight: null
    }
  },
  methods: {
    loggs() {
      console.log(this.dataList)
    },
    saveData() {
      if (!this.inputNameState.length || !this.inputDescriptionState.length) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Поля Имя и Описание обязательны для заполнения',
        });
        if(this.variant==='category' && (!this.channelName1 || !this.channelName2 || !this.maxWeight || !this.minWeight)){
          this.$notify.error({
            duration: this.$constants.global.notifyDurationError,
            title: 'Ошибка',
            message: 'Вы заполнили не все поля',
          });
        }
        return;
      }
      switch (this.variant) {
        case 'location':
          return this.saveLocation();
        case 'sub-location':
          return this.saveSubLocation();
        case 'category':
          return this.saveCategory();
        default:
          return;
      }
    },
    editTitle() {
      if (this.variant === 'location') {
        return 'локацию'
      }
      if (this.variant === 'category') {
        return 'категорию'
      }
      if (this.variant === 'sub-location') {
        return 'под-локацию'
      }
    },
    resetAllAdd() {
      this.showInputsState = null
      this.inputNameState = null
      this.inputDescriptionState = null
      this.propsChangeInputShow()
    },
    saveLocation() {
      this.$api.location.save({description: this.inputDescriptionState, name: this.inputNameState})
          .then(data => {
            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Локация сохранена',
              });
              this.getLocations()
              this.resetAllAdd()

            } else {
              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors[0]
              });
            }

          })

    },
    saveSubLocation() {
      this.$api.location.saveSubLocation({
        description: this.inputDescriptionState,
        name: this.inputNameState,
        location_id: this.activeLocation
      }).then(data => {
        if (!data.errors) {
          this.$notify.success({
            duration: this.$constants.global.notifyDurationSuccess,
            title: 'Успешно',
            message: 'Под-локация сохранена',
          });
          this.getLocations()
          this.resetAllAdd()
        } else {
          this.$notify.error({
            duration: this.$constants.global.notifyDurationError,
            title: 'Ошибка',
            message: data.errors[0]
          });
        }
      })
    },
    saveCategory() {
      this.$api.category.save({
        description: this.inputDescriptionState,
        name: this.inputNameState,
        sub_location_id: String(this.activeSubLocation).replace('undefined-', ''),
        channel_one_name: "fsdfds",
        channel_two_name: "fdsfds",
        trigger_limit_max: "12",
        trigger_limit_min: "12",
      }).then(data => {
        if (!data.errors) {
          this.$notify.success({
            duration: this.$constants.global.notifyDurationSuccess,
            title: 'Успешно',
            message: 'Категория сохранена',
          });
          this.getLocations()
          this.resetAllAdd()
        } else {
          this.$notify.error({
            duration: this.$constants.global.notifyDurationError,
            title: 'Ошибка',
            message: data.errors[0]
          });
        }
      })
    }
  }


}
</script>

<style lang="scss" scoped>
.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn-add-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 3px;
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 0;


  li {
    cursor: pointer;
    padding: 8px 7px;
    border: solid 1px #bebebe;
    line-height: 1;
    border-radius: 10px;
    transition: .3s;

    &:hover {
      border: solid 1px #24387f;
    }

    &.active {
      color: #fff;
      background: #24387f;
    }
  }
}

div {
  button {
    margin-top: 10px;
    cursor: pointer;
    background: #fff;
    border: solid 1px #24387f;
    border-radius: 50%;
    padding: 7px;
    height: 35px;
    width: 35px;

    i {
      color: #24387f;
      font-size: 16px;
      line-height: 1;
      font-weight: bold;
    }
  }

  .btn-save {
    margin-top: 20px;
    border-radius: 10px;
    font-size: 12px;
    width: 110px;
    transition: .3s;

    &:hover {
      background: #24387f;
      color: #fff;
    }
  }

  .btn-close {
    border: none;
    font-size: 10px;
    padding: 0;
    width: 40px;
    height: 20px;
    margin: 0;
    color: grey;
    transition: .3s;

    &:hover {
      color: red;
    }
  }
}

.add-inputs {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: solid 1px black;
  border-right: none;
  border-left: none;
  padding: 10px 0;

  h5 {
    margin-bottom: 5px;
    text-align: center;
  }

  label {
    width: 100%;

    p {
      margin-bottom: 0;
      color: #555;
    }
  }

  input {
    width: 100%;
    font-family: inherit;
  }
}

.weightBlock{
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>
