<template>
  <div :class="'progress-container ' + uniqueClass">
    <div class="progress">
      <div class="progress__value">
        <div class="percent">{{percent}}%</div>
        <div class="text" v-if="text">{{text}}</div>
      </div>
      <div class="center-circle"></div>
    </div>
  </div>
</template>

<script>
export default {

  name: "circular-progress-bar",
  props: {
    size: {
      type: Number,
      default:0
    },
    color: {
      type: String,
      default: 'lightgreen'
    },
    speed: {
      type: Number,
      default: 15
    },
    percent: {
      type: Number,
      default: 10
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      uniqueClass: 'r' +  '_' + Math.random().toString(36).substr(2, 9)+(Date.now()),
    }
  },
  created() {

  },
  mounted() {
    this.init()
  },

  methods: {
    init() {

      let progressContainer = document.querySelector('.progress-container.'+this.uniqueClass),
          containerWidth = progressContainer.offsetWidth,
          centerCircle = progressContainer.querySelector('.center-circle'),
          progress = progressContainer.querySelector('.progress'),
          valuesContainer = progressContainer.querySelector('.progress__value'),
          valuePercent = progressContainer.querySelector('.percent'),
          startValue = 0,
          endValue = this.percent;

      progress.style.width = `${containerWidth}px`
      progress.style.height = `${containerWidth}px`
      centerCircle.style.width = `${containerWidth - 15}px`
      centerCircle.style.height = `${containerWidth - 15}px`
      valuesContainer.style.fontSize = `${containerWidth/5}px`

      let progressInterval = setInterval(() => {
        startValue++;
        valuesContainer.style.color = this.color;
        valuePercent.textContent = `${startValue}%`;
        progress.style.background = `conic-gradient(${this.color} ${startValue * 3.6}deg, #ddd 0deg)`;

        if(startValue === endValue) {
          clearInterval(progressInterval);
        }
      }, this.speed)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";
  .progress-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .progress {
      position: relative;
      height: 200px;
      width: 200px;
      background: conic-gradient($--color-danger 300deg, #ddd 0deg);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .center-circle {
        position: absolute;
        height: 180px;
        width: 180px;
        border-radius:50%;
        background: #ffffff;
        transition: all 0.1s ease-out;
      }
      &__value {
        position: relative;
        font-size: 1em;
        display: flex;
        font-weight: 600;
        transition: all 0.1s ease-out;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 7;
        line-height: .8;
      }
      &:hover {
        .progress {
          &__value {
            transform: scale(1.1);
          }

        }
        .center-circle {
          transform: scale(1.05);
        }
      }
    }
  }
</style>