import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        get(payload){
            return requestSender('get', 'question/', payload);
        },
        save(payload){
            return requestSender('post', 'question/save', payload);
        },
        delete(payload){
            return requestSender('post', 'question/delete', payload);
        },

    }
}