<template>
  <el-row type="flex" justify="center" >
    <el-col 
        class="question-form-container"
        :span="22"
    >
      <el-row>
        <el-row v-if="selectedQuestion.id">
          <h4>Редактирование вопроса {{selectedQuestion.name}}</h4>
        </el-row>
        <el-row v-else>
          <h4>Добавление вопроса</h4>
        </el-row>

        <el-row class="form-fields">
          <el-row>
            <el-col :span="6">Название</el-col>
            <el-col :span="18">
              <el-input
                  type="text"
                  placeholder="Введите название"
                  v-model="selectedQuestion.name"
              ></el-input>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">Описание</el-col>
            <el-col :span="18">
              <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="Введите описание"
                  v-model="selectedQuestion.description">
              </el-input>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">Запрос</el-col>
            <el-col :span="18">
              <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="Введите запрос"
                  v-model="selectedQuestion.query">
              </el-input>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="6">Реверс</el-col>
            <el-col :span="18" style="text-align: left;">
              <el-switch
                  v-model="selectedQuestion.reverse"
                  :active-value="1"
                  :inactive-value="0"></el-switch>
            </el-col>
          </el-row>



        </el-row>


      </el-row>

      <el-row class="command-buttons">
        <el-button
            plain
            round
            @click="cancel"
        >
          Отмена
        </el-button>

        <el-button
            type="primary"
            round
            @click="save"
        >
          Сохранитьi
        </el-button>
      </el-row>


    </el-col>


  </el-row>
</template>

<script>
export default {
  name: "question-form",
  props: {
    selectedQuestion :{},
    selectedQuestionType :null,
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.$emit('save')
    }
  }
}
</script>

<style lang="scss" scoped>

  .question-form-container {
    h4 {
      text-align: center;
    }
    .form-fields {
      > .el-row {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        > .el-col {
          &:first-child{
            padding-right: 10px;
            text-align: right;
          }
        }
      }

    }
    .command-buttons {
      text-align: center;
      margin-bottom: 25px;
    }
  }

</style>