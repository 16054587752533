<template>
  <el-row class="search">
    <el-row class="search-form">
      <h2>Поиск по критериям</h2>
        <div class="general">
            <el-form class="queries">
                <el-form-item label="Запрос 1">
                    <el-input type="textarea" v-model="query1"/>
                </el-form-item>
                <el-form-item label="Запрос 2">
                    <el-input type="textarea" v-model="query2"/>
                </el-form-item>
            </el-form>
            <el-form>
                <el-form-item label="Поиск в столбцах">
                    <el-cascader
                            :options="fields"
                            :props="{
                              multiple:true
                            }"

                            clearable
                            @change="changeFields"
                    >
                    </el-cascader>
                </el-form-item>
                <el-form-item label="Поиск в локациях">
                    <el-cascader
                            :options="locations"
                            :props="{
                               multiple:true
                            }"

                            clearable
                            @change="changeLocations"
                    >
                    </el-cascader>
                </el-form-item>
                <el-form-item label="Лимит звонков">
                    <el-input-number v-model="limit"></el-input-number>
                </el-form-item>
                <el-row style="text-align: center">
                    <el-button type="primary" @click="getDataBySearchForm" :disabled="loading">{{loading ? 'Анализирую': 'Сформировать'}}</el-button>
                    <el-button type="cancel">Oтмена</el-button>
                </el-row>
            </el-form>

        </div>
    </el-row>

      <div class="results">
          <el-row class="results1">
              <h4 >Найдено в {{searchResult.defaultLength1}}  звонке(ах)</h4>
              <!--    <h4 v-if="searchResult.length">Найдено в {{searchResult.length}}  звонке(ах)</h4>-->

              <el-collapse
                      v-if="searchResult.result1.length"
                      v-model="activeNames"
              >
                  <el-collapse-item
                          v-for="(res,idx) in searchResult.result1"
                          :key="res.call_id + '-'+ idx"
                          :name="res.call_id"
                          :disabled="!res.call_id"
                  >
                      <template slot="title">
                          <span v-if="res.call_id"> Информация о звонке: {{res.call_id}} ({{res.id_at_the_client}})</span>
                          <span v-else style="color: red"> В звонке {{res.searchedBy}} не найдено</span>
                      </template>
                      <div class="highlight">
                          <div>
                              <h4>В первом канале</h4>
                              <div
                                      v-for="(one, index) in res.highlight.text_channel_1"
                                      :key="index + 'one'"
                                      v-html="index+1 + '. '+ one"
                              >
                              </div>
                          </div>
                          <div>
                              <h4>Во втором канале </h4>
                              <div
                                      v-for="(two, index) in res.highlight.text_channel_2"
                                      :key="index + 'two'"
                                      v-html="index+1 + '. '+ two"
                              >
                              </div>
                          </div>
                          <div>
                              <h4>В полном тексте</h4>
                              <div
                                      v-for="(full, index) in res.highlight.json_text"
                                      :key="index + 'full'"
                                      v-html="index+1 + '. '+ full"
                              >
                              </div>
                          </div>
                      </div>

                  </el-collapse-item>
              </el-collapse>
          </el-row>
          <el-row class="results2">
              <h4 >Найдено в {{searchResult.defaultLength2}}  звонке(ах)</h4>
              <!--    <h4 v-if="searchResult.length">Найдено в {{searchResult.length}}  звонке(ах)</h4>-->

              <el-collapse
                      v-if="searchResult.result2.length"
                      v-model="activeNames"
              >
                  <el-collapse-item
                          v-for="(res, idx) in searchResult.result2"
                          :key="res.call_id  + '-'+ idx"
                          :name="res.call_id"
                          :disabled="!res.call_id"
                  >
                      <template slot="title">
                          <span v-if="res.call_id"> Информация о звонке: {{res.call_id}} ({{res.id_at_the_client}})</span>
                          <span v-else style="color: red"> В звонке {{res.searchedBy}} не найдено</span>

                      </template>
                      <div class="highlight">
                          <div>
                              <h4>В первом канале</h4>
                              <div
                                      v-for="(one, index) in res.highlight.text_channel_1"
                                      :key="index + 'one'"
                                      v-html="index+1 + '. '+ one"
                              >
                              </div>
                          </div>
                          <div>
                              <h4>Во втором канале </h4>
                              <div
                                      v-for="(two, index) in res.highlight.text_channel_2"
                                      :key="index + 'two'"
                                      v-html="index+1 + '. '+ two"
                              >
                              </div>
                          </div>
                          <div>
                              <h4>В полном тексте</h4>
                              <div
                                      v-for="(full, index) in res.highlight.json_text"
                                      :key="index + 'full'"
                                      v-html="index+1 + '. '+ full"
                              >
                              </div>
                          </div>
                      </div>

                  </el-collapse-item>
              </el-collapse>
          </el-row>
      </div>


  </el-row>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      loading: false,
      locations: [],
      query1: '',
      query2: '',
      fields: [
        {value: 'json_text', label: 'Во всем тексте'},
        {value: 'text_channel_1', label: 'В 1-м канале'},
        {value: 'text_channel_2', label: 'Во 2-м канале'},
      ],
      limit: 1,
      searchResult: {
          result1: [],
          result2: [],
          defaultLength1: 0,
          defaultLength2 : 0,
      },
      activeNames: [],
      selectedFields: [],
      selectedLocations: [],
      selectedSubLocations: [],
      selectedCategories: [],
    }
  },
  mounted() {
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.$api.location.get()
          .then(data => {
            this.locations = this.prepareLocationsData(data);
          })
    },
    prepareLocationsData(data) {
      return data.map(location => {
        return {
          value: location.id,
          label: location.name,
          children: location.sub_locations.map(subLocation => {
            return {
              value: subLocation.id,
              label: subLocation.name,
              children: subLocation.categories.map(category => {
                return {value: category.id, label: category.name}
              })
            }
          })
        }
      })
    },
    changeFields(values) {
      if (values) {
        this.selectedFields = values.map(item => item[0])
      }
    },
    changeLocations(values) {
      if (values) {
        this.selectedLocations = values.map(item => item[0])
        this.selectedLocations = [...new Set(this.selectedLocations)];
        this.selectedSubLocations = values.map(item => item[1])
        this.selectedCategories = values.map(item => item[2])
      }

    },
    getDataBySearchForm() {
      if (!this.query1 && !this.query2) {
          return this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Мммм...',
          message: "А где запрос?"
        });
      }

      this.loading = true;

      this.$api.management.getSearchData({
        query1: this.query1,
        query2: this.query2,
        fields: this.selectedFields,
        locations: [...new Set(this.selectedLocations)],
        sub_locations: this.selectedSubLocations,
        categories: this.selectedCategories,
        limit: this.limit,
      })
          .then(data => {
              this.searchResult.defaultLength1 = data.result1.length;
              this.searchResult.defaultLength2 = data.result2.length;
              this.prepareResultData(data)

          })
          .catch(e => {

          })
          .finally(() => {
              this.loading = false;
          })
    },
      prepareResultData(data) {
          if(data.result1.length >= data.result2.length) {
              this.searchResult.result1 = data.result1;
              this.searchResult.result2 = this.checkingResultData(data.result1, data.result2)
          }else {
              this.searchResult.result2 = data.result2;
              this.searchResult.result1 = this.checkingResultData(data.result2, data.result1)
          }
      },
      checkingResultData (result1, result2) {
          return result1.map(item => {

              let desired = result2.find(needleItem => needleItem.call_id === item.call_id)

              if(!desired) {
                  return {
                      call_id: null,
                      highlight: [],
                      id_at_the_client: null,
                      searchedBy: item.call_id
                  }
              }
              return desired;
          })
      }
  }
}
</script>

<style lang="scss" scoped>
.search {
  .general {
    width: 100%;
    background: #fff;
    text-align: center;
    .el-form {
      width: 50%;
      margin: 0 auto;
    }
    .queries {
      width: 100%;
      display: flex;
    }
  }
  .forms {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    ::v-deep .el-form {
      padding: 20px;
      background: #fff;
      width: 100%;

      &-item {
        display: flex;
        width: 100%;

        &__label {
          width: 30%;
        }

        &__content {
          width: 70%;

          > * {
            width: 100%;
          }
        }
      }
    }
  }
  .results {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .results1, .results2 {
      margin-top: 25px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
    h4 {
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    ::v-deep .el-collapse {
      width: calc(100% - 50px);
      &-item {
        &__header {
          color: #555;
          padding-left: 10px;
          font-size: 14px;
        }
        &__content {
          color: #555;
          font-weight: 300;
          font-size: 13px;
        }
        .highlight {
            text-align: left;
          h4 {
            margin-bottom: 10px;
            margin-top: 10px;
            color: #555;
            font-size: 14px;
            text-align: left;
          }
          //display: grid;
          //grid-template-columns: 1fr 1fr 1fr;
          padding: 10px;
          b {
            color: red;
            font-size: 12px;
          }
          & div {
            border-bottom: 1px solid #ddd;
          }
        }

      }

    }
  }
}

</style>