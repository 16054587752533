<template>
  <el-row style="z-index: 99999;" v-loading="loading">
    <el-row class="categories">
      <h1>Вл. локация: {{ subLocation.name }}</h1>
      <el-row class="page-command-buttons">
        <el-popover
            class="location-popover"
            placement="right"
            width="200"
            trigger="click"
        >
          <el-row>
            <p>
              <i
                  class="el-icon-edit"
                  @click="showSubLocationForm(locationId)">Редактировать</i>
            </p>
          </el-row>
          <el-row>
            <p>
              <el-popconfirm
                  @confirm="deleteSubLocation(subLocation.id)"
                  title="Вы уверены?"
              >

                <i slot="reference" class="el-icon-delete">Удалить</i>

              </el-popconfirm>
            </p>

          </el-row>
          <i class="el-icon-more-outline" slot="reference"></i>
        </el-popover>
        <i
            class="el-icon-refresh"
            @click="updateLocation(locationId)"
        ></i>
      </el-row>
      <el-row type="flex" class="general-info">
        <el-col :span="6" class="dictionaries">

          <el-row>
            <h4>Категории</h4>
            <i
                class="el-icon-circle-plus-outline"
                @click="showCategoryForm()"
            ></i>
          </el-row>

          <h5>Выберите категорию:</h5>

            <el-row v-if="subLocation.categories">
              <el-row
                  v-for="category in subLocation.categories"
                  :key="category.id"
              >
                <el-row class="category">
                  <i
                      class="el-icon-edit"
                      @click="showCategoryForm(category.id)"
                  ></i>
                  <router-link
                      @click.native="updateStoreVariables({ category: category })"
                      :to="'/location/'
                      + subLocation.location_id
                      + '/sub-location/'
                      + subLocation.id
                      + '/category/'
                      + category.id"
                  >
                    {{ category.name }}
                  </router-link>
                </el-row>
              </el-row>
            </el-row>

          <template>
            <el-drawer
                class="category-drawer"
                :visible.sync="visibleCategoryFormDrawer"
                direction="rtl"
                size="40%"
            >
              <category-form

                  :selected-category="selectedCategory"
                  :sub-location-id="subLocation.id"
                  @save-category="save"
                  @cancel="visibleCategoryFormDrawer = false"
              >
              </category-form>
            </el-drawer>
          </template>
        </el-col>

        <el-col :span="4">
          <customer-geography/>
        </el-col>
        <el-col :span="3">
          <report/>
        </el-col>
        <el-col :span="12">
          <about-audio/>
        </el-col>
      </el-row>
      <el-row class="trend">
        <el-row type="flex">
          <el-col :span="14">
            <h2>Тенденции</h2>
          </el-col>
          <el-col :span="11">
            <el-row type="flex" justify="end" class="trend__description">
              <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="по"
                  start-placeholder="Начало"
                  end-placeholder="Окончание"
                  :picker-options="dateRangeOptions">
              </el-date-picker>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <trend-chart
              :data="chartData"
              :dates="categories"
              :detail="false"
          />
        </el-row>
      </el-row>
      <el-row>
      </el-row>
    </el-row>
    <template>
      <el-drawer
          :visible.sync="visibleSubLocationFormDrawer"
          direction="rtl"
          size="40%"
      >
        <sub-location-form
            :selected-sub-location="subLocation"
            :location-id="locationId"
            @cancel="visibleSubLocationFormDrawer = false"
            @update="updateLocation"
        >
        </sub-location-form>
      </el-drawer>
    </template>
  </el-row>

</template>

<script>

import SystemDictionary from "@/components/dictionary/SystemDictionary";
import ClientDictionary from "@/components/dictionary/ClientDictionary";
import DictionaryForm from "@/components/dictionary/DictionaryForm";

import SubLocationForm from "@/components/location/SubLocationForm";
import CategoryForm from "@/components/category/CategoryForm";
import CustomerGeography from "@/components/CustomerGeography";
import Report from "@/components/Report";
import AboutAudio from "@/components/AboutAudio";
import TrendChart from "@/components/TrendChart";
import {getDaysArray} from "@/utils/helpers";
import {mapMutations} from "vuex";
export default {
  name: "sub-location",
  components: {SubLocationForm, SystemDictionary, ClientDictionary, DictionaryForm,  CategoryForm, CustomerGeography, Report, AboutAudio, TrendChart},
  props: {
    locationId: {},
    subLocation: {}
  },
  data() {
    return {
      loading: false,
      visibleCategoryDrawer: false,
      visibleCategoryFormDrawer: false,
      visibleSubLocationFormDrawer:false,
      selectedCategory: {},

      dateRange: '',
      dateRangeOptions: {
        shortcuts: [{
          text: 'Пред. неделя',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Пред.месяц',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'За 3 месяца',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

      days: 15,
      chartData: [{
        name: 'Всего аудио',
        data: [44, 55, 57, 56, 61, 58, 63, 60, 120, 123, 61, 58, 63, 60, 43],
        color: '#642cf7',
      }, {
        name: 'К обработке',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 44, 61, 58, 63, 60, 54],
        color: '#f6d080'
      }, {
        name: 'Негативные',
        data:[35, 41, 36, 26, 45, 48, 52, 53, 41,65, 61, 58, 63, 165, 67],
        color: '#75d688'
      },{
        name: 'Позитивные',
        data: [35, 41, 36, 26, 45, 44, 55, 57, 56, 61, 61, 58, 63, 60, 32],
        color: '#5583f8'
      }],
      categories: [],
    }
  },
  created() {

  },
  mounted() {
    this.getCategories()
  },
  methods: {
    ...mapMutations(['updateCategory']),
    updateStoreVariables(obj) {
      this.updateCategory(obj.category)
    },
    getCategories() {
      this.loading = true;
      console.log(this.subLocation)
      this.$api.category.getCategories({sub_location_id: this.subLocation.id})
          .then(data => {
            this.subLocation.categories = data
          })
          .finally(() => {
            this.loading = false;
          })
    },

    showSubLocationForm() {
      this.visibleSubLocationFormDrawer = true;
    },

    updateLocation() {
      this.visibleSubLocationFormDrawer = false;
      this.$emit('update', this.locationId)
    },
    showCategoryForm(id = null) {
      if (id) {
        this.selectedCategory = this.subLocation.categories.find(category => category.id === id)
      } else {
        this.selectedCategory = {};
      }
      this.visibleCategoryFormDrawer = true;
    },
    save() {
      this.loading = true;
      if (!this.selectedCategory.name || !this.selectedCategory.description) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Поля Имя и Описание обязательны для заполнения',
        });
        return;
      }

      this.selectedCategory.sub_location_id = this.subLocation.id;

      this.$api.category.save(this.selectedCategory)
          .then(data => {

            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Категория сохранена',
              });

            } else {

              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors[0]
              });

            }

          })
          .finally(() => {
            this.getCategories();
            this.visibleCategoryFormDrawer = false;
          })
    },
    deleteSubLocation(id) {
      this.loading = true;
      this.$api.location.delete({id: id})
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Вл. локация удалена',
            });
            this.getLocations()
          })
          .finally(() => {
            this.loading = false;
          })
    },

  }
}
</script>


<style>
.v-modal {
  z-index: 2000!Important;
}
</style>


<style lang="scss" scoped>
@import "./src/assets/css/project-variables";
.categories {
  background: #e5e5e5;
  padding:  0 30px;
  .empty {
    font-size: 14px;
    color: indianred;
  }
  .category {
    a {
      display: inline-block;
      color: $--color-text-primary;
      margin-bottom: 10px;
    }
  }
  h1 {
    margin-left: 10px;
  }
  margin: 0;
  h4 {
    margin-bottom: 10px;
    font-size: 19px;
  }
  h5 {
    color: $--color-danger;
    margin-bottom: 10px;
  }
  .general-info {
    .el-col {
      background: #fff;
      padding: 30px;
      border-radius: 5px;
      margin: 10px;
      box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;
    }
  }
  .trend {
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;
    &__description {
      font-size: 14px;
      color: #b5b5b5;
      i {
        font-size: 27px;
        color: #000;
        cursor: pointer;
        position: absolute;
        right: 0;
      }
      .el-col {
        > span {
          &:nth-child(odd) {
            color: #000;
            font-weight: 900;
          }
          &:nth-child(even) {
            font-size: 16px;
          }
          &.color {
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            top: 5px;
            left: -15px;
          }
        }
      }
    }
  }
  i.el-icon-circle-plus-outline {
    position: absolute;
    top: 5px;
    right: 0;
    color: #b5b5b5;
    transition: all 0.1s ease-out;
    font-weight: bold;
    font-size: 16px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .dictionaries, .checklists {
    p {
      color: #b5b5b5;
      margin-bottom: 10px;
      margin-left: 15px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>