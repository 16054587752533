<template>
  <div class="list">
    <div class="list__header">

      <h5>Список звонков</h5>

      <my-button
          @click="uploadAll"
          class="btn"
      >
        Отправить все
      </my-button>
    </div>


    <ul v-if="files.length">
      <uploader-list-item
          :key="file.file.name"
          :file="file"
          v-for="file in files"
          @remove="$emit('remove', file)"
          @send="$emit('send',file)"
          :propsStateUploadsAllFile="propsStateUploadsAllFile"
      />
    </ul>

  </div>

</template>

<script>
import MyButton from "@/components/UI/MyButton.vue";
import UploaderListItem from "@/components/uploadCalls/uploaderList/uploaderListItem.vue";

export default {
  name: "uploader-list",
  components: {UploaderListItem, MyButton},
  props: {
    files: {
      type: Array
    },
    propsStateUploadsAllFile:{
      type:Boolean
    },
    propsListItemSendingCompleted: {
      type: Function
    }
  },
  data() {
    return {}
  },

  methods: {
    uploadAll() {
      this.$emit('sendAll')
    }
  },

  computed: {}
}
</script>

<style lang="scss" scoped>

.list {
  overflow: auto;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .btn {
      height: 26px;
      font-size: 12px;
      padding: 2px 10px;
      margin: 0 2px;
      font-weight: 400;
    }
  }

  h5 {
    margin: 0 0 10px;
    color: #555;
  }
}

</style>