<template>
  <div class="integrations" v-if="!loading">
    <div class="integrations__title">
      <h3>Интеграции</h3>
    </div>
    <el-tabs v-model="integrations" @tab-click="handleClick">
      <el-tab-pane label="ВАТС" name="ВАТС">
        <div class="vats">
          <el-tabs v-model="vatsTabs" @tab-click="handleClick">
            <el-tab-pane :disabled="!this.$store.getters.vatsConfig" label="управление" name="controls">
              <div class="controls">
                <VatsControl/>
              </div>
            </el-tab-pane>
            <el-tab-pane label="настройки" name="setting">
              <div class="setting">
                <VatsConfig :propsVatsConfig="vatsConfig"/>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import VatsConfig from "@/components/integrations/vats/VatsConfig.vue";
import VatsControl from "../components/integrations/vats/VatsControl.vue";

export default {
  name: "Integrations",
  components: {VatsControl, VatsConfig},
  data() {
    return {
      loading: true,
      integrations: 'ВАТС',
      vatsTabs: 'controls',
      vatsConfig: null
    };
  },
  mounted() {
    this.getVatsConfig()
  },
  methods: {
    handleClick(tab, event) {

    },
    getVatsConfig() {
      this.$api.integration.getVATSConfig().then((data) => {
        this.$store.commit("updateVatsConfig", data)
        if (!data) {
          this.vatsTabs = 'setting'
          return this.loading = false
        }
        this.vatsTabs = 'controls'
        this.vatsConfig = data
        return this.loading = false
      })
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.integrations {


  height: calc(100vh - 135px);

  &__title {
    padding: 10px;
    background: #fff;
    font-size: 18px;
    font-weight: 700;
    color: #555;

    h3 {
      margin-bottom: 10px;
    }
  }

  ::v-deep {
    .el-tabs--top {

      background: #fff;
    }

    .el-tabs__item {
      font-weight: bold;

      min-width: 150px;
      text-align: center;
    }
  }

  .vats {
    .controls, .calls, .setting {
      border-top: solid 2px #E4E7ED;
      padding: 10px;
    }

    ::v-deep {

      .el-tabs__nav-scroll {
        display: flex;
        justify-content: flex-end;

        .el-tabs__item {
          font-weight: normal;

          text-align: center;
        }

      }

      .el-tabs__nav-wrap::after {
        background: inherit !important;
      }
    }
  }
}
</style>