<template>
    <div class="upload-calls" v-if="jwt">
        <div class="title">
            <h2 v-if="stepsState===1">Заполните информации о звонках</h2>
            <h2 v-if="stepsState===2">Загрузите аудио файлы</h2>
            <h2 v-if="stepsState===3">Отправьте файлы</h2>
        </div>
        <my-steps :props-step-state="stepsState"/>
        <div class="get-info">
            <collection-of-general-call-information
                    :propsGetCollectInfo="getCollectInfo"
                    :class="stepsState === 1? '':'disabled'"
            />
            <file-receiver
                    :propsInfo="collectInfo"
                    @addFile="addFiles"
                    :class="stepsState === 2? '':'disabled'"
            />
            <all-uploads-file-info
                    :class="stepsState === 3? '':'disabled'"
                    :propsFilesInfo="collectInfo"
                    :propsCountFiles="uploadedFilesList.length"
            />
        </div>
        <div class="step-btn">
            <MyButton v-if="stepsState>1 " @click="setPreviousStep">Назад</MyButton>
            <MyButton v-if="collectInfo.state && stepsState === 1" @click="setNextStep">Дальше</MyButton>
            <MyButton v-if="uploadedFilesList.length && stepsState === 2" @click="setNextStep">Дальше</MyButton>
            <MyButton v-if="uploadedFilesList.length && stepsState === 3" @click="setShowListCalls">Показать звонки
            </MyButton>
        </div>
        <uploader-list
                @remove="removeFile"
                @send="sendFile"
                @sendAll="sendAllFiles"
                v-if="uploadedFilesList.length && showListCalls"
                :files="actualFileListToSend"
                :propsStateUploadsAllFile="stateUploadsAllFile"
        />
        <el-dialog
                title=""
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :show-close="false"
                :visible.sync="dialogTableVisible"
                top="30vh"
        >
            <div class="modal-content">
                <div class="succ" v-if="!uploadStatus">
                    <h3>Все файлы успешно отправлены</h3>
                    <my-button @click="closeModal">ОК</my-button>
                </div>
                <div v-else>
                    <h3>Отправка файлов</h3>
                    <el-progress :text-inside="true" :stroke-width="20"
                                 :percentage="setPercentageToProgress"></el-progress>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import MyButton from "@/components/UI/MyButton.vue";
import Uploader from "@/components/uploadCalls/uploaderList/uploaderListItem.vue";
import UploaderListItem from "@/components/uploadCalls/uploaderList/uploaderListItem.vue";
import UploaderList from "@/components/uploadCalls/uploaderList/uploaderList.vue";
import FileReceiver from "@/components/uploadCalls/fileReceiver.vue";
import MySteps from "@/components/UI/MySteps.vue";
import CollectionOfGeneralCallInformation from "@/components/uploadCalls/collectionOfGeneralCallInformation.vue";
import AllUploadsFileInfo from "@/components/uploadCalls/allUploadsFileInfo.vue";
import jwtDecode from "vue-jwt-decode";
import axios from "axios";

export default {
    name: "UploadCalls",
    components: {
        AllUploadsFileInfo,
        CollectionOfGeneralCallInformation, MySteps, FileReceiver, UploaderList, UploaderListItem, MyButton
    },
    data() {
        return {
            dialogTableVisible: false,
            showListCalls: false,
            stepsState: 1,
            uploadedFilesList: [],
            listItemSent: null,
            stateUploadsAllFile: false,
            collectInfo: {
                state: false,
                date: null,
                category: {label: '', value: ''},
                type: null,
                reverse: null,
                agentName: null,
                agentPhone: null,
                files: []
            },
            jwt: null,
            uploadStatus: false,
            countAllFilesToSend: 0,
            currentFilesToSend: 0

        }
    },
    mounted() {
        this.getJWT()
    },
    methods: {
        getJWT() {
            const jwt = localStorage.getItem('access_token')
            return this.jwt = jwtDecode.decode(jwt)

        },
        getCollectInfo(data) {
            this.collectInfo = data
        },
        addFiles(files) {
            const fileSizeLimit = 50
            files.forEach((el) => {
                if (!this.uploadedFilesList.find((f) => f.file.name === el.file.name)) {
                    if (el.file.type === "audio/mpeg" || el.file.type === "audio/wav" || el.file.type === "audio/ogg") {
                        let fileSize = (+el.file.size / 1000 / 1000).toFixed(2)
                        if (fileSize <= fileSizeLimit) {
                            this.uploadedFilesList.push(el)
                        } else {
                            alert(`Файл ${el.file.name} (${fileSize}мб) слишком большой, максимальный размер файла ${fileSizeLimit}мб`)
                        }
                    }
                }
            })
        },
        removeFile(file) {
            this.uploadedFilesList = this.uploadedFilesList.filter(item => item.file.name !== file.file.name)
        },
        sendFile(file) {
            if (this.stateUploadsAllFile) {
                this.uploadStatus = true
                this.dialogTableVisible = true
                if (!this.countAllFilesToSend) {
                    this.countAllFilesToSend = this.uploadedFilesList.length
                }
            }
            let currentFile = this.uploadedFilesList.filter(item => item.file.name === file.file.name)[0]
            let formData = new FormData()
            formData.append('org_id', this.jwt.organization_id)
            let dateTime = new Date(currentFile.date)
            let isoTime = new Date(dateTime.getTime() - dateTime.getTimezoneOffset() * 60000).toISOString();

            formData.append('date_call', isoTime)
            formData.append('agent_name', currentFile.agentName)
            formData.append('agent_phone', currentFile.agentPhone)
            formData.append('files', currentFile.file)
            formData.append('category', currentFile.category.value)
            formData.append('call_type', currentFile.type)
            formData.append('reverse', currentFile.revers)
            axios.post('https://int.audio-control.by/save-audio', formData).then((data) => {
            // axios.post('http://127.0.0.1:8181/save-audio', formData).then((data) => {
              if (data.status === 200) {
                this.uploadedFilesList = this.uploadedFilesList.filter(item => item.file.name !== file.file.name)
                this.currentFilesToSend += 1

                if (!this.uploadedFilesList.length) {
                  this.uploadStatus = false
                  this.uploadedFilesList = []
                  this.stateUploadsAllFile = false
                  this.stepsState = 1
                  this.showListCalls = false
                  this.files = []
                  this.countAllFilesToSend = 0
                  this.currentFilesToSend = 0
                }
              }
            })
        },
        sendAllFiles() {
            this.stateUploadsAllFile = true
        },
        setNextStep() {
            this.stepsState = this.stepsState < 3 ? this.stepsState + 1 : 1
        },
        setPreviousStep() {
            this.stepsState = this.stepsState > 1 ? this.stepsState - 1 : 1
        },
        setShowListCalls() {
            this.showListCalls = true
        },
        closeModal() {
            this.dialogTableVisible = false
        }
    },

    watch: {
        collectInfo() {
            // console.log(this.collectInfo)
        }
    },
    computed: {
        actualFileListToSend() {
            return [...this.uploadedFilesList]
        },
        setPercentageToProgress() {
            return +(+(+this.currentFilesToSend / +this.countAllFilesToSend) * 100).toFixed(1)
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-calls {
  overflow: hidden;
  padding: 15px;
  background: #fff;
  min-height: 86vh;

  .title {
    h2 {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
      color: #555;
    }
  }

  .get-info {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 300px;
    gap: 50px;
  }

  .step-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    button {
      font-size: 12px !important;
      font-weight: normal;
    }

  }
}

.disabled {
  opacity: 0.3 !important;
  pointer-events: none;
}

::v-deep {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body, .el-dialog {
    border-radius: 4px !important;
  }

  .modal-content {
    h3 {
      color: #555;
      text-align: center;
      margin-bottom: 20px;
    }

    button {
      width: 100px;
      margin: 0 auto;
    }

    .succ {
      display: grid;
      justify-content: center;
    }

    //min-height:190px;
  }

  .el-progress-bar__innerText {
    color: #fff !important;
  }
}

</style>