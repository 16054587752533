<template>
 <div class="div">
   <i :style="{fontSize: size + 'px'}" class="el-icon-loading"></i>
 </div>
</template>

<script>
export default {
  name: "MySpinner",
  props:{
    size: {
      type: Number,
      default: 40
    }
  }
}
</script>

<style scoped>
.div{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-self: center;
  justify-self: center;
}
i{

  color: #24387f;
}
</style>