<template>
  <el-row class="edit" v-loading="loading">
    <div
        v-if="organizations"
        v-for="organization in organizations"
        :key="organization.id"
    >
      <el-collapse v-model="activeName" @change="checkForOpen" accordion>
        <el-collapse-item :name="organization.id">
          <template slot="title">
            <div class="collapse-title-container">
              <div class="collapse-title">
                <p>Организация</p>
                <p>ID</p>
                <p>Тарифный план</p>
                <p>Остаток минут</p>
                <p>Статус</p>
                <p>Использовать администратором</p>
              </div>
              <div class="collapse-content">
                <p>{{ organization.name }} <i class="el-icon-edit" @click="formDrawer = true" @click.stop.stopPropagation></i></p>
                <p>{{organization.id}}</p>
                <p>{{organization.tp.name}}</p>
                <p v-if="organization.remaining_minutes.length">{{organization.remaining_minutes[0].minutes }}</p>
                <p v-else>-</p>
                <p>{{organization.status.name }}</p>
                <p @click.stop.stopPropagation>
                  <el-switch
                      v-if="organization.id !== 11"
                      @change="changeUsedOrgForAdmin(organization.id)"
                      :value="organization.id"
                      :active-value="usedOrgForAdmin"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                  />
                </p>
              </div>
            </div>
          </template>
          <div class="calls-info" v-if="selectedOrganization.call_info">
            <div class="calls-info__title">
              <p>Всего</p>
              <p>Входящих</p>
              <p>Исходящих</p>
              <p>Распознано</p>
              <p>Распознано входящих</p>
              <p>Распознано исходящих</p>
              <p >Способ распознания</p>
            </div>
            <div class="calls-info__content">
              <p>{{ selectedOrganization.call_info.count_calls }}</p>
              <p>{{ selectedOrganization.call_info.count_in_calls }}</p>
              <p>{{ selectedOrganization.call_info.count_out_calls }}</p>
              <p>{{ selectedOrganization.call_info.count_recognized_calls }}</p>
              <p>{{ selectedOrganization.call_info.count_recognized_in_calls }}</p>
              <p>{{ selectedOrganization.call_info.count_recognized_out_calls }}</p>
              <div class="recognizedBlock">
                <el-select
                    class="recognitionSwitch"
                    size="mini"
                    placeholder=""
                    :value="recognitionMethod"
                    @change="(value)=>changeRecognitionMethod(value,organization.id)"
                >
                  <el-option
                      v-for="item in recognitionMethodOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>

          </div>
          <div class="users">
            <div class="users__header">
              <h2>Пользователи</h2>
              <div
                  class="new-user"
                  @click="addNewUser(organization.id)"
              >
                <span><i class="el-icon-plus"></i>Добавить</span>
              </div>
            </div>

            <div class="users__container">
              <div
                  v-if="prepareLocations"
                  v-for="user in selectedOrganization.users"
                  :key="organization.id + '-' + user.id"
              >
                <user-edit
                    :user="user"
                    :roles="roles"
                    :organization-id="selectedOrganization.id"
                    :prepare-locations="prepareLocations"
                    @update="getOrganization"
                />
              </div>
            </div>
          </div>

        </el-collapse-item>
      </el-collapse>
    </div>

    <el-drawer
        :visible.sync="formDrawer"

        direction="rtl"
        size="50%"
    >
      <organization-form
        :org="selectedOrganization"
        @cancel="formDrawer = false"
        @save="save"
      />
    </el-drawer>

  </el-row>
</template>

<script>
import OrganizationForm from "@/components/management/OrganizationForm";
import OrganizationEdit from "@/components/management/OrganizationEdit.vue";
import UserEdit from "@/components/management/UserEdit.vue";
export default {
  name: "edit",
  components: {UserEdit, OrganizationEdit, OrganizationForm},
  data() {
    return {
      recognitionMethod:null,
      recognitionMethodOptions:[{"value":1,"label":"Яндекс"},{"value":2,"label":"Виспер"},],
      loading: false,
      formDrawer:false,
      selectedOrganization: {
        id: null
      },
      prepareLocations: [],
      tariffPlans: [],
      statuses: [],
      roles: [],
      organizations: [],
      activeName: null,
      usedOrgForAdmin: null,
    }
  },
  mounted() {
    this.getAllOrganizations();
    this.getTariffPlans()
    this.getStatuses()
    this.getAvailableRoles()
  },
  methods: {
    changeRecognitionMethod(value, org_id){
      const myJWT = localStorage.getItem("access_token")
      this.recognitionMethod=value
      const url = 'https://int.audio-control.by/change_recognition_method';
      const data = {
        org_id: org_id,
        value: String(value)
      };
      const options = {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'x-auth': myJWT,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };
      fetch(url, options)
          .then(response => {

            if (!response.ok) {
              throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
          })
          .then(data => {
            this.$notify({
              title: 'Успешно',
              message: 'Способ распознания изменён',
              type: 'success'
            });
          })
          .catch(error => {
            this.$notify.error({
              title: 'Ошибка',
              message: `Что то пошло не так: ${error}`
            });
          });
    },

    getOrganizationLocations() {
      this.$api.management.getOrganizationLocations({
        id: this.selectedOrganization.id
      })
          .then(data => {
            this.getPrepareLocations(data)
          })
          .finally(() => {

          })
    },
    getAllOrganizations() {
      this.loading = true;
      this.$api.management.getAll()
          .then(data => {
            this.organizations = data.organizations;
            this.usedOrgForAdmin = data.used_org_for_admin;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getOrganization(id) {
      this.loading = true;
      this.$api.management.getOne({
        id: id ? id : this.selectedOrganization.id
      })
          .then(data => {
            this.selectedOrganization = data;
            this.getOrganizationLocations()
            this.recognitionMethod = data.recognition_method
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getAvailableRoles() {
      if(!this.$store.getters.user.id) {
        setTimeout(() => {
          this.getAvailableRoles()
        }, 300)
      }else {
        this.$api.management.getAvailableRoles(
            {id: this.$store.getters.user.id}
        )
            .then(data => {
              this.roles = data;
            })
      }
    },
    addNewUser(organizationId){
      this.selectedOrganization.users.push({
        id: null,
        login: null,
        first_name: null,
        last_name: null,
        patronym: null,
        email: null,
        status: 1,
        role: {
          name: 'user'
        },
        categories: []
      })
    },
    getTariffPlans() {
      this.$api.management.getTariffPlans()
          .then(data => {
            this.tariffPlans = data;
          })
    },
    getStatuses() {
      this.$api.management.getStatuses()
          .then(data => {
            this.statuses = data;
          })
    },
    save() {
      this.loading = true;
      this.$api.management.save(this.selectedOrganization)
          .then(data => {
            if(!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }else{
              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors,
              });
            }
            this.getAllOrganizations();
          })
          .finally(() => {
            this.formDrawer = false;
            this.loading = true;
          })
    },
    deleteOrganization(id) {
      this.loading = true;
      this.$api.management.delete({id: id})
          .then(data => {

            if(!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }else{
              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors,
              });
            }
            this.getAllOrganizations();
          })
          .finally(() => {
            this.formDrawer = false;
            this.loading = false;
          })
    },
    changeUsedOrgForAdmin(id) {
      this.loading = true;
      this.$api.management.changeUsedOrgForAdmin({id: id })
          .then(data => {
            if(!data.errors) {
              this.usedOrgForAdmin = id;
              this.$notify.success({
                duration: this.$constants.global.notifyDurationError,
                title: 'Успешно',
                message: 'Данные сохранены',
              });
            }else{
              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors,
              });
            }
            this.getAllOrganizations();
          })
          .finally(() => {
            this.formDrawer = false;
            this.loading = true;
          })
    },
    // showOrganizationForm(id) {
    //   this.selectedOrganization = this.organizations.filter(org => org.id === id)[0];
    //   this.formDrawer = true;
    // },
    checkForOpen(orgId) {
      if(orgId) {
        this.getOrganization(orgId);

      }
    },
    getPrepareLocations(locations) {
      this.prepareLocations = [];
      this.prepareLocations = locations.map(location => {
        return {
          id: location.id,
          label: location.name,
          children: location.sub_locations.map(subLocation => {
            return {
              id: subLocation.id,
              label: subLocation.name,
              children: subLocation.categories.map(category => {
                return {category_id: category.id, label: category.name}
              })
            }
          })
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";
.edit {
  .recognizedBlock{
    transform: translateY(-3px);
    display: flex;
    align-items: center;
    justify-content: center;
    .recognitionSwitch{

      width:  75px !important;
      &::v-deep{
        input{
          height: 20px;
          padding-right: 15px;
        }
        .el-input__suffix{
          display: none;
        }
      }
    }
  }

  min-height: 700px;
  padding-left: 10px;

  ::v-deep .el-collapse {
    margin-bottom: 5px;
    &-item {
      &__wrap {
        background: #f5f7fd;
      }
      &__header {
        display: grid;
        grid-template-columns: 1fr 30px;
        padding: 7px 20px;
        height: 55px;
        .collapse-title-container {
          .collapse {

            &-title, &-content {
              display: grid;
              grid-template-columns: 3fr 1fr 2fr 2fr 2fr 2fr;
              > p {
                text-align: center;
                &:first-child, &:last-child {
                  text-align: left;
                }
              }



            }
          }
          p {
            margin-bottom: 0;
            line-height: 1;
          }
          .collapse {
            &-title {
              margin-top: 5px;
              p {
                font-size: 10px;
              }
            }
            &-content {
              margin: 5px 0 5px 0;
              p {
                color: #555555;
                font-size: 14px;
                line-height: 1.6;
              }
            }
          }
        }
      }

      .el-switch.is-checked .el-switch__core::after {
        margin-left: -15px;
      }

      .el-switch {
        width: 35px;
        &__core {
          width: 35px!Important;
          height: 14px;

          &:after {
            top: -1px;
            width: 14px;
            height: 14px;
          }
        }
      }
      .calls-info {
        margin: 10px 20px;
        background: #fff;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid #eee;
        &__title, &__content {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          p {
            color: #555555;
            margin-bottom: 0;
            text-align: center;
          }
        }
        &__title {
          p {
            margin-bottom: 10px;
            font-size: 10px;
          }

        }
        &__content {
          p {
            font-size: 12px;
            font-weight: bold;
          }
        }
      }

      .users {
        &__header {
          display: flex;
          justify-content: space-between;
          padding: 5px 20px 0 20px;
          h2 {
            margin-top: 10px;
            font-weight: bold;
            margin-left: 20px;
          }
          .new-user {
            font-size: 10px;
            border: 1px solid #555555;
            color: #555555;
            border-radius: 4px;
            padding: 2px 10px;
            cursor: pointer;
          }
        }
        h2 {
          margin: 0;
          font-size: 12px;
          color: #555555;
        }

        &__container {
          margin: 0 10px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          .user {
            background: #fff;
            margin: 10px;
            padding: 10px;
            border-radius: 4px;
          }
        }
      }

    }
  }
}

</style>