<template>
  <el-row>
    <el-row type="flex-wrap" :gutter="20" >
      <el-col v-for="question in questions" :key="question.id" :span="8" style="margin-bottom: 10px;">
        <el-card
            shadow="hover"
            class="box-card"
        >
          <i class="el-icon-edit" @click="edit(question.id)"></i>

          <el-popconfirm
              :title="'Вы уверны, что хотите удалить системный словарь ' + question.name + '?'"
              @confirm="deleteQuestion(question.id, 'client')"
          >

            <i class="el-icon-delete" slot="reference"></i>

          </el-popconfirm>
          <el-row class="question">
            <h2>{{question.name}} <span><i class="el-icon-star-on" :style="'color:' + question.color_main + ';'"></i></span></h2>
            <el-divider></el-divider>
            <el-row class="information">
              <el-popover
                  placement="top-start"
                  trigger="hover"
                  content="найдено | не проверено"
              >
                <i class="el-icon-info" slot="reference"></i>
              </el-popover>
            </el-row>
            <el-row>
              <el-col :span="20">За последние 24 часа:</el-col>
              <el-col :span="4">12 | 24</el-col>
            </el-row>
            <el-row>
              <el-col :span="20">За текущий месяц:</el-col>
              <el-col :span="4">12 | 24</el-col>
            </el-row>
            <el-row>
              <el-col :span="20">За все время:</el-col>
              <el-col :span="4">12 | 24</el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="margin-top: 10px ">
              <el-link type="primary" @click="showQuestionForm(question.id)">Подробнее...</el-link>
            </el-row>
          </el-row>

        </el-card>
      </el-col>
    </el-row>
  </el-row>

</template>

<script>

export default {
  name: "client-question",
  components: {},
  props: {
    questions: {}
  },
  data() {
    return {}
  },
  watch: {

  },
  computed: {


  },
  mounted() {

  },
  methods: {
    edit(id, type) {
      this.$emit('edit', {id:id, type: type} );
    },
    showQuestionForm(id, type) {
      this.$emit('show', {id:id, type: type} );
    },
    deleteQuestion(id, type) {
      this.$emit('delete', {id:id, type: type} );
    },
  },
}

</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";

.box-card {
  padding: 2rem;
  position: relative;
  .el-icon-delete, .el-icon-edit {
    position: absolute;
    top: 7px;
    cursor: pointer;
  }
  .el-icon-delete {
    right: 5px;
  }
  .el-icon-edit {
    right: 24px;
  }
  .dictionary {
    font-size: 12px;
    h2 {
      position: relative;
      > span {
        position: absolute;
        right: 0;
        width: 10px;
        height: 10px;
        display: inline-block;
      }
    }
    .el-row {
      > .el-col:last-child {
        text-align: right;
      }
    }
    .information {
      position: relative;
      .el-icon-info {
        position: absolute;
        right: 12px;
        top: -34px;
        cursor: pointer;
        color: #999999;
      }
    }
    .el-button.is-round {

      padding: 5px 15px;
      font-size: 10px;
    }
    .el-icon-star-on {
      position: relative;
      right: 22px;
      font-size: 24px;
    }
  }
}

</style>