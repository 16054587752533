<template>
  <el-row type="flex" justify="center">
    <el-col 
        class="location-form-container" 
        :span="12"
    >

      <h4>Словарь "{{selectedDictionary.name}}"</h4>

      <el-row style="text-align: left;">
        <ul>
          <li>Название: {{selectedDictionary.name}}</li>
          <li>Описание: {{selectedDictionary.description}}</li>
          <li>Запрос: {{selectedDictionary.query}}</li>
          <li>Реверсивный: {{selectedDictionary.reverse}}</li>
          <li>Положительный вес: {{selectedDictionary.positive_weight}}</li>
          <li>Отрицательный вес: {{selectedDictionary.negative_weight}}</li>
          <li>Искать во входящих: {{selectedDictionary.search_in_call}}</li>
          <li>Искать в исходящих: {{selectedDictionary.search_out_call}}</li>
          <li>Цвет: {{selectedDictionary.color_success}}</li>
          <li>Цвет "не найдено": {{selectedDictionary.color_reject}}</li>

        </ul>
      </el-row>


    </el-col>


  </el-row>
</template>

<script>
export default {
  name: "dictionary-form",
  props: {
    selectedDictionary :{},
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.$emit('save')
    }
  }
}
</script>

<style lang="scss" scoped>

  .location-form-container {
    text-align: center;
    > *:not(:first-child) {
      margin-bottom: 25px;
    }
  }

</style>