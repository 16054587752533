<template>
  <div class="header-calls">
    <div class="header-calls__title">
      <h4>Данные за: </h4>
      <el-select v-model="valueSelect" placeholder="Select">
        <el-option-group
            v-for="group in options"
            :key="group.label"
            :label="group.label">
          <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-option-group>
      </el-select>
      <div
          :class="valueSelect !=='выбрать период'? 'data-picker hideDatePicker' : 'data-picker'"
      >
        <el-date-picker
            v-model="valueRangeDataTimePicker"
            type="daterange"
            start-placeholder="От"
            end-placeholder="До">
        </el-date-picker>
      </div>
      <div
          :class="valueSelect !=='выбрать день'? 'data-picker hideDatePicker' : 'data-picker'"
      >
        <el-date-picker
            v-model="valueDatePicker"
            type="date"
            placeholder="выберите день">
        </el-date-picker>
      </div>
    </div>
<!--    <div class="counts-call">-->

<!--      <p>всего звонков: {{ totalCountListLength }}</p>-->
<!--    </div>-->
  </div>

</template>

<script>
export default {
  name: "my-date-period-picker",
  props: {
    getTime: {
      type: Function
    },
    totalCountListLength: {
      type: Number
    }
  },
  data() {
    return {
      valueSelect: 'день текущий',
      valueRangeDataTimePicker: [],
      valueDatePicker: '',
      startEndDate: {start: '', end: ''},
      options: [
        {
          label: 'Популярное',
          options: [{value: 'день текущий', label: 'день текущий'}, {value: 'current month', label: 'текущий месяц'},
            {value: 'previous month', label: 'предыдущий месяц'}]
        },
        {
          label: 'Произвольное',
          options: [{value: 'выбрать день', label: 'выбрать день'}, {value: 'выбрать период', label: 'выбрать период'}]
        }],
    }
  },
  mounted() {
    this.getToday()
  },
  methods: {
    getToday() {
      let start = Math.round(this.setStartTimeDate(new Date()) / 1000)
      let end = Math.round(this.setEndTimeDate(new Date()) / 1000)
      this.startEndDate = {start: start, end: end}
    },
    setStartTimeDate(data) {
      data.setHours(0)
      data.setMinutes(0)
      data.setSeconds(0)
      return data
    },
    setEndTimeDate(data) {
      data.setHours(23)
      data.setMinutes(59)
      data.setSeconds(59)
      return data
    },
    getStartEndDayFromCurrentMonth() {
      let firstDay = new Date();
      firstDay.setDate(1);

      let lastDay = new Date();
      lastDay.setMonth(lastDay.getMonth() + 1)
      lastDay.setDate(0)
      return [Math.round(firstDay / 1000), Math.round(lastDay / 1000)]
    },
    formatDate() {
      let dateStartTIme = this.setStartTimeDate(new Date())
      let dateEndTIme = this.setEndTimeDate(new Date())
      switch (this.valueSelect) {
        case "день текущий":

          return this.startEndDate = {start: Math.round(dateStartTIme / 1000), end: Math.round(dateEndTIme / 1000)}
        case "current month":
          let firstDay = new Date();
          firstDay.setDate(1);
          let lastDay = new Date();
          lastDay.setMonth(lastDay.getMonth() + 1)
          lastDay.setDate(0)
          let startResult = this.setStartTimeDate(firstDay)
          let lastResult = this.setEndTimeDate(lastDay)

          return this.startEndDate = {start: Math.round(startResult / 1000), end: Math.round(lastResult / 1000)}
        case "previous month":
          let startPreviousDay = new Date();
          startPreviousDay.setDate(1);
          startPreviousDay.setMonth(startPreviousDay.getMonth() - 1)
          this.setStartTimeDate(startPreviousDay)

          let endPreviousDay = new Date();
          endPreviousDay.setMonth(endPreviousDay.getMonth() )
          endPreviousDay.setDate(0)
          this.setEndTimeDate(endPreviousDay)

          return this.startEndDate = {start: Math.round(startPreviousDay / 1000), end: Math.round(endPreviousDay / 1000)}
        default:
          return this.startEndDate = null
      }
    }
  },
  watch: {
    valueSelect() {
      this.formatDate()
    },
    valueRangeDataTimePicker() {
      this.startEndDate = {
        start: Math.round(this.valueRangeDataTimePicker[0] / 1000),
        end: Math.round(this.setEndTimeDate(new Date(this.valueRangeDataTimePicker[1])) / 1000)
      }
    },
    valueDatePicker() {
      this.startEndDate = {
        start: Math.round(this.valueDatePicker / 1000),
        end: Math.round(this.setEndTimeDate(new Date(this.valueDatePicker)) / 1000)
      }
    },
    startEndDate() {
      if (this.startEndDate) {
        this.getTime(this.startEndDate)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-calls {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ::v-deep .el-input__inner {
    height: 30px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;

    .select {
      width: 100px;
      max-width: 65px;

      ::v-deep .el-input__inner {
        border: none;
        background: inherit;
        height: 40px;
        font-weight: 700;
        font-family: inherit;
        padding: 0;
        color: #24387f;
        font-size: 16px;
      }

      ::v-deep .el-input {
        width: 70px;
        display: flex;
        align-items: center;
        gap: 10px;

      }

      ::v-deep .el-select__caret {
        font-size: 11px;
      }

      ::v-deep .el-input__suffix {
        display: none;
      }
    }

    .data-picker {
      ::v-deep .el-input__inner {
        height: 30px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-range-separator {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-range-input {
        width: 79%;
      }

      ::v-deep .el-date-editor--datetimerange {
        width: 350px;
      }
    }
  }

  .counts-call {
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;

      .selectCountCalls {
        width: 20px;

        ::v-deep .el-input__inner {
          padding: 0 0 0 0px;
          height: 20px;
          text-align: center;
          font-weight: bold;
          color: #24387f;
          background: inherit;
          border: none;
        }

        ::v-deep .el-input__suffix-inner {
          display: none;
        }
      }
    }
  }

  p {
    margin: 0;
  }

  h4 {
    margin: 0;
    color: #252525;
    font-size: 15px;
  }

  margin-bottom: 20px;
}

.hideDatePicker {
  position: absolute;
  opacity: 0;
  z-index: -1111;
}

::v-deep .el-select-group__title {
  font-weight: bold;
  font-size: 12px;
}

::v-deep .el-select-dropdown__item {
  font-size: 12px;
}

.no-calls {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-select {
  i {
    line-height: 0;
  }
}
</style>