<template>
  <div class="wrapper">
    <el-row class="category-checklists-container" v-loading="loading">
      <el-row>
        <h4>
          Чек-листы
          <i class="el-icon-edit" @click="edit()"></i>
        </h4>
      </el-row>
      <el-tabs v-model="activeTabs">
          <el-tab-pane
              :key="checklist.id"
              :label="checklist.checklist.name"
              :name="checklist.checklist.name"
              v-for="checklist in categoryChecklists"
              :class="!checklist.relevant ? 'disabled':''"
          >
            <span
                :class="!checklist.relevant ? 'disabled':''"
                slot="label"
            >
              {{checklist.checklist.name}}
            </span>
            <div class="tab-content">
              <checklist-work-tab-item
                  :propsCheckListData="checklist"
                  @show-call-info-drawer="showCallInfoDrawer"
                  @show-category-calls-dialog="showCategoryCallsDialog"
              />
              <checklist-work-tab-info
                  :propsCheckListInfo="checklist"
                  @show-call-info-drawer="showCallInfoDrawer"
                  @show-category-calls-dialog="showCategoryCallsDialog"
              />
            </div>
          </el-tab-pane>
      </el-tabs>
      <el-drawer
          :visible.sync="checklistsDrawer"
          direction="rtl"
          size="75.1%"
      >
        <category-checklists-transfer
            :category-id="this.$route.params.category_id"
            :selected-checklists="categoryChecklists"
            @reload="getCategoryChecklists"
        />
      </el-drawer>
    </el-row>
  </div>
</template>

<script>


import CategoryChecklistsTransfer from "@/components/category/CategoryChecklistsTransfer.vue";
import CheckListItem from "@/components/newCatigories/checkListItem.vue";
import ChecklistWorkTabItem from "@/components/newCatigories/checklistTabs/checklistWorkTabItem.vue";
import ChecklistWorkTabInfo from "@/components/newCatigories/checklistTabs/checklistWorkTabInfo.vue";


export default {
  name: "category-checklists",
  components: {ChecklistWorkTabInfo, ChecklistWorkTabItem, CheckListItem, CategoryChecklistsTransfer},
  props: {
    category: {},
    date_start: {},
    date_end: {},
    dateStartEndForRequest:{}
  },
  data() {
    return {
      loading: false,
      activeTabs: null,
      checklistsDrawer: false,
      categoryChecklists: [],
      selectedCategory: {}
    }
  },
  mounted() {
    // this.getCategoryChecklists()
  },
  computed: {},
  methods: {
    // getCategoryChecklistDictionaryFullData(dictionary, index) {
    //   this.$api.category.getCategoryDictionaryFullData({
    //     category_dictionary_id: dictionary.id,
    //     date_start: this.dateStartEndForRequest ? this.dateStartEndForRequest?.start : '',
    //     date_end: this.dateStartEndForRequest ? this.dateStartEndForRequest?.end : '',
    //   }).then(data => {
    //     data.dictionary.loaded_period_data = true;
    //     this.dictionaries[index] = data;
    //     this.countUnloadedLoadedDictionaries--;
    //   })
    // },

    getCategoryChecklistAnalytics(dictionary, index) {
      this.$api.category.getCategoryChecklistAnalytics({
        category_id: this.$route.params.category_id,
        date_start: this.dateStartEndForRequest ? this.dateStartEndForRequest?.start : '',
        date_end: this.dateStartEndForRequest ? this.dateStartEndForRequest?.end : '',
      }).then(data => {
        this.categoryChecklists = data;
        this.loading = false;
      })
    },
    getCategoryChecklists() {
      this.loading = true;
      this.$api.category.getCategoryChecklists({
        category_id: this.$route.params.category_id,
        date_start: this.dateStartEndForRequest ? this.dateStartEndForRequest?.start : '',
        date_end: this.dateStartEndForRequest ? this.dateStartEndForRequest?.end : '',
      })
          .then(data => {
            this.categoryChecklists = data;
            this.activeTabs = data[0]?.checklist?.name
            this.$emit('set-count-checklists', data)
            this.getCategoryChecklistAnalytics()
          })
    },
    edit() {
      this.checklistsDrawer = true;
    },
    getCompleted(checklist) {
      return {
        status: false,
        value: 10
      }
    },
    showCallInfoDrawer(id){
      this.$emit('show-call-info-drawer', id)
    },
    showCategoryCallsDialog(data){
      console.log(data)
      this.$emit('show-category-calls-dialog', data)
    }
  },
  watch:{
    dateStartEndForRequest(){
      this.getCategoryChecklists()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1150px;
}

.category-checklists-container {
  margin: 15px 20px;
  max-height: 491px;
  overflow-y: auto;
  color: #252525;

  h4 {
    margin-bottom: 0px;
    font-size: 13px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 450;

    i {
      line-height: 1;
      font-size: 12px;
      color: #555;
    }
  }

  .el-carousel__item {
    &.is-active {
      background: #fff;
    }

    .checklists-list {
      border-radius: 2px;
      max-height: 400px;
      overflow-y: auto;
      box-shadow: inset 0 0 4px 0 rgb(0 0 0 / 13%);

      ul {
        padding: 5px 10px;

        li {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #555;
          font-weight: 450;
          line-height: 1.3;
          font-size: 12px;
          font-family: inherit;
          margin: 3px 0;

          .dictionary-color {
            height: 15px;
            padding: 0 5px;
            background: gray;
            border-radius: 4px;
            display: flex;
            justify-content: center;

            span {
              text-align: center;
              justify-content: center;
              color: #fff;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}

.tab-content {
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  box-sizing: border-box;
  gap: 10px;
}

::v-deep {
  .el-tabs__item {
    font-size: 13px !important;
    line-height: 2;
    height: 30px;
  }

  .el-tabs__nav-prev {
    i {
      line-height: 1 !important;
      transform: translateY(-7px);
    }
  }

  .el-tabs__nav-next {
    i {
      line-height: 1 !important;
      transform: translateY(-7px);
    }
  }
}
.disabled{
  opacity: 0.3;
  text-decoration: line-through;

}
</style>