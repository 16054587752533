<template>
  <el-row type="flex" :gutter="20">
    Вывести стату, обсудить, что, куда и как
  </el-row>
</template>

<script>


export default {
  name: 'Statistics',

  components: {

  },

  data() {
    return {
      loading: false,
    }
  },
  computed: {
  },

  mounted() {

  },
  methods: {

  }
}

</script>

<style lang="scss">

.user-row-inactive {
  color: #cecece;
}

.page {

  &__head {

    &-right {
      display: flex;
      align-items: center;
    }
  }
}

.ellipsis-menu-slot {

  > span:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

</style>
