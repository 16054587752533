<template>
  <div class="list">
    <div class="list__title">
      <h3>Список Агентов</h3>
    </div>
    <sorted-block
        :propsStateSort="propsStateSort"
        :propsChangeSort="propsChangeSort"
    />
    <el-progress v-if="!propsStateSort.status" class="progress" :text-inside="true" :stroke-width="12" :percentage="computedInterestCalculation" ></el-progress>
    <ul>
      <user-rating-list-item
          :propsStartEndDateForCallListAndCategoryDictionaries="propsStartEndDateForCallListAndCategoryDictionaries"
          :propsSelectedCategories="propsSelectedCategories"
          :key="item.title"
          :propsUniqueDataItem="item"
          :propsIndex="index"
          :propsGetCurrentItemRating="propsGetCurrentItemRating"
          :propsChangePendingRequests="propsChangePendingRequests"
          v-for="(item,index) in propsUniqueData"
          @modal="showDialogCalls(item)"
      />
    </ul>
    <dialog-calls-user
        :propsUserData="callsUserData"
        @close="closeModal"
        :dialog-visible="dialogVisible"
        @show-call-info-drawer="setCallIdToDrawer"
    />
    <el-drawer
        :style="{display:stateDrawer?'flex':'none'}"
        title="I am the title"
        :visible.sync="stateDrawer"
        :with-header="false"
        size="95%"
    >
      <drawer-call-info
          :categoryName="'---'"
          :dateCall="'--'"
          :clientNumber="''"
          :clientName="''"
          :stateDrawer="stateDrawer"
          :callId="callIdForDrawer"
          :period="propsStartEndDateForCallListAndCategoryDictionaries"
      />
    </el-drawer>
  </div>
</template>

<script>
import UserRatingListItem from "@/components/analytics/basicEmployeeAnalysis/userRatingList/userRatingListItem.vue";
import SortedBlock from "@/components/analytics/basicEmployeeAnalysis/userRatingList/sortedBlock.vue";
import DialogCallsUser from "@/components/analytics/basicEmployeeAnalysis/dialogCallsUser.vue";
import DrawerCallInfo from "@/components/newCatigories/drawerCallInfo.vue";

export default {
  name: "userRatingList",
  components: {DrawerCallInfo, DialogCallsUser, SortedBlock, UserRatingListItem},
  props: {
    propsGetCurrentItemRating: {
      type: Function
    },
    propsUniqueData: {
      type: Array
    },
    propsStartEndDateForCallListAndCategoryDictionaries: {
      type: Object
    },
    propsSelectedCategories: {
      type: Array
    },
    propsStateSort:{
      type:Object
    },
    propsChangeSort:{
      type:Function
    },
    propsChangePendingRequests:{
      type:Function
    },
    propsTotalCountRequests:{
      type:Number
    },
    propsPendingRequests:{
      type:Number
    },
  },
  data(){
    return{
      dialogVisible:false,
      callsUserData:{type:null,title:null,data:null},
      stateDrawer:false,
      callIdForDrawer:null
    }
  },
  methods:{
    setCallIdToDrawer(id){
      this.callIdForDrawer = id
      this.stateDrawer=true
    },
    closeModal(state){
      this.dialogVisible=state
    },
    showDialogCalls(state){
      this.callsUserData = state
      this.dialogVisible = true
    }
  },
  computed:{
    computedInterestCalculation(){
      if (this.propsPendingRequests && this.propsTotalCountRequests){
        return +((this.propsPendingRequests/this.propsTotalCountRequests)*100).toFixed(1)
      }
      return 0
    }
  }


}
</script>

<style lang="scss" scoped>
.list {
  margin-top: 20px;

  &__title {
    margin-bottom: 10px;

    h3 {
      color: #555;
      font-size: 15px;
      margin-bottom: 0;
    }
  }

  ul {
    margin-bottom: 0;
    min-height: 200px;
    padding: 0 10px 0 5px;
  }

}
.progress{
  ::v-deep{
    .el-progress-bar__innerText{
      color: #fff !important;
      font-size: 10px;
      transform: translateY(-1px) !important;
    }
  }
}



</style>