<template>
  <div class="item">
    <div class="title">
      <h6>{{ propsData.name }}</h6>
    </div>
    <div :style="{background: propsData.color_success}" class="color-line"></div>
    <div class="graph" v-loading="loading">
      <div class="graph-item">
        <p>за день:</p>
        <div  class="row">
          <div :style="{width: this.setActualWidth().day}" class="filled"></div>
          <p>{{ dictCount.day }}</p>
        </div>
      </div>
      <div class="graph-item">
        <p>за месяц:</p>
        <div  class="row">
          <div :style="{width: this.setActualWidth().month}" class="filled"></div>
          <p>{{ dictCount.month }}</p>
        </div>
      </div>
      <div class="graph-item">
        <p> за всё вермя:</p>
        <div class="row">
          <div :style="{width: this.setActualWidth().all}" class="filled"></div>
          <p>{{ dictCount.all }}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "dictionaryIsDashboardItem",
  props: {
    propsData: {}
  },
  data() {
    return {
      loading: false,
      dictCount: {day: null, month: null, all: null}
    }
  },
  mounted() {
    this.getCountByPeriod()
  },
  methods: {
    getCountByPeriod() {
      this.loading = true
      this.$api.dictionary.getDictionaryInformationByPeriods({type: 'client', id: this.propsData.id}).then((data) => {
        this.dictCount = data['found_count_by_period']
        this.loading = false
      })
    },
    setActualWidth() {
      if (this.dictCount.month != null && this.dictCount.all != null && this.dictCount.day != null) {
        return {
          day: `${(+this.dictCount.day / +this.dictCount.all) * 100}%`,
          month: `${(+this.dictCount.month / +this.dictCount.all) * 100}%`,
          all: +this.dictCount.all > 0 ? '100%': '0%'
        }
      }
      return 0
    }
  },
  computed: {

  }
}


</script>


<style scoped lang="scss">
.item {

  background: #fff;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0 #8080802e;
  border-radius: 5px;

  .title {
    padding: 8px 8px;

    h6 {
      color: #555;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .color-line {
    height: 4px;
    width: 100%;
  }

  .graph {
    padding: 10px 8px;

    .graph-item {
      display: grid;

      p {
        color: #777777;
        font-size: 10px;
        margin-bottom: 0;

      }

      .row {
        margin: 5px 0;
        display: flex;
        align-items: center;
        gap: 5px;


        .filled {

          height: 4px;
          background: rgba(0, 0, 255, 0.7);
          border-radius: 5px;
          transition: width .3s;
          width: 0;
        }

        p {
          line-height: 0;
          margin-bottom: 0;
        }
      }
    }

  }
}
</style>