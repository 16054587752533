<template>
    <el-row type="flex">
        <el-col
                class="location-form-container"
                :span="22"
        >
            <el-row>

                <h4>Добавление организации</h4>
                <el-form :model="org" :rules="rules" ref="ruleForm">
                    <el-row class="form-fields">
                        <el-form-item label="Название" prop="name">
                            <el-input
                                type="text"
                                placeholder="Введите название"
                                required
                                v-model="org.name"
                            ></el-input>
                        </el-form-item>

                        <el-form-item label="E-mail" prop="email">
                            <el-input
                                type="text"
                                placeholder="Введите e-mail"
                                v-model="org.email"
                            >
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Страна регистрации" prop="country">
                            <el-input
                                type="text"
                                placeholder="Введите страну регистрации"
                                v-model="org.country"
                            >
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Регистрационный код" prop="org_registration_key">
                            <el-input
                                type="text"
                                placeholder="Введите регистрационный код"
                                v-model="org.org_registration_key"
                            >
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Тарифный план" prop="tariff_plan_id">
                            <el-select
                                style="width: 100%;"
                                v-model="org.tariff_plan_id"
                            >
                                <el-option
                                    v-for="tariff in tariffPlans"
                                    :key="tariff.id"
                                    :label="tariff.name"
                                    :value="tariff.id"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Логин администратора" prop="login">
                            <el-input
                                type="text"
                                placeholder="Введите login"
                                v-model="org.login"
                            >
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Пароль администратора" prop="password">
                            <el-input
                                type="password"
                                placeholder="Введите пароль"
                                v-model="org.password"
                            >
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Пароль - подтверждение" prop="password_confirm">
                            <el-input
                                type="password"
                                placeholder="Повторите пароль"
                                v-model="org.password_confirm"
                            >
                            </el-input>
                        </el-form-item>

                    </el-row>
                    <el-row class="command-buttons">
                        <el-button
                                plain
                                round
                                @click="resetForm('ruleForm')"
                        >
                            Очистить
                        </el-button>

                        <el-button
                                type="primary"
                                round
                                @click="submitForm('ruleForm')"
                        >
                            Создать
                        </el-button>
                    </el-row>
                </el-form>

            </el-row>

        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "create",
    props: {},
    data() {
        return {

            org: {
                name: null,
                email: null,
                login: null,
                country: null,
                org_registration_key: null,
                password: null,
                password_confirm: null,
                tariff_plan_id: null,
            },
            tariffPlans: [],
            rules: {
                name: [
                    {required: true, message: 'Обязательно для заполнения', trigger: 'blur'},
                    {min: 3, message: 'Мин. длинна не меньще 3 знаков', trigger: 'blur'}
                ],
                email: [
                    {required: true, message: 'Обязательно для заполнения', trigger: 'blur'},

                ],
                login: [
                    {required: true, message: 'Обязательно для заполнения', trigger: 'blur'},

                ],
                password: [
                    {required: true, message: 'Обязательно для заполнения', trigger: 'blur'},

                ],
                password_confirm: [
                    {required: true, message: 'Обязательно для заполнения', trigger: 'blur'},

                ],
            }
        }
    },
    mounted() {
        this.getTariffPlans();
    },
    methods: {
        getTariffPlans() {
            this.$api.management.getTariffPlans()
                .then(data => {
                    this.tariffPlans = data;
                })
        },
        submitForm(formName) {
            console.log(formName)
            this.$refs[formName].validate((valid) => {
                console.log(valid)
                if (valid) {
                    this.save()
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        save() {
            this.$api.management.create(this.org)
                .then(data => {
                  if(data.errors) {
                    this.$notify.success({
                      duration: this.$constants.global.notifyDurationSuccess,
                      title: 'Ошибка',
                      message: data.errors,
                    });
                  }else {
                    this.$notify.success({
                      duration: this.$constants.global.notifyDurationSuccess,
                      title: 'Успешно',
                      message: 'Организация и администратор добавлены',
                    });
                    this.resetForm('ruleForm');

                  }
                })
                .finally(() => {
                  this.loading = false;
                })
        }

    }
}
</script>

<style lang="scss" scoped>

.location-form-container {
  h4 {
    margin-bottom: 20px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    width: 50%;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;

    > .el-row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      > .el-col {
        &:first-child {
          padding-right: 10px;
          text-align: right;
        }
      }
    }
  }

  .command-buttons {
    margin-bottom: 25px;
  }
}

</style>