import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        getOne(payload){
            return requestSender('get', 'organization/get-one', payload);
        },
        getAll(payload){
            return requestSender('get', 'organization/get-all', payload);
        },
        create(payload){
            return requestSender('post', 'organization/create', payload);
        },
        save(payload){
            return requestSender('post', 'organization/save', payload);
        },
        saveUser(payload){
            return requestSender('post', 'management/save-organization-user', payload);
        },
        deleteUser(payload){
            return requestSender('post', 'management/delete-organization-user', payload);
        },
        delete(payload){
            return requestSender('post', 'organization/delete', payload);
        },
        changeUsedOrgForAdmin(payload){
            return requestSender('post', 'organization/change-used-org-for-admin', payload);
        },
        getTariffPlans(payload){
            return requestSender('get', 'organization/get-tariff-plans', payload);
        },
        getStatuses(payload){
            return requestSender('get', 'organization/get-statuses', payload);
        },
        getAvailableRoles(payload){
            return requestSender('get', 'management/get-available-roles', payload);
        },
        getOrganizationLocations(payload){
            return requestSender('get', 'management/get-organization-locations', payload);
        },
        saveUserCategories(payload) {
            return requestSender('get', 'management/save-user-categories', payload);
        },
        getSearchData(payload){
            return requestSender('post', 'management/get-search-data', payload);
        },
    }
}