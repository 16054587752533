<template>
  <el-row>
    <h4>Сводка</h4>
    <el-row type="flex">
      <el-col :span="12" class="data">
        <h5>Всего данных:</h5>
        <el-row>
          <el-col :span="17">Словарей</el-col>
          <el-col :span="7">32 из 87</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">Чек-дистов</el-col>
          <el-col :span="7">4 из 21</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">К проверке (всего)</el-col>
          <el-col :span="7">652</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">Проверено | ожидает</el-col>
          <el-col :span="7">632 | 20</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">Найдено трендов</el-col>
          <el-col :span="7">18</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">Категорий</el-col>
          <el-col :span="7">4</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">Пользователей</el-col>
          <el-col :span="7">3</el-col>
        </el-row>
      </el-col>
      <el-col :span="12" class="data">
        <h5>За последние 24 часа:</h5>
        <el-row>
          <el-col :span="17">загружено | в очереди</el-col>
          <el-col :span="7">176 | 23</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">обработано | к проверке</el-col>
          <el-col :span="7">153 |   3</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">проверено (с замечаниями)</el-col>
          <el-col :span="7">2</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">максимальная пауза</el-col>
          <el-col :span="7">91 сек</el-col>
        </el-row>
        <el-row>
          <el-col :span="17">средняя пауза</el-col>
          <el-col :span="7">11 сек</el-col>
        </el-row>

      </el-col>
    </el-row>
  </el-row>
</template>

<script>
export default {
  name: "about-audio"
}
</script>

<style lang="scss" scoped>
.el-row {
  > .el-row {
    > .el-col {
      &:first-child {
        border-right: 1px solid #e1e1e1;
      }
      &:last-child {
        padding-left: 20px;
      }
    }

  }
  >h4 {
    margin-bottom: 10px;
  }
  h5 {
    margin-bottom: 10px;
    color: #000;
  }
  p {
    margin: 0 0 10px 0;
    color: #b5b5b5;
    font-size: 12px;
  }
  .data {
    font-size: 14px;
    .el-row {
      margin-bottom: 5px;
      > .el-col {
        &:first-child {
          color: #b5b5b5;
        }
      }
    }
  }
}
</style>