<template>
  <el-row type="flex" :gutter="20">
    Организации
  </el-row>

</template>

<script>



export default {
  name: 'Organization',
  components: {},
  data() {
    return {
      locations: [],
      confirmShow: false,
      selectedId: null,
    }
  },
  computed: {
  },

  mounted() {
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.$api.location.get()
          .then(data => {
            this.locations = data
          })
          .finally(() => {
            this.loading = false;
          })
    },
    confirmDelete(id) {
      this.selectedId = id;
      alert(this.selectedId)
    }

  }
}

</script>

<style lang="scss" scoped >

.el-card {
  padding: 2rem;
  position: relative;
}

.el-icon-delete {
  position: absolute;
  top: -25px;
  right: -26px;
  cursor: pointer;
}

</style>
