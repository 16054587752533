<template>
  <div>
    <h5>{{ groups.length ? 'Группы словарей ' : 'Словари' }}</h5>
    <el-row class="groups">

      <el-row
          class="group"
          v-if="groups"
          v-for="group in groups" :key="group.id"
      >
        <div @click.stop="goToGroup(group.id)">
          <span class="commands">
        <i class="el-icon-edit" @click.stop="editGroup(group)"></i>
        <el-popconfirm

            @confirm="deleteGroup(group.id)"
            :title="'Вы уверены, что хотите удалить группу \'' + group.name + '\'?'"
        >
            <i class="el-icon-delete" @click.stop slot="reference"></i>
        </el-popconfirm>


      </span>
          <div class="title">
            <h4>{{ group.name }}</h4>
          </div>

          <el-row class="color" :style="{backgroundColor: group.color}"></el-row>
          <el-row>
            <el-row><h6>Словари</h6></el-row>
            <el-row class="group-dictionaries">
              <ul>
                <li
                    v-for="dictionary in group.dictionaries"
                    :key="dictionary.id"
                >
                  {{ dictionary.name }}
                </li>
              </ul>
            </el-row>

          </el-row>
        </div>


      </el-row>
    </el-row>


    <el-dialog
        :class="'modalAddDictGroup'"
        title="Добавить группу словарей"
        :visible.sync="modalCreateDictGroupState"
        width="40%"
        center>
      <div>
        <div class="modalRow">
          <label>
            <p>Название</p>
            <el-input placeholder="Название" v-model="selectedDictionariesGroup.name"></el-input>
          </label>
        </div>
        <div class="modalRow">
          <label>
            <p>Выберите словари</p>
            <dictionary-group-list
                v-if="modalCreateDictGroupState"
                :propsDictList="clientDictionaryList"
                :checked="selectedDictionariesIds"
                @update-dictionaries="updateSelectedDictionariesForGroup"
            />
          </label>
        </div>
        <div class="modalRow">
          <label>
            <p>Цвет:</p>
            <el-color-picker v-model="selectedDictionariesGroup.color"/>
          </label>

        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modalCreateDictGroupState = false">отмена</el-button>
        <el-button type="primary" @click="saveDictionariesGroup">Сохранить</el-button>
      </span>
    </el-dialog>

  </div>


</template>

<script>
import DictionaryGroupList from "@/components/dictionary/group/dictionaryGroupList.vue";

export default {
  name: "group",
  components: {
    DictionaryGroupList

  },
  props: {
    groups: {
      Type: Array,
    }
  },
  data() {
    return {
      selectedDictionariesGroup: {
        name: null,
        type: 'client',
        color: '#24387f',
        dictionaries: []
      },
      selectedDictionariesIds: [],
      modalCreateDictGroupState: false,
      clientDictionaryList: [],
    }
  },
  mounted() {

  },
  computed: {
    // selectedDictionariesIds() {
    //   if(this.selectedDictionariesGroup.dictionaries.length) {
    //     return this.selectedDictionariesGroup.dictionaries.map(item => item.dictionary_id)
    //   }
    //   return [];
    // }
  },
  methods: {
    goToGroup(id) {
      this.$router.push({path: '/dictionaries-group/' + id})
    },
    setDictionariesList(data) {
      this.clientDictionaryList = data;
    },
    showDictionaryForm(isNew = true) {
      if (isNew) {
        this.selectedDictionariesGroup = {
          name: null,
          type: 'client',
          color: '#000000',
          dictionaries: []
        }
      }
      this.modalCreateDictGroupState = true
    },
    updateSelectedDictionariesForGroup(data) {

      let isChecked = data.filter(item => item.checked)

      isChecked = isChecked.map(item => {
        return {
          name: item.label,
          dictionary_group_id: this.selectedDictionariesGroup.id,
          dictionary_id: item.value
        }

      })

      this.selectedDictionariesGroup.dictionaries = isChecked;
    },
    editGroup(group) {
      this.selectedDictionariesGroup = group;
      this.selectedDictionariesIds = this.getSelectedDictionariesIds()
      this.showDictionaryForm(false)
    },
    getSelectedDictionariesIds() {
      return this.selectedDictionariesGroup.dictionaries.map(item => item.dictionary_id)
    },
    deleteGroup(id) {
      this.$api.dictionary.deleteDictionariesGroup({
        id: id,
        type: 'client'
      })
          .then(data => {
            this.$emit('update-list', data)
          })
    },
    saveDictionariesGroup() {

      if (!this.selectedDictionariesGroup.name || !this.selectedDictionariesGroup.type) {
        return this.$notify.error('Необходить заполнить поле "Название"')
      }
      this.$api.dictionary.saveDictionariesGroup({
        id: typeof this.selectedDictionariesGroup['id'] !== "undefined" ? this.selectedDictionariesGroup.id : null,
        name: this.selectedDictionariesGroup.name,
        color: this.selectedDictionariesGroup.color,
        type: this.selectedDictionariesGroup.type,
        dictionaries_ids: this.selectedDictionariesGroup.dictionaries.map(item => item.dictionary_id)
      })
          .then(data => {
            this.$emit('update-list', data)
          })
          .finally(() => {
            this.modalCreateDictGroupState = false
          })

    },
  },
}
</script>


<style scoped lang="scss">
h5 {
  margin: 10px 0;
  color: #555;
}

.groups {
  display: grid;
  //flex-wrap: nowrap;
  //overflow-x: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media (max-width:2250px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width:1800px) {
    grid-template-columns: 1fr 1fr 1fr ;
  }

  @media (max-width:1400px) {
    grid-template-columns: 1fr 1fr ;
  }

  .group {
    cursor: pointer;
    position: relative;
    min-width: 400px;
    padding: 5px 0 0 0;
    margin-bottom: 10px;
    user-select: none;
    border: solid 1px #8080802e;
    box-shadow: 2px 2px 7px 0 #8080802e;
    border-radius: 5px;
    margin-right: 20px;

    .title {
      padding: 5px 8px;
    }

    h4 {
      margin: 0 0 10px 0;
      color: #555;
      font-weight: 500;
      line-height: 1.3;
      font-size: 12px;
      text-transform: uppercase;
      width: 90%;
    }

    .color {
      height: 4px;
      width: 100%;
    }

    .commands {
      color: #8a8a8a;
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 8px;

      .el-icon-edit {
        display: inline-block;
        margin-right: 5px;
      }
    }

    h6 {
      margin: 10px 0 10px 8px;
    }

    .group-dictionaries {
      background: #fff;
      padding: 5px 8px;
      height: 135px;
      overflow-y: auto;


      ul {
        margin-left: 15px;

        li {
          position: relative;
          font-size: 12px;
          color: #4a4a4a;

          &:before {
            position: absolute;
            width: 3px;
            height: 3px;
            top: 7px;
            left: -13px;
            content: '';
            border-radius: 50%;
            background: rgb(25, 198, 3);
          }
        }
      }
    }
  }
}

::v-deep {
  .el-row {
    &:before {
      display: none;
    }
  }
}
</style>
