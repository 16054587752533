<template>
  <div class="content">
    <ul>
      <li><strong>Название:</strong> навание</li>
      <li><strong>Описание:</strong> описание</li>
      <li><strong>Id локации:</strong> 1</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DrawerLocationsContent"
}
</script>

<style lang="scss" scoped>
.content{
  padding: 15px;
}
ul{
  li{
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
</style>