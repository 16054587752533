<template>

  <div v-loading="loading" class="container">
    <div class="title">
      <h3>Группа словарей "{{group ? group.name : ''}}"</h3>
      <el-input
          :class="'input-search'"
          placeholder="Ищем словарь ?"
          v-model="search"
          suffix-icon="el-icon-search"
      >
      </el-input>
      <my-button @click="modalCreateDictGroupState = true">Добавить словари</my-button>
    </div>

    <div>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{path: '/dictionaries-and-checklists'}">Cловари / Чек-листы</el-breadcrumb-item>
        <el-breadcrumb-item>Группа словарей "{{group ? group.name : ''}}"</el-breadcrumb-item>
      </el-breadcrumb>
    </div>


    <div class="dictionaries-container">
      <group-dictionary-item
          v-if="computedDictionaries"
          v-for="(dictionary, index) in computedDictionaries"
          :key="'client' +'-'+ dictionary.id+'-'+index"
          :dictData="dictionary"
          :type="'client'"
          @show="showDictionaryInformationForm"
          @edit="showDictionaryForm"
          @delete="deleteDictionary"
          @delete-from-group="deleteDictionaryFromGroup"
      />
      <div v-else> Словари отсутствуют</div>
    </div>


    <el-drawer
        :visible.sync="visibleDictionaryDrawer"
        direction="rtl"
        size="50%"
    >
      <dictionary-form
          :selected-dictionary="selectedDictionary"
          :loading="loading"
          @cancel="visibleDictionaryDrawer = false"
          @save="save"
      >
      </dictionary-form>
    </el-drawer>
    <el-drawer
        :visible.sync="visibleDictionaryInformationDrawer"
        direction="rtl"
        size="50%"
    >
      <dictionary-information-form
          :selected-dictionary="selectedDictionary"
          @cancel="visibleDictionaryInformationDrawer = false"
          @save="save"
      >
      </dictionary-information-form>
    </el-drawer>

    <el-dialog
        :class="'modalAddDictGroup'"
        title="Добавить группу словарей"
        :visible.sync="modalCreateDictGroupState"
        width="40%"
        center>
      <div>
        <div class="modalRow">
          <label>
            <p>Название</p>
            <el-input placeholder="Название" v-model="group.name" disabled></el-input>
          </label>
        </div>
        <div class="modalRow">
          <label>
            <p>Выберите словари</p>
            <dictionary-group-list
                v-if="modalCreateDictGroupState"
                :propsDictList="clientDictionaryList"
                :checked="selectedDictionariesIds"
                @update-dictionaries="updateSelectedDictionariesForGroup"
            />
          </label>
        </div>
        <div class="modalRow">
          <label>
            <p>Цвет:</p>
            <el-color-picker v-model="group.color" disabled/>
          </label>

        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="modalCreateDictGroupState = false">отмена</el-button>
        <el-button type="primary" @click="saveDictionariesGroup">Сохранить</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import MyButton from "@/components/UI/MyButton.vue";
import DictionaryItem from "@/components/dictionary/DictonaryItem.vue";
import DictionaryForm from "@/components/dictionary/DictionaryForm.vue";
import DictionaryInformationForm from "@/components/dictionary/DictionaryInformationForm.vue";
import GroupDictionaryItem from "@/components/dictionary/group/GroupDictionaryItem.vue";
import DictionaryGroupList from "@/components/dictionary/group/dictionaryGroupList.vue";

export default {
  name: "DictionariesGroup",
  components: {
    DictionaryGroupList,
    GroupDictionaryItem, DictionaryInformationForm, DictionaryForm, DictionaryItem, MyButton},
  data() {
    return {
      modalCreateDictGroupState: false,
      clientDictionaryList: [],
      search: null,
      loading: false,
      group: {},
      dictionaries: [],
      visibleDictionaryDrawer: false,
      visibleDictionaryInformationDrawer: false,
      emptyDictionary: {
        reverse: 0,
        type: 'client',
        search_in_channel_1: 1,
        search_in_channel_2: 1,
        search_in_call: 1,
        search_out_call: 1,
        change_to_type: null
      },
      selectedDictionary: {},

    }
  },
  mounted() {
    this.getDictionariesGroup()
    this.setDictionariesList()
  },
  computed:  {
    computedDictionaries() {
      if(this.group.dictionaries) {
        if(this.search && this.search.length > 1) {
          let data =  this.group.dictionaries.filter(item => (item.dictionary.name.toLowerCase()).includes(this.search.toLowerCase()))
          if(data) {
            return data.map(item => item.dictionary)
          }
          return []
        }else {
          return this.group.dictionaries.map(item => item.dictionary)
        }
      }
      return []
    },
    selectedDictionariesIds() {
      if(this.group.dictionaries.length) {
        return this.group.dictionaries.map(item => item.dictionary_id)
      }
      return [];
    }
  },
  methods: {
    saveDictionariesGroup() {
      if (!this.group.name || !this.group.type) {
        return this.$notify.error('Необходить заполнить поле "Название"')
      }
      this.$api.dictionary.saveDictionariesGroup({
        id: this.group.id,
        name: this.group.name,
        color: this.group.color,
        type: 'client',
        dictionaries_ids: this.group.dictionaries.map(item => item.dictionary_id)
      })
          .then(data => {
            this.$emit('update-list', data)
          })
          .finally(() => {
            this.modalCreateDictGroupState = false
          })

    },
    setDictionariesList() {
        this.loading = true;
        this.$api.dictionary.get()
            .then(data => {
              this.dictionaries = data
              this.clientDictionaryList = data.for_group.map(item => {
                return {value:item.id,label:item.name, checked:false}
              })
            })
            .finally(() => {
              this.loading = false;
            })
    },
    updateSelectedDictionariesForGroup(data) {

      let isChecked = data.filter(item => item.checked)
      isChecked = isChecked.map(item => item.value)

      this.group.dictionaries = this.dictionaries.client.filter(item => isChecked.includes(item.id))
      this.group.dictionaries =  this.group.dictionaries.map(item => {
        return {dictionary_id: item.id, dictionary: item }
      })

    },
    getDictionariesGroup() {
      this.loading = true;
      this.$api.dictionary.getDictionariesGroup({
        id: this.$route.params.id
      })
          .then(data => {
            this.group = data
            this.loading = false;
          })
    },
    showDictionaryForm(data) {
      if (data) {
          this.selectedDictionary = this.group.dictionaries.find(dictionary => dictionary.dictionary.id === data.id);
          this.selectedDictionary = this.selectedDictionary.dictionary
          this.selectedDictionary.type = 'client';
      } else {
        this.selectedDictionary = this.emptyDictionary
      }
      this.visibleDictionaryDrawer = true;

    },
    showDictionaryInformationForm(data) {
      this.selectedDictionary = this.group.dictionaries.find(dictionary => dictionary.dictionary.id === data.id);
      this.visibleDictionaryInformationDrawer = true;
    },
    save() {
      this.loading = true;
      if (!this.selectedDictionary.name) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Поля Имя обязательно для заполнения',
        });
        return;
      }

      if (this.selectedDictionary.change_to_type === this.selectedDictionary.type) {
        this.selectedDictionary.change_to_type = null;
      }
      this.$api.dictionary.save(this.selectedDictionary)
          .then(data => {

            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Словать сохранен',
              });
              this.reset();
              this.getDictionariesGroup();

            } else {

              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors.message
              });
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },
    deleteDictionary(data) {
      this.loading = true;
      this.$api.dictionary.delete({id: data.id, type: data.type})
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Словарь удален',
            });
            this.getDictionariesGroup()
          })
          .finally(() => {
            this.loading = false;
          })
    },
    deleteDictionaryFromGroup(data) {
      this.loading = true;
      this.$api.dictionary.deleteDictionaryFromGroup({
        group_id: this.group.id,
        dictionary_id: data.id,
      })
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Словарь удален',
            });
            this.getDictionariesGroup()
          })
          .finally(() => {
            this.loading = false;
          })
    }
  }
}
</script>


<style lang="scss" scoped>

.container {
  background: #fff;
  padding: 1rem;

  ::v-deep {
    .input-search {
      margin-right: 20px;
      max-width: 220px;
      input {
        height: 30px;
        border: none;
        border-bottom: solid 1px black;
        border-radius: 0;

      }
      .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .title {
    display: grid;
    grid-template-columns: 1fr 200px 190px;
    gap: 30px;
    h3 {
      margin-bottom: 10px;
    }
    .my-button {
      height: 28px;
    }
  }
  .el-breadcrumb {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .dictionaries-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 1700px) {
      & {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    @media (max-width: 1500px) {
      & {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    @media (max-width: 1200px) {
      & {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

}

</style>