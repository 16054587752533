<template>
  <div class="container">
    <div class="title">
      <h4>Сравнительный анализ сотрудников <span>(базовый)</span></h4>
    </div>
    <div class="content">
      <div class="select-block"
           :style="{display:selectedCategories?selectedCategories.length?'grid':'flex':'flex',
           justifyContent:selectedCategories?selectedCategories.length?'unset':'space-between':'space-between'}"
      >
        <selection-block-category-employees
            :getCategory="getSelectedCategories"
            :getLocationsData="getLocationsData"
        />
        <my-date-period-picker-analytics
            :getTime="getStartEndTimeForGetCategoryCalls"
        />
      </div>
      <div class="statistic-block">
        <employees-statistic-block
            :locationsData="locationsData"
            :propsData="agentsResult"
            :loading="loading"
        />
      </div>
    </div>


  </div>
</template>

<script>


import MyDatePeriodPickerAnalytics from "@/components/analytics/basicCategoryAnalysis/myDatePeriodPickerAnalytics.vue";
import SelectionBlockCategoryEmployees from "@/components/analytics/сomparativeAnalysisEmployee/SelectionBlockCategoryEmployees.vue";
import EmployeesStatisticBlock from "@/components/analytics/сomparativeAnalysisEmployee/EmployeesStatisticBlock.vue";


export default {
  name: "ComparativeAnalysisEmployee",
  components: {EmployeesStatisticBlock, SelectionBlockCategoryEmployees, MyDatePeriodPickerAnalytics},

  data() {
    return {
      startEndDateForCallListAndCategoryDictionaries: {start: '', end: ''},
      selectedCategories: null,
      agentsResult: [],
      locationsData: [],
      loading: false,

    }
  },
  methods: {
    getLocationsData(callback) {
      return this.locationsData = callback
    },
    getStartEndTimeForGetCategoryCalls(callback) {
      this.startEndDateForCallListAndCategoryDictionaries = callback
    },
    getCategoryDictionaries(agent) {
      this.loading = true;
      this.$api.category.getCategoryDictionaries({
        category_id: agent.category_id,
        agent_name: agent.agent_name,
        date_start: this.startEndDateForCallListAndCategoryDictionaries.start ? this.startEndDateForCallListAndCategoryDictionaries.start : '',
        date_end: this.startEndDateForCallListAndCategoryDictionaries.end ? this.startEndDateForCallListAndCategoryDictionaries.end : '',
      })
          .then(data => {
            let agentsResult = [...this.agentsResult]
            agentsResult.find(item => item.category_id === agent.category_id && item.agent_name === agent.agent_name).dictionaries = data
            this.agentsResult = agentsResult
            this.getCategoryDictionariesFullData()
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getCategoryDictionariesFullData() {
      this.loading = true;
      this.agentsResult.forEach((item, index) => {

        if(item.dictionaries) {
          item.dictionaries.system.forEach((dict, idx) => {
            setTimeout(()=>{},[2000])
            this.$api.category.getCategoryDictionaryFullData({
              category_id: item.category_id,
              dictionary_id: dict.dictionary_id,
              dictionary_type: dict.dictionary_type,
              agent_name: item.agent_name,
              with_data: 1,
              date_start: this.startEndDateForCallListAndCategoryDictionaries.start ? this.startEndDateForCallListAndCategoryDictionaries.start : '',
              date_end: this.startEndDateForCallListAndCategoryDictionaries.end ? this.startEndDateForCallListAndCategoryDictionaries.end : '',
            })
                .then(data => {
                  this.agentsResult[index].dictionaries.system[idx] = data
                })
                .finally(() => {
                  this.loading = false;
                })
          })

          item.dictionaries.client.forEach((dict, idx) => {
            setTimeout(()=>{},[2000])
            this.$api.category.getCategoryDictionaryFullData({
              category_id: item.category_id,
              dictionary_id: dict.dictionary_id,
              dictionary_type: dict.dictionary_type,
              agent_name: item.agent_name,
              with_data: 1,
              date_start: this.startEndDateForCallListAndCategoryDictionaries.start ? this.startEndDateForCallListAndCategoryDictionaries.start : '',
              date_end: this.startEndDateForCallListAndCategoryDictionaries.end ? this.startEndDateForCallListAndCategoryDictionaries.end : '',
            })
                .then(data => {
                  this.agentsResult[index].dictionaries.client[idx] = data
                })
                .finally(() => {
                  this.loading = false;
                })
          })
        }


      })


    },
    getCategoryRecognizedCalls(agent) {
      this.loading = true;
      this.$api.category.getCategoryRecognizedCalls({
        category_id: agent.category_id,
        agent_name: agent.agent_name,
        date_start: this.startEndDateForCallListAndCategoryDictionaries.start,
        date_end: this.startEndDateForCallListAndCategoryDictionaries.end,
      })
          .then(data => {
            let agentsResult = [...this.agentsResult]
            // agentsResult.find((item => item.id === id)).calls = data
            agentsResult.find(item => item.category_id === agent.category_id && item.agent_name === agent.agent_name).calls = data
            this.agentsResult = agentsResult
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getCategoryChecklists(agent) {
      this.loading = true;
      this.$api.category.getCategoryChecklists({
        category_id: agent.category_id,
        agent_name: agent.agent_name,
        date_start: this.startEndDateForCallListAndCategoryDictionaries.start ? this.startEndDateForCallListAndCategoryDictionaries.start : '',
        date_end: this.startEndDateForCallListAndCategoryDictionaries.end ? this.startEndDateForCallListAndCategoryDictionaries.end : '',
      })
          .then(data => {
            let agentsResult = [...this.agentsResult]
            agentsResult.find(item => item.category_id === agent.category_id && item.agent_name === agent.agent_name).checklist = data
            this.agentsResult = agentsResult
            this.getChecklistAnalytics(agent)
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getChecklistAnalytics(agent) {
      this.loading = true;
      this.$api.category.getCategoryChecklistAnalytics({
        category_id: agent.category_id,
        agent_name: agent.agent_name,
        date_start: this.startEndDateForCallListAndCategoryDictionaries.start ? this.startEndDateForCallListAndCategoryDictionaries.start : '',
        date_end: this.startEndDateForCallListAndCategoryDictionaries.end ? this.startEndDateForCallListAndCategoryDictionaries.end : '',
      })
          .then(data => {
            let agentsResult = [...this.agentsResult]
            agentsResult.find(item => item.category_id === agent.category_id && item.agent_name === agent.agent_name).checklist = data
            this.agentsResult = agentsResult
          })
          .finally(() => {
            this.loading = false;
          })
    },
    getSelectedCategories(callBack) {
      this.selectedCategories = callBack

    },
    getCategoryApi(item) {
      this.$api.category.getCategoryAgent({
        category_id: item[0],
        agent_name: item[1],
      })
          .then((data) => {
            this.agentsResult = [...this.agentsResult, data]
            this.getCategoryRecognizedCalls(data)
            this.getCategoryChecklists(data)
            this.getCategoryDictionaries(data)
          })
          .finally(() => {
          })
    },
  },
  watch: {
    selectedCategories() {
      this.agentsResult = []
      this.selectedCategories.forEach(item => {
        this.getCategoryApi(item)
      })
    },
    startEndDateForCallListAndCategoryDictionaries() {
      this.agentsResult = []
      if (this.selectedCategories && this.selectedCategories) {
        this.selectedCategories.forEach(id => {
          this.getCategoryApi(id)
        })
      }

    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 15px;
  min-height: 85vh;


  .title {
    margin-bottom: 30px;

    h4 {
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 700;
      color: #555;

      span {
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  .content {
    padding: 15px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
    border-radius: 10px;
    background: #fff;
    min-height: 75vh;

    .select-block {
      display: flex;
      //align-items: center;
      justify-content: space-between;
      gap: 10px;
      .wrapp{
        display: flex ;
      }

    }
  }
}

::v-deep {
  .el-input__inner {
    height: 30px;
  }

  .el-input__suffix {
    i {
      line-height: 1;
    }
  }
}
</style>