import Vue from "vue";
import Vuex from "vuex";

import Api from '../api/base'
import ConstantsPlugin from '../plugins/constants'
import router from '@/router'

Vue.use(Vuex);
Vue.use(ConstantsPlugin);



export default new Vuex.Store({
    state: {
        vatsConfig:null,
        user: {
            id: null,
            permissions: [],
        },
        location: { id: null},
        subLocation: { id: null},
        category: { id: null},
        dictionary: { id: null},
        checklist: { id: null},
    },
    getters: {
        user(state) {
            return state.user
        },

        location(state) {
            return state.location
        },
        subLocation(state) {
            return state.subLocation
        },
        category(state) {
            return state.category
        },
        dictionary(state) {
            return state.dictionary
        },
        checklist(state) {
            return state.checklist
        },
        menuLoading(state) {
            return state.menuLoading
        },
        locationMenuItems(state) {
            return state.locationMenuItems
        },
        vatsConfig(state) {
            return state.vatsConfig
        },
    },
    actions: {
        fetchUser(ctx) {
            Api.user.getAuthUser()
                .then(data => {
                    ctx.commit('updateUser', data.user);
                })
        },
    },

    mutations: {
        updateUser(state, user) {
            state.user = user;
        },
        updateLocation(state, location) {
            state.location = location;
        },
        updateSubLocation(state, subLocation) {
            state.subLocation = subLocation;
        },
        updateCategory(state, category) {
            state.category = category;
        },
        updateDictionary(state, dictionary) {
            state.dictionary = dictionary;
        },
        updateChecklist(state, checklist) {
            state.checklist = checklist;
        },
        updateVatsConfig (state,newDate){
            state.vatsConfig = newDate
        }
    }

})