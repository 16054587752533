<template>
  <div class="item">

    <div class="item-header">
      <span>Процент выполнения(среднее)</span>

    </div>
    <div class="item-body__progress">
      <my-progress :percentage="propsCheckListData.average.percentage"/>

    </div>
    <div class="item-body">
      <ul class="item-body__list">
        <li
            v-if="dict"
            v-for="(dict, index) in sortingByCountCalls.checklist.dictionaries"
            :key="dict.dictionary_type + '-' + index + '-' + dict.id"
        >
          <el-tooltip
              v-if="dict.dictionary.reverse"
              effect="dark"
              content="Реверсивный"
              placement="right-start"
          >
            <i class="el-icon-sort"></i>
          </el-tooltip>

          <span class="dictionary-name">{{ dict.name }}</span>

          <span class="show-call-dialog" @click="showCategoryCallDialog(dict, 'checklist-dictionary')">
            {{dict.dictionary.found_count_by_period.all.length}}
          </span>
        </li>
      </ul>
    </div>
    <div class="item-footer">
      <div class="item-footer__performed">
<!--        <span v-if="propsCheckListData.average.percentage === 0">Не выполнен</span>-->
        <span v-if="propsCheckListData.average.percentage < 100">Порог срабатывания - {{propsCheckListData.limit_result}} баллов</span>
        <span v-else>Выполнено</span>
      </div>
      <div class="item-footer__content">
        <div class="points">
<!--         Необходимый результат для выполнения чек-листа: {{propsCheckListData.limit_result}}-->
        </div>
        <div class="score"></div>
      </div>
    </div>
  </div>

</template>

<script>
import MyProgress from "@/components/UI/MyProgress.vue";

export default {
  name: "checklist-work-tab-item",
  components: {MyProgress},
  props: {
    propsCheckListData: {
      type: Object
    }
  },
  methods: {
    showDrawer(callId) {
      this.$emit('show-call-info-drawer', callId)
    },
    showCategoryCallDialog(data, type) {
      console.log('test')
      console.log(data)
      this.$emit('show-category-calls-dialog', {data:data, type: type})
    }
  },

  computed: {
    sortingByCountCalls: function () {
      let dictionaries = [...this.propsCheckListData.checklist.dictionaries.system, ...this.propsCheckListData.checklist.dictionaries.client]
      dictionaries.sort((a,b) => b.dictionary.found_count_by_period.all.length - a.dictionary.found_count_by_period.all.length)
      this.propsCheckListData.checklist.dictionaries = dictionaries;
      return this.propsCheckListData;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/css/project-variables";
.item {
  border-radius: 10px;
  user-select: none;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0px #8080802e;
  height: 100%;

  .item-body__progress {
    padding: 0 10px 10px;
  }


  .item-header {
    padding: 10px 10px 0;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: #555;
      font-weight: 500;
      font-size: 13px;
    }

    button {
      cursor: pointer;
      padding: 5px;
      border: none;
      background: none;

      i {
        color: #bababa;
      }
    }
  }

  .item-body {
    padding: 0 15px;
    margin-bottom: 10px;
    height: 300px;
    overflow: auto;

    &__list {

      .show-call-dialog {
        color: $--color-primary;
        cursor: pointer;
      }

      li {
        font-size: 11px;
        color: #555;
        font-weight: normal;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        padding-left: 20px;
        
        .el-icon-sort {
          position: absolute;
          top: 11px;
          left: 0;
          transform: rotate(90deg);
          color: red;
          font-weight: bold;
        }
        
        span {
          &:first-child {

          }

          transform: translateY(8px);
          background: #fff;
        }
        ::v-deep .category-calls .popover-button {
          padding: 0 2px;
        }
      }
    }
  }


  .item-footer {
    position: relative;
    background: #ececec;

    &__performed {
      position: absolute;
      top: -10px;
      right: 10px;

      span {
        color: #fff;
        font-size: 12px;
        border-radius: 2px;
        padding: 5px 5px;
        background: gray;
      }
    }

    &__content {

      padding: 15px 20px 10px;

      .points {
        font-size: 11px;
        color: #8a8a8a;
      }

      .score {
        font-size: 11px;
        color: #8a8a8a;
      }
    }
  }
}


</style>