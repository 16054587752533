<template>
  <el-row>
    <h4>Обработано</h4>
   <el-row class="progress">
     <circular-progress-bar
         :color="'#f2be58'"
         :percent="70"
         :text="'Аудио'"
     />
   </el-row>
    <el-row>
      <el-row type="flex">
        <el-col :span="16">Всего аудио</el-col>
        <el-col :span="8">16 280</el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="16">К анализу</el-col>
        <el-col :span="8">4 180</el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="16">Обработано</el-col>
        <el-col :span="8">2 550</el-col>
      </el-row>
    </el-row>
  </el-row>

</template>

<script>

import CircularProgressBar from "@/components/common/CircularProgressBar";
export default {
  name: "report",
  components: {CircularProgressBar}
}
</script>

<style lang="scss" scoped>
.el-row {
  h4 {
    text-align: center;
  }
  .progress {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  > .el-row {
    > .el-col {
      font-size: 14px;
      &:first-child {
        color: #b5b5b5;
      }
      &:last-child {
        color: #1A2122;
        font-weight: 700;
      }
    }
  }
}
</style>