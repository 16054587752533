<template>
  <div class="container">
    <div class="title">
      <h4>Анализ эффективности сотрудника <span>(пиктограммы)</span></h4>
    </div>
    <div class="content">
      <div class="select-block"  :style="{pointerEvents:totalCountRequests ===pendingRequests?'unset':'none',
      opacity:totalCountRequests ===pendingRequests?1:0.5}">
        <div class="wrap"
             :style="{marginTop:selectedCategories?selectedCategories.length?'0':'0':'0'}">
          <my-switch :propsGetSwitchState="getSwitchState"/>
          <my-date-period-picker-analytics
              :getTime="getStartEndTimeForGetCategoryCalls"
          />
        </div>
        <selection-block-category
            :getCategory="getSelectedCategories"
            :getLocationsData="getLocationsData"
        />
      </div>
      <div class="result">
        <user-rating-list
            v-if="uniqueData.length"
            :propsStartEndDateForCallListAndCategoryDictionaries="startEndDateForCallListAndCategoryDictionaries"
            :propsUniqueData="computedSortedData"
            :propsSelectedCategories="selectedCategories"
            :propsGetCurrentItemRating="getCurrentItemRating"
            :props-change-sort="changeSort"
            :props-state-sort="stateSort"
            :propsChangePendingRequests="changePendingRequests"
            :propsTotalCountRequests="totalCountRequests"
            :propsPendingRequests="pendingRequests"
        />
      </div>
    </div>
  </div>

</template>

<script>
import SelectionBlockCategory from "@/components/analytics/basicCategoryAnalysis/SelectionBlockCategory.vue";
import MyDatePeriodPickerAnalytics from "@/components/analytics/basicCategoryAnalysis/myDatePeriodPickerAnalytics.vue";
import UserRatingList from "@/components/analytics/basicEmployeeAnalysis/userRatingList/userRatingList.vue";
import MySwitch from "@/components/UI/MySwitch.vue";
import UserCallList from "@/components/analytics/basicEmployeeAnalysis/userCallsList/userCallList.vue";
import MyButton from "@/components/UI/MyButton.vue";

export default {
  name: "BasicEmployeeAnalysis",
  components: {MyButton, UserCallList, MySwitch, UserRatingList, MyDatePeriodPickerAnalytics, SelectionBlockCategory},
  data() {
    return {
      selectedCategories: [],
      startEndDateForCallListAndCategoryDictionaries: null,
      locationsData: null,
      switchType: 'имени',
      uniqueData: [],
      stateSort: {status: false, type: null, field: null},
      totalCountRequests: 0,
      pendingRequests: 0,
    }
  },
  watch: {
    pendingRequests() {
      if (this.totalCountRequests) {
        if (this.totalCountRequests === this.pendingRequests) {
          this.stateSort = {...this.stateSort, status: true}
        }
      }
    },
    selectedCategories() {
      this.uniqueData = []
      this.getUniqueData()
      this.stateSort = {status: false, type: null, field: null}
      this.pendingRequests = 0
    },
    switchType() {
      if (this.selectedCategories.length) {
        this.uniqueData = []
        this.getUniqueData()
        this.stateSort = {status: false, type: null, field: null}
        this.pendingRequests = 0

      }
    },
    startEndDateForCallListAndCategoryDictionaries() {
      if (this.selectedCategories.length) {
        this.uniqueData = []
        this.getUniqueData()
        this.stateSort = {status: false, type: null, field: null}
        this.pendingRequests = 0
      }
    }
  },
  computed: {
    computedSortedData() {
      if (this.stateSort.type && this.stateSort.field) {
        if (this.stateSort.type === 'DESC') {
          return this.uniqueData.sort((a, b) => b.data[this.stateSort.field] - a.data[this.stateSort.field])
        } else {
          return this.uniqueData.sort((a, b) => a.data[this.stateSort.field] - b.data[this.stateSort.field])
        }
      }
      return this.uniqueData
    }
  },
  methods: {
    changePendingRequests() {

      this.pendingRequests += 1
    },
    changeSort(sort) {
      this.stateSort = sort
    },
    getCurrentItemRating(data) {
      this.uniqueData[data.index].data = data
    },
    getSwitchState(state) {
      this.switchType = state
    },

    getLocationsData(callback) {
      return this.locationsData = callback
    },
    getSelectedCategories(callBack) {
      this.selectedCategories = callBack
    },
    getStartEndTimeForGetCategoryCalls(callback) {
      this.startEndDateForCallListAndCategoryDictionaries = callback
    },
    getUniqueData() {
      if (this.switchType === 'имени') {
        this.getUniqueName()
      } else {
        this.getUniqueNumbersPhone()
      }
    },
    getUniqueName() {
      this.$api.analysis.getUniqueUsers(
          {
            categories: this.selectedCategories,
            date_start: this.startEndDateForCallListAndCategoryDictionaries.start ? this.startEndDateForCallListAndCategoryDictionaries.start : '',
            date_end: this.startEndDateForCallListAndCategoryDictionaries.end ? this.startEndDateForCallListAndCategoryDictionaries.end : '',
          }
      ).then(data => {
        let newUniqueData = []
        data.forEach(({agent_name}) => {
          newUniqueData.push({title: agent_name, type: 'name', categories: this.selectedCategories})
        })
        this.uniqueData = newUniqueData
        this.totalCountRequests = newUniqueData.length


      })
    },
    getUniqueNumbersPhone() {
      this.$api.analysis.getUniquePhoneNumbers(
          {
            categories: this.selectedCategories,
            date_start: this.startEndDateForCallListAndCategoryDictionaries.start ? this.startEndDateForCallListAndCategoryDictionaries.start : '',
            date_end: this.startEndDateForCallListAndCategoryDictionaries.end ? this.startEndDateForCallListAndCategoryDictionaries.end : '',
          }
      ).then(data => {
        let newUniqueData = []
        data.forEach(({extension_number}) => {
          newUniqueData.push({title: extension_number, type: 'phone'})
        })
        this.uniqueData = newUniqueData
        this.totalCountRequests = newUniqueData.length

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 15px;
  min-height: 85vh;
  border-right: 10px;

  .title {
    margin-bottom: 30px;

    h4 {
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 700;
      color: #555;

      span {
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  .content {
    padding: 15px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
    border-radius: 10px;
    background: #fff;
    min-height: 75vh;

    .result {
      border-top: solid 1px #DCDFE6;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
    }
  }
}

.select-block {
  display: flex;
  align-items: flex-start;
  gap: 20px;

}

.wrap {
  display: flex;
  gap: 20px;
  justify-content: space-between;

}

::v-deep {
  .el-input__suffix {
    display: none;
  }

  .el-input__inner {
    height: 30px;
  }

  .el-input__suffix {
    i {
      line-height: 1;
    }
  }
}
</style>