import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        get(payload){
            return requestSender('get', 'checklist/', payload);
        },
        save(payload){
            return requestSender('post', 'checklist/save', payload);
        },
        delete(payload){
            return requestSender('post', 'checklist/delete', payload);
        },
        saveChecklistDictionary(payload) {
            return requestSender('post', 'checklist/save-checklist-dictionary', payload);
        },
        deleteChecklistDictionary(payload) {
            return requestSender('post', 'checklist/delete-checklist-dictionary', payload);
        }

    }
}