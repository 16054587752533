var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-header"},[_c('h4',[_vm._v(_vm._s(_vm.dictData.dictionary.name))]),_vm._m(0)]),_c('div',{staticClass:"dict-description"},[_vm._m(1),_c('ul',_vm._l((_vm.dictData.dictionary.description),function(row,index){return _c('li',{key:index},[_vm._v(_vm._s(row.replaceAll('|', ' | '))+" ")])}),0)]),_c('div',{staticClass:"item-footer"},[(_vm.dictData.dictionary.positive_weight || _vm.dictData.dictionary.negative_weight)?_c('div',{staticClass:"item-footer__performed"},[(_vm.dictData.dictionary.positive_weight)?_c('span',{style:({background:_vm.dictData.dictionary.color_success})},[_vm._v(" +"+_vm._s(_vm.dictData.dictionary.positive_weight)+" ")]):_vm._e(),(_vm.dictData.dictionary.negative_weight)?_c('span',{style:({background:_vm.dictData.dictionary.color_reject})},[_vm._v(" -"+_vm._s(_vm.dictData.dictionary.negative_weight)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"item-footer__content"},[(_vm.dictData.dictionary.found.length)?_c('div',[_c('p',[_vm._v("Найденые фразы:")]),_c('div',{staticClass:"high-lite-block"},[(_vm.propsCallReverse
                   ?_vm.dictData.dictionary.found[0]['search_text_highlight_one']
                   :_vm.dictData.dictionary.found[0]['search_text_highlight_two'])?_c('div',{staticClass:"client",on:{"click":function($event){return _vm.propsGetHighlight(
                   _vm.propsCallReverse
                   ? {data:_vm.dictData.dictionary.found[0]['search_text_highlight_one'][0],reverse:_vm.dictData.dictionary.reverse,target:'client'}
                   : {data:_vm.dictData.dictionary.found[0]['search_text_highlight_two'][0],reverse:_vm.dictData.dictionary.reverse,target:'client'})}}},[_c('div',[_c('p',[_c('span',{staticClass:"client-span"},[_vm._v(" Клиент: ")]),_vm._l((_vm.propsCallReverse
                   ? _vm.dictData.dictionary.found[0]['search_text_highlight_one']
                   : _vm.dictData.dictionary.found[0]['search_text_highlight_two']),function(word){return _c('span',_vm._l((word),function(item){return _c('i',{staticClass:"i",domProps:{"innerHTML":_vm._s(item)}})}),0)})],2)])]):_vm._e(),(_vm.propsCallReverse
                  ?_vm.dictData.dictionary.found[0]['search_text_highlight_two']
                  :_vm.dictData.dictionary.found[0]['search_text_highlight_one'])?_c('div',{staticClass:"employee",on:{"click":function($event){return _vm.propsGetHighlight(_vm.propsCallReverse
                      ? {data:_vm.dictData.dictionary.found[0]['search_text_highlight_two'][0],reverse:_vm.dictData.dictionary.reverse,target:'employee'}
                      : {data:_vm.dictData.dictionary.found[0]['search_text_highlight_one'][0],reverse:_vm.dictData.dictionary.reverse,target:'employee'}                        )}}},[_c('div',[_c('p',[_c('span',{staticClass:"employee-span"},[_vm._v(" Сотрудник: ")]),_vm._l((_vm.propsCallReverse
                   ? _vm.dictData.dictionary.found[0]['search_text_highlight_two']
                   : _vm.dictData.dictionary.found[0]['search_text_highlight_one']),function(word){return _c('span',_vm._l((word),function(item){return _c('i',{staticClass:"i",domProps:{"innerHTML":_vm._s(item)}})}),0)})],2)])]):_vm._e()])]):_c('p')])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{attrs:{"type":"button"}},[_c('i',{staticClass:"el-icon-more"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("Описание:")]),_c('br')])}]

export { render, staticRenderFns }