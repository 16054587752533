<template>
  <div class="calls">
    <el-dialog
        :visible.sync="localModalState"
        @close="close"
        width="85%"
        :close-on-press-escape="true"
        :close-on-click-modal="true"
        :title="'Звонки пользователя'"
    >
      <div
          class="calls-header"
      >
<!--        <div>Кто ответил</div>-->
<!--        <div>Тип звонка</div>-->
        <div>ID звонка</div>
        <div>ID у организации</div>
        <div>Дата звонка</div>
        <div>Продолжительность</div>
        <div>Вес</div>
      </div>

      <div class="calls-body">

        <div

            v-for="(call, index) in propsUserData.data?propsUserData.data.calls:[]"
            :key="index"
            class="calls-body__call"
            @click="showDrawer(call.call_info_id)"
        >
<!--          <div>{{propsUserData.title}}</div>-->
<!--          <div>-&#45;&#45;</div>-->
          <div>{{call.call_id}}</div>
          <div>{{call.id_at_the_client}}</div>
          <div>{{call.date_call}}</div>
          <div>{{call.call_time_minutes}}</div>
          <div class="weight"><span>{{call.total_positive_weight}}</span> | <span>{{call.total_negative_weight}}</span></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "dialog-calls-user",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    propsUserData:{
      type:Object
    }
  },
  data() {
    return {
      localModalState:false
    }
  },
  watch:{
    dialogVisible(){
      this.localModalState = this.dialogVisible
    }
  },

  methods: {
    showDrawer(callId) {
      this.$emit('show-call-info-drawer', callId)
    },
    close() {
      this.$emit('close',false)
    },
  }
}
</script>

<style lang="scss" scoped>
.calls {
  padding: 0 30px;
  box-sizing: border-box;
  text-align: center;
  &-header {
    display: grid;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -20px;
    gap: 20px;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    div {
      &:first-child {
        text-align: left;
      }
    }
  }
  &-body {
    height: 50vh;
    overflow-y: auto;
    &__call {
      display: grid;
      gap: 20px;
      align-items: center;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      transition: background 200ms linear;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      div {
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        padding: 5px 0;
        font-size: 11px;
        &:first-child {
          text-align: left;
        }
      }
      &:hover {
        background: #f5f7fd;
      }
    }
  }

  &-call {
    &__body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

      //height: 80vh;
      //overflow-y: auto;

    }


    ::v-deep .el-button {
      background: #000;
    }

    ::v-deep .el-statistic {
      .head {
        font-size: 12px;
      }
      .con {
        margin-bottom: 3px;
        padding: 0 10px;
        .number {
          font-size: 14px;
        }

      }
    }
  }

  .popover-button {
    color: #24387f;
    cursor:pointer;
    font-size: 12px;
    padding: 0 10px;
  }
}
.weight{
  span{
    &:first-child{
      color: green;
    }
    &:last-child{
      color: red;
    }
  }
}
</style>