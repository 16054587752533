<template>
  <div v-loading="loading" class="checklists">
    <div class="top-block">
      <div class="title">
        <h4>Чеклисты</h4>
      </div>
      <div class="btn-wrapper">
        <my-button @click="showChecklistForm()">
          Добавить
        </my-button>
      </div>
    </div>

    <div v-if="checklists.length">
      <div class="checklists-container">
        <div
            class="checklist"
            v-for="checklist in checklists"
            :key="checklist.id"

        >
          <div class="item">
            <div class="item-header">
              <h4>{{ checklist.name }}</h4>
              <div class="btns-actions">
                <i class="el-icon-edit" @click="showChecklistForm(checklist.id)"></i>
                <el-popconfirm
                    :title="'Вы уверны, что хотите удалить чеклист ' + checklist.name + '?'"
                    @confirm="deleteChecklist(checklist.id)"
                >
                  <i class="el-icon-delete" slot="reference"></i>
                </el-popconfirm>
              </div>

            </div>


            <div class="dictionaries">

              <ul v-if="checklist.dictionaries.system.length || checklist.dictionaries.client.length">

                <li v-for="row in [...checklist.dictionaries.system, ...checklist.dictionaries.client]">
                  <div>
                    <div>
                      <el-tooltip class="tool-tip" effect="dark" content="Цвет 'успешно' | Цвет 'отклонен'"
                                  placement="top">
                        <div class="item-footer__performed">
                          <span
                              v-if="row.dictionary.positive_weight"
                              :style="{background: row.dictionary.color_success}"
                          >
                            +{{ row.dictionary.positive_weight }}
                          </span>
                          <span
                              v-if="row.dictionary.negative_weight"
                              :style="{background: row.dictionary.color_reject}"
                          >
                            -{{ row.dictionary.negative_weight }}
                          </span>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div>{{ row.dictionary.name }}</div>
                </li>
              </ul>
            </div>


            <div class="footer-wrapper">
              <div class="item-footer">
<!--                <el-tooltip class="tool-tip" effect="dark" content="Цвет 'успешно' | Цвет 'отклонен'" placement="top">-->
<!--                  <div class="item-footer__performed">-->
<!--                    <span v-if="checklist.positive_weight"-->
<!--                          :style="{background:checklist.color_success}">+{{ checklist.positive_weight }}</span>-->
<!--                    <span v-if="checklist.negative_weight"-->
<!--                          :style="{background:checklist.color_reject}">-{{ checklist.negative_weight }}</span>-->
<!--                  </div>-->
<!--                </el-tooltip>-->
                <div class="item-footer__content">
                  <div>
                    <h5>Порог срабатывания: {{ checklist.limit_result }}</h5>
                    <h5>Включенные словари:
                      {{ checklist.dictionaries.system.length + checklist.dictionaries.client.length }}
                    </h5>
                  </div>
                  <i
                      class="el-icon-edit"
                      @click="showChecklistDictionaryForm(checklist.id)"
                  ></i>
                  <!--                  <ul>-->
                  <!--                    <li  v-on:click.stop>-->
                  <!--                      <span>За текущий день:</span>-->
                  <!--                      <span> {{ checklist.found_count_by_period.day }}</span>-->
                  <!--                    </li>-->
                  <!--                    <li v-on:click.stop>-->
                  <!--                      <span>За текущий месяц:</span>-->
                  <!--                      <span> {{ checklist.found_count_by_period.month }}</span>-->
                  <!--                    </li>-->
                  <!--                    <li v-on:click.stop>-->
                  <!--                      <span>За все время:</span>-->
                  <!--                      <span> {{ checklist.found_count_by_period.all }}</span>-->
                  <!--                    </li>-->
                  <!--                  </ul>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <el-alert
          title="Чеклисты отсутствуют"
          type="error">
      </el-alert>
    </div>

    <el-drawer
        :visible.sync="visibleChecklistDrawer"
        direction="rtl"
        size="50%"
    >
      <checklist-form
          :selected-checklist="selectedChecklist"
          @cancel="visibleChecklistDrawer = false"
          @save="save"
      >
      </checklist-form>
    </el-drawer>

    <el-drawer
        :visible.sync="visibleChecklistDictionaryDrawer"
        direction="rtl"
        size="90%"
    >

      <checklist-dictionaries-transfer
          v-if="selectedChecklist"
          :checklist="selectedChecklist"
          :selected-system-dictionaries="selectedSystemDictionaries"
          :selected-client-dictionaries="selectedClientDictionaries"
          @reload="getChecklists"
      >
      </checklist-dictionaries-transfer>
    </el-drawer>

  </div>

</template>

<script>
import ChecklistForm from "@/components/checklist/ChecklistForm";
import ChecklistMenu from "@/components/checklist/ChecklistMenu";
import ChecklistDictionariesTransfer from "@/components/checklist/ChecklistDictionariesTransfer.vue";
import MyButton from "@/components/UI/MyButton.vue";


export default {
  name: 'checklist',
  components: {MyButton, ChecklistDictionariesTransfer, ChecklistMenu, ChecklistForm},
  data() {
    return {
      loading: false,
      checklists: [],
      visibleChecklistDrawer: false,
      visibleChecklistDictionaryDrawer: false,
      selectedChecklist: {},
      selectedSystemDictionaries: [],
      selectedClientDictionaries: [],
    }
  },
  computed: {},

  mounted() {
    this.getChecklists()
  },
  methods: {
    getChecklists(selectedId = null) {
      this.loading = true;
      this.$api.checklist.get()
          .then(data => {
            this.checklists = data
            if (selectedId) {
              this.selectedChecklist = {};
              this.showChecklistDictionaryForm(selectedId)
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },
    deleteChecklist(id) {
      this.loading = true;
      this.$api.checklist.delete({id: id})
          .then(data => {
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Успешно',
              message: 'Локация удалена',
            });
            this.getChecklists()
          })
          .finally(() => {
            this.loading = false;
          })
    },

    showChecklistForm(id = null) {
      if (id) {
        this.selectedChecklist = this.checklists.find(checklist => checklist.id === id)
      } else {
        this.selectedChecklist = {};
      }
      this.visibleChecklistDrawer = true;
    },

    showChecklistDictionaryForm(id) {
      this.selectedChecklist = this.checklists.find(category => category.id === id);
      this.selectedSystemDictionaries = this.selectedChecklist.dictionaries.system;
      this.selectedClientDictionaries = this.selectedChecklist.dictionaries.client;
      this.visibleChecklistDictionaryDrawer = true;
    },

    save() {
      this.loading = true;
      if (!this.selectedChecklist.name) {
        this.$notify.error({
          duration: this.$constants.global.notifyDurationError,
          title: 'Ошибка',
          message: 'Поля Имя обязательно для заполнения',
        });
        return;
      }

      this.$api.checklist.save(this.selectedChecklist)
          .then(data => {

            if (!data.errors) {
              this.$notify.success({
                duration: this.$constants.global.notifyDurationSuccess,
                title: 'Успешно',
                message: 'Локация сохранена',
              });
              this.reset();
              this.getChecklists();

            } else {

              this.$notify.error({
                duration: this.$constants.global.notifyDurationError,
                title: 'Ошибка',
                message: data.errors[0]
              });

            }

          })
          .finally(() => {
            this.loading = false;
          })
    },
    reset() {
      this.visibleChecklistDrawer = false;
      this.selectedChecklist = {};
    }


  }
}

</script>

<style lang="scss" scoped>

.checklists-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  overflow: hidden;

  @media (max-width: 1700px) {
    & {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  @media (max-width: 1500px) {
    & {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: 1200px) {
    & {
      grid-template-columns: 1fr 1fr;
    }
  }

  h4, h5 {
    color: #555;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 12px;
  }

  h5 {
    font-size: 10px;
    position: relative;

    i.el-icon-edit {
      position: absolute;
      right: 20px;
      font-size: 13px;
      top: -1px;
    }
  }

  .checklist {
    max-width: 450px;
    width: 100%;
    background: #fff;
    padding-bottom: 75px;
    position: relative;
    border-radius: 5px;
    user-select: none;
    border: solid 1px #8080802e;
    box-shadow: 2px 2px 7px 0 #8080802e;
    @media (max-width: 1700px) {
      & {
        max-width: 330px;
      }
    }
    @media (max-width: 1500px) {
      & {
        max-width: 400px;
      }
    }
    @media (max-width: 1200px) {
      & {
        max-width: unset;

        p {
          max-width: 480px !important;
        }
      }
    }

    .checklist-description {
      color: #555;
    }

    p {
      word-wrap: break-word;
      max-width: 320px;
      max-height: 200px;
      overflow: auto;
      margin: 0;
    }

    ::v-deep .el-tooltip {
      &__popper.is-dark {
        font-size: 40px;
        background: red;
      }

    }

    .item-header {
      padding: 10px 10px 5px;
      display: flex;
      justify-content: space-between;

      .btns-actions {
        display: flex;

        & > i {
          margin-right: 5px;
          height: 14px;
        }

        & > span {
          line-height: 1;
        }

        i {

          color: #8a8a8a;
        }

      }

      button {
        cursor: pointer;
        padding: 5px;
        border: none;
        background: none;

        i {
          color: #bababa;
        }
      }
    }

    .dictionaries {
      padding: 0 10px 25px 10px;

      ul {
        padding: 5px 10px 0 12px;
        max-height: 270px;
        overflow: auto;
        list-style: square;
        margin-bottom: 0;

        li {
          position: relative;
          display: flex;
          align-items: center;
          list-style: none;
          color: #8a8a8a;
          font-size: 11px;
          line-height: 2.4;
          margin-bottom: 5px;

          & > div {
            &:first-child {
              min-width: 35px;
            }

            &:last-child {
              line-height: 1.3;
            }
          }

        }
      }

      p {
        font-size: 11px;
        color: #8a8a8a;
        padding: 0 10px 0 0;

        span:first-child {
          font-weight: bold;
        }
      }
    }

    .footer-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #ececec;
    }

    .item-footer {
      position: relative;

      &__performed {
        display: flex;
        top: -24px;
        left: -10px;
        border-radius: 2px;
        font-size: 12px;
        text-align: center;
        height: 20px;
        justify-content: flex-end;

        > span {
          display: inline-block;
          height: 100%;
          line-height: 1.7;
          border-radius: 4px;
          width: 30px;
          margin-right: 5px;
          color: #fff;

          &:last-child {
            background: red;
          }

          &:first-child {
            background: green;
          }
        }
      }

      &__content {
        padding: 15px 10px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        ul {
          margin: 0;

          li {
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #8a8a8a;
          }
        }


      }
    }

    .client-span {
      color: red;
    }

    .colleague-span {
      color: #24387f;
    }
  }
}


.add {
  text-align: right;
  margin-bottom: 10px;
}

.top-block {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
  }
}

.search-block {
  margin-bottom: 20px;
}

.title h4{
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #555;
}
</style>
