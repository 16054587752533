<template>
  <el-row class="category-dictionaries-container">
    <el-row>
      <h4>
        Словари
        <i class="el-icon-edit" @click="edit()"></i>
      </h4>

    </el-row>
    <el-row class="dictionary-title">
      <el-col :span="9">Название</el-col>
      <el-col :span="3">Тип</el-col>
      <el-col :span="2">П/вес</el-col>
      <el-col :span="2">Н/вес</el-col>
      <el-col :span="2">Успех</el-col>
      <el-col :span="2">Отклонен</el-col>
      <el-col :span="3">Канал</el-col>
    </el-row>
    <el-row
        v-if="systemCategoryDictionaries.length"
        class="dictionary"
        v-for="dictionary in systemCategoryDictionaries"
        :key="dictionary.id"
    >
      <el-col :span="9">{{dictionary.dictionary.name}}</el-col>
      <el-col :span="3">Сист...</el-col>
      <el-col :span="2">{{dictionary.positive_weight}}</el-col>
      <el-col :span="2">{{dictionary.negative_weight}}</el-col>
      <el-col :span="2"><span :style="{background: dictionary.color_success}"></span></el-col>
      <el-col :span="2"><span :style="{background: dictionary.color_reject}"></span></el-col>
      <el-col :span="3">{{dictionary.channel}}</el-col>
    </el-row>
    <el-row
        class="dictionary"
        v-if="clientCategoryDictionaries.length"
        v-for="dictionary in clientCategoryDictionaries"
        :key="dictionary.id"
    >
      <el-col :span="9">{{dictionary.dictionary.name}}</el-col>
      <el-col :span="3">Польз...</el-col>
      <el-col :span="2">{{dictionary.positive_weight}}</el-col>
      <el-col :span="2">{{dictionary.negative_weight}}</el-col>
      <el-col :span="2"><span :style="{background: dictionary.color_success}"></span></el-col>
      <el-col :span="2"><span :style="{background: dictionary.color_reject}"></span></el-col>
      <el-col :span="3">{{dictionary.channel}}</el-col>
    </el-row>

    <el-drawer
        :visible.sync="dictionariesDrawer"
        direction="rtl"
        size="70%"
    >
      <category-dictionaries-transfer
          :category-id="this.$route.params.category_id"
          :selected-system-dictionaries="systemCategoryDictionaries"
          :selected-client-dictionaries="clientCategoryDictionaries"
          @reload="getCategoryDictionaries()"
      />
    </el-drawer>

  </el-row>


</template>

<script>
import CategoryDictionariesTransfer from "@/components/category/CategoryDictionariesTransfer.vue";

export default {
  name: "category-dictionaries",
  components: {CategoryDictionariesTransfer},
  props: {
    category: {}
  },
  data() {
    return {
      dictionariesDrawer: false,
      systemCategoryDictionaries:[],
      clientCategoryDictionaries: [],
      addDrawer: false
    }
  },
  mounted() {
    this.getCategoryDictionaries()
  },
  methods: {
    getCategoryDictionaries() {
      this.$api.category.getCategoryDictionaries({
        category_id: this.$route.params.category_id
      })
          .then(data => {
            this.systemCategoryDictionaries = data.system
            this.clientCategoryDictionaries = data.client
          })
          .finally(() => {
            this.loading = false;
          })
    },
    edit() {
      this.dictionariesDrawer = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.category-dictionaries-container {
  margin: 15px;
  max-height: 400px;
  overflow-y: auto;
  color: #252525;
  h4 {
    margin-bottom: 20px;
  }
}
.dictionary-title {
  > .el-col {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
    &:first-child{
      text-align: left;
    }
  }

}
.dictionary {
  > .el-col {
    text-align: center;
    &:first-child{
      text-align: left;
    }
    > span {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}
</style>