<template>
  <el-row class="horizontal-page-menu">
      <el-menu  mode="horizontal">
        <el-menu-item @click="add"><i class="el-icon-plus"></i> Добавить</el-menu-item>
      </el-menu>
  </el-row>



</template>

<script>


export default {
  name: "checklist-menu",
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {

  },
  computed: {



  },
  mounted() {

  },
  methods: {
    add() {
      this.$emit('add');
    }
  },
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.project-menu {
  margin: 0 0 32px 0;

  &__wrap {
    width: 100%;
    overflow: auto;
  }

  &__list {
    width: 100%;
    display: flex;
    border: 1px solid $--color-divider-2;
    border-radius: 6px;
  }

  &__item {
    position: relative;
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 12px 32px 12px 16px !important;
    margin-right: 0 !important;
    line-height: 1;

    &-name {
      margin: 0 0 4px;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5;
      color: $--color-text-regular-3;
    }

    &-status, &-date {
      font-size: 14px;
      line-height: 1.5;
      color: $--color-success;
    }

    &-date {
      margin-top: auto;
      color: $--color-text-regular-4;
    }

    &:hover {
      background-color:$--color-divider-2;
    }

    &.is-active {
      background-color:$--color-divider-2;

      .project-menu__item-name {
        color: $--color-text-regular;
      }
    }
  }

  @media only screen and (max-width: 1680px) {
    margin-bottom: 24px;


    &__list {

      .project-menu__item {
        padding: 8px !important;
        font-size: 12px;

        &-name {
          font-size: 12px;
        }

        &-status, &-date {
          font-size: 10px;
        }
      }
    }
  }
}

</style>
