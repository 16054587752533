<template>
  <div class="container" v-loading="loadDataCalls">
    <div class="result-info">
      <h2>Результат автоматической проверки</h2>
      <div class="result-info__container">
        <el-collapse v-if="checkLists.length" v-model="activeNames">
          <el-collapse-item name="check">
            <template slot="title">
              <div class="accord-title"><strong> Чек листов</strong>
                ({{ checkListsFormat.filter(item => item.relevant).length }})
              </div>
            </template>
            <my-slider slide-per-desk="2" v-loading="loadDataCalls" v-if="checkListsFormat.length">
              <swiper-slide :key="checkListItem.id"
                            v-for="checkListItem in checkListsFormat.filter(item => item.relevant)">
                <check-list-item
                    :checkListData="checkListItem"
                    :propsGetHighLightChecklist="getHighLightChecklist"
                    :propsCallReverse="callData.reverse"
                />
              </swiper-slide>
            </my-slider>
          </el-collapse-item>
        </el-collapse>
        <el-collapse v-model="activeNames" v-if="dictionariesSystem.length">
          <el-collapse-item name="system">
            <template slot="title">
              <div class="accord-title"><strong> Системных словарей</strong> ({{
                  dictionariesSystem.length
                }})
              </div>
            </template>
            <my-slider slide-per-desk="3" v-if="dictionariesSystem.length && timeOutSlider">
              <swiper-slide :key="dictInfo.id" v-for="dictInfo in dictionariesSystem">
                <dict-list-item
                    :propsGetHighlight="getHighlightDict"
                    :dictData="dictInfo"
                    :propsCallReverse="callData.reverse"
                    ref="dict"
                />
              </swiper-slide>
            </my-slider>


          </el-collapse-item>
        </el-collapse>
        <el-collapse v-if="dictionariesClient.length" v-model="activeNames">
          <el-collapse-item name="users">
            <template slot="title">
              <div class="accord-title"><strong> Пользовательских словарей</strong>
                ({{ dictionariesClient.length }})
              </div>
            </template>
            <my-slider slide-per-desk="3" v-if="dictionariesClient.length && timeOutSlider">
              <swiper-slide
                  :key="dictInfo.id"
                  v-for="dictInfo in dictionariesClient.length >3
                  ?dictionariesClient.slice(0,Math.ceil(dictionariesClient.length / 2))
                  :dictionariesClient"
              >
                <dict-list-item
                    :dictData="dictInfo"
                    ref="dict"
                    @click="getSelect1Dictionary($refs.dict)"
                    :propsGetHighlight="getHighlightDict"
                    :propsCallReverse="callData.reverse"
                />
              </swiper-slide>
            </my-slider>
            <my-slider slide-per-desk="3" v-if="dictionariesClient.length>3 && timeOutSlider">
              <swiper-slide
                  :key="dictInfo.id"
                  v-for="dictInfo in dictionariesClient.slice(Math.ceil(dictionariesClient.length / 2))"
              >
                <dict-list-item
                    :dictData="dictInfo"
                    ref="dict"
                    @click="getSelectDictionary($refs.dict)"
                    :propsGetHighlight="getHighlightDict"
                    :propsCallReverse="callData.reverse"
                />
              </swiper-slide>
            </my-slider>

          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="call-info">
      <h2>Информация о разговоре</h2>
      <a class="button_go_link"
         target="_blank"
         :href="callData.external_link"
         v-if="callData.external_link"
         >Перейти по ссылке</a>
      <div class="call-info__container">
        <el-collapse v-model="activeNames">
          <el-collapse-item title="ПОДРОБНОСТИ" name="1">
            <div class="info-block-greed">
              <div>

                <div v-if="callData.call"><strong>ID звонка:</strong> {{callData.call.call_id_user}}</div>
<!--                <div><strong>ID звонка:</strong> {{callData.call.call_id_user ? callData.call.call_id_user : ''}}</div>-->
                <div><strong>Категория:</strong> {{ categoryName ? categoryName : 'не заполнено' }}
                </div>
                <div
                    v-if="callData.call && callData.call.call_type === 'in'">
                  <strong>Тип:</strong> Входящий
                </div>
                <div
                    v-else-if="callData.call  && callData.call.call_type === 'out'">
                  <strong>Тип:</strong> Исходящий
                </div>
                <div
                    v-else>
                  <strong>Тип:</strong> Не определен
                </div>


                <div v-if="dateCall"><strong>Дата разговора:</strong>{{actualCallDate}}
                </div>

                <div><strong>Ожидание разговора</strong>
                  {{ callData.wait_time ? callData.wait_time : 'не заполнено' }}
                </div>
                <div><strong>Продолжительность разговора</strong>
                  {{ callData.ring_time ? callData.ring_time : 'не заполнено' }}
                </div>
              </div>
              <div>
                <div><strong>Телефон клиента:</strong> {{ clientNumber }}</div>
                <div><strong>Имя Клиента:</strong> {{ clientName }}</div>
                <br>
                <div><strong>Номер оператора:</strong>
                  {{ callData.extension_number ? callData.extension_number : 'не заполнено' }}
                </div>
                <div><strong>Имя оператора:</strong>
                  {{ callData.agent_name ? callData.agent_name : 'не заполнено' }}
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <my-custom-audio-player-test
            :stateDrawer="stateDrawer"
            :chosenWordRecognized="chosenWordRecognized?chosenWordRecognized:''"
            :getCurrentTimeAudio="setTheTimeOfThePlayingFile"
            :audioPath="callData['file_path']"
            :audioName="callData['file_name']"
        />


        <recognized-call-text
            ref="highLite"
            v-if="jsonText"
            :allText="jsonText"
            :propsCallReverse="callData.reverse"
            :currentTimeAudio="currentTimeAudioFile"
            @clickToWords="setRecognizedSelectWord"
        />
      </div>
    </div>
  </div>
</template>

<script>

import MySlider from "@/components/UI/MySlider.vue";
import {SwiperSlide} from 'swiper-vue2'
import CheckListItem from "@/components/newCatigories/checkListItem.vue";
import DictListItem from "@/components/newCatigories/dictListItem.vue";
import MyCustomAudioPlayer from "@/components/UI/MyCustomAudioPlayer.vue";
import RecognizedCallText from "@/components/newCatigories/recognized-call-text.vue";
import MyCustomAudioPlayerTest from "@/components/UI/MyCustomAudioPlayer_test.vue";
import VueHtml2pdf from 'vue-html2pdf'
import login from "@/views/auth/Login.vue";

export default {
  name: "drawer-call-info",
  components: {
    VueHtml2pdf,
    MyCustomAudioPlayerTest,
    RecognizedCallText, MyCustomAudioPlayer, DictListItem, CheckListItem, MySlider, SwiperSlide
  },
  props: {
    dateCall: {
      type: String
    },
    callId: {
      required: true
    },
    stateDrawer: {
      type: Boolean
    },
    categoryName: {
      type: String
    },
    clientNumber: {
      type: String
    },
    clientName: {
      type: String
    },
    period: {
      type: Object
    }
  },
  data() {
    return {
      activeNames: ['1', 'check', 'users', 'system'],
      callData: {
        // call: {
        // call_type: null
        // }
      },
      dictionariesClient: [],
      dictionariesSystem: [],
      checkLists: [],
      loadDataCalls: true,
      jsonText: null,
      currentTimeAudioFile: 0,
      chosenWordRecognized: 0,
      selectedDictionary: null,
      timeOutSlider: false,
      search_in_channel: {
        one: 'search_text_highlight_one',
        two: 'search_text_highlight_two',
        full: 'search_text_highlight_full'
      }
    }
  },

  mounted() {
    this.getCategoryCall()

  },
  watch: {
    callId() {
      this.callData = [];
      this.checkLists = []
      this.dictionariesSystem = []
      this.dictionariesClient = []
      this.getCategoryCall()
    }
  },
  computed: {
    getDateForPdf() {
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const year = currentDate.getFullYear();
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');

      return `${day}.${month}.${year} в ${hours}:${minutes}`
    },
    checkListsFormat() {
      let newDict = [...this.checkLists]

      let newFilteredData = []

      newDict.forEach((checkList) => {
        let successDictArr = []
        let negativeDictArr = []
        const allDictInCheckList = checkList.checklist.dictionaries
        for (let obj in allDictInCheckList) {
          allDictInCheckList[obj].forEach(item => {

            if (this.isSuccessDictionary(item.dictionary)) {
              successDictArr.push(item)
            } else {
              negativeDictArr.push(item)
            }
          })
        }
        const totalCountDict = successDictArr.length + negativeDictArr.length
        let completionRate = Math.round((100 * successDictArr.length) / totalCountDict)


        newFilteredData.push({
          ...checkList,
          successDictArr: successDictArr,
          negativeDictArr: negativeDictArr,
          completionRate: totalCountDict ? completionRate : 0,
          totalCountDict: totalCountDict
        })
      })
      return newFilteredData

    },

    actualCallDate() {
      if (this.dateCall && this.dateCall!=='--') {
        return ' '+ this.dateCall
      }else{
        if (this.callData['call']['date_call']){
          return ' '+ this.callData['call']['date_call']
        }else{
          return ' не заполнено'
        }
      }
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    resetAllSelectHighLite() {
      const allTextItems = [...document.querySelectorAll('.recognized-text li i')].filter((item) => item.attributes['data-high-lite']);
      allTextItems.forEach(el => {
        el.classList.remove('highlight')
      })
    },
    getHighLightChecklist(data) {
      this.resetAllSelectHighLite()
      if (data) {
        if (data['search_text_highlight_one']) {
          let highLightTextArr = []
          data['search_text_highlight_one'].forEach(el => {
            el.forEach(el2 => {
              const formatEl = el2.trim().replaceAll('<b>', '').replaceAll('</b>', '').replaceAll('  ', ' ')
              highLightTextArr.push(formatEl)
            })
          })
          this.showHighLiteToRecognitionText({
            data: highLightTextArr,
            target: this.callData.reverse ? 'client' : 'employee',
          })
        }
        if (data['search_text_highlight_two']) {
          let highLightTextArr = []
          data['search_text_highlight_two'].forEach(el => {
            el.forEach(el2 => {
              const formatEl = el2.trim().replaceAll('<b>', '').replaceAll('</b>', '').replaceAll('  ', ' ')
              highLightTextArr.push(formatEl)
            })
          })
          this.showHighLiteToRecognitionText({
            data: highLightTextArr,
            target: this.callData.reverse ? 'employee' : 'client',
          })
        }
      }
    },
    getHighlightDict(data) {

      this.resetAllSelectHighLite()
      let highLightTextArr = []
      data.data.forEach(el => {

        const formatEl = el.trim().replaceAll('<b>', '').replaceAll('</b>', '').replaceAll('  ', ' ')
        highLightTextArr.push(formatEl)
      })

      this.showHighLiteToRecognitionText({data: highLightTextArr, target: data.target})
    },
    showHighLiteToRecognitionText(requests) {

      let allTextItems = [...document.querySelectorAll('.recognized-text li i')].filter((item) =>
          item.attributes['data-high-lite'] && item.attributes['data-target'].value === requests.target);
      if (!allTextItems.length) {
        allTextItems = [...document.querySelectorAll('.recognized-text li i')].filter((item) =>
            item.attributes['data-high-lite'] && item.attributes['data-target'].value === 'client');
      }

      let words = ''
      allTextItems.forEach(el => {
        words += ' ' + el.innerText
      })
      requests.data.forEach((request, indexR) => {
        let result = []
        const requestArr = request.split(' ')
        allTextItems.forEach((el, indexAll) => {
          if (result.length !== requestArr.length) {
            if (el.innerText.trim() === requestArr[0]) {

              requestArr.forEach((text, index2) => {
                if (text == allTextItems[indexAll + index2].innerText.trim()) {
                  result.push(allTextItems[indexAll + index2])
                } else {
                  result = []
                }
              })
            }
          }
        })
        result.forEach((el, index) => {
          el.classList.add('highlight')
        })
        if (indexR === 0) {
          if (result[indexR]) {
            result[indexR].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
          }
        }
      })


    },
    getCategoryCall() {
      this.loadDataCalls = true
      this.$api.category.getCategoryCall({
        call_id: this.callId,
        date_start: this.period.start,
        date_end: this.period.end
      })
          .then((data) => {

            this.callData = data;
            this.checkLists = data.checklists_search
            this.dictionariesSystem = data.dictionaries_search.system
            this.dictionariesClient = data.dictionaries_search.client
            this.jsonText = data.json?.response?.chunks
            this.loadDataCalls = false;

            setTimeout(() => {
              this.timeOutSlider = true
            }, 1500)

          })
    },
    setTheTimeOfThePlayingFile(time) {
      this.currentTimeAudioFile = time
    },
    setRecognizedSelectWord(target) {
      if (target.attributes['data-time']) {
        this.chosenWordRecognized = +target.attributes['data-time'].value
      }

    },
    getSelectDictionary() {

    },
    isSuccessDictionary(dictionary) {

      let search_in_channel = this.getChannelForCheck(dictionary);

      if (!search_in_channel) {
        return false;
      }

      return this.getSuccessDictionaryByChannel(dictionary, search_in_channel);

    },

    getChannelForCheck(dictionary) {
      switch (true) {
        case dictionary.search_in_channel_1 === 1 && dictionary.search_in_channel_2 === 1:
          return this.search_in_channel.full;
        case dictionary.search_in_channel_1 === 1 && dictionary.search_in_channel_2 === 0:
          return this.search_in_channel.one;
        case dictionary.search_in_channel_1 === 0 && dictionary.search_in_channel_2 === 1:
          return this.search_in_channel.two;
      }
      return false;
    },

    getSuccessDictionaryByChannel(dictionary, channel) {

      if ((dictionary.found.length && dictionary.found[0] && !dictionary.reverse) ||
          (!dictionary.found.length && dictionary.reverse)) {
        return true
      }
      return false;
    }


  },

}
</script>

<style lang="scss" scoped>
//.pdf-content {
//  page-break-inside: avoid;
//}
//
//.pdf-content * {
//  page-break-inside: avoid;
//}
.container {
  display: flex;
  width: 100%;

  h2 {
    text-align: center;
    color: #555;
    margin-bottom: 5px;
  }

  .result-info {
    border-right: solid 1px #cccccc;
    padding: 20px;
    width: 50%;

    ::v-deep .el-collapse {
      border-top: none;
    }
  }

  .call-info {
    padding: 20px;
    width: 50%;

    ::v-deep .el-collapse-item__header {
      color: #555;
      font-weight: bold;
    }

    ::v-deep .el-collapse {
      border-top: none;
    }

    ::v-deep .el-collapse-item__wrap {
      border-bottom: none !important;
    }

    &__container {
      .info-block-greed {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        font-size: 11px;

      }

      div {
        color: #555;

        strong {
          color: #555 !important;
        }
      }
    }
  }

  .accord-title {
    strong {
      color: #555;
    }
  }
}

@media (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column;

    .result-info {
      width: 100%;
    }

    .call-info {
      order: -1111;
      width: 100%;
    }
  }
}

.pdf-content {
  width: 100%;

  .separator {
    margin: 10px 0;
    border: solid 1px #b5b5b5;
  }

  p {
    color: black;
  }

  .pdf-content-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__left {
      justify-self: flex-start;
      width: 100%;

      img {
        max-width: 300px;
        width: 100%;
      }

      div {
        margin-left: 0px;
        max-width: 350px;
        width: 100%;

        p {
          text-align: center;
          width: 100%;

          &:first-child {
            text-align: center;
            margin-bottom: 10px;
          }
        }
      }
    }

    &__right {
      width: 100%;

      .call-info {
        margin-top: 30px;
        width: 100%;

        .info-block-greed {
          display: flex;
          gap: 30px;

          .first {
            min-width: 300px;
          }

          .second {
            min-width: 350px;
          }

        }
      }
    }
  }

  .dataRow {
    display: flex;

  }
}

.pdfCheckListItem {
  max-width: 350px !important;
  overflow: hidden;
}

.countCheck {
  margin-bottom: 10px;
}
.button_go_link{
  background: #ececec;
  padding: 2px 7px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 10px;
  border: none;
  font-size: 10px;
  color: #555;
  &:hover{
    color: #2b51b9;
  }
}

</style>
