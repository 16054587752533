<template>
  <el-steps :active="propsStepState" align-center >
    <el-step title="Шаг 1" description=""></el-step>
    <el-step title="Шаг 2" description=""></el-step>
    <el-step title="Шаг 3" description=""></el-step>
<!--    <el-step title="Шаг 4" description=""></el-step>-->
<!--    <el-step title="Шаг 5" description=""></el-step>-->
  </el-steps>
</template>

<script>
export default {
  name: "my-steps",
  props:{
    propsStepState:{
      type:Number,
      default:1
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-steps{
    font-family: inherit !important;
    font-weight: normal;
  }
  .el-step__title{
    font-family: inherit !important;
    font-size: 12px ;
    line-height: 3;
    font-weight: bold;
  }
}

</style>