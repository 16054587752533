<template>
  <div :key="keyReload">
    <div class="my-player">
      <div class="controls">
        <div class="controls__play">
          <i v-if="!play" @click="togglePlayHandler" class="el-icon-caret-right"></i>
          <i v-else class="el-player-stop" @click="togglePlayHandler"></i>
        </div>
        <div class="separator"></div>
        <div class="controls__stop">
          <i class="el-player-sqrt" @click="restartAudioAndStop"></i>
        </div>
      </div>
      <div class="timeline" ref="timeline" @click="timelineHandler">
        <div class="timeline__progress" :style="{ width: timeLineWidth }">
            <span class="dot">
              <i></i>
            </span>
        </div>
      </div>
      <div class="time">
        <div class="current">{{ audioCurrentTime }}</div>
        <div class="divider">/</div>
        <div class="length">{{ audioLength }}</div>
      </div>

      <div class="volume">
        <img v-if="muted" src="@/assets/img/volume-off.svg" alt="" @click="mutedAudio">
        <img v-else src="@/assets/img/volume.svg" alt="" @click="mutedAudio">
        <div v-if="!muted" class="volume__slide" ref="volumeSlider" @click="changeVolumeHandler($event)">
          <div class="volume-progress" :style="{width: volumePercentage}">
             <span class="dot">
              <i></i>
            </span>
          </div>
        </div>
      </div>
      <div class="separator"></div>
      <div class="speed">
        <el-select v-model="speedValue" placeholder="Select">
          <el-option
              v-for="item in optionSpeed"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="separator"></div>
      <div class="download">
        <a download="true" :href="$constants.audio_url + audioPath" target="_blank">
          <i class="el-icon-download"></i>
        </a>
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "my-custom-audio-player",
  props: {
    audioName: {
      type: String,
      default: 'Загружаю...'
    },
    audioPath: {
      type: String,
    },
    getCurrentTimeAudio: {},
    chosenWordRecognized: {
      type: [Number, String]
    },
    stateDrawer: {
      type: Boolean
    }
  },
  data() {
    return {
      speedValue: 1,
      optionSpeed: [
        {value: 0.5, label: '0.5x'}, {value: 1, label: "1x"}, {value: 1.5, label: "1.5x"}, {
          value: 2,
          label: "2x"
        }, {value: 2.5, label: "2.5x"}
      ],
      audio: null,
      audioLength: null,
      volumePercentage: null,
      timeLineWidth: '0',
      audioCurrentTime: '00:00',
      loadFile: '',
      muted: false,
      play: false,
      volumeToMute: 0,
      noAudioFile: false,
      keyReload:0,
    }
  },
  methods: {
    playAudio() {
      this.audio.addEventListener('timeupdate', this.setTimeLineProgressAndCurrentTime)
      this.audio.play()
      this.play = true
      this.endAudio()

    },
    stopAudio() {
      this.audio.pause()
      this.play = false
      this.audio.removeEventListener('timeupdate', this.setTimeLineProgressAndCurrentTime)
    },
    mutedAudio() {
      if (!this.muted) {
        this.volumeToMute = this.audio.volume
        this.audio.volume = 0
      } else {
        this.audio.volume = this.volumeToMute
      }
      this.muted = !this.muted
    },

    restartAudioAndStop() {
      this.audio.pause()
      this.sendCurrentTimeToParentNode(0)
      this.play = false
      this.audio.currentTime = 0
      this.timeLineWidth = 0
      this.audio.removeEventListener('timeupdate', this.setTimeLineProgressAndCurrentTime)
    },

    endAudio() {
      this.audio.addEventListener('ended', () => {
        this.sendCurrentTimeToParentNode(0)
        this.play = false
        this.audio.removeEventListener('timeupdate', this.setTimeLineProgressAndCurrentTime)
      })
    },


    setTimeLineProgressAndCurrentTime() {
      this.timeLineWidth = this.audio.currentTime / this.audio.duration * 100 + "%";
      this.audioCurrentTime = this.getTimeCodeFromNum(this.audio.currentTime);
      this.sendCurrentTimeToParentNode(this.audio.currentTime)
    },

    sendCurrentTimeToParentNode(time) {
      this.getCurrentTimeAudio(time)
    },

    getTimeCodeFromNum(num) {
      let seconds = parseInt(num);
      let minutes = parseInt(String(seconds / 60));
      seconds -= minutes * 60;
      const hours = parseInt(String(minutes / 60));
      minutes -= hours * 60;
      if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
      return `${String(hours).padStart(2, 0)}:${minutes}:${String(
          seconds % 60
      ).padStart(2, 0)}`;
    },
    changeVolumeHandler(e) {
      let volume = e.offsetX / this.$refs.volumeSlider.clientWidth
      this.audio.volume = volume <= 1 ? volume : 1
      this.volumePercentage = e.offsetX * 1 + 'px';
    },
    setTimeToAudioPlayerByWord(e) {
      let time = parseFloat(e.target.getAttribute('data-time-start'));
      this.getCurrentTextByTime(time)
    },
    timelineHandler(e) {

      this.audio.currentTime = e.offsetX / parseInt(this.$refs.timeline.clientWidth) * this.audio.duration;
    },
    togglePlayHandler() {

      (this.audio.paused && this.audio.playbackRate > 0) ? this.playAudio() : this.stopAudio()
    },
    downloadFile() {
      axios.get(this.$constants.audio_url + this.audioPath).then()
    }

  },
  mounted(){

  },
  watch: {
    audioPath() {
      if (this.audioPath) {
        this.audio = new Audio(this.$constants.audio_url + this.audioPath)
        this.audio.onerror = () => {
          this.noAudioFile = true
        }
        this.audio.onloadeddata = () => {
          setTimeout(()=>{
            this.audioLength = this.getTimeCodeFromNum(this.audio.duration)
          },400)

        }
      }
      this.keyReload++
    },
    chosenWordRecognized() {
      this.audio.currentTime = this.chosenWordRecognized
    },
    stateDrawer() {
      this.stopAudio()
      this.timeLineWidth = 0
    },
    speedValue() {
      this.audio.playbackRate = this.speedValue
    }
  }
}
</script>

<style lang="scss" scoped>
$gray: #dadada;
$text: rgba(121, 118, 118, 0.98);
$controls: rgba(166, 165, 165, 0.98);
$background: #ececec;
$progress: #24387f;

.separator {
  height: 18px;
  width: 1px;
  background: rgba(187, 181, 181, 0.98);

}

.my-player {
  padding: 2px 10px;
  display: grid;
  border-radius: 8px;
  border: 1px solid #dadada;
  background: #fff;
  box-shadow: 0 0 4px 1px hsl(0deg 0% 83.5% / 6%);
  grid-template-columns: 65px 1fr 80px 30px 1px 30px 1px 30px;
  align-items: center;
  color: rgba(121,118,118,.98);
  font-size: 11px;
  gap: 7px;
  font-weight: 500;

  .controls {
    display: grid;
    grid-template-columns: 20px 1px 27px;
    gap: 5px;
    align-items: center;

    i {
      cursor: pointer;
      font-size: 17px;
      color: $controls;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__play {
      justify-self: center;
      align-self: center;
      width: 100%;

      i {
        font-size: 23px;
      }

      .el-player-stop {
        width: 100%;
        position: relative;
        display: flex;
        gap: 3px;

        &:before, &:after {
          content: '';
          width: 3px;
          height: 13px;
          bottom: 0;
          background: $controls;
        }
      }
    }

    &__stop {
      justify-self: center;
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $controls;

      .el-player-sqrt {
        width: 14px;
        height: 14px;
        border: solid 2px $controls;
      }
    }

  }

  .timeline {
    cursor: pointer;
    width: 100%;
    height: 4px;
    background: #fff;
    display: flex;
    align-self: center;
    border-radius: 3px;
    box-shadow: inset 4px 4px 4px 0px #afafaf;

    &__progress {
      transition: .3s;
      width: 0%;
      height: 4px;
      background: $progress;
      position: relative;

      .dot {
        width: 14px;
        height: 14px;
        background: #fff;
        border-radius: 50%;
        justify-self: flex-end;
        align-self: center;
        position: absolute;
        right: -7px;
        top: calc(50% - 7px);
        box-shadow: 0 0 2px $text;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: $progress;
        }
      }
    }
  }

  .time {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      align-self: center;
      justify-self: center;
      line-height: 0;
    }
  }

  .volume {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: .3s;

    &__slide {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
      position: absolute;
      height: 6px;
      width: 55px;
      background: #fff;
      border: solid 1px #b0afaf;
      bottom: 25px;
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      border-radius: 3px;
      box-shadow: inset 4px 4px 4px 0px #afafaf;
      transform: rotate(-90deg) translateX(30px);

      .volume-progress {
        width: 100%;
        height: 100%;
        background: $progress;
        position: relative;
        border-radius: 3px;

        .dot {
          width: 14px;
          height: 14px;
          background: #fff;
          border-radius: 50%;
          justify-self: flex-end;
          align-self: center;
          position: absolute;
          right: -5px;
          top: -5px;
          box-shadow: 0 0 2px #000;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: $progress;
          }
        }
      }
    }

    &:hover {
      transition: .3s;

      .volume__slide {
        opacity: 1;
        transition: .3s;
        visibility: visible;
      }
    }


    img {
      cursor: pointer;
      align-self: center;
      justify-self: center;
      width: 18px;
    }
  }

  .speed {
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .el-select {
      background: inherit;
      width: 100%;

      .el-input__suffix {
        display: none;
      }

      input {
        font-weight: 500;
        width: 100%;
        border: none;
        background: inherit;
        color: $text;
        padding: 0;
        text-align: center;
        font-family: inherit;
      }
    }
  }

  .download {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $controls;

      i {
        align-self: center;
        font-size: 20px;
        line-height: 0;
        font-weight: 500;
      }
    }
  }
}

.error-file {
  margin-top: 40px;

  p {
    font-weight: bold;
    color: #555;
    text-align: center;
  }
}
</style>