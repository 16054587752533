import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        get(payload){
            return requestSender('get', 'location/', payload);
        },
        getAllWithAgents(payload){
            return requestSender('get', 'location/get-all-with-agents', payload);
        },
        getLocation(payload){
            return requestSender('get', 'location/get-location', payload);
        },
        save(payload){
            return requestSender('post', 'location/save', payload);
        },
        delete(payload){
            return requestSender('post', 'location/delete', payload);
        },

        getSubLocation(payload){
            return requestSender('get', 'location/get-sub-location', payload);
        },

        getSubLocations(payload){
            return requestSender('get', 'location/get-sub-locations', payload);
        },

        saveSubLocation(payload){
            return requestSender('post', 'location/save-sub-location', payload);
        },

        testManticore(payload){
            return requestSender('post', 'location/test-manticore', payload);
        },

    }
}