<template>
  <div class="vats-control">
    <div class="category-list">
      <div class="title">
        <h5>Категории</h5>
      </div>
      <div class="hr"></div>
      <div class="tree">
        <ul>
          <li v-for="location in this.treeData" :key="location.id">
            <span class="caret" @click="(e)=>toggleNode(e,`location_${location.id}`)"> {{ location.name }}</span>
            <ul class="subLocation-ul" :class="{'nested': true, 'active': activeNodes[`location_${location.id}`]}">
              <li v-for="subLocation in location['sub_locations']" :key="subLocation.id">
                <span class="caret"
                      @click="(e)=>toggleNode(e,`subLocation_${location.id}_${subLocation.id}`)">{{
                    subLocation.name
                  }}</span>
                <ul class="category-ul"
                    :class="{'nested': true, 'active': activeNodes[`subLocation_${location.id}_${subLocation.id}`]}">
                  <li
                      @click="()=>selectCategoryMethod({location:+location.id, subLocation:+subLocation.id, category:+category.id})"
                      class="categoryButton"
                      v-for="category in subLocation['categories']"
                      draggable="true"
                      :key="category.id"
                  >
                    {{ category.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="phones-list">
      <div class="title">
        <h5>Не назначеные номера телефонов </h5>
        <button
            type="button"
            :class="['reload-phones', loadsPhones ? 'active' : ''].join(' ')"
            @click="getPhones"
        >
          <i class="el-icon-refresh"></i>
        </button>
      </div>
      <div class="hr"></div>
      <div class="phones">
        <ul>
          <li v-for="phone in this.phonesList" :key="phone.id" draggable="true">{{ phone.phone_number }}</li>
        </ul>
      </div>
    </div>
    <div class="phoneNumbersInvolved">
      <div class="title">
        <h5 v-if="this.actualCategoryData">{{this.actualCategoryData.location}} - {{this.actualCategoryData.subLocation}} - {{this.actualCategoryData.category}} </h5>
      </div>
      <div v-if="this.actualCategoryData" class="hr"></div>
      <div v-if="this.actualCategoryData" class="category_phones_list">
        <ul>
          <li draggable="true" v-for="phone in this.actualCategoryData.phones">{{phone.phone_number}}</li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "vats-control",
  data() {
    return {
      actualCategoryData:null,
      selectedCategoryData: null,
      phonesList: null,
      loadsPhones: false,
      getCategoryWithPhoneData: false,
      activeNodes: {},
      treeData: null,
    };
  },
  mounted() {
    this.getCategory();
    this.getPhones()
    this.getCategoryWithPhone()
  },
  methods: {
    selectCategoryMethod(value) {
      const data = {
        location: this.getCategoryWithPhoneData.find(item => item.id === value.location)?.name,
        subLocation: this.getCategoryWithPhoneData.find(item => item.id === value.location)?.sub_locations
            .find(item => item.id === value.subLocation)?.name,
        category: this.getCategoryWithPhoneData.find(item => item.id === value.location)?.sub_locations
            .find(item => item.id === value.subLocation)?.categories
            .find(item => item.id === value.category)?.name,
        phones: this.getCategoryWithPhoneData.find(item => item.id === value.location)?.sub_locations
            .find(item => item.id === value.subLocation)?.categories
            .find(item => item.id === value.category)?.vats_phones
      };
      this.actualCategoryData=data
    },
    toggleNode(e, node) {
      e.target.classList.toggle('active')
      this.$set(this.activeNodes, node, !this.activeNodes[node]);
    },
    getPhones() {
      this.loadsPhones = true;
      this.$api.integration.getVATSPhones().then((data) => {
        this.phonesList = data
      }).finally(() => this.loadsPhones = false)
    },
    getCategoryWithPhone() {
      this.$api.integration.getCategoryWithPhone().then((data) => {
        this.getCategoryWithPhoneData = data
      })
    },
    getCategory() {
      this.$api.location.get().then((data) => {
        let activeNodes = {}
        data.forEach((location) => {
          activeNodes[`location_${location.id}`] = false
          location['sub_locations'].forEach((subLocation) => {
            activeNodes[`subLocation_${location.id}_${subLocation.id}`] = false
          })
        })
        this.activeNodes = activeNodes
        this.treeData = data

      });
    },

  },
};
</script>

<style scoped lang="scss">
.vats-control {
  min-height: 66vh;
  display: flex;
  justify-content: flex-start;
  gap: 20px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;


    h5 {
      margin-bottom: 0;
    }

    button {
      background: unset;
      border: none;
      padding: 0;

      &.active {
        pointer-events: none;

        i {
          color: #2b51b9;
          animation-name: example;
          animation-duration: 1s;
          animation-timing-function: linear;
          animation-iteration-count: infinite;
          transition: 0.3s;
          @keyframes example {
            from {
              transform: rotate(0);
            }
            to {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  .category-list {
    padding: 15px;
    flex-basis: 35%;
    border-radius: 5px;
    border: solid 1px #e4e7ed;


    .tree {
      .subLocation-ul {
        margin-left: 15px;
      }

      .category-ul {
        margin-left: 30px;
      }

      .categoryButton {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .phones-list {
    padding: 15px;
    flex-basis: 25%;
    border-radius: 5px;
    border: solid 1px #e4e7ed;

    .phones {

      ul {
        margin-bottom: 0;
        max-height: 57vh;
        overflow: auto;
        display: grid;
        gap: 5px;
        grid-template-columns: 1fr 1fr;
        li{
          display: flex;
          align-items: center;
          padding: 5px 10px;
          border-radius: 5px;
          margin-right: 15px;
          line-height: 1;
          font-size: 12px;
          border: solid 1px #bebebe;
          font-weight: 500;
          justify-content: center;
        }
      }
    }
  }

  .phoneNumbersInvolved {
    padding: 15px;
    flex-basis: 35%;
    border-radius: 5px;
    border: solid 1px #e4e7ed;
    .category_phones_list{
      ul{
        max-height: 57vh;
        overflow: auto;
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        li{
          border: solid 1px #bebebe;
          border-radius: 5px;
          font-size: 12px;
          font-weight: 500;
          color:#000;
          line-height: 1;
          padding: 5px 10px;
        }
      }
    }

  }

  .hr {
    margin: 5px 0;
    width: 100%;
    height: 1px;
    background: #e4e7ed;
  }
}

.tree ul {
  list-style-type: none;
}

.tree ul,
.tree li {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.tree .caret {
  user-select: none;
}


.tree .caret {
  &::before {
    content: "\25B6";
    color: #24387f;
    display: inline-block;
    margin-right: 6px;
    transition: transform 0.3s;
  }

  &.active {
    &::before {
      transform: rotate(90deg);
    }
  }
}

.tree .caret-down::before {
  transform: rotate(90deg);
}

.tree .nested {
  display: none;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.tree .active {
  display: block;
  max-height: 700px; /* adjust as needed */
  transition: max-height 0.3s ease-in;

}
</style>
