<template>
  <div class="login">
    <el-card style="padding: 24px">
      <h2>Авторизация</h2>
      <el-form
          class="login-form"
          :model="model"
          :rules="rules"
          ref="form"
          @submit.native.prevent="login"
      >
        <el-form-item prop="login">
          <el-input v-model="model.login" placeholder="Логин" prefix-icon="fas fa-user"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              v-model="model.password"
              placeholder="Пароль"
              type="password"
              prefix-icon="fas fa-lock"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
              :loading="loading"
              class="login-button"
              type="primary"
              native-type="submit"
              block
          >Войти
          </el-button>
        </el-form-item>
      </el-form>

      <router-link
        :to="{ name: 'Restore'}"
      >
        Забыли пароль?
      </router-link>

    </el-card>
  </div>
</template>

<script>


export default {
  name: 'Login',
  components: {},

  data() {
    return {
      loading: false,

      model: {
        login: 'admin',
        password: 'admin',
      },
      rules: {},
    }
  },
  computed: {},

  mounted() {

  },
  methods: {
    login() {
      this.loading = true;
      this.$api.user.login({
        login: this.model.login,
        password: this.model.password,
      })
          .then(data => {
            localStorage.setItem('jwt', data.token);
            this.$router.push({path: 'new-locations'})
          })
          .finally(() => {
            this.loading = false;

          })
    },

  }
}

</script>

<style lang="scss" scoped>

.login {
  height: 90vh;
  position: relative;
  /*border: 3px solid green;*/
}

.el-card {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


/*.login {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

.login-button {
  width: 100%;
}

.login-form {
  width: 290px;
}

</style>
<style lang="scss">

.login .el-card {
  //margin-top:300px;
  width: 340px;
  //display: flex;
  justify-content: center;
}
</style>
