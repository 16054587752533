<template>
  <el-row v-loading="loading">
    <el-row>
      <el-row>
        <el-row>
          <h4>вл. Локации</h4>
          <i
              class="el-icon-circle-plus-outline"
              @click="showSubLocationForm()"
          ></i>
        </el-row>

        <h5>Выберите вл. Локацию:</h5>
        <el-row v-if="location.sub_locations.length">
          <el-row
              v-for="subLocation in location.sub_locations"
              :key="subLocation.id"
          >
            <el-row class="sub-location">
              <i
                  class="el-icon-edit"
                  @click="showSubLocationForm(subLocation.id)"
              ></i>
              <span
                  @click="showSubLocation(subLocation)"
              >
              {{ subLocation.name }}
            </span>
            </el-row>
          </el-row>
        </el-row>



        <el-drawer
            :visible.sync="visibleSubLocationFormDrawer"
            direction="rtl"
            size="40%"
        >
          <sub-location-form
              :selected-sub-location="selectedSubLocation"
              :location-id="location.id"
              @cancel="visibleSubLocationFormDrawer = false"
              @update="updateLocation"
          >
          </sub-location-form>
        </el-drawer>

      </el-row>
    </el-row>


    <template>
      <el-drawer
          :visible.sync="visibleSubLocationDrawer"
          direction="rtl"
          size="calc(100% - 110px)"
      >
        <sub-location
            :key="selectedSubLocation.id"
            :sub-location="selectedSubLocation"
        />
      </el-drawer>
    </template>
  </el-row>


</template>

<script>
import CircularProgressBar from "@/components/common/CircularProgressBar";
import SubLocationForm from "@/components/location/SubLocationForm";
import SubLocation from "@/components/location/SubLocation";
import { mapMutations } from 'vuex'
export default {
  name: "sub-locations",
  components: {SubLocationForm, SubLocation, CircularProgressBar},
  props: {
    location: {}
  },
  data() {
    return {
      loading: false,

      visibleSubLocationDrawer: false,
      visibleSubLocationFormDrawer: false,

      selectedSubLocation: {},
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    ...mapMutations(['updateLocation', 'updateSubLocation']),
    updateStoreVariables(obj) {
      this.updateLocation(obj.location)
      this.updateSubLocation(obj.subLocation)
    },

    showSubLocationForm(id = null) {
      if (id) {
        this.selectedSubLocation = this.location.sub_locations.find(subLocation => subLocation.id === id)
      } else {
        this.selectedSubLocation = {};
      }
      this.visibleSubLocationFormDrawer = true;
    },
    showSubLocation(subLocation) {
      this.selectedSubLocation = subLocation;
      this.visibleSubLocationDrawer = true;
    },
    save() {
      this.loading = true;
    },
    updateLocation(data) {
      this.selectedSubLocation = {}
      this.visibleSubLocationFormDrawer = false;
      this.$emit('update', data)
    }

  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/css/project-variables";
.v-modal {
  z-index: 9999!important;
}
.el-row {
  >h4, h5 {
    margin-bottom: 10px;
  }
  h5 {
    color: $--color-danger;
  }
  i.el-icon-circle-plus-outline, i.el-icon-refresh {
    position: absolute;
    top: 5px;
    right: 0;
    color: #b5b5b5;
    transition: all 0.1s ease-out;
    font-weight: bold;
    font-size: 16px;
    &:hover {
      transform: scale(1.1);
    }
  }
  i.el-icon-refresh {
      right: auto;
      left: -20px;
      top: -20px;
  }
  .sub-location {
    margin: 0 0 10px 0;
    span {
      cursor: pointer;
      color: $--color-text-primary;
    }
  }
  > .el-row {
    > p {
      color: #b5b5b5;
      margin-left: 15px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>