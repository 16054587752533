
<template>
  <div class="item" @click="showDictionaryForm($event,dictData.id)">
    <div class="item-header">
      <h4>{{ dictData.name }}</h4>

      <div  class="btns-actions">
        <el-popconfirm
            :title="'Редактирование словаря приведет к полному изменению этого словаря в системе. Продолжить?'"
            @confirm="edit(dictData.id)"
        >
          <i class="el-icon-edit" :data-stop="true" slot="reference"></i>
        </el-popconfirm>


        <el-popover
            placement="bottom"
            width="auto"
            trigger="click"
            content="this is content, this is content, this is content">
<!--          v-if="$store.getters.user.role.name === 'admin' || type !== 'system' || dictData.from_system_dictionary"-->
          <i
              class="el-icon-delete"
              slot="reference"
              :data-stop="true"
          ></i>
          <template stot="content">
            <div class="delete-buttons">
              <el-popconfirm
                  :title="'Вы уверны, что хотите удалить  словарь ' + dictData.name + '? Он удалится из из системы. Действие не обратимо '"
                  @confirm="deleteDictionary(dictData)"
              >
                <my-button class="first" slot="reference">Из системы</my-button>
              </el-popconfirm>

              <el-popconfirm
                  :title="'Вы уверны, что хотите удалить словарь \'' + dictData.name + '\' из группы?'"
                  @confirm="deleteDictionaryFromGroup(dictData)"
              >
                <my-button slot="reference">Из группы</my-button>
              </el-popconfirm>
            </div>




          </template>
        </el-popover>

      </div>

    </div>
    <div class="dict-description">
      <p><span>Oписание:</span></p>
      <ul>
        <li v-if="dictData.description" :key="index" v-for="(row,index) in descriptionArray(dictData.description)">{{ row.replaceAll('|',' | ') }}</li>
      </ul>


    </div>
    <div class="footer-wrapper">
      <div class="item-footer">
        <el-tooltip class="tool-tip" effect="dark" content="Цвет 'успешно' | Цвет 'отклонен'" placement="top">
          <div  class="item-footer__performed">
            <span v-if="dictData.positive_weight"
                  :style="{background:dictData.color_success}">+{{ dictData.positive_weight }}</span>
            <span  v-if="dictData.negative_weight"
                   :style="{background:dictData.color_reject}">-{{ dictData.negative_weight }}</span>
          </div>
        </el-tooltip>
        <div class="item-footer__content">
          <ul>
            <li  v-on:click.stop>
              <span>За текущий день:</span>
              <span v-loading="loading"> {{ day }}</span>
            </li>
            <li v-on:click.stop>
              <span>За текущий месяц:</span>
              <span v-loading="loading"> {{ month }}</span>
            </li>
            <li v-on:click.stop>
              <span>За все время:</span>
              <span v-loading="loading"> {{ all }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyButton from "@/components/UI/MyButton.vue";

export default {
  name: "group-dictionary-item",
  components: {MyButton},
  props: {
    dictData: {},
    type: String,
  },
  data() {
    return {
      loading: true,
      day: 0,
      month: 0,
      all: 0.
    }
  },
  mounted() {
    this.getDictionariesInformationByPeriods()
  },
  methods: {
    getDictionariesInformationByPeriods() {

      this.$api.dictionary.getDictionaryInformationByPeriods({
        id: this.dictData.id,
        type: this.type
      }).then(data => {
        this.loading = false;
        this.day = data.found_count_by_period.day;
        this.month = data.found_count_by_period.month;
        this.all = data.found_count_by_period.all;
      })

    },
    edit(id) {
      this.$emit('edit', {id: id, type: this.type});
    },
    showDictionaryForm(e, id) {
      if (!e.target.attributes['data-stop']) {
        this.$emit('show', {id: id, type: this.type});
      }
    },
    deleteFromGroup(dictionary) {
      this.type = dictionary.from_system_dictionary ? 'client' : this.type;
      this.$emit('delete', {
        id: dictionary.id,
        type: this.type
      });
    },
    deleteDictionary(dictionary) {
      this.type = dictionary.from_system_dictionary ? 'client' : this.type;
      this.$emit('delete', {
        id: dictionary.id,
        type: this.type
      });
    },
    deleteDictionaryFromGroup(dictionary) {
      this.$emit('delete-from-group', {
        id: dictionary.id,
        type: 'client'
      });
    },
    descriptionArray(description) {
      if(!description) {
        return []
      }
      return description.split('#');
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-loading-spinner {
  .circular {
    width: 15px;
    height: 15px;
    top: 14px !IMPORTANT;
    position: relative;
  }
}
::v-deep .el-loading-parent{
  &--relative {
    width: 20px;
  }
}
.item {
  max-width: 330px;
  background: #fff;
  padding-bottom: 75px;
  position: relative;
  border-radius: 5px;
  user-select: none;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0 #8080802e;
  @media (max-width: 1700px) {
    & {
      max-width: 330px;
    }
  }
  @media (max-width: 1500px) {
    & {
      max-width: 400px;
    }
  }
  @media (max-width: 1200px) {
    & {
      max-width: unset;

      p {
        max-width: 480px !important;
      }
    }
  }
  .first {
    margin: 0 5px;
  }

  p {
    word-wrap: break-word;
    max-width: 320px;
    max-height: 200px;
    overflow: auto;
    margin: 0;
  }

  ::v-deep .el-tooltip {
    &__popper.is-dark {
      font-size: 40px;
      background: red;
    }

  }

  .item-header {
    padding: 10px 10px 5px;
    display: flex;
    justify-content: space-between;

    .btns-actions {
      display: flex;

      & > i {
        margin-right: 5px;
        height: 14px;
      }

      & > span {
        line-height: 1;
      }

      i {

        color: #8a8a8a;
      }

    }

    h4 {
      margin: 0;
      color: #555;
      font-weight: 500;
      line-height: 1.3;
      font-size: 12px;
    }

    button {
      cursor: pointer;
      padding: 5px;
      border: none;
      background: none;
      i {
        color: #bababa;
      }
    }
  }

  .dict-description {
    padding: 0 10px;
    ul{

      padding: 5px 10px 0 12px;

      max-height: 200px;
      overflow: auto;
      list-style: square;
      li{

        word-wrap: break-word;
        list-style: square;
        color: #8a8a8a;
        font-size: 11px;
      }
    }

    p {
      font-size: 11px;
      color: #8a8a8a;
      padding: 0 10px 0 0;
      span:first-child {
        font-weight: bold;
      }
    }
  }

  .footer-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background: #ececec;
  }

  .item-footer {
    position: relative;


    &__performed {
      position: absolute;
      display: flex;
      top: -9px;
      right: 10px;
      border-radius: 2px;
      font-size: 12px;
      text-align: center;
      width: 85px;
      height: 20px;
      justify-content: flex-end;

      > span {
        display: inline-block;

        height: 100%;
        line-height: 1.7;
        border-radius: 4px;
        width: 80px;
        margin-right: 15px;

        color: #fff;

        &:last-child {
          background: red;
        }

        &:first-child {


          background: green;
        }
      }
    }

    &__content {
      padding: 15px 10px 10px;

      ul {
        margin: 0;

        li {
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #8a8a8a;
        }
      }


    }
  }

  .client-span {
    color: red;
  }

  .colleague-span {
    color: #24387f;
  }
}


</style>