<template>
  <el-row>
    <el-row>
      <template>
        <div style="margin-top: 30px;">
          <apexchart
              width="100%"
              height="350"
              type="bar"
              :options="chartOptions"
              :series="series"
          ></apexchart>
        </div>
      </template>
    </el-row>
    <el-row class="chart-data" v-if="detail">
      <div
          v-for="(datum, index) in series"
          class="chart-data__row"
          :style="{gridTemplateColumns: '105px repeat(' + dates.length + ', 1fr)'}"
      >
        <div><div :style="{backgroundColor: datum.color}"></div></div>
        <div
            v-for="(data, idx) in datum.data"
            :key="`${index}-${idx}-${data}`"
        >
            <span :style="{marginLeft: idx * 2.2 + '%'}">
              {{ data }}
            </span>
        </div>
      </div>

    </el-row>
  </el-row>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: "trend-chart",
  components: {VueApexCharts},
  props: {
    data: {},
    dates: {},
    detail: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      datum: {},
      series: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '40%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: '(количество)'
          }
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      },
    }
  },
  created() {
    this.chartOptions.xaxis.categories = this.dates
    this.series = this.data;
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .chart-data {
    &__row {
      display: grid;
      > div {
        font-size: 14px;
        margin-bottom: 10px;
        color: #b5b5b5;
        > div {
          width: 12px;
          height: 12px;
          text-align: right;
          position: relative;
          right: -60px;
          top: 5px;
        }
      }
    }
  }
</style>