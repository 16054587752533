<template>
  <el-popover
      popper-class="notifications"
      placement="bottom-end"
      trigger="click"
      @show="loadNotifications(true)"
      width="448"
      :visible-arrow="false"
  >
    <el-badge
        class="notification-counter"
        :hidden="newNotificationsCount == 0"
        slot="reference"
        style="font-size:20px;
               margin-top:10px;
               margin-right:20px;"
        v-model="newNotificationsCount"

        :value="newNotificationsCount"
        type="primary"
    >

      <el-row class="header__account-bell">
        <img src="@/assets/img/icons/bell.svg" alt="Bell" class="img-responsive">
      </el-row>
    </el-badge>

    <el-row
        type="flex"
        justify="space-between"
        class="notifications__header"
    >
      <span v-if="onlyNew">У вас {{ newNotificationsCount }} новых уведомлений</span>
      <label>
        <el-switch v-model="onlyNew" @change="loadNotifications(true)"></el-switch>
        <span class="only-new-notifications">Непрочитанные</span>
      </label>
    </el-row>

    <ul
        class="notification-list"
        v-infinite-scroll="loadNotifications"
        :infinite-scroll-distance="0"
        :infinite-scroll-disabled="loading"
    >
      <li
          v-model="notifications"
          v-for="notification in notifications"
          class="notification-list-item"
          :class="{ seen: notification.seen }"
      >
        <span
            class="notification-list-item__date">{{ new Date(notification.created_at * 1000).toLocaleString() }}</span>
        <span class="notification-list-item__text" v-html="notification.text"></span>
        <el-button
            v-if="notification.seen"
            type="text"
            size="small"
            icon="far fa-envelope-open"
            class="notification-list-item__btn"
            @click="setSeen(notification, 0)"
        >
          Пометить непрочитанным
        </el-button>
        <el-button
            v-else
            type="text"
            size="small"
            icon="far fa-envelope"
            class="notification-list-item__btn"
            @click="setSeen(notification, 1)"
        >
          Прочитать
        </el-button>
      </li>
    </ul>

    <div
        v-show="!notifications.length"
        align="center"
        style="padding: 12px;"
    >
      Нет уведомлений
    </div>

    <div align="center">
      <el-button
          v-show="loading"
          disabled
          type="text"
          size="mini"
          :loading="loading"
      >Загружаю
      </el-button>
    </div>
    <div
        v-show="notifications.length"
        class="notifications__footer"
    >
      <el-button
          type="text"
          icon="far fa-eye"
          @click="markAllRead"
      >
        Прочитать все
      </el-button>
    </div>


  </el-popover>
</template>

<script>

import Paginator from "../Paginator";
import {paginator} from "../../mixins/paginator";

export default {
  components: {Paginator},
  name: "Notifications",
  mixins: [paginator],
  watch: {},
  data() {
    return {
      loading: false,
      markAllLoading: false,
      onlyNew: true,
      newNotificationsCount: 0,
      notifications: [],
    }
  },
  mounted() {
    this.getCounter();
  },
  computed: {},
  methods: {
    getCounter() {
      this.$api.notification.getNewNotificationsCount()
          .then(data => {
            // this.newNotificationsCount = parseInt(data.count);
            this.newNotificationsCount = 0;
          }).finally(() => {
        // setTimeout(() => {this.getCounter()}, 20000)
      })
    },
    setSeen(notification, seen) {
      this.loading = true;
      this.$api.notification.setSeen({
        id: notification.id,
        seen: seen
      })
          .then((data) => {
            notification.seen = seen;
            this.$notify.success({
              duration: this.$constants.global.notifyDurationSuccess,
              title: 'Изменено',
              message: 'Статус просмотра изменен'
            });
          })
          .finally(() => {
            this.loading = false;
            this.getCounter();
          })
    },
    loadNotifications(reset = false) {
      this.loading = true;
      if (reset) {
        this.notifications = [];
        this.paginator.page = 1;
      }

      if (!this.paginator.pageCount || this.paginator.page <= this.paginator.pageCount) {
        this.$api.notification.search({
          seen: this.onlyNew ? 1 : 0,
          [this.paginator.param]: this.paginator.page,
          [this.paginator.paramPerPage]: this.paginator.perPage
        })
            .then(({data, paginator}) => {
              this.notifications = this.notifications.concat(data.notifications);
              this.paginatorFill(paginator);
              this.paginator.page++;

            })
            .finally(() => {
              this.loading = false;
              this.getCounter();
            })
      } else {
        this.loading = false;
      }
    },
    markAllRead() {
      this.markAllLoading = true;
      this.$api.notification.markAllRead()
          .then(data => {
            this.newNotificationsCount = 0;
            this.notifications.forEach(notify => {
              notify.seen = 1;
            })
            this.$notify.success({
              duration: this.$constants.global.notifyDuration,
              title: 'Изменено',
              message: 'Все новые уведомления просмотрены'
            });
          })
          .finally(() => {
            this.markAllLoading = false;
          })
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/css/project-variables";

.header__account-bell {
  position: relative;
  margin-right: 0;
}

.notifications {
  padding: 0;
  border-right: 5px;
  box-shadow: 1px 0 30px 7px rgba(20, 33, 64, .06);

  &__header {
    padding: 24px 24px 16px;
    border-bottom: 1px solid $--color-divider;

    > span {
      font-size: 12px;
      padding-top: 3px;
    }

    > label {
      display: flex;
      align-items: center;
      margin-left: auto;

      .el-switch {
        line-height: 1.5;
        margin-right: 8px;

        + span {
          font-size: 14px;
          color: $--color-text-regular-4;
        }

        &.is-checked {

          + span {
            color: $--color-text-regular;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 20px 24px 16px;
    border-top: 1px solid $--color-divider;
  }

  @media only screen and (max-width: 1480px) {

    &__header,
    &__footer {
      padding: 12px 16px;
    }
  }
}

.notification-counter {
  cursor: pointer;

  .el-badge__content {
    position: absolute;
    background-color: #E21A1A;
    height: 20px;
    line-height: 18px;
    font-size: 12px;
    top: 5px;
    margin-right: 16px;
  }
}

.notification-list {
  max-height: 400px;
  overflow: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;

  .notification-list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    border-bottom: 1px solid $--color-divider;
    cursor: pointer;
    transition: background-color .4s;

    &__date {
      margin-bottom: 4px;
      font-size: 12px;
      color: $--color-text-regular-4;
    }

    &__text {
      margin-bottom: 8px;
      text-align: left;
    }

    &__btn {

      i {
        font-size: 14px;
      }
    }

    &:hover {
      background-color: $--color-divider-4;
    }

    &:last-child {
      border-bottom: none;
    }

    &.seen {

      .notification-list-item__text {
        color: $--color-text-regular-4;
      }
    }
  }

  @media only screen and (max-width: 1480px) {

    .notification-list-item {
      padding: 12px 16px;

      &__text {
        font-size: 12px;
      }
    }
  }
}

</style>
